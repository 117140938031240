import React, {useState} from "react";
import compareDates from "../../../util/dev-ops/compareDates";
import {UserHandle, DateHandle} from "../../global/figma/SkeletonText";

function Card ({data}) {
	const [user, setUser] = useState();

	const setActivityColor =  (lastActivityAt) => {
		return Number(compareDates(lastActivityAt))
	}
	console.log(data)
	return (
		<div className="card card-aside pd-0">
			<a href={`https://figma.com/file/${data.key}/${data.name}`}  target="_blank"
			   className="card-aside-column  cover-image rounded-left-11"
			   data-image-src={data.thumb}
			   style={{background: `url(${data.thumb}) center center`}}></a>
			<div className="card-body">
				<h5 className="main-content-label tx-dark tx-medium mg-b-10">{data.name}</h5>
					<DateHandle data={data} />
				<div className="d-flex align-items-center pt-3 mt-auto">
					<div className={`mg-r-8 user-avatar user-avatar-${32} ${data.users ? '' : 'loading'}`}>
						{
							data.users ? <img alt="avatar" className="rounded-circle" src={data.users.img_url} /> : <></>
						}

					</div>
					<UserHandle data={data} />
				</div>
			</div>
		</div>


	)
}

export default Card
