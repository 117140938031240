import React, {useEffect} from 'react';
import compareDates from "../../../util/dev-ops/compareDates";

const setActivityColor =  (lastActivityAt) => {
	return Number(compareDates(lastActivityAt))
}

export const SkeletonTextStatus = ({ width, height, className }) => {
	return (
		<>
			<div className={`skeleton-text ${className}`} style={{ width, height }}></div>
		</>
	);
};

const SkeletonText = ({ width, height, className }) => {
	return (
		<>
			<div className={`skeleton-text ${className}`} style={{ width, height }}></div>
			<div className={`skeleton-text ${className}`} style={{ width, height }}></div>
		</>
	);
};

const SkeletonTextSingle = ({ width, height, className }) => {
	return (
		<>
			<div className={`skeleton-text ${className} `} style={{ width, height }}></div>
		</>
	);
};

export const UserHandle = ({ data }) => {
	const isLoading = !data.users;

	return (
		<div className="mg-l-8">
			{isLoading ? (
				<SkeletonText width="170px" height="16px" className="mb-1" />
			) : (
				<>
					<strong>{data.users.handle}</strong>
					<small className="d-block text-muted">{'user'}</small>
				</>
			)}
		</div>
	);
};

export const DateHandle = ({ data }) => {
	const isLoading = !data.users;

	return (
		<div>
			{isLoading ? (
				<SkeletonTextSingle width="227px" height="16px" className="mb-1" />
			) : (
				<div className={'d-flex align-items-center'}>
					<span style={{width:'15px', height:'15px', marginRight:'8px'}} data-placement="top" data-toggle="tooltip" title="Currently Worked On" className={setActivityColor(data.last_modified) <= 30 ? "circle badge badge-success active" : setActivityColor(data.last_modified) <= 60 ? "circle badge badge-warning" : "circle badge badge-danger active" }></span>
					<small>{new Date(data.last_modified).toLocaleDateString()}</small>
				</div>
			)}
		</div>
	);
};


export const DashNewsPicture = ({ loading, data, image }) => {
	const isLoading = !loading;
	const [theImage, setTheImage] = React.useState(null);

	useEffect(() => {
		if (!theImage) {
			setTheImage(image)
		}
	}, [image]);

	// console.log('THE IMAGE', theImage)
	if (!theImage) {
		return 	<SkeletonTextSingle width="180px" height="142px" className="d-flex rounded-lg" />
	} else {
		return (
			<a
				href={data.url}
				target={"_blank"}
				className="card-aside-column cover-image rounded-lg"
				data-image-src={theImage}
				style={{
					backgroundImage: theImage ? `url(${theImage})` : 'none',
					backgroundPosition: 'center center',
					backgroundSize: 'cover'  // Explicitly set the background-size
				}}
			/>
		);
	}

};

export const DashAuthorPicture = ({ loading, data, image }) => {
	const isLoading = !loading;
	const [theImage, setTheImage] = React.useState(null);
	useEffect(() => {
		if (!theImage) {
			setTheImage(image)
		}
	}, [image]);

	if (!theImage) {
		return 	<SkeletonTextSingle width="32px" height="32px" className="w-10 rounded-circle" />
	} else {
		return (
			<img
				src={theImage}
				className="rounded-circle"
				alt="avatar-img"
				style={{width: '40px', marginBottom: '10px', marginRight: '5px'}}
			/>
		);
	}

};

export const NewsPicture = ({ loading, data, image }) => {
	const isLoading = !loading;
	const [theImage, setTheImage] = React.useState(null);
	useEffect(() => {
		if (!theImage) {
			setTheImage(image)
		}
	}, [image]);

	if (!theImage) {
		return 	<SkeletonTextSingle width="294px" height="294px"/>
	} else {
		return (
			<img
				src={image}
				alt="avatar-img"
			/>
		);
	}

};