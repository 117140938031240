import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const signin = async (user) => {
  let response = await fetch(AUTH_BASE_URL + "auth/session", {
    method: "POST",
    body: JSON.stringify({data:user}),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json"
    }
  })
  // console.log("Util/session/signIn.js:", response)
  return response;
}
