import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef} from "react";

const actionContext = React.createContext()
function DropDownEllipsis (props) {
    const actionsRef = useRef(null)
    const handleActionsMenu = () => {
        if(actionsRef.current.classList.contains('show')){
            actionsRef.current.classList.remove('show')
        }  else{
            actionsRef.current.classList.add('show')
        }
    }

    return (
        <div id={props.page} className={'dropdown-container'} style={{position: 'relative', display: 'flex', justifyContent: 'flex-end'}}>
            <button type="button" className={'btn btn-outline-primary btn-rounded'} onClick={handleActionsMenu}>
                {props.label}
                <FontAwesomeIcon  icon={`fa-solid fa-ellipsis-vertical`} fontSize={20}/>
            </button>
            <div ref={actionsRef} className={`dropdown-menu more animated-menu actions-menu`} style={{right: '0'}}>
                <ul className={props.classOptions ? `${props.classOptions} `: ''} style={{textAlign: 'left'}} onClick={handleActionsMenu} ref={props.listElements}>
                    {props.children}
                </ul>
            </div>
        </div>
    )
}

export default DropDownEllipsis
