import React from "react";
import TableRow from "./TableRow";
import {connect} from "react-redux";

function Table (props) {
	console.log('dave')
	return (
		<table className="table card-table table-striped table-vcenter mb-0">
			<thead>
			<tr>
				<th className={'wd-lg-5p'}><span>Creator</span></th>
				<th className={'wd-lg-25p'}><span>Test Url</span></th>
				<th className={'wd-lg-15p'}><span>Repo</span></th>
				<th className={'wd-lg-20p'}><span>Instructor</span></th>
				<th className={'wd-lg-5p'}><span>Created</span></th>
				<th className={'wd-lg-5p'}><span>Active</span></th>
				<th className={'wd-lg-35p'}><span>Action</span></th>
			</tr>
			</thead>
			<tbody>
			{
				props.allClassTestData ? props.allClassTestData.map((test,index) => {
					console.log('all class tests', test)
					return (

						<TableRow  key={index} data={test} handleDuplicateModal={props.handleDuplicateModal} handleIntroductionTextModal={props.handleIntroductionTextModal} handleIntroductionModal={props.handleIntroductionModal} handleEditModal={props.handleEditModal} handleOpenCollectModal={props.handleOpenCollectModal} handlePreQuestionModal={props.handlePreQuestionModal} handlePostQuestionModal={props.handlePostQuestionModal}/>
					)
				}) : []
			}

			</tbody>
		</table>
	)
}
const mapStateToProps = ({allClassTestData}) => ({
	allClassTestData
});


export default connect(
	mapStateToProps
)(Table);
