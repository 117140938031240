import React from "react";
import TableRow from "./Row";
import TableHead from "./Head";
import {connect} from "react-redux";

/**
 * Description: Renders table header and row information
 * @param {*} data: An array of data that should be shown in the paginated form
 * @param {*} deleteUser: Function to delete user
 * @param {*} tableHeaders: The string array of table headers, to be displayed in the table
 * @param {*} tableFields: The string array of data fields to return in each table row,
 *  Note: tableFields should match the order of the tableHeaders
 * @param {*} type: Indicates the name of the data object used in TableRow to get the data
 *
 * @returns  Table and Row component
 */
const Controller = (props) => {
  const { data, deleteUser, tableHeaders, tableFields, type } = props;

  const renderTableHeaders = () => {
    return <TableHead tableHeaders={tableHeaders} />;
  };

  const renderTableRows = () => {
    if (data && data.length > 0) {
      return data?.map((user, index) => {
        return (
          <TableRow
            key={index}
            person={user}
            deleteUser={deleteUser}
            tableFields={tableFields}
            type={type}
          />
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={7}>Loading Users...</td>
        </tr>
      );
    }
  };

  return (
    <div id="table-wrapper">
      <div className="table-responsive userlist-table">
        <table className="table card-table table-striped table-vcenter text-nowrap mb-0 issues-table">
          {renderTableHeaders()}
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = ({ session }) => ({
  session,
});
export default connect(mapStateToProps)(Controller);
