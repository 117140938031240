import React from 'react';
import {connect} from "react-redux";
import TextArea from "../../global/inputs/TextArea";
import {useForm} from "react-hook-form";
import {createToDo} from "../../../util/todo/createToDo"


const NewToDo = props => {

    const {
        register,
        control,
        handleSubmit,
    } = useForm();

    const handleSave = async (data) => {

        let todo_data = {
            email: props.session.email,
            projectId: props.details.project._id,
            taskId: props.details._id,
            toDo: data?.toDo,
        }

        createToDo(todo_data).then(()=>{
            props.getDetails()
            props.close()
        })
    }

    return(
        <div className="modal d-block">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow">
                    <form onSubmit={handleSubmit(handleSave)}>
                    <div className="modal-header">
                        <span>{props.details.name}</span>
                        <h2 className="modal-title">Add To Do</h2>
                    </div>

                    <div className='detail-modal-body'>
                            <div className="form-group">
                                <TextArea
                                    label="To Do"
                                    name="toDo"
                                    register={register}
                                    placeholder="Write a single to do"
                                    required={true}
                                    card
                                />
                            </div>
                    </div>

                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="submit">Save</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.close}>Cancel</button>
                    </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewToDo);
