import React, {useEffect, useRef, useState} from 'react';
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import {connect} from "react-redux";
import {allUserData} from "../../actions/allUsers";
import {allPrototypes} from '../../actions/allPrototypes'
import {createProject} from "../../util/projects/createProject"
import {addData} from "../../util/data/addData";
import {getSidebarData} from "../../actions/sidebar";

const NewResource = props => {

    const titleRef = useRef(null),
		pathRef = useRef(null)

    const handleSave = () => {
		const jsonData = props.sidebarData
		let resources =  props.sidebarData.filter(val => val.label === 'Resources');
		let submenu = resources[0].submenu

        let page_data = {
            label: titleRef.current.value,
            path: '/resources/' + titleRef.current.value.replace(/ /g, "_"),
            productionReady: true
        }
		submenu.push(page_data)
		resources[0].submenu = submenu
        resources[0].productionReady = true
		fetch(`${AUTH_BASE_URL}sidebar`, {
			method:'POST',
			body: JSON.stringify(jsonData),
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		}).then(response => response.json()).then(data => {
			props.getData(data.data)
			props.handleCloseNewModal()
		})



    }

    return(
        <div className="modal d-block">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow pd-20">
                    <div className="modal-header border-bottom-0">
                        <h6 className="modal-title">New Resource Page</h6>
                    </div>
                    <form>
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                ref={titleRef}
                                className="form-control"
                                placeholder="New Page"
                                type="text"
                                required
                            />
                        </div>
                    </form>

                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="button" onClick={handleSave}>Save</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseNewModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({session, users, prototypes, sidebarData}) => ({
    session, users, prototypes, sidebarData
});

const mapDispatchToProps = dispatch => ({
  getData: (data) => dispatch(getSidebarData(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewResource);
