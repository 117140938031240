import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import compareDates from "../../../util/dev-ops/compareDates";
import { connect } from "react-redux";
import { signin } from "../../../actions/session/signIn";
import { allGitLabRepos } from "../../../actions/allGitlabRepos";
import DropDown from '../../global/DropDown';
import { hasAuthentication } from "../../../actions/hasAuthentication";
import reactLogo from '../../../assets/images/framework/react.png';
import vueLogo from '../../../assets/images/framework/vue.png';
import gulpLogo from '../../../assets/images/framework/gulp.png';
import docuLogo from '../../../assets/images/framework/docusaurus.svg';
import astroLogo from '../../../assets/images/framework/astro.svg';
import eleventyLogo from '../../../assets/images/framework/11ty.png';
import storybookLogo from '../../../assets/images/framework/storybook.png';
import Avatar from "../../global/Avatar";
import { filteredGitLabRepos } from "../../../actions/filteredData";
import htmlLogo from "../../../assets/images/framework/html.png";
import { toastMessage } from "../../../actions/toast";

function ArchiveTableRow(props) {

	console.log('23THE PROPS', props)
	const setActivityColor = (lastActivityAt) => {
		return Number(compareDates(lastActivityAt));
	};

	return (
		<>
			<tr style={{ height: '72px' }}>
				<td>
					{props.data.data.user && props.data.data.user[0] ? <Avatar user={props.data.data.user[0]} size='sm' /> : <></>}
				</td>
				<td>
					{compareDates(props.data.data.created_at) <= 1 ? <span className="repos badge badge-gradient mg-r-8">New</span> : <></>}
					<a href={props.data.data.http_url_to_repo}>{props.data.data.name}</a>
				</td>
				<td>
					<a target="_blank" href={props.data.data.url}>{props.data.data.url}</a>
				</td>
				<td>
					{(() => {
						switch (props.data.data.framework) {
							case 'Create React App':
							case 'create-react-app':
								return <img style={{ width: '30px' }} alt="logo" src={reactLogo} />;
							case 'Vue.js':
								return <img style={{ width: '30px' }} alt="logo" src={vueLogo} />;
							case 'Html':
								return "";
							case 'gulp.js':
								return <img style={{ width: '30px' }} alt="logo" src={gulpLogo} />;
							case 'Docusaurus 2':
								return <img style={{ width: '30px' }} alt="logo" src={docuLogo} />;
							case 'astro':
							case 'Astro':
								return <img style={{ width: '30px' }} alt="logo" src={astroLogo} />;
							case 'Eleventy':
								return <img style={{ width: '30px' }} alt="logo" src={eleventyLogo} />;
							case 'Parcel':
								return <img style={{ width: '30px' }} alt="logo" src={htmlLogo} />;
							case 'react-storybook':
							case 'Storybook React App':
								return <img style={{ width: '30px' }} alt="logo" src={storybookLogo} />;
							default:
								return props.data.data.framework;
						}
					})()}
				</td>
				<td>
					{(() => {
						const activityColor = setActivityColor(props.data.data.last_activity_at);
						if (activityColor <= 1) return <span className="repos badge badge-primary pd-0 mg-r-8">Today</span>;
						if (activityColor === 2) return <span className="repos badge badge-primary pd-0 mg-r-8">Yesterday</span>;
						if (activityColor <= 7) return <span className="repos badge badge-primary pd-0 mg-r-8">This Week</span>;
						if (activityColor <= 14) return <span className="repos badge badge-primary pd-0 mg-r-8">Last Week</span>;
						if (activityColor <= 30) return <span className="repos badge badge-primary pd-0 mg-r-8">This Month</span>;
						return new Date(props.data.data.last_activity_at).toLocaleDateString();
					})()}
				</td>
				<td>
					{new Date(props.data.data.created_at).toLocaleDateString()}
				</td>
				<td>
                    <span
						data-placement="top"
						data-toggle="tooltip"
						title="Currently Worked On"
						className={
							setActivityColor(props.data.data.last_activity_at) <= 30
								? "circle badge badge-success active"
								: setActivityColor(props.data.data.last_activity_at) <= 60
									? "circle badge badge-warning"
									: "circle badge badge-danger active"
						}
					></span>
				</td>
				<td>
					<DropDown label={"Manage"} icon={"fa-chevron-down"}>
						<li>
							<button onClick={() => {
								props.setShowModal(true);
								props.setModalData({ data: props.data.data, id: props.data._id });
							}}>
								Add Web Url
							</button>
						</li>					</DropDown>
				</td>
			</tr>
		</>
	);
}

const mapStateToProps = ({ session, repos, hasCiCd, gitlabGroupId, hasAuthentication, filteredData }) => ({
	session, repos, hasCiCd, gitlabGroupId, hasAuthentication, filteredData
});

const mapDispatchToProps = dispatch => ({
	signin: user => dispatch(signin(user)),
	allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
	getAuthentication: bool => dispatch(hasAuthentication(bool)),
	filteredGitLabRepos: repos => dispatch(filteredGitLabRepos(repos)),
	toastMessage: data => dispatch(toastMessage(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveTableRow);
