import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Wrapper from "../../global/Wrapper";
import { connect } from "react-redux";
import ReactMarkdown from 'react-markdown';
import { toastMessage } from "../../../actions/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import PearsonAccordion, { AccordionItem } from '@pearson-ux/accordion-react';
import "./Details.css";

function Details(props) {
	const { componentName } = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [readmeContent, setReadmeContent] = useState("");

	useEffect(() => {
		const fetchComponentDetails = async () => {
			if (!componentName) {
				console.error("Component name is not provided.");
				return;
			}

			try {
				setLoading(true);
				const response = await fetch(`${AUTH_BASE_URL}dev-ops/webcomponents/${componentName}`, {
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${props.session.token}`
					}
				});

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();

				if (data.readmeContent) {
					setReadmeContent(data.readmeContent);
				}

				setLoading(false);
			} catch (error) {
				console.error("Error fetching component details:", error);
				setLoading(false);
			}
		};

		fetchComponentDetails();
	}, [componentName, props.session.token]);

	useEffect(() => {
		if (componentName) {
			document.title = `${componentName} - Web Component Details`;
		}
	}, [componentName]);

	return (
		<Wrapper>
			<div className="main-content pt-0 gitlab-repos">
				<div className="container-fluid">
					<div className="inner-body users">
						<div className="page-header">
							<div>
			
							</div>
							<div className='page-group-controls'>
								<button
									onClick={() => navigate('/dev-ops/web-components')}
									type="button"
									className="btn btn-secondary btn-icon-text back-button"
								>
									<FontAwesomeIcon className='sidemenu-icon mg-r-4' icon={`fa-solid fa-arrow-left`} /> Back
								</button>
							</div>
						</div>

						{loading ? (
							<p>Loading...</p>
						) : (
							<>
								<div className="readme-content">
									<ReactMarkdown>{readmeContent}</ReactMarkdown>
								</div>

								{/* Theme Demos */}
								<h2>Theme Demos</h2>

								<h3>ELL Theme</h3>
								<PearsonAccordion theme="ell">
									<AccordionItem label="Section 1">Content for section 1</AccordionItem>
									<AccordionItem label="Section 2">Content for section 2</AccordionItem>
								</PearsonAccordion>

								<h3>P-Plus Theme</h3>
								<PearsonAccordion theme="p-plus">
									<AccordionItem label="Section 1">Content for section 1</AccordionItem>
									<AccordionItem label="Section 2">Content for section 2</AccordionItem>
								</PearsonAccordion>

								<h3>P-Com Theme</h3>
								<PearsonAccordion theme="p-com">
									<AccordionItem label="Section 1">Content for section 1</AccordionItem>
									<AccordionItem label="Section 2">Content for section 2</AccordionItem>
								</PearsonAccordion>
							</>
						)}
					</div>
				</div>
			</div>
		</Wrapper>
	);
}

// CSS styles embedded in the component
const styles = `
.readme-content {
    font-family: 'Arial', sans-serif;
    line-height: 1.5;
    color: #333;
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    margin-top: 20px;
}

.readme-content h1, 
.readme-content h2, 
.readme-content h3 {
    color: #2c3e50;
    font-weight: 700;
    margin-top: 25px;
}

.readme-content h1 {
    font-size: 2.8em;
}

.readme-content h2 {
    font-size: 2.2em;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin-top: 25px;
}

.readme-content h3 {
    font-size: 1.8em;
    margin-top: 20px;
}

.readme-content p {
    margin: 15px 0;
    font-size: 1.2em;
    color: #555;
}

.readme-content code {
    background-color: #e8e8e8;
    padding: 2px 6px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
}

.readme-content pre {
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 20px;
    border-radius: 6px;
    overflow-x: auto;
    margin: 20px 0;
}

.readme-content pre code {
    background-color: transparent;
    color: inherit;
    padding: 0;
    border-radius: 0;
}

.readme-content ul, 
.readme-content ol {
    padding-left: 25px;
    margin: 15px 0;
}

.readme-content li {
    margin-bottom: 8px;
}

.readme-content a {
    color: #3498db;
    text-decoration: none;
}

.readme-content a:hover {
    text-decoration: underline;
}

.readme-content blockquote {
    border-left: 4px solid #3498db;
    padding-left: 20px;
    margin-left: 0;
    color: #555;
    font-style: italic;
    margin: 20px 0;
}
`;

const mapStateToProps = ({ session }) => ({
	session
});

const mapDispatchToProps = dispatch => ({
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Details);

// Apply the styles by injecting them into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
