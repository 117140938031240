import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAddressBook,
    faBell,
    faCalendar,
    faCamera,
    faCheck,
    faClock,
    faCog,
    faComments,
    faEdit,
    faEnvelope,
    faExclamationCircle,
    faEye,
    faFile,
    faFolder,
    faHeart,
    faHome,
    faInfoCircle,
    faKey,
    faLightbulb,
    faLink,
    faLock,
    faMap,
    faMinus,
    faPaperclip,
    faPen,
    faPhone,
    faPlus,
    faPrint,
    faQuestionCircle,
    faSearch,
    faShoppingCart,
    faStar,
    faSync,
    faThumbsUp,
    faTrash,
    faUser,
    faUserCircle,
    faVolumeUp,
    faWifi,
    faBars
} from "@fortawesome/free-solid-svg-icons";

function IconSelect(props) {
    const icons = [
        { name: 'Address Book', icon: faAddressBook },
        { name: 'Bell', icon: faBell },
        { name: 'Calendar', icon: faCalendar },
        { name: 'Camera', icon: faCamera },
        { name: 'Check', icon: faCheck },
        { name: 'Clock', icon: faClock },
        { name: 'Cog', icon: faCog },
        { name: 'Comments', icon: faComments },
        { name: 'Edit', icon: faEdit },
        { name: 'Envelope', icon: faEnvelope },
        { name: 'Exclamation Circle', icon: faExclamationCircle },
        { name: 'Eye', icon: faEye },
        { name: 'File', icon: faFile },
        { name: 'Folder', icon: faFolder },
        { name: 'Heart', icon: faHeart },
        { name: 'Home', icon: faHome },
        { name: 'Info Circle', icon: faInfoCircle },
        { name: 'Key', icon: faKey },
        { name: 'Lightbulb', icon: faLightbulb },
        { name: 'Link', icon: faLink },
        { name: 'Lock', icon: faLock },
        { name: 'Map', icon: faMap },
        { name: 'Minus', icon: faMinus },
        { name: 'Paperclip', icon: faPaperclip },
        { name: 'Pen', icon: faPen },
        { name: 'Phone', icon: faPhone },
        { name: 'Plus', icon: faPlus },
        { name: 'Print', icon: faPrint },
        { name: 'Question Circle', icon: faQuestionCircle },
        { name: 'Search', icon: faSearch },
        { name: 'Shopping Cart', icon: faShoppingCart },
        { name: 'Star', icon: faStar },
        { name: 'Sync', icon: faSync },
        { name: 'Thumbs Up', icon: faThumbsUp },
        { name: 'Trash', icon: faTrash },
        { name: 'User', icon: faUser },
        { name: 'User Circle', icon: faUserCircle },
        { name: 'Volume Up', icon: faVolumeUp },
        { name: 'Wifi', icon: faWifi },
        { name: 'Bars', icon: faBars }
    ];

    const [selectedIcon, setSelectedIcon] = useState(icons[0].icon);

    useEffect(() => {
        props.setIcon(selectedIcon)
    }, [selectedIcon]);
    return (
        <div>
            <select
                className={"form-control mb-4"}
                value={selectedIcon.iconName}
                onChange={(e) => setSelectedIcon(icons.find(icon => icon.icon.iconName === e.target.value).icon)}
            >
                {icons.map((item, index) => (
                    <option key={index} value={item.icon.iconName}>
                        {item.name}
                    </option>
                ))}
            </select>
            <div>
                Selected Icon:
                <FontAwesomeIcon icon={selectedIcon} size={'lg'}/>
            </div>
        </div>
    );
}

export default IconSelect;
