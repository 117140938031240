import {useEffect, useState} from "react";

export const useFilter = (linkRecords, filter) => {
  const [filteredData, setFilteredData] = useState(linkRecords);

  // Rerun effect when filter changes
  useEffect(() => {
    function filterLinkRecordsByType(filter) {
      const allTypes = "All";

      // Initialize filtered data
      let filteredLinkData = linkRecords;

      if (filter && filter !== allTypes && linkRecords.length > 0) {
        // Filter records by type
        filteredLinkData = linkRecords.filter((record) => {
          const typeSubstring = record.type.substring(
            record.type.lastIndexOf("/") + 1
          );

          const loweredTypeStr =
            typeSubstring[0].toUpperCase() + typeSubstring.substring(1);
          if (record.type === filter || filter === loweredTypeStr)
            return record;
          else
            return;
        }); // filter
      }
      return filteredLinkData;
    } // function

    setFilteredData(filterLinkRecordsByType(filter));
  }, [linkRecords, filter]);

  return [filteredData];
};
