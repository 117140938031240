import { useEffect, useState } from "react";
import { useCallback } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { graphConfig } from "../../../../util/sso/authConfig";
import { silentLogin } from "../../../../util/sso/silentLogin";
import {
  acquireTokenWithOutAccount,
  acquireTokenWithAccount,
} from "../../../../util/sso/acquireToken";
import { fetchUserData } from "../../../../util/sso/fetchUserData";
import { faCropSimple } from "@fortawesome/free-solid-svg-icons";

/**
 * Description: Hook used to acquire a user AccessToken
 * and assembles the MSGraph request and receive
 * the response.  In the case of an account expiration
 * (id-token).  A new account is generated with  function
 * accountTokenWithOutAccount
 * @param {*} msGraphName - The name of the MS
 * Graph Config object in util/sso/authConfig
 * @param {*} searchTerm - The term to search for in the
 * MS Graph request.
 * @param {*} fields - The fields to return comma seperated
 * @returns
 */
export const useAuthFetch = (graphRequests) => {
  // console.log("UseAuthFetch: Object:", graphRequests);
  const [msGraphName, setMsGraphName] = useState();
  const [searchTerm, setSearchTerm] = useState(null);
  const [fields, setFields] = useState(null);
  const [type, setType] = useState(null);
  const [endpoint, setEndpoint] = useState(null);
  const [email, setEmail] = useState(null);

  let msConfigRoot;
  // let endpoint;
  let scopes;

  const [requestCounter, setRequestCounter] = useState();

  const setDefaults = () => {
    if (Array.isArray(graphRequests)) {
      graphRequests.forEach((request, index) => {
        setMsGraphName(request.msGraphName);
        setSearchTerm(request.searchTerm);
        setFields(request.fields);
        setEmail(request.email);
        setRequestCounter(index);
      });
    } else {
      setMsGraphName(graphRequests.msGraphName);
      setSearchTerm(graphRequests.searchTerm);
      setFields(graphRequests.fields);
      setEmail(graphRequests.email);
      setRequestCounter(0);
    }
  };
  /**
   * Step 1: Set default when
   * request object changes.
   */
  useEffect(() => {
    setDefaults();
  }, [graphRequests]);

  /**
   * Step 2: Set MsGraph Collection
   * info when msGraphName is set
   */
  useEffect(() => {
    // console.log("UseAuthFetch:msGraphName:", msGraphName);
    // console.log("GraphConfig:", graphConfig);
    // console.log("ConfigRoot", graphConfig[msGraphName])
    if (msGraphName) {
      msConfigRoot = graphConfig[msGraphName];
      setEndpoint(msConfigRoot.endpoint);
      scopes = msConfigRoot.scopes;
      setType(msConfigRoot.type);
      // console.log(
      //   "UserAuthFetch: Type:",
      //   type,
      //   "; endpoint:",
      //   endpoint,
      //   ";SearchTerm:",
      //   searchTerm
      // );
    }
  }, [msGraphName]);

  const [userData, setUserData] = useState(null);
  const [ssoResponse, setSsoResponse] = useState(null);
  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0] || {});
// console.log("useAuthFetch: Account:", account)
  /*************************************/
  const getAccessTokenWithAccount = useCallback(
    async (instance, scopes, account) => {
      // console.log("In useAuthFetch:", instance, scopes, account);
      const ssoResponse = await acquireTokenWithAccount(
        instance,
        scopes,
        account
      );
      // console.log("acquireTokenWithAccount: Response:", ssoResponse);
      setSsoResponse(ssoResponse);
    },
    []
  );

  /************************************/
  let accountFound = account;

  /**
   * Step 3: Acquire an MsGraph AccessToken
   * once all msGraph Collection info is set
   */
  useEffect(() => {
    /**
     * Determine if login type is native for graphMe,
     * or SSO for all other GraphAPI Requests.
     * Execute silent logon when native user  is
     * accessing the GraphAPI, not for SSO log in.
     */
    // console.log(`UseAuthFetch: RequestCounter: requestCounter: ${requestCounter}, account: ${JSON.stringify(account)}, endpoint: ${endpoint}, instance ${JSON.stringify(instance)}`);
    let tokenResponse;

    /**
     * Perform Silent Login when Access Token
     * has expired after 30mins, in order to use
     * invite user (msGraphName === "graphPeople")
     * Note: Can not use isAuthenticated as a check, because
     * user can be logged in, with an expired access token
     */
    // console.log("useAuthFetch1: msGraphName:", msGraphName, ";Account:", account);
    if (!account && msGraphName === "graphPeople") {
      // console.log("Run silentLogin:","useAuthFetch1: msGraphName:", msGraphName, ";Account:", account )
      silentLogin(scopes, instance).then(async (response) => {
        let newAccount = await response;
        // console.log("useAuthFetch2: msGraphName:", msGraphName);
        // console.log("useAuthFetch: newAccount:", newAccount);
        if (newAccount) {
          const idTokenClaims = newAccount.idTokenClaims;
          const forceRefresh = new Date(idTokenClaims?.exp + 1000) < new Date();
          tokenResponse = await acquireTokenWithOutAccount(
            instance,
            scopes,
            forceRefresh,
            newAccount
          );
          setSsoResponse(tokenResponse);
        }
      });
    } else if (account) {
      // console.log("useAuthFetch3: msGraphName:", msGraphName);
      // console.log("Account1:", account);
      const accountRoles = account.idTokenClaims.roles;
      // console.log("AccountRoles:", accountRoles);
      if (userData) {
        userData.role = accountRoles;
        localStorage.setItem("endeavor_peopleGraph_role", accountRoles);
        // console.log("useAuthFetch: AD PeopleGraph role stored in local storage.");
      }
      /*********************************/
      // 1/18/2023
      getAccessTokenWithAccount(instance, scopes, account).catch(console.error);
      /*********************************/
    } //if
  }, [account, instance, searchTerm, endpoint, type, email]);

  /**
   * Step 4: Perform Data Fetch after Access Token resceived
   */
  useEffect(() => {
    /**
     * Step 2: After Token Response Recieved
     * Perform Fetch UserData Fetch from Ms Graph
     */
    // console.log("useAuthFetch4: msGraphName:", msGraphName);

    if (
      (msGraphName === "graphUsers" && email) ||
      msGraphName !== "graphUsers"
    ) {
      if (accountFound && instance && endpoint) {
        // Once the update is complete, store the user object in local storage
        localStorage.setItem("endeavor_userGraph_role", account.idTokenClaims.roles);
        // console.log("useAuthFetch: AD userGraph role stored in local storage.");
        fetchUserData(
          accountFound,
          type,
          instance,
          account.idTokenClaims.roles,
          endpoint,
          searchTerm,
          fields,
          email,
          ssoResponse
        ).then((ssoUserData) => {
          /**
           * Set MSGraph Return Data:
           * combine all results into one
           *  data object, if possible
           */
          switch (true) {
            case type === "me":
              setUserData((prevState) => {
                if (prevState) {
                  return { ...prevState, ...ssoUserData };
                }
              });
              setUserData(ssoUserData);
              break;
            case type === "people":
              setUserData(ssoUserData);
              break;
            case type === "users":
              setUserData(ssoUserData);
              break;
            default:
          }
        });
      }
    }
  }, [ssoResponse]);

  // console.log("useAuthFetch userData:", userData);
  return [userData];
};
