
import React from 'react';

// your component code here
import {connect} from "react-redux";

function Avatar(props) {

	if (typeof props.user === 'string') {
		if (props.user) {
			let firstName = props.user.split(' ')[0],
				lastName = props.user.split(' ')[1];
			return (
				<div className={`avatar avatar-${props.size} mg-r-8`}>
					{firstName && firstName.charAt(0).toUpperCase()}
					{lastName && lastName.charAt(0).toUpperCase()}
				</div>
			);
		} else {
			return (
				<div className={`avatar avatar-${props.size} mg-r-8`}>
					{'Figma'}{' User'}
				</div>
			)
		}

	} else {
		if (props.user && props.user.firstName && props.user.lastName) {
			if (props.user.avatar === null || props.user.avatar === ' ' || !props.user.avatar) {
				if (props.test === 'yes') {
					return (
						<div className={`avatar avatar-${props.size}`}>
							TU
						</div>
					)
				} else {
					return (
						<div className={`avatar avatar-${props.size}`}>
							{props.user.firstName.charAt(0).toUpperCase()}{props.user.lastName.charAt(0).toUpperCase()}
						</div>
					)
				}
			} else {
				return (
					<div className={`avatar avatar-${props.size}`}>
						<img alt="avatar" className="rounded-circle" src={props.user.avatar} />
					</div>
				)
			}
		} else {
			return (
				<div className={`avatar avatar-${props.size}`}>
					TU
				</div>
			)
		}
	}


}

const mapStateToProps = ({session}) => ({
    session
});

export default connect(
    mapStateToProps,
)(Avatar);
