import DropDown from "../../dev-ops/gitlab-repos/DropDown";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import stop from '../../../util/usertesting/stopClassTest'
import {allTestData} from "../../../actions/allUserTests";
import deleteClassTest from "../../../util/usertesting/deleteClassTest"
import Avatar from "../../global/Avatar";
import {useNavigate} from "react-router-dom";
import validateToken from "../../../util/dev-ops/validateToken";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import {signin} from "../../../actions/session/signIn";
import updatePublicHtml from "../../../util/dev-ops/updatePublicHtml";
import {toastMessage} from "../../../actions/toast";
import {allClassTests} from "../../../actions/allClassTests";
import launchClassTest from "../../../util/usertesting/launchClassTest";

function TableRow (props) {
	const [disabled, setDisabled] = useState();
	const navigate = useNavigate();

	useEffect(()=>{
		setDisabled(isDisabled())

	},[props.allTests])

	function launchTest(testData) {
		let repoData = {}
		repoData.id = testData.repo
		console.log(testData, repoData, 'REPO DATA')
		validateToken(props.session).then(data => {
			props.signin(data.user)

			fetch(`${AUTH_BASE_URL}dev-ops/gitlab/${props.data.repo}/package/update/${data.token}`).then(response => response.json()).then(file => {
				let framework = !file.file.package.message ? file.file.framework[0] : null;
				updatePublicHtml(repoData, data.token, framework).then(response => {
					props.toastMessage(`${props.data.name} is being setup for a usertest.  Give the system 20 minutes or so, you'll get a notification when the test is live.`)
					launchClassTest(testData,data.token).then(response => {
						fetch(`${AUTH_BASE_URL}usertesting/classtest`, {
							method: 'GET',
							headers: {
								"Content-Type": "application/json",
							},
						}).then((response => {
							return response.json()
						})).then((data => {
							props.allClassTests(data)
							setDisabled(isDisabled())
						}))
					})
				})
			})

		})
	}

	function returnAdmin () {
		return props.session.systemAccess === 4 ||  props.data.user[0].userId === props.session.userId
	}

	function deleteTest(data) {
		console.log('delete data', data);
		props.toastMessage(`${props.data.name} is being deleted.  You'll receive a notification in a few minutes.`)
		deleteClassTest(data).then(response => {
			fetch(`${AUTH_BASE_URL}usertesting/classtest`, {
				method: 'GET',
				headers: {
					"Content-Type": "application/json",
				},
			}).then((response => {
				return response.json()
			})).then((data => {
				props.allClassTests(data)
				setDisabled(isDisabled())
			}))
		})
	}

	function viewResults(data) {
		navigate(`/usertesting/online/results/${props.data.name}`, {state:{id: props.data._id}})
	}

	function stopTest (data) {
		console.log('stop data', data);
		props.toastMessage(`The usertest ${props.data.name} is being stopped.  The database and app will be removed.  You can launch the test again at anytime.`)
		stop(data);
		fetch(`${AUTH_BASE_URL}usertesting/classtest`, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json",
			},
		}).then((response => {
			return response.json()
		})).then((data => {
			props.allClassTests(data)
			setDisabled(isDisabled())
		}))
	}
	function isDisabled () {
		if (props.data.introduction && props.data.introductionData && props.data.introductionData.video && props.data.introductionData.video.length === 0) {
			return 'true'
		} else if (props.data.collectPersonal && props.data.personalData && props.data.personalData.length === 0) {
			return 'true'
		} else if (props.data.preTest && props.data.preTestData && props.data.preTestData.length === 0) {
			return 'true'
		} else if (props.data.postTest && props.data.postTestData && props.data.postTestData.length === 0) {
			return 'true'
		}else {
			return 'false'
		}
	}
	function viewInDigital () {
		window.open(`https://cloud.digitalocean.com/apps/${props.data.app.id}/overview`, '_blank')
	}
	function invite (event) {
		event.preventDefault();
		props.toastMessage(`Were sending an email to ${props.data.instructorEmail} with a link to the test.  They'll be able to launch the test from the email.`)
		const encodedInstructorEmail = encodeURIComponent(props.data.instructorEmail);
		const encodedUrl = encodeURIComponent(props.data.url);
		const encodedPassword = encodeURIComponent(props.data.password);

		fetch(`${AUTH_BASE_URL}usertesting/classtest/invite/${encodedInstructorEmail}/${encodedUrl}/${encodedPassword}`, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json",
			},
		}).then((response => {
			console.log(response)
		}))

	}


	console.log('the data', props.data);
	if (props.allTests && props.allTests.length === 0) {
		return (
			<tr>
				<td colSpan={8}>
					There are no tests setup
				</td>
			</tr>
		)
	} else {
		return (
			<tr>
				<td>
					{
						props.data.user && props.data.user[0]? <Avatar user={props.data.user[0]} size='sm'/> : <></>
					}
				</td>
				<td>
					{
						!props.data.launching ? props.data.url : <a href={props.data.url}>{props.data.url}</a>
					}

				</td>
				<td>
					{props.data.repoName}
				</td>
				<td>
					{props.data.instructorEmail}
				</td>
				<td>
					{new Date(props.data.createdAt).toLocaleDateString()}
				</td>

				<td>
					<span data-placement="top" data-toggle="tooltip" title="Currently Worked On" className={ props.data.launching && !props.data.live ? "circle badge badge-warning active" : props.data.live ? "circle badge badge-success active" : "circle badge badge-danger active"}></span>

				</td>
				<td>
					<DropDown data={props.data} label={"Manage"} icon={"fa-chevron-down"}>
						{
							props.data.live ? <li><button onClick={invite}>Invite Instructor</button></li> : <></>
						}
						{
						    !props.data.duplicate ? <li><button onClick={()=>{props.handleDuplicateModal(props.data)}}>Duplicate Test</button></li> : <></>
						}
						{
							props.data.launching ? <li><button onClick={viewInDigital}>View in Digital Ocean</button></li> : <></>
						}
						{
							 !props.data.launching && returnAdmin() ?<li><button  onClick={()=>{props.handleEditModal(props.data)}} >Edit Test</button></li>  : <></>
						}
						{
							! props.data.launching && returnAdmin() ? <li><button  disabled={disabled === 'true'} className={disabled === 'true' ? 'disabled': 'text-success'} onClick={()=>{launchTest(props.data)}}>Launch Test</button></li> : returnAdmin() ? <li><button onClick={()=>{stopTest(props.data)}}>Stop Test</button></li> : <></>
						}
						{
							!props.data.launching && returnAdmin() ? <li><button className={'text-danger'} onClick={()=>{deleteTest(props.data)}}>Delete Test</button></li> : <></>
						}

						{/*<li><button  onClick={()=> {window.open(props.data.appUrl[0].webLink, '_blank')}}>Schedule Test</button></li>*/}

						{/*<li><button  className='text-danger' onClick={()=> {window.open(props.data.appUrl[0].webLink, '_blank')}}>Delete Test</button></li>*/}
					</DropDown>
				</td>
			</tr>
		)
	}

}
const mapStateToProps = ({session, allClassTestData}) => ({
	session, allClassTestData
});

const mapDispatchToProps = dispatch => ({
	allTestData: (data) => dispatch(allTestData(data)),
	signin: user =>dispatch(signin(user)),
	toastMessage: (data) => dispatch(toastMessage(data)),
	allClassTests: (data) => dispatch(allClassTests(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TableRow);

