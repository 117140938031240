import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../util/routes/protected";
import { AuthRoute } from "../util/routes/auth";
import SignIn from "../components/authentication/SignIn";
import SignUp from "../components/authentication/SignUp";
import SingleSignOn from "./authentication/SingleSignOn";
import ResetPassword from "./authentication/ResetPassword";
import ForgotPassword from "./authentication/ForgotPassword";
import Dashboard from "./dashboard/Dashboard";
import Projects from "./projects/Projects";
import ProjectDetail from "./projects/ProjectDetail";
import TaskDetail from "./tasks/TaskDetail";
import SubtaskDetail from "./tasks/subtasks/SubtaskDetail";
import Settings from "./settings/Settings";
import Users from "./users/Users";
import ManageTemplates from './dev-ops/digital-ocean/apps/templates/ManageTemplates'
import Planning from "./projects/Planning/Planning";
import Prototypes from "./apps/Prototypes";
import ViewReport from "./reporting/ViewReport";
import GitLabRepos from "./dev-ops/gitlab-repos/GitLabRepos";
import Team from "./team/Team";
import Reporting from "./reporting/Reporting";
import Online from "./usertesting/self-lead/Online";
import ThankYou from "./message_pages/ThankYou";
import Verified from "./message_pages/Verified";
import Unauthorized from "./message_pages/Unauthorized";
import AddGitLabRepo from "./dev-ops/AddGitLabRepo";
import CareerPathsComponent from "./global/CareerPathsComponent";
import News from './news/News'
import ArchiveRepo from "./dev-ops/gitlab-repos/Archive";
import Mailgun from './mailgun/Mailgun'
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { openWebSocket } from "../util/websockets/openWebSocket";
import { saveWSClient } from "../actions/wsclient";
import { library } from "@fortawesome/fontawesome-svg-core";
import AddPage from "./resources/Resources";
import Figma from "./design-ops/figma/Project";
import GitLab from "./dev-ops/gitlab/TableContainer";
import WebComponentDetails from "./dev-ops/web-components/Details"
import WebComponents from "./dev-ops/web-components/TableContainer";
import DigitalOceanApps from './dev-ops/digital-ocean/apps/TableContainer'
import DigitalOceanDroplets from './dev-ops/digital-ocean/droplets/TableContainer'
import DigitalOceanDatabase from './dev-ops/digital-ocean/databases/TableContainer'
import DigitalOceanAddApp from './dev-ops/digital-ocean/apps/add/AddContainer'
import Utilities from "./dev-ops/util/Utilities";
import FigmaComponents from "./design-ops/figma/Components";
import ManageTestUsers from "./usertesting/ManageTestUsers/ManageTestUsers";
import Tools from "./resources/tools/Tools";
import * as FullStory from '@fullstory/browser';
import {

} from "@fortawesome/free-solid-svg-icons";

import {
  faWpforms,
  faChrome,
  faDigitalOcean,
  faFigma,
  faGitlab,
    faJira,
    faReact
} from "@fortawesome/free-brands-svg-icons";

import {
  faFileExcel,
  faBellConcierge,
  faChartBar,
  faChevronRight,
  faCalendarAlt,
  faFileCode,
  faShareAlt,
  faVideo,

  faTasks,
  faProjectDiagram,
  faFileWord,

  faMicrophone,

  faUsers,
  faLink,
  faShieldAlt,
  faBookReader,
  faCloud,
  faPen,
  faFillDrip,
  faShoppingCart,
  faHeartbeat,
  faGlobe,
  faDatabase,
  faLock,
  faFilePowerpoint,
  faStamp,
  faCode,
  faHandPointer,
  faDollarSign,
  faWallet,
  faBook,
  faPhoneVolume,
  faPalette,
  faGraduationCap,
  faHammer,
  faBars,
  faBell,
  faBookmark,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faClipboardList,
  faCodeCommit,
  faCodeCompare,
  faCodeFork,
  faDiagramNext,
  faEye,
  faEyeSlash,
  faFilter,
  faFolder,
  faGripVertical,
  faHouse,
  faLockOpen,
  faMessage,
    faNewspaper,
  faMicroscope,
  faPencil,
  faPlus,
  faServer,
  faTrash,
  faThumbTack,
  faUpload,
  faUserPlus,

  faWindowMaximize,
  faXmark,
  faSquareCheck,
  faCodeBranch,
  faBoxArchive,
  faClock,
  faPersonChalkboard,
  faFile,
  faBuilding,

  faHeart,
  faDesktop,
  faSackDollar,
  faPlaneDeparture,
  faBed,
  faHeadSideCough,
  faDoorOpen,
  faCircleDown,
  faCircleUp,
  faAddressBook,
  faCalendar,
  faCamera,
  faCog,
  faComments,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faHome,
  faInfoCircle,
  faKey,
  faLightbulb,

  faMap,
  faMinus,
  faPaperclip,

  faPhone,
  faPrint,
  faQuestionCircle,
  faSearch,

  faStar,
  faSync,
  faThumbsUp,
  faUser,
  faUserCircle,
  faVolumeUp,
  faWifi,
    faFileShield,
  faEllipsisVertical,

  faSort
} from "@fortawesome/free-solid-svg-icons";
import Results from "./usertesting/results/Results";
import All from "./usertesting/results/All";
// import { TaskProvider } from "./global/Providers/TaskProvider";
import ClassTest from "./usertesting/class-test/ClassTest";
import Podcasts from "./resources/podcasts/Podcasts";
import DashboardSample from "./dashboard/DashboardSample";
import Details from "./dev-ops/digital-ocean/apps/details/Details";
import AddWebComponent from "./dev-ops/web-components/AddWebComponent";

library.add(
    faFileExcel,
    faWpforms,
    faReact,
    faFileShield,
    faFileCode,
    faBellConcierge,
    faShieldAlt,
    faCloud,
    faChartBar,
    faBook,
    faCalendarAlt,
    faEnvelope,
    faFilePowerpoint,
    faShareAlt,
    faChevronRight,
    faVideo,
    faUsers,
    faTasks,
    faProjectDiagram,
    faFileWord,
    faLightbulb,
    faMicrophone,
    faHeartbeat,
    faUsers,
    faLink,
    faShieldAlt,
    faBookReader,
    faCloud,
    faPen,
    faFillDrip,
    faShoppingCart,
    faHeartbeat,
    faGlobe,
    faDatabase,
    faLock,
    faFilePowerpoint,
    faStamp,
    faCode,
    faHandPointer,
    faDollarSign,
    faWallet,
    faBook,
    faPhoneVolume,
    faPalette,
    faGraduationCap,
    faHammer,
    faJira,
    faChrome,
    faDigitalOcean,
    faFigma,
    faGitlab,
    faBars,
    faBell,
    faBookmark,
    faEllipsisVertical,
    faMessage,
    faGripVertical,
    faHouse,
    faBoxArchive,
    faUpload,
    faUsers,
    faTrash,
    faFilter,
    faNewspaper,
    faUserPlus,
    faCodeBranch,
    faWindowMaximize,
    faPlus,
    faLock,
    faLockOpen,
    faCodeCompare,
    faCodeCommit,
    faXmark,
    faCircleCheck,
    faCheck,
    faCircleInfo,
    faCircleExclamation,
    faCodeFork,
    faFolder,
    faChevronDown,
    faChevronUp,
    faPencil,
    faClipboardList,
    faServer,
    faMicroscope,
    faEye,
    faEyeSlash,
    faDiagramNext,
    faSquareCheck,
    faClock,
    faPersonChalkboard,
    faFile,
    faBuilding,
    faCloud,
    faHeart,
    faDesktop,
    faSackDollar,
    faPlaneDeparture,
    faBed,
    faHeadSideCough,
    faDoorOpen,
    faCircleDown,
    faCircleUp,
    faAddressBook,
    faBell,
    faCalendar,
    faCamera,
    faCheck,
    faClock,
    faCog,
    faComments,
    faEdit,
    faEnvelope,
    faExclamationCircle,
    faEye,
    faFile,
    faFolder,
    faHeart,
    faHome,
    faInfoCircle,
    faKey,
    faLightbulb,
    faLink,
    faLock,
    faMap,
    faMinus,
    faPaperclip,
    faPen,
    faPhone,
    faPlus,
    faPrint,
    faQuestionCircle,
    faSearch,
    faShoppingCart,
    faStar,
    faSync,
    faThumbsUp,
    faThumbTack,
    faTrash,
    faUser,
    faUserCircle,
    faVolumeUp,
    faWifi,
    faSort
);

function App(props) {
  useEffect(() => {
    console.log('the session', props, props.session)
	  if(process.env.REACT_APP_NODE_ENV === 'production') {
        FullStory.init({ orgId: 'o-1D7S77-na1' });
        // This is an example script - don't forget to change it!
        FullStory.identify(props.session.userId, {
          displayName: `${props.session.firstName} ${props.session.lastName}`,
          email: props.session.email,
          // Add your own custom user variables here, details at
          // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Capturing-custom-user-properties
          reviewsWritten_int: 14
        });
	  }
  }, []);
  useEffect(() => {
    //if there is a user logged in
    if (props.session.userId) {
      openWebSocket().then((response) => {
        if (response.status === 200) {
          if (!props.wsClient) {
            let wsclient;
            wsclient = new W3CWebSocket(
              `wss://${process.env.REACT_APP_HOST}/websockets`
            );
            wsclient.onopen = () => {
              console.log("websocket client connected");
              props.saveWSClient(wsclient);
            };
            wsclient.onclose = () => {
              console.log("websocket client closed");
            };
          }
        }
      });
    }
  }, [props.session]);

  return (
    <div className={`app theme-${props.session.theme}`}>
      {/* <TaskProvider> */}
        <Routes>
          {AuthRoute("/sign-in", SignIn, props.session.userId)}
          {AuthRoute("/sign-up", SignUp, props.session.userId)}
          {AuthRoute("/sso", SingleSignOn, props.session.userId)}
          {AuthRoute("/reset-password", ResetPassword, props.session.userId)}
          {AuthRoute("/forgot-password", ForgotPassword, props.session.userId)}

          {/* confirmation pages */}
          <Route exact path="/thank-you" element={<ThankYou />} />
          <Route exact path="/unauthorized" element={<Unauthorized />} />
          <Route exact path="/verified" element={<Verified />} />

          {/* main */}
          <Route exact path="/" element={<Navigate to="/dashboard" />} />
          {ProtectedRoute("/dashboard", Dashboard, props.session.userId)}
          {ProtectedRoute("/news", News, props.session.userId)}
          {ProtectedRoute("/team", Team, props.session.userId)}
          {ProtectedRoute("/reporting", Reporting, props.session.userId)}
          {ProtectedRoute("/reporting/generate", Reporting, props.session.userId)}
          {ProtectedRoute("/reporting/:id", ViewReport, props.session.userId)}
          {ProtectedRoute("/projects/planning", Planning, props.session.userId)}
          {ProtectedRoute("/projects", Projects, props.session.userId)}
          {ProtectedRoute("/projects/:id", ProjectDetail, props.session.userId)}
          {ProtectedRoute("/tasks/:id", TaskDetail, props.session.userId)}
          {ProtectedRoute("/subtasks/:id", SubtaskDetail, props.session.userId)}
          {ProtectedRoute("/settings", Settings, props.session.userId)}
          {ProtectedRoute("/users", Users, props.session.userId)}
          {ProtectedRoute("/mailgun", Mailgun, props.session.userId)}
          {ProtectedRoute("/archive", ArchiveRepo, props.session.userId)}
          {/*{ProtectedRoute("/hosting", DigitalOcean, props.session.userId)}*/}
          {ProtectedRoute("/hosting/apps/:id", Details, props.session.userId)}
          {ProtectedRoute("/hosting/apps", DigitalOceanApps, props.session.userId)}
          {ProtectedRoute("/hosting/apps/add", DigitalOceanAddApp, props.session.userId)}
          {ProtectedRoute("/hosting/apps/templates", ManageTemplates, props.session.userId)}
          {ProtectedRoute("/hosting/droplets", DigitalOceanDroplets, props.session.userId)}
          {ProtectedRoute("/hosting/database", DigitalOceanDatabase, props.session.userId)}
          {/*{ProtectedRoute("/hosting/storage", DigitalOcean, props.session.userId)}*/}
          {ProtectedRoute("/dev-ops/web-components/details/:componentName", WebComponentDetails, props.session.userId)}
          {ProtectedRoute("/dev-ops/web-components/add", AddWebComponent, props.session.userId)}
          {ProtectedRoute("/dev-ops/web-components", WebComponents, props.session.userId)}
          {ProtectedRoute("/dev-ops/gitlab-repos", GitLab, props.session.userId)}
          {ProtectedRoute("/dev-ops/utilities", Utilities, props.session.userId)}
          {ProtectedRoute("/dev-ops/gitlab-repos/add", AddGitLabRepo, props.session.userId)}
          {ProtectedRoute("/design-ops/figma/projects", Figma, props.session.userId)}
          {ProtectedRoute("/design-ops/figma/components", FigmaComponents, props.session.userId)}
          {ProtectedRoute("/usertesting/online", Online, props.session.userId)}
          {ProtectedRoute("/usertesting/classtest", ClassTest, props.session.userId)}
          {ProtectedRoute("/usertesting/classtest/new", ClassTest, props.session.userId)}
          {ProtectedRoute("/usertesting/classtest/edit", ClassTest, props.session.userId)}
          {ProtectedRoute("/usertesting/classtest/duplicate", ClassTest, props.session.userId)}
          {ProtectedRoute("/usertesting/online/new", Online, props.session.userId)}
          {ProtectedRoute("/usertesting/online/introduction/video", Online, props.session.userId)}
          {ProtectedRoute("/usertesting/online/introduction/text", Online, props.session.userId)}
          {ProtectedRoute("/usertesting/online/edit", Online, props.session.userId)}
          {ProtectedRoute("/usertesting/online/collect", Online, props.session.userId)}
          {ProtectedRoute("/usertesting/online/pre-data", Online, props.session.userId)}
          {ProtectedRoute("/usertesting/online/post-data", Online, props.session.userId)}
          {ProtectedRoute("/usertesting/online/results/:id", Results, props.session.userId)}
          {ProtectedRoute("/usertesting/online/results/:name/all", All, props.session.userId)}
          {ProtectedRoute("/usertesting/online/import-test-users", Online, props.session.userId)}
          {ProtectedRoute("/usertesting/online/users/:id", ManageTestUsers, props.session.userId)}
          {ProtectedRoute("/resources/*", AddPage, props.session.userId)}
          {ProtectedRoute("/resources/**/*", AddPage, props.session.userId)}
          {ProtectedRoute("/resources/tools", Tools, props.session.userId)}
          {ProtectedRoute("/resources/Podcasts", Podcasts, props.session.userId)}
          <Route exact path="/apps/career-paths" element={<CareerPathsComponent />} />
          <Route path="*" element={<Navigate replace to="/dashboard" />} />
        </Routes>
      {/* </TaskProvider> */}
    </div>
  );
}

const mapStateToProps = ({ session, wsClient, sidebarData }) => ({
  session,
  wsClient,
  sidebarData,
});

const mapDispatchToProps = (dispatch) => ({
  saveWSClient: (data) => dispatch(saveWSClient(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
