import React from "react";

const DateSelector = ({ name, label, register, required, defaultValue, card, theme }) => {
  return (
    <div className="form-group">
             <label>{label}</label>
            <input 
            {...register(name)}
            className={`form-control ${card ? 'card-input' : null} ${theme === 'dark' ? 'dark' : null}`}
              type="date" 
              required={required}
              defaultValue={defaultValue}
            />
    </div>
    
  );
};

export default DateSelector;
