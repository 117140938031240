import React, {useEffect} from "react";
import validateToken from "../../util/dev-ops/validateToken";
import {signin} from "../../actions/session/signIn";
import {connect} from "react-redux";
import {getGitlabGroupIdData} from "../../actions/gitlabGroupId";
import {getGitlabSubGroupIdData} from "../../actions/gitlabSubGroupId";
import {updateUser} from "../../util/settings/updateUser";
import getGitlabSubGroups from "../../util/dev-ops/getGitlabSubGroups"
import {getGitlabSubGroupData} from '../../actions/gitlabSubGroups'

function SubGroupSelect (props) {
    useEffect(event => {
        if (props.gitlabGroupId) {
            validateToken(props.session).then(data => {
				if (!data.message) {
					props.signin(data.user)
					getGitlabSubGroups(data, props.gitlabGroupId).then(subGroup => {
						props.getGitlabSubGroupData(subGroup)
					})
				}

            })
        }
    },[props.gitlabGroups])


    function handleGroupChange (event) {
        props.getGitlabSubGroupIdData(event.target.value)
        updateUser({
            email: props.session.email, gitlabSubGroup: event.target.value
        }).then(response => response.json()).then(data => {
            props.signin(data.user)
        })
    }

    return (
        <select onChange={handleGroupChange} style={{display: 'inline-flex', height: '40px'}}  name="group" className="form-control card-input select mg-r-8" required="" value={props.session.gitlabSubGroup}>
            <option key={0} data-index={0} value={0} >No Subgroup Selected - Root Folder</option>
            {
                props.gitlabSubGroups ? props.gitlabSubGroups.map((group,index) => {
                    return (
                        <option key={index+ 1} data-index={index + 1} value={group.id} >{group.name}</option>
                    )
                }) : <></>
            }
        </select>
    )
}
const mapStateToProps = ({session, gitlabSubGroups, gitlabGroups, gitlabGroupId, gitlabSubGroupId}) => ({
    session, gitlabSubGroups, gitlabGroups, gitlabGroupId, gitlabSubGroupId
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user)),
    getGitlabSubGroupData: groups => dispatch(getGitlabSubGroupData(groups)),
    getGitlabGroupIdData: id => dispatch(getGitlabGroupIdData(id)),
    getGitlabSubGroupIdData: id => dispatch(getGitlabSubGroupIdData(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubGroupSelect);
