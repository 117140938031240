import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const getProjectDetail = async (id) => {
    // console.log("getProjectDetail", id)
    return fetch(`${AUTH_BASE_URL}projects/project/${id}`, {
    // return fetch(`${AUTH_BASE_URL}projects/project/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
}