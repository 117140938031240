import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const addPinnedItems = async (data) => {
    // console.log("addPinnedProject: data:", data)
    return fetch(`${AUTH_BASE_URL}auth/user/pinned/add/`+data.userId, {
        method: "PUT",
        body: JSON.stringify({data: data}),
        headers: {
            "Content-Type": "application/json"
        }
    })
}