import React from "react";

/**
 * 
 * @param {Optiona} noHeader - true to indicate an inline item which will supply its on header. 
 * false indicates a block item, which will pass the header as a prop.
 * @returns 
 */
const Text = (props) => {
  return (
    <>
    {props.noHeader ? (
      <div className="form-group mg-b-0 flex-grow-1">
    <input
      {...props.register(props.name)}
      className={`form-control ${props.card ? 'card-input' : null}`}
      placeholder={props.placeholder}
      type="text"
      required={props.required}
      defaultValue={props.defaultValue}
    />
  </div>
    ) : (
      <div className="form-group">
    <label>{props.label}</label>
    <input
     {...props.register(props.name)}
      className={`form-control ${props.card ? 'card-input' : null}`}
      placeholder={props.placeholder}
      type="text"
      required={props.required}
    />
  </div>
    )}
    </>
  );
};

export default Text;
