import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Wrapper from "../global/Wrapper";
import { updateSession } from "../../actions/session/updateSession";
import { toastMessage } from "../../actions/toast";
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import useFetchImage from "../../util/useFetchImage";
import DashboardWidgetContainer from "./DashboardWidgetContainer";

const widgets = [
    { type: "tasks", size: 2, column: 1 },
    { type: "activity", size: 1, column: 1 },
    { type: "tests", size: 1, column: 2 },
    { type: "resources", size: 1, column: 1 },
    { type: "projects", size: 1, column: 1 },
    { type: "figma", size: 1, column: 1 },
    { type: "executive news", size: 1, column: 3 },
    { type: "featured news", size: 1, column: 3 },
    { type: "Saved news", size: 1, column: 3 },
];

const Dashboard = (props) => {
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState(null);
    const [imageURLToFetch, setImageURLToFetch] = useState(null);
    const [dashWidgets, setDashWidgets] = useState(widgets);
    const [editMode, setEditMode] = useState(false);
    const [dragItem, setDragItem] = useState(null);
    const [dropLocation, setDropLocation] = useState(null);

    const fetchedImage = useFetchImage(imageURLToFetch);

    useEffect(() => {
        setImageURLToFetch(newsData?.openGraphSummary?.image?.mediaUrl);
    }, [newsData]);

    useEffect(() => {
        setImage(fetchedImage);
    }, [fetchedImage, imageURLToFetch]);

    useEffect(() => {
        setDragItem(null);
        setDropLocation(null);
    }, [dashWidgets]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${AUTH_BASE_URL}news/executive`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error(`An error has occurred: ${response.status}`);
                }

                const data = await response.json();

                setNewsData(data.documents[0].item);
                setLoading(false);
            } catch (error) {
                console.error("There was a problem with the fetch operation:", error);
            }
        };

        fetchData();
    }, []);

    const dragStart = (item) => {
        setDragItem(item);
    };

    const dragOver = (event, item, location) => {
        event.preventDefault();

        if (event.currentTarget.classList.contains("drop-zone")) {
            event.currentTarget.classList.add("drag-over");
        }

        setDropLocation({ item: item, location: location });
    };

    const dragLeave = (event) => {
        event.preventDefault();

        if (event.currentTarget.classList.contains("drop-zone")) {
            event.currentTarget.classList.remove("drag-over");
        }

        setDropLocation(null);
    };

    const dropItem = (event) => {
        event.preventDefault();

        if (dropLocation && dragItem) {
            let newWidgets = [...dashWidgets];
            const itemToMoveIndex = newWidgets.findIndex((f) => f.type === dragItem.type);
            const itemToMove = newWidgets.splice(itemToMoveIndex, 1)[0];

            itemToMove.size = dropLocation.item.size;
            itemToMove.column = dropLocation.item.column;

            const dropLocationIndex = newWidgets.findIndex((f) => f.type === dropLocation.item.type);
            let newArr;

            if (dropLocation.location === "above") {
                newArr = [...newWidgets.slice(0, dropLocationIndex), itemToMove, ...newWidgets.slice(dropLocationIndex)];
            } else {
                newArr = [...newWidgets.slice(0, dropLocationIndex + 1), itemToMove, ...newWidgets.slice(dropLocationIndex + 1)];
            }

            setDashWidgets(newArr);
        }
    };

    return (
        <Wrapper>
            {/* Always start the page with Wrapper, including sidebar and header */}
            <div className="main-content pt-0">
                <div className="container-fluid">
                    <div className="inner-body dashboard">
                        {props.session.isPearson && props.session.systemAccess === 1 ? (
                            <div className="row row-sm">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                                    <div className="card custom-card pd-0">
                                        <div className="card-body">
                                            <h5>Your current role is guest, and you have minimum access.</h5>
                                            <p>
                                                You can request to change your role through{" "}
                                                <a
                                                    href={
                                                        "https://pearson.service-now.com/help?id=sc_cat_item&table=sc_cat_item&sys_id=84cff2d8db8f1744af8b36fffe9619c1"
                                                    }
                                                    target={"_blank"}
                                                >
                                                    Pearson My Help.
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Dashboard</h2>
                            </div>
                            {editMode ? (
                                <button type="button" class="btn btn-primary my-2 btn-icon-text" onClick={() => setEditMode(false)}>
                                    Done
                                </button>
                            ) : (
                                <button type="button" class="btn btn-primary my-2 btn-icon-text" onClick={() => setEditMode(true)}>
                                    Manage
                                </button>
                            )}
                        </div>
                        <div className="sortable ui-sortable">
                            <div className="row row-sm">
                                <div className="col-lg-12 col-sm-12">
                                    <div className="card custom-card shadow-2" style={{ background: "teal", minHeight: "100px" }}>
                                        <h2>Banner</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-sm">
                                <div className="col-lg-12 col-sm-12">
                                    {dashWidgets.filter((f) => f.size === 3).length > 0 ? (
                                        dashWidgets.filter((f) => f.size === 3).map((item, index) => {
                                            return (
                                                <DashboardWidgetContainer
                                                    draggable={editMode}
                                                    item={item}
                                                    title={item.type}
                                                    index={"size3" + index}
                                                    dragStart={() => dragStart(item)}
                                                    dragOver={(e, loc) => dragOver(e, item, loc)}
                                                    dragStop={(e) => dragLeave(e)}
                                                    drop={(e) => dropItem(e)}
                                                >
                                                    <p>test test</p>
                                                </DashboardWidgetContainer>
                                            );
                                        })
                                    ) : (
                                        <div
                                            className={`column-empty drop-zone ${editMode ? "active" : ""}`}
                                            onDragEnter={(event) => dragOver(event)}
                                            onDragLeave={(event) => dragLeave(event)}
                                            onDragOver={(event) => dragOver(event, { column: 1, size: 3 }, "below")}
                                            onDrop={(e) => dropItem(e)}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="row row-sm">
                                <div className="col-lg-8 col-sm-12">
                                    <div className="row row-sm">
                                        <div className="col-lg-12 col-sm-12">
                                            {/* Any two-column size */}
                                            {dashWidgets.filter((f) => f.size === 2).length > 0 ? (
                                                dashWidgets
                                                    .filter((f) => f.size === 2)
                                                    .map((item, index) => {
                                                        return (
                                                            <DashboardWidgetContainer
                                                                draggable={editMode}
                                                                item={item}
                                                                title={item.type}
                                                                type={item.type}
                                                                key={"size2" + index}
                                                                dragStart={() => dragStart(item)}
                                                                dragOver={(e, loc) => dragOver(e, item, loc)}
                                                                dragStop={(e) => dragLeave(e)}
                                                                drop={(e) => dropItem(e)}
                                                            >
                                                                <div className="tab-menu-heading">
                                                                    <ul class="nav panel-tabs main-nav-line main-nav-line-chat d-flex pl-3 ">
                                                                        <li>
                                                                            <a href="#team-tasks" class="nav-link active mr-3" data-toggle="tab">
                                                                                Activity
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#team-tasks" class="nav-link" data-toggle="tab">
                                                                                Tasks
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="panel-body tabs-menu-body p-0">
                                                                    <div className="tab-content">
                                                                        <div className="tab-pane" id="team-tasks"></div>
                                                                        <div className="tab-pane" id="team-tasks"></div>
                                                                    </div>
                                                                </div>
                                                            </DashboardWidgetContainer>
                                                        );
                                                    })
                                            ) : (
                                                <div
                                                    className={`column-empty drop-zone ${editMode ? "active" : ""}`}
                                                    onDragEnter={(event) => dragOver(event)}
                                                    onDragLeave={(event) => dragLeave(event)}
                                                    onDragOver={(event) => dragOver(event, { column: 1, size: 2 }, "below")}
                                                    onDrop={(e) => dropItem(e)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="row row-sm">
                                        {/* column 1 */}
                                        <div className="col-lg-6 col-sm-12">
                                            {dashWidgets.filter((f) => f.column === 1).length > 0 &&
                                            dashWidgets.filter((f) => f.size === 1).length > 0 ? (
                                                dashWidgets
                                                    .filter((f) => f.size === 1 && f.column === 1)
                                                    .map((item, index) => {
                                                        return (
                                                            <DashboardWidgetContainer
                                                                draggable={editMode}
                                                                item={item}
                                                                title={item.type}
                                                                key={"size1" + index}
                                                                dragStart={() => dragStart(item)}
                                                                dragOver={(e, loc) => dragOver(e, item, loc)}
                                                                dragStop={(e) => dragLeave(e)}
                                                                drop={(e) => dropItem(e)}
                                                            >
                                                                <p>test test test</p>
                                                            </DashboardWidgetContainer>
                                                        );
                                                    })
                                            ) : (
                                                <div
                                                    className={`column-empty drop-zone ${editMode ? "active" : ""}`}
                                                    onDragEnter={(event) => dragOver(event)}
                                                    onDragLeave={(event) => dragLeave(event)}
                                                    onDragOver={(event) => dragOver(event, { column: 1, size: 1 }, "below")}
                                                    onDrop={(e) => dropItem(e)}
                                                />
                                            )}
                                        </div>
                                        {/* column 2 */}
                                        <div className="col-lg-6 col-sm-12">
                                            {dashWidgets.filter((f) => f.column === 2).length > 0 &&
                                            dashWidgets.filter((f) => f.size === 1).length > 0 ? (
                                                dashWidgets
                                                    .filter((f) => f.column === 2)
                                                    .map((item, index) => {
                                                        return (
                                                            <DashboardWidgetContainer
                                                                draggable={editMode}
                                                                item={item}
                                                                title={item.type}
                                                                key={"size1" + index}
                                                                dragStart={() => dragStart(item)}
                                                                dragOver={(e, loc) => dragOver(e, item, loc)}
                                                                dragStop={(e) => dragLeave(e)}
                                                                drop={(e) => dropItem(e)}
                                                            >
                                                                <p>test test</p>
                                                            </DashboardWidgetContainer>
                                                        );
                                                    })
                                            ) : (
                                                <div
                                                    className={`column-empty drop-zone ${editMode ? "active" : ""}`}
                                                    onDragEnter={(event) => dragOver(event)}
                                                    onDragLeave={(event) => dragLeave(event)}
                                                    onDragOver={(event) => dragOver(event, { column: 2, size: 1 }, "below")}
                                                    onDrop={(e) => dropItem(e)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* column 3 */}
                                <div className="col-lg-4 col-sm-12">
                                    {dashWidgets.filter((f) => f.column === 3).length > 0 &&
                                    dashWidgets.filter((f) => f.size === 1).length > 0 ? (
                                        dashWidgets
                                            .filter((f) => f.column === 3)
                                            .map((item, index) => {
                                                return (
                                                    <DashboardWidgetContainer
                                                        draggable={editMode}
                                                        title={item.type}
                                                        key={"size1" + index}
                                                        dragStart={() => dragStart(item)}
                                                        dragOver={(e, loc) => dragOver(e, item, loc)}
                                                        dragStop={(e) => dragLeave(e)}
                                                        drop={(e) => dropItem(e)}
                                                    >
                                                        <p>test test</p>
                                                    </DashboardWidgetContainer>
                                                );
                                            })
                                    ) : (
                                        <div
                                            className={`column-empty drop-zone ${editMode ? "active" : ""}`}
                                            onDragEnter={(event) => dragOver(event)}
                                            onDragLeave={(event) => dragLeave(event)}
                                            onDragOver={(event) => dragOver(event, { column: 3, size: 1 }, "below")}
                                            onDrop={(e) => dropItem(e)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );

};

const mapStateToProps = ({ session, wsClient, score }) => ({
  session,
      wsClient,
  score,
});

// props.toastMessage('Hi from the global toast system.')
const mapDispatchToProps = (dispatch) => ({
  updateSession: (user) => dispatch(updateSession(user)),
  toastMessage: (data) => dispatch(toastMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
