import React, {useEffect} from 'react'
import {connect} from "react-redux";
import {signin} from "../../actions/session/signIn";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TeamSelect from "../global/figma/TeamSelect";


function Figma (props) {


    function handleOnClick () {
		window.location.href = `https://www.figma.com/oauth?client_id=${process.env.REACT_APP_FIGMA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FIGMA_REDIRECT}&scope=file_read&state=${props.session.email}:${window.location.pathname}&response_type=code`
    }
    return (
        <div className="card custom-card">
            <div className="card-body">
                <div className="card-item">
                    <div className="card-item-title mb-2">
                        <label className="main-content-label tx-14 font-weight-bold mb-1">Figma Designers</label>
						<span className="d-block tx-13 mb-0">You'll need to connect your Figma account to use design operations</span>
                    </div>
                    <div className="card-item-body d-flex">
                        <div className="card-item-stat">
                            <div className="btn-list">
                                <button onClick={handleOnClick} className="btn ripple btn-secondary">
                                    <FontAwesomeIcon icon={`fa-brands fa-figma`} className={'mg-r-8'}/>Connect to Figma
                                </button>
                            </div>
                            {
                                !props.session.figmaAccessToken ?
                                    <span className="label text-danger d-flex align-items-center mg-l-8">
                                        <span className="dot-label bg-danger mr-1"></span>
                                        <span className={'text-danger'} style={{fontSize: '12px'}}>Not Connected</span>
                                    </span> :
                                    <span className="label text-success d-flex align-items-center mg-l-8">
                                        <span className="dot-label bg-success mr-1"></span>
                                        <span className={'text-success'} style={{fontSize: '12px'}}>Connected</span>
                                    </span>
                            }
                        </div>

                    </div>
					{
						props.session.figmaAccessToken ?
							<>
								<div className="card-item-body d-flex mg-t-40 bd-t">
									<div className="card-item-stat d-flex flex-column ">
										<div className="d-flex flex-column profile wd-100p">
											<span className="d-block tx-13 mg-b-10">Select a default team.</span>
											<div>
												<TeamSelect type={'default'}/>
											</div>

										</div>
									</div>

								</div>

							</>

							: <></>
					}

                </div>
            </div>
        </div>

    )
}
const mapStateToProps = ({session, figmaProjectsTeamId}) => ({
    session,figmaProjectsTeamId
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Figma);
