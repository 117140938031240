import React from "react";
import TableRow from "./TableRow";
import {connect} from "react-redux";

function Table (props) {
	return (
		<table className="table card-table table-striped table-vcenter mb-0">
			<thead>
			<tr>
				<th className={'wd-lg-5p'}><span>Creator</span></th>
				<th className={'wd-lg-25p'}><span>Test Url</span></th>
				<th className={'wd-lg-15p'}><span>Repo</span></th>
				<th className={'wd-lg-10p'}><span>Branch</span></th>
				<th className={'wd-lg-10p'}><span># Users</span></th>
				<th className={'wd-lg-5p'}><span>Results</span></th>
				<th className={'wd-lg-5p'}><span>Created</span></th>
				<th className={'wd-lg-5p'}><span>Active</span></th>
				<th className={'wd-lg-35p'}><span>Action</span></th>
			</tr>
			</thead>
			<tbody>
			{
				props.allTests ? props.allTests.map((test,index) => {
					return (
						<TableRow  key={index} data={test} handleIntroductionTextModal={props.handleIntroductionTextModal} handleIntroductionModal={props.handleIntroductionModal} handleEditModal={props.handleEditModal} handleOpenCollectModal={props.handleOpenCollectModal} handlePreQuestionModal={props.handlePreQuestionModal} handlePostQuestionModal={props.handlePostQuestionModal} handleImportUsersModal={props.handleImportUsersModal}/>
					)
				}) : []
			}

			</tbody>
		</table>
	)
}
const mapStateToProps = ({allTests}) => ({
	allTests
});


export default connect(
	mapStateToProps
)(Table);
