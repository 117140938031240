import Wrapper from "../../global/Wrapper";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NewTest from "./NewTest";
import Toast from "../../global/Toast";
import {useNavigate} from "react-router-dom";
import Table from "./Table";
import CollectUserData from "../self-lead/CollectUserData";
import Introduction from "../self-lead/IntroductionVideo";
import IntroductionText from "../self-lead/IntroductionText";
import PreQuestionData from "../self-lead/PreQuestionData";
import PostQuestionData from "../self-lead/PostQuestionData";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import {connect} from "react-redux";
import EditTest from "./EditTest";
import {allClassTests} from "../../../actions/allClassTests";
import DuplicateTest from "./DuplicateTest";


function ClassTest (props) {
	const [data, setData] = useState(null)
	const [preData, setPreData] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [editData, setEditData] = useState(false)
	const [showCollectModal, setShowCollectModal] = useState(false)
	const [showPreQuestionModal, setPreQuestionModal] = useState(false)
	const [showPostQuestionModal, setPostQuestionModal] = useState(false)
	const [showIntroductionModal, setShowIntroductionModal] = useState(false)
	const [showIntroductionTextModal, setShowIntroductionTextModal] = useState(false)
	const [showDuplicateModal, setShowDuplicateModal] = useState(false)
	const navigate = useNavigate();

	const handleOpenNewModal = (path) => {
		navigate('/usertesting/classtest/new')
		setShowModal(true)
	}
	const handleEditModal = (data) => {
		setEditData(data)
		navigate('/usertesting/classtest/edit')
		setShowEditModal(true)
	}
	const handleDuplicateModal = (data) => {
		console.log('the data', data)
		setEditData(data)
		navigate('/usertesting/classtest/duplicate')
		setShowDuplicateModal(true)
	}
	const handleIntroductionModal = (data) => {
		setData(data)
		navigate('/usertesting/online/introduction/video')
		setShowIntroductionModal(true)
	}
	const handleIntroductionTextModal = (data) => {
		console.log('intro text fired', data)
		setData(data)
		navigate('/usertesting/online/introduction/text')
		setShowIntroductionTextModal(true)
	}
	const handleOpenCollectModal = (data) => {
		setData(data)
		navigate('/usertesting/online/collect')
		setShowCollectModal(true)
	}
	const handlePreQuestionModal = (data) => {
		setPreData(data)
		navigate('/usertesting/online/pre-data')
		setPreQuestionModal(true)
	}
	const handlePostQuestionModal = (data) => {
		setPreData(data)
		navigate('/usertesting/online/post-data')
		setPostQuestionModal(true)
	}

	const handleCloseNewModal = () => {
		setShowModal(false)
		setShowEditModal(false)
		setShowDuplicateModal(false)
		navigate('/usertesting/classtest')
	}

	useEffect(()=> {
		if (window.location.pathname === '/usertesting/classtest/new') {
			setShowModal(true)
		} else if (window.location.pathname === '/usertesting/classtest/edit') {
			setShowEditModal(true)
		}  else if (window.location.pathname === '/usertesting/classtest/duplicate') {
			setShowEditModal(true)
		} else {
			setShowModal(false)
		}
	},[])

	useEffect(()=> {
		if(!props.allClassTestData || props.allClassTestData.length === 0) {
			fetch(`${AUTH_BASE_URL}usertesting/classtest`, {
				method: 'GET',
				headers: {
					"Content-Type": "application/json",
				},
			}).then((response => {
				return response.json()
			})).then((data => {
				console.log('hi from data', data)
				props.allClassTests(data)
			}))
		}
	},[])
	return (
		<Wrapper>
			<div className="main-content pt-0">
				<div className="container-fluid">
					<div className="inner-body users">
						{/* Page Header */}
						{showDuplicateModal === true ? <DuplicateTest data={editData} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showModal === true ? <NewTest handleCloseNewModal={handleCloseNewModal} /> : null}
						{showEditModal === true ? <EditTest data={editData} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showCollectModal === true ? <CollectUserData data={data} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showPreQuestionModal === true ? <PreQuestionData data={preData} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showPostQuestionModal === true ? <PostQuestionData data={preData} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showIntroductionModal === true ? <Introduction data={data} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showIntroductionTextModal === true ? <IntroductionText data={data} handleCloseNewModal={handleCloseNewModal} /> : null}
						<div className="page-header">
							<div>
								<h2 className="main-content-title tx-24 mg-b-5">User Testing - Class Test</h2>
							</div>
							<div className="d-flex">
								<div className="justify-content-center table-controls">
									<button onClick={handleOpenNewModal} type="button" className="btn btn-primary my-2 btn-icon-text">
										<FontAwesomeIcon className='mg-r-4' icon={`fa-solid fa-plus`} /> New Test
									</button>
								</div>
							</div>
						</div>
						{/* End Page Header */}
						{/*Row*/}
						<div className="row row-sm">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
								<div className="table-responsive" style={{minHeight: '75vh'}}>
									<Table handleDuplicateModal={handleDuplicateModal} handleIntroductionTextModal={handleIntroductionTextModal} handleIntroductionModal={handleIntroductionModal} handleEditModal={handleEditModal} handleOpenCollectModal={handleOpenCollectModal} handlePreQuestionModal={handlePreQuestionModal} handlePostQuestionModal={handlePostQuestionModal}/>
								</div>
							</div>{/* COL END */}
						</div>
						{/* row closed  */}
					</div>
				</div>
			</div>

		</Wrapper>
	)
}


const mapStateToProps = ({session, users, allClassTestData}) => ({
	session, users, allClassTestData
});

const mapDispatchToProps = dispatch => ({
	allClassTests: (data) => dispatch(allClassTests(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ClassTest);

