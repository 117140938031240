import React, {useRef, useEffect, useState} from 'react';
import Sidebar from "./sidebar/SidebarContainer";
import Header from "../global/Header";
import {getSidebarData} from "../../actions/sidebar";
import {connect} from "react-redux";
import Toast from "./Toast";
import {saveWSClient} from "../../actions/wsclient";

const Wrapper = (props) => {
	// console.log(
	// 	'wrap toast', props.toast)
	const wrapperRef = useRef(null)

    const handleSidebar = () => {
        if(wrapperRef.current.classList.contains('main-sidebar-show')){
            wrapperRef.current.classList.remove('main-sidebar-show')
        }  else{
            wrapperRef.current.classList.add('main-sidebar-show')
        }
    }

    if (props.noSidebar && props.noHeader) {
        return (
			<>
				<Toast/>
				<div ref={wrapperRef} className="page main-body">
					<div className="main-content">
						<div className="container-fluid">
							{props.children}
						</div>
					</div>
				</div>
			</>

        )
    } else if (props.noSidebar){
        return (
			<>
				<Toast/>
				<div ref={wrapperRef} className="page main-body">
					<Header handleSidebar={handleSidebar} noSidebar={props.noSidebar}/>
					<div className="main-content pd-12">
						<div className="container-fluid">
							{props.children}
						</div>
					</div>
				</div>
			</>


        )
    } else if (props.noHeader) {
        return (
			<>
				<Toast/>
				<div ref={wrapperRef} className="page main-body leftmenu main-sidebar-show">
					<Sidebar/>
					<div className="main-content side-content">
						<div className="container-fluid">
							{props.children}
						</div>
					</div>
				</div>
			</>


        )
    } else {
        return (
			<>
				<Toast/>
				<div ref={wrapperRef} className="page main-body leftmenu main-sidebar-show">
					<Sidebar/>
					<Header handleSidebar={handleSidebar}/>
					<div className="main-content side-content">
						<div className="container-fluid">
							{props.children}
						</div>
					</div>
				</div>
			</>


        )
    }
}

const mapStateToProps = ({session, toast}) => ({
	session, toast
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Wrapper);
