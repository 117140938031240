import React, {useState} from "react";

const WidgetTabs = (props) => {
    const [selectedTab, setSelectedTab] = useState(0);


    return (
        <div className="tab-container">
            <div class="tab-menu-heading">
                <ul class="nav panel-tabs main-nav-line main-nav-line-chat d-flex p-0" role="tablist">
                {props.children.length > 1 ? props.children.map((child, index) => {
                    return (
                        <li role="presentation" key={"tab-" + index}>
                        <button className={selectedTab === index ? "nav-link active" : "nav-link"} role="tab" 
                        aria-selected={selectedTab === index}
                        aria-controls={child.props.id}
                         onClick={() => setSelectedTab(index)}>{child.props.label}</button></li>
                    ) 
                }) : 
                    <li role="presentation">
                        <button className={selectedTab === 0 ? "nav-link active" : "nav-link"} role="tab" 
                        aria-selected={selectedTab === 0}
                        aria-controls={props.children[0].id}
                         onClick={() => setSelectedTab(0)}>{props.children[0].label}</button></li>
                    
            }
                </ul>
            </div>
            <div className="tab-content">
                {props.children.length > 1 ? props.children.map((child, index) => {
                    if (index !== selectedTab) return undefined;
                    return child.props.children;
                 }) : props.children}
            </div>
        </div>
    )
}
export default WidgetTabs