import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import Select from "react-select";
import allTests from "../../../util/usertesting/allTests";
import {allTestData} from "../../../actions/allUserTests";


const EditTest = props => {
	const [nameData, setnameData] = useState({})
	const [repoData, setRepoData] = useState()
	const [repoNameData, setRepoNameData] = useState()
	const [branchData, setBranchData] = useState([])
	const [selected, setSelected] = useState()
	const [selectBranch, setSelectBranch] = useState();
	const [introduction, setIntroduction] = useState(false);
	const [introductionText, setIntroductionText] = useState(false);
	const [recordTest, setRecordTest] = useState(false);
	const urlRef = useRef(null);
	const branchRef = useRef()
	const pwRef = useRef()
	const nameRef = useRef()
	const numRef = useRef()
	const collectRef = useRef()
	const preRef = useRef()
	const postRef = useRef()

	useEffect(() => {
		if (props.data) {
			setIntroduction(props.data.introduction);
			setIntroductionText(props.data.introductionText)
			setRecordTest(props.data.recordTest)
		}
	}, [props.data]);

	useEffect(()=> {
		if (props.data) {
			setRepoNameData(props.data.repoName)
			setSelected(props.data.repo)
		}
		let group = props.session.gitlabGroup ? props.session.gitlabGroup : '10566838'
		fetch(`${AUTH_BASE_URL}dev-ops/gitlab/repos/recent?group=${group}&id=${props.session.gitLab[0].id}`, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json"
			}
		}).then(response => response.json()).then(data => {
			let filteredData = data.framework.filter(val => val.frameworkName === 'Create React App')
			setRepoData(filteredData)
		})
	},[])

	useEffect(()=> {
		if (selected) {

			fetch(`${AUTH_BASE_URL}dev-ops/gitlab/repos/branches?id=${selected.toString()}`, {
				method: 'GET',
				headers: {
					"Content-Type": "application/json"
				}
			}).then(response => response.json()).then(data => {
				setBranchData(data.branchData.map(item => item.name))
			})
		}
	},[selected])
	let data = {}

	function handleName (event) {
		data.name = event.target.value
		setnameData(data)
	}

	function handleUpdate () {
		console.log(props.data._id)
		let dataObj = {}
		dataObj.name = nameData.name ? nameData.name : nameRef.current.value
		dataObj.url = urlRef.current.value
		dataObj.users = numRef.current.value
		dataObj.repoName = repoNameData
		dataObj.repo = selected
		dataObj.branch = selectBranch ? selectBranch : props.data.branch
		dataObj.password = pwRef.current.value
		dataObj.collectPersonal = collectRef.current.checked
		dataObj.preTest = preRef.current.checked
		dataObj.postTest = postRef.current.checked
		dataObj.introduction = introduction;
		dataObj.introductionText = introductionText;

		dataObj.recordTest = recordTest;
		dataObj.id = props.data._id
		fetch(`${AUTH_BASE_URL}usertesting`, {
			method: 'PUT',
			body: JSON.stringify(dataObj),
			headers: {
				"Content-Type": "application/json"
			}
		}).then(response => response.json()).then(data => {
			props.handleCloseNewModal()
			allTests().then(data => {
				props.allTestData(data)
			})
		})
	}

	function handleUrlInputValue () {
		return 'usertesting'
	}

	return(
		<div className="modal d-block">
			<div className="modal-dialog" role="dialog">
				<div className="modal-content modal-content-demo shadow">
					<div className="modal-header">
						<h6 className="modal-title">Edit</h6>
					</div>
					<form className={"pd-30"}>
						<div className={"mg-b-20"}>
							<label>Name of the test</label>
							<input ref={nameRef} defaultValue={props.data ? props.data.name : ''} onChange={handleName} className="form-control"/>
						</div>
						<div className={"mg-b-20"}>
							<label>URL - read only and generated from the name</label>
							<input ref={urlRef} readOnly className="form-control" value={`https://${nameData.name ? nameData.name.split(' ').join('-') : (props.data ? props.data.name.split(' ').join('-') : '')}.${handleUrlInputValue()}.pearsonct.design`} />
						</div>
						<div className={"mg-b-20"}>
							<label>Generate # of users</label>
							<input defaultValue={props.data ? props.data.users : ''} ref={numRef} className="form-control"/>
						</div>
						<div className={"mg-b-20"}>
							<label>Choose the React application to test</label>
							<Select
								options={repoData ? repoData.map(val => {console.log('the', val); return {label: val.data.name, value: val.data}}) : []}
								className="basic-multi-select"
								classNamePrefix="select"
								onChange={(value)=>{setSelected(value.value.id); setRepoNameData(value.label)}}
								defaultInputValue={props.data ? props.data.repoName : ''}
							/>
						</div>
						<div className={"mg-b-20"}>
							<label>Choose the branch you want tested</label>
							<Select
								ref={branchRef}
								options={branchData ? branchData.map(val => {return {label: val, value: val}}) : [{label: 'hi', value: 'hi'}]}
								className="basic-multi-select"
								classNamePrefix="select"
								onChange={(value)=>{setSelectBranch(value.value)}}
								defaultInputValue={props.data ? props.data.branch : ''}
							/>
						</div>
						<div className={"mg-b-20"}>
							<label>Set password to access test</label>
							<input defaultValue={props.data ? props.data.password : ''} ref={pwRef} className="form-control" type={"text"}/>
						</div>
						<div className="mg-b-20">
							<label className="custom-switch">
								<input
									type="checkbox"
									name="custom-switch-checkbox"
									className="custom-switch-input"
									onChange={(e) => setIntroduction(e.target.checked)}
									defaultChecked={props.data ? props.data.introduction : false}
								/>
								<span className="custom-switch-indicator"></span>
								<span className="custom-switch-description">Add an introduction video for context? </span>
							</label>
						</div>
						<div className="mg-b-20">
							<label className="custom-switch">
								<input
									type="checkbox"
									name="custom-switch-checkbox"
									className="custom-switch-input"
									onChange={(e) => setIntroductionText(e.target.checked)}
									defaultChecked={props.data ? props.data.introductionText : false}
								/>
								<span className="custom-switch-indicator"></span>
								<span className="custom-switch-description">Add an introduction text for context? </span>
							</label>
						</div>
						<div className="mg-b-20">
							<label className="custom-switch">
								<input
									type="checkbox"
									name="custom-switch-checkbox"
									className="custom-switch-input"
									onChange={(e) => setRecordTest(e.target.checked)}
									defaultChecked={props.data ? props.data.recordTest : false}
								/>
								<span className="custom-switch-indicator"></span>
								<span className="custom-switch-description">Record the test? </span>
							</label>
						</div>
						<div className={"mg-b-20"}>
						<label className="custom-switch"><input ref={collectRef} type="checkbox" name="custom-switch-checkbox"
																className="custom-switch-input" defaultChecked={props.data.collectPersonal} /><span
							className="custom-switch-indicator"></span><span className="custom-switch-description">Collect personal data of user?</span></label>
						</div>
						<div className={"mg-b-20"}>
							<label className="custom-switch"><input ref={preRef} type="checkbox" name="custom-switch-checkbox"
																	className="custom-switch-input" defaultChecked={props.data.preTest} /><span
								className="custom-switch-indicator"></span><span className="custom-switch-description">Add pre test questioner?</span></label>
						</div>
						<div className={"mg-b-20"}>
							<label className="custom-switch"><input ref={postRef}  type="checkbox" name="custom-switch-checkbox"
																	className="custom-switch-input" defaultChecked={props.data.postTest} /><span
								className="custom-switch-indicator"></span><span className="custom-switch-description">Add Post Test Questioner?</span></label>
						</div>
						<div className={"mg-b-20"}>
						<p>You can manage what data youd like to collect and add questioners by clicking the manage button after you Save.</p>
						</div>
					</form>
					<div className="modal-footer">
						<button type="button" onClick={props.handleCloseNewModal} className="btn btn-secondary">Close</button>
						<button type="button" onClick={handleUpdate} className="btn btn-primary">Save</button>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	session: state.session,
	testing: state.testing
})

export default connect(mapStateToProps, {allTestData})(EditTest);
