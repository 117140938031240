import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toastMessage } from "../../../../../actions/toast";
import AUTH_BASE_URL from "../../../../../util/AUTH_BASE_URL";
import getUserById from "../../../../../util/users/getUserById";

function TemplateRow({ template, session, toastMessage, fetchData }) {
    const [user, setUser] = useState(null);

    const handleDeleteTemplate = async (templateId) => {
        try {
            const response = await fetch(`${AUTH_BASE_URL}templates/${templateId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${session.digitalAccessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                toastMessage('Template deleted successfully.');
                fetchData(session.digitalAccessToken, false);
            } else {
                const errorData = await response.json();
                toastMessage(`Failed to delete template: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error deleting template:', error);
            toastMessage('Error deleting template.');
        }
    };

    const handleViewRepo = (repoUrl) => {
        window.open(repoUrl, '_blank');
    };

    useEffect(() => {
        const fetchUser = async () => {
            if (template.type !== 'global') {
                try {
                    const userData = await getUserById(template.type);
                    setUser(userData);
                } catch (error) {
                    console.error('Error fetching user:', error);
                }
            }
        };

        fetchUser();
    }, [template.type]);

    return (
        <tr>
            <td>{template.name || 'N/A'}</td>
            <td>
                {template.repo ? (
                    <a href={template.repo} target="_blank" rel="noopener noreferrer">
                        {template.repo}
                    </a>
                ) : 'N/A'}
            </td>
            <td>{template.type !== 'global' ? (user ? user.email : 'Loading...') : template.type}</td>
            <td>
                <button className="btn btn-secondary" onClick={() => handleDeleteTemplate(template._id)}>Remove</button>
            </td>
        </tr>
    );
}

const mapStateToProps = ({ session }) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(TemplateRow);
