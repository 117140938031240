import React, {useRef, useEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 //images
 import serviceNow from "../../assets/images/brand/serviceNow.png"

const BannerCarousel = (props) => {

    const slider_settings = {
        dots: true,
        arrows: false,
        // autoplay: true,
        autoplay: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false
    };

    return (
        <div className="feature-carousel slider-container">
            <Slider {...slider_settings}>

                <div className='slide'>
                    <div className={'framework-banner align-items-center justify-items-center'} style={{maxHeight: '70px'}}>
                        <div>
                            <div style={{pointerEvents: 'none'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="152" viewBox="0 0 326 152">
                                    <path d="M33.8113 125.681C28.6661 125.681 24.0109 125.028 19.8458 123.721C15.6806 122.414 12.128 120.454 9.18786 117.841C6.24774 115.227 3.96099 111.92 2.32759 107.918C0.775864 103.916 0 99.22 0 93.8298V41.8878H26.0935V92.7273C26.0935 95.5857 26.6652 97.7908 27.8086 99.3426C29.0336 100.813 31.0345 101.548 33.8113 101.548C36.5881 101.548 38.5482 100.813 39.6915 99.3426C40.9166 97.7908 41.5291 95.5857 41.5291 92.7273V41.8878H67.7451V93.8298C67.7451 99.22 66.9284 103.916 65.295 107.918C63.7433 111.92 61.4566 115.227 58.4348 117.841C55.4947 120.454 51.942 122.414 47.7769 123.721C43.6117 125.028 38.9565 125.681 33.8113 125.681ZM33.8113 110.981C21.5608 110.981 15.4356 104.978 15.4356 92.9723V51.6882H12.2505V92.9723C12.2505 99.8326 14.088 105.1 17.7632 108.775C21.52 112.369 26.8694 114.166 33.8113 114.166C40.7532 114.166 46.1026 112.369 49.8594 108.775C53.6162 105.1 55.4946 99.8326 55.4946 92.9723V51.6882H52.187V92.9723C52.187 104.978 46.0618 110.981 33.8113 110.981Z"/>
                                    <path d="M117.779 84.642L133.827 113.798H137.502L119.616 81.3344L135.909 51.6882H132.234L117.779 78.0267L103.323 51.6882H99.648L115.941 81.3344L98.0555 113.798H101.731L117.779 84.642ZM103.568 81.3344L81.8849 41.8878H110.428L117.779 55.2408L125.129 41.8878H153.795L132.112 81.3344L155.388 123.598H126.844L117.779 107.305L108.836 123.598H80.2923L103.568 81.3344Z"/>
                                    <path d="M154.951 77.3055C151.982 77.3055 149.394 76.6734 147.189 75.4091C144.984 74.1155 143.294 72.2779 142.118 69.8964C140.942 67.4855 140.354 64.6483 140.354 61.3848C140.354 58.1507 140.942 55.3429 142.118 52.9614C143.294 50.5505 144.984 48.7129 147.189 47.4487C149.394 46.155 151.982 45.5082 154.951 45.5082C156.892 45.5082 158.729 45.8169 160.464 46.4343C162.199 47.0223 163.713 47.8897 165.006 49.0363C165.565 49.4773 165.844 50.0654 165.844 50.8004C165.844 51.3296 165.697 51.7853 165.403 52.1675C165.109 52.5204 164.756 52.6968 164.345 52.6968C163.845 52.6968 163.36 52.5057 162.889 52.1234C161.478 51.065 160.185 50.33 159.009 49.9184C157.862 49.4773 156.554 49.2568 155.083 49.2568C151.849 49.2568 149.365 50.3006 147.63 52.388C145.896 54.4755 145.028 57.4744 145.028 61.3848C145.028 65.3246 145.896 68.3382 147.63 70.4257C149.365 72.5131 151.849 73.5569 155.083 73.5569C156.495 73.5569 157.774 73.3364 158.92 72.8953C160.096 72.4543 161.419 71.7193 162.889 70.6903C163.478 70.308 163.963 70.1169 164.345 70.1169C164.756 70.1169 165.109 70.308 165.403 70.6903C165.697 71.0431 165.844 71.4841 165.844 72.0133C165.844 72.7483 165.565 73.3364 165.006 73.7774C163.713 74.924 162.199 75.8061 160.464 76.4235C158.729 77.0115 156.892 77.3055 154.951 77.3055Z"/>
                                    <path d="M201.131 74.3066C201.278 74.6594 201.351 74.9534 201.351 75.1886C201.351 75.7473 201.116 76.2177 200.645 76.5999C200.204 76.9821 199.705 77.1732 199.146 77.1732C198.764 77.1732 198.396 77.0703 198.043 76.8645C197.72 76.6293 197.47 76.3059 197.294 75.8943L194.383 69.279H178.242L175.331 75.8943C175.155 76.3059 174.89 76.6293 174.537 76.8645C174.184 77.0703 173.817 77.1732 173.435 77.1732C172.847 77.1732 172.317 76.9821 171.847 76.5999C171.406 76.2177 171.185 75.7473 171.185 75.1886C171.185 74.9534 171.259 74.6594 171.406 74.3066L183.843 47.0958C184.048 46.6254 184.372 46.2579 184.813 45.9933C185.283 45.7287 185.768 45.5964 186.268 45.5964C186.768 45.5964 187.238 45.7287 187.679 45.9933C188.15 46.2579 188.488 46.6254 188.694 47.0958L201.131 74.3066ZM179.873 65.6627H192.795L186.312 51.065L179.873 65.6627Z"/>
                                    <path d="M233.429 74.1302C233.694 74.5124 233.826 74.924 233.826 75.365C233.826 75.8943 233.606 76.35 233.165 76.7322C232.753 77.085 232.268 77.2614 231.71 77.2614C230.886 77.2614 230.225 76.8939 229.725 76.1589L223.815 66.9857C223.139 65.9273 222.419 65.1922 221.654 64.7806C220.89 64.3396 219.89 64.1191 218.655 64.1191H213.672V74.8799C213.672 75.5856 213.466 76.1442 213.054 76.5558C212.672 76.9674 212.128 77.1732 211.423 77.1732C210.747 77.1732 210.203 76.9674 209.791 76.5558C209.379 76.1442 209.174 75.5856 209.174 74.8799V48.0661C209.174 47.3899 209.365 46.8606 209.747 46.4784C210.158 46.0668 210.717 45.861 211.423 45.861H222.404C225.844 45.861 228.461 46.6254 230.254 48.1543C232.077 49.6831 232.988 51.9176 232.988 54.8577C232.988 57.298 232.298 59.2826 230.916 60.8115C229.534 62.3109 227.564 63.2665 225.006 63.6781C225.771 63.8839 226.461 64.2514 227.079 64.7806C227.696 65.3099 228.299 66.0449 228.887 66.9857L233.429 74.1302ZM221.875 60.4587C224.168 60.4587 225.859 60.0176 226.947 59.1356C228.064 58.2242 228.622 56.8276 228.622 54.9459C228.622 53.0643 228.078 51.6971 226.991 50.8445C225.903 49.9625 224.198 49.5214 221.875 49.5214H213.628V60.4587H221.875Z"/>
                                    <path d="M244.959 76.9527C244.253 76.9527 243.695 76.7616 243.283 76.3794C242.901 75.9678 242.71 75.4238 242.71 74.7476V48.0661C242.71 47.3899 242.901 46.8606 243.283 46.4784C243.695 46.0668 244.253 45.861 244.959 45.861H261.188C261.865 45.861 262.379 46.0227 262.732 46.3461C263.114 46.6695 263.305 47.1252 263.305 47.7133C263.305 48.3013 263.114 48.757 262.732 49.0804C262.379 49.3744 261.865 49.5214 261.188 49.5214H247.164V59.312H260.306C260.983 59.312 261.497 59.4737 261.85 59.7971C262.232 60.1205 262.423 60.5763 262.423 61.1643C262.423 61.7523 262.232 62.208 261.85 62.5314C261.497 62.8549 260.983 63.0166 260.306 63.0166H247.164V73.2923H261.188C262.6 73.2923 263.305 73.895 263.305 75.1004C263.305 75.6885 263.114 76.1442 262.732 76.4676C262.379 76.791 261.865 76.9527 261.188 76.9527H244.959Z"/>
                                    <path d="M273.844 76.9527C273.138 76.9527 272.58 76.7616 272.168 76.3794C271.786 75.9678 271.595 75.4238 271.595 74.7476V48.0661C271.595 47.3899 271.786 46.8606 272.168 46.4784C272.58 46.0668 273.138 45.861 273.844 45.861H290.073C290.75 45.861 291.264 46.0227 291.617 46.3461C291.999 46.6695 292.19 47.1252 292.19 47.7133C292.19 48.3013 291.999 48.757 291.617 49.0804C291.264 49.3744 290.75 49.5214 290.073 49.5214H276.049V59.312H289.191C289.868 59.312 290.382 59.4737 290.735 59.7971C291.117 60.1205 291.308 60.5763 291.308 61.1643C291.308 61.7523 291.117 62.208 290.735 62.5314C290.382 62.8549 289.868 63.0166 289.191 63.0166H276.049V73.2923H290.073C291.485 73.2923 292.19 73.895 292.19 75.1004C292.19 75.6885 291.999 76.1442 291.617 76.4676C291.264 76.791 290.75 76.9527 290.073 76.9527H273.844Z"/>
                                    <path d="M324.736 74.1302C325 74.5124 325.132 74.924 325.132 75.365C325.132 75.8943 324.912 76.35 324.471 76.7322C324.059 77.085 323.574 77.2614 323.016 77.2614C322.192 77.2614 321.531 76.8939 321.031 76.1589L315.121 66.9857C314.445 65.9273 313.725 65.1922 312.96 64.7806C312.196 64.3396 311.196 64.1191 309.961 64.1191H304.978V74.8799C304.978 75.5856 304.772 76.1442 304.361 76.5558C303.978 76.9674 303.434 77.1732 302.729 77.1732C302.053 77.1732 301.509 76.9674 301.097 76.5558C300.685 76.1442 300.48 75.5856 300.48 74.8799V48.0661C300.48 47.3899 300.671 46.8606 301.053 46.4784C301.465 46.0668 302.023 45.861 302.729 45.861H313.71C317.15 45.861 319.767 46.6254 321.56 48.1543C323.383 49.6831 324.295 51.9176 324.295 54.8577C324.295 57.298 323.604 59.2826 322.222 60.8115C320.84 62.3109 318.87 63.2665 316.312 63.6781C317.077 63.8839 317.767 64.2514 318.385 64.7806C319.002 65.3099 319.605 66.0449 320.193 66.9857L324.736 74.1302ZM313.181 60.4587C315.474 60.4587 317.165 60.0176 318.253 59.1356C319.37 58.2242 319.928 56.8276 319.928 54.9459C319.928 53.0643 319.385 51.6971 318.297 50.8445C317.209 49.9625 315.504 49.5214 313.181 49.5214H304.934V60.4587H313.181Z"/>
                                    <path d="M143.661 123.725C142.985 123.725 142.441 123.519 142.029 123.108C141.618 122.696 141.412 122.137 141.412 121.432V94.6179C141.412 93.9417 141.603 93.4124 141.985 93.0302C142.397 92.6186 142.956 92.4128 143.661 92.4128H154.29C157.583 92.4128 160.14 93.2213 161.963 94.8384C163.786 96.4555 164.698 98.7488 164.698 101.718C164.698 104.658 163.786 106.952 161.963 108.598C160.14 110.245 157.583 111.068 154.29 111.068H145.954V121.432C145.954 122.137 145.749 122.696 145.337 123.108C144.955 123.519 144.396 123.725 143.661 123.725ZM153.805 107.407C158.156 107.407 160.332 105.511 160.332 101.718C160.332 97.9549 158.156 96.0732 153.805 96.0732H145.954V107.407H153.805Z"/>
                                    <path d="M197.384 120.858C197.531 121.211 197.604 121.505 197.604 121.74C197.604 122.299 197.369 122.769 196.898 123.152C196.457 123.534 195.958 123.725 195.399 123.725C195.017 123.725 194.649 123.622 194.296 123.416C193.973 123.181 193.723 122.858 193.547 122.446L190.636 115.831H174.495L171.584 122.446C171.408 122.858 171.143 123.181 170.79 123.416C170.437 123.622 170.07 123.725 169.688 123.725C169.1 123.725 168.57 123.534 168.1 123.152C167.659 122.769 167.439 122.299 167.439 121.74C167.439 121.505 167.512 121.211 167.659 120.858L180.096 93.6477C180.302 93.1772 180.625 92.8097 181.066 92.5451C181.536 92.2805 182.021 92.1482 182.521 92.1482C183.021 92.1482 183.492 92.2805 183.933 92.5451C184.403 92.8097 184.741 93.1772 184.947 93.6477L197.384 120.858ZM176.127 112.214H189.048L182.565 97.6168L176.127 112.214Z"/>
                                    <path d="M211.382 123.725C210.706 123.725 210.147 123.519 209.706 123.108C209.295 122.667 209.089 122.108 209.089 121.432V96.2056H200.268C198.857 96.2056 198.152 95.5734 198.152 94.3092C198.152 93.6918 198.328 93.2213 198.681 92.8979C199.063 92.5745 199.592 92.4128 200.268 92.4128H222.496C223.172 92.4128 223.686 92.5745 224.039 92.8979C224.421 93.2213 224.613 93.6918 224.613 94.3092C224.613 95.5734 223.907 96.2056 222.496 96.2056H213.675V121.432C213.675 122.108 213.47 122.667 213.058 123.108C212.646 123.519 212.088 123.725 211.382 123.725Z"/>
                                    <path d="M255.593 92.1923C256.269 92.1923 256.813 92.4128 257.224 92.8538C257.636 93.2654 257.842 93.8094 257.842 94.4856V121.432C257.842 122.108 257.636 122.667 257.224 123.108C256.813 123.519 256.269 123.725 255.593 123.725C254.916 123.725 254.373 123.519 253.961 123.108C253.549 122.696 253.343 122.137 253.343 121.432V109.568H235.967V121.432C235.967 122.108 235.762 122.667 235.35 123.108C234.938 123.519 234.394 123.725 233.718 123.725C233.042 123.725 232.498 123.519 232.086 123.108C231.675 122.696 231.469 122.137 231.469 121.432V94.4856C231.469 93.8094 231.675 93.2654 232.086 92.8538C232.498 92.4128 233.042 92.1923 233.718 92.1923C234.394 92.1923 234.938 92.4128 235.35 92.8538C235.762 93.2654 235.967 93.8094 235.967 94.4856V105.864H253.343V94.4856C253.343 93.8094 253.549 93.2654 253.961 92.8538C254.373 92.4128 254.916 92.1923 255.593 92.1923Z"/>
                                    <path d="M278.425 123.857C276.19 123.857 274.059 123.549 272.03 122.931C270.031 122.314 268.429 121.461 267.223 120.373C266.665 119.903 266.385 119.3 266.385 118.565C266.385 118.036 266.532 117.595 266.826 117.242C267.12 116.86 267.473 116.669 267.885 116.669C268.296 116.669 268.796 116.86 269.384 117.242C272.001 119.183 274.985 120.153 278.337 120.153C280.689 120.153 282.497 119.712 283.761 118.83C285.026 117.948 285.658 116.683 285.658 115.037C285.658 113.743 285.07 112.773 283.894 112.126C282.718 111.479 280.836 110.862 278.249 110.274C275.779 109.745 273.75 109.142 272.163 108.466C270.575 107.79 269.325 106.878 268.414 105.732C267.532 104.555 267.091 103.071 267.091 101.277C267.091 99.4838 267.576 97.8961 268.546 96.5143C269.546 95.103 270.928 94.0152 272.692 93.2507C274.456 92.4569 276.47 92.06 278.734 92.06C280.821 92.06 282.747 92.354 284.511 92.942C286.275 93.53 287.775 94.3974 289.009 95.544C289.597 96.0438 289.891 96.6466 289.891 97.3522C289.891 97.852 289.73 98.293 289.406 98.6753C289.112 99.0575 288.759 99.2486 288.348 99.2486C287.966 99.2486 287.481 99.0575 286.892 98.6753C285.54 97.6462 284.261 96.9112 283.056 96.4702C281.88 95.9997 280.454 95.7645 278.778 95.7645C276.514 95.7645 274.75 96.235 273.486 97.1758C272.221 98.0872 271.589 99.3662 271.589 101.013C271.589 102.424 272.148 103.497 273.265 104.232C274.382 104.938 276.176 105.585 278.645 106.173C281.233 106.761 283.32 107.378 284.908 108.025C286.525 108.642 287.804 109.51 288.745 110.627C289.715 111.715 290.2 113.126 290.2 114.861C290.2 116.625 289.7 118.198 288.701 119.579C287.73 120.932 286.349 121.99 284.555 122.755C282.791 123.49 280.748 123.857 278.425 123.857Z"/>
                                    <rect x="145.25" y="0.280273" width="151.49" height="3.78886" rx="1.89443" transform="rotate(90 145.25 0.280273)" fill="#CDED03"/>
                                </svg>
                            </div>
                            <h2 className={'mg-0 mg-l-8 mg-r-8' }>Find your career path</h2>
                        </div>
                        
                        <button id="readMore" onClick={()=>{window.location.href = '/apps/career-paths/'}} type="button" className="btn btn-secondary">Check it out</button>
                    </div>
                </div>

                <div className='slide slide-2'>
                    <div>
                        <img src={serviceNow}/>
                        <p>A certification task that has been assigned to you/your group requires attention.</p>
                    </div>
                    <button onClick={()=>{}} type="button" className="btn btn-secondary">Go to ServiceNow</button>
                </div>

                <div className='slide slide-3'>
                    <div>
                        <FontAwesomeIcon className='sidemenu-icon' icon={`fa-solid fa-newspaper`} />
                        <h4>Automation does not mean fewer jobs <br/> it means different jobs</h4>
                    </div>
                    <button onClick={()=>{window.location.href = '/news'}} type="button" className="btn btn-secondary">More News</button>
                </div>

            </Slider>

            {/* <button className='close-btn' onClick={props.handleCloseCarousel}>
                <FontAwesomeIcon
                    icon="fa-solid fa-xmark"
                />
            </button> */}
        </div>	
    )
}

export default BannerCarousel
