import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserLinks from "../links/UserLink";
import {connect} from "react-redux";

const DevOpsSubmenu = (props) => {
    const [location, setLocation] = useState(useLocation());
    const navigate = useNavigate();
    return (
        <>
            <Link className='nav-link' to={'/dev-ops/gitlab-repos'}>
                <span className="shape1"/>
                <span className="shape2"/>
                {
                    props.session.token ?
                        <svg xmlns="http://www.w3.org/2000/svg" className={'svg-inline--fa sidemenu-icon'}
                             viewBox="105 100 160 160">
                            <path style={{fill: '#e24329'}}
                                  d="M282.83,170.73l-.27-.69-26.14-68.22a6.81,6.81,0,0,0-2.69-3.24,7,7,0,0,0-8,.43,7,7,0,0,0-2.32,3.52l-17.65,54H154.29l-17.65-54A6.86,6.86,0,0,0,134.32,99a7,7,0,0,0-8-.43,6.87,6.87,0,0,0-2.69,3.24L97.44,170l-.26.69a48.54,48.54,0,0,0,16.1,56.1l.09.07.24.17,39.82,29.82,19.7,14.91,12,9.06a8.07,8.07,0,0,0,9.76,0l12-9.06,19.7-14.91,40.06-30,.1-.08A48.56,48.56,0,0,0,282.83,170.73Z"/>
                            <path style={{fill: '#fc6d26'}}
                                  d="M282.83,170.73l-.27-.69a88.3,88.3,0,0,0-35.15,15.8L190,229.25c19.55,14.79,36.57,27.64,36.57,27.64l40.06-30,.1-.08A48.56,48.56,0,0,0,282.83,170.73Z"/>
                            <path style={{fill: '#fca326'}}
                                  d="M153.43,256.89l19.7,14.91,12,9.06a8.07,8.07,0,0,0,9.76,0l12-9.06,19.7-14.91S209.55,244,190,229.25C170.45,244,153.43,256.89,153.43,256.89Z"/>
                            <path style={{fill: '#fc6d26'}}
                                  d="M132.58,185.84A88.19,88.19,0,0,0,97.44,170l-.26.69a48.54,48.54,0,0,0,16.1,56.1l.09.07.24.17,39.82,29.82s17-12.85,36.57-27.64Z"/>
                        </svg> :    <FontAwesomeIcon className='sidemenu-icon' icon='fa-solid fa-brands fa-gitlab'/>
                }

                <span className="sidemenu-label">Code</span>
            </Link>
            <ul className="nav-sub"
                style={location.pathname.split('/')[1] === 'dev-ops' ? {overflow: 'visible'} : {overflow: 'hidden'}}>
                <li className="nav-sub-item">
                    <button onClick={() => {
                        navigate('/dev-ops/gitlab-repos')
                    }} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Gitlab Repos</span>
                    </button>
                </li>
                <li className="nav-sub-item">
                    <button onClick={() => {
                        navigate('/dev-ops/gitlab-repos/add')
                    }} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Add Gitlab Repo</span>
                    </button>
                </li>

            </ul>
        </>
    )

};

const mapStateToProps = ({session}) => ({
    session
});

export default connect(mapStateToProps)(DevOpsSubmenu);


