import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserLinks from "../links/UserLink";

const ResourceSubmenu = () => {
    const [location, setLocation] = useState(useLocation());
    const navigate = useNavigate();
    return (
        <>
            <Link className='nav-link' to={'/resources/tools'}>
                <span className="shape1"/>
                <span className="shape2"/>
                <FontAwesomeIcon className='sidemenu-icon' icon='clock'/>
                <span className="sidemenu-label">Resources</span>
            </Link>
            <ul className="nav-sub"
                style={location.pathname.split('/')[1] === 'resources' ? {overflow: 'visible'} : {overflow: 'hidden'}}>
                <li className="nav-sub-item">
                    <button onClick={() => {
                        navigate('/resources/tools')
                    }} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Pearson Tools</span>
                    </button>
                </li>
                <li className="nav-sub-item">
                    <button onClick={() => {
                        navigate('/resources/Podcasts')
                    }} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Podcasts</span>
                    </button>
                </li>
                <li className="nav-sub-item">
                    <button onClick={() => {
                        navigate('/resources/References')
                    }} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">References</span>
                    </button>
                </li>
            </ul>
        </>
    )

};

export default ResourceSubmenu;
