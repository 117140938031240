import React from "react";
import { connect } from "react-redux";
// import { allProjects } from "../../actions/allProjects";
import { v4 as uuidv4 } from "uuid";

const ProjectStatus = ({project}) => {

  return (
    <span
      className={`repos badge badge-${project.status}-pr`}
      data-type="link"
      style={{
        background:
          project.status === "active"
            ? "#0CA789"
            : project.status === "new"
            ? "#ecb529"
            : project.status === "inactive"
            ? "#fd6074"
            :project.status === "closed"
            ? "#fd6074"
            : project.status === "inactive"
            ? "#fd6074"
            : "#fd6074",
        color:
          project.status === "active"
            ? "white"
            : project.status === "new"
            ? "#333333"
            : project.status === "inactive"
            ? "white"
            : "white"
      }}
      key={uuidv4}
    >
      {project?.status}
    </span>
  );
};

const mapStateToProps = ({ session, projects }) => ({
  session,
  projects,
});

const mapDispatchToProps = (dispatch) => ({
  // allProjects: (data) => dispatch(allProjects(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectStatus);
