import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toastMessage } from "../../../../../actions/toast";
import { signin } from "../../../../../actions/session/signIn";
import SpinnerButton from "../../../../global/SpinnerButton";
import { createDigitalOceanApp } from "../../../../../util/dev-ops/createDigitalOceanApp";
import TemplateSelect from "./TemplateSelect";
import RepoSelect from "./RepoSelect";
import AUTH_BASE_URL from "../../../../../util/AUTH_BASE_URL";
import { handleCreateRepo } from "../../../../../util/dev-ops/createReactRepo";

const CardComponent = ({ session, repos, toastMessage, signin }) => {
    const [subdomain, setSubdomain] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [loading, setLoading] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedRepo, setSelectedRepo] = useState(null);
    const [templateScope, setTemplateScope] = useState('personal');
    const navigate = useNavigate();
    const digitalOceanToken = session.digitalAccessToken;
    const gitlabToken = process.env.REACT_APP_GL_TOKEN;

    useEffect(() => {
        fetchTemplates();
    }, [isFlipped]);

    const fetchTemplates = async () => {
        try {
            const response = await fetch(`${AUTH_BASE_URL}templates`);
            const data = await response.json();
            const userId = session.userId;

            const filteredTemplates = data.filter(template =>
                template.type === 'global' || template.type === userId
            ).map(template => ({ value: template.repo, label: template.name }));

            setTemplates(filteredTemplates);
        } catch (error) {
            console.error('Failed to fetch templates', error);
        }
    };

    const handleInputChange = (e) => setSubdomain(e.target.value);
    const handleTemplateNameChange = (e) => setTemplateName(e.target.value);

    const handleTemplateSelectChange = async (templateId) => {
        try {
            const response = await fetch(`https://gitlab.com/api/v4/projects/${templateId}`, {
                headers: {
                    'PRIVATE-TOKEN': gitlabToken
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch repository from GitLab');
            }
            const repoData = await response.json();
            setSelectedRepo(repoData);
        } catch (error) {
            console.error('Error fetching repository:', error);
            toastMessage('Error fetching repository from GitLab');
        }
    };

    const handleScopeChange = (e) => setTemplateScope(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await setRepoVisibility(selectedRepo.id, 'public');

            await handleCreateRepo(
                subdomain,
                session,
                signin,
                toastMessage,
                digitalOceanToken,
                navigate,
                selectedRepo.http_url_to_repo
            );

            await setRepoVisibility(selectedRepo.id, 'private');
        } catch (error) {
            toastMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const setRepoVisibility = async (repoId, visibility) => {
        const response = await fetch(`https://gitlab.com/api/v4/projects/${repoId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'PRIVATE-TOKEN': gitlabToken
            },
            body: JSON.stringify({ visibility })
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to set template to ${visibility}: ${errorData.message || response.statusText}`);
        }
    };

    const handleRepoSelect = (selectedOptions) => {
        if (selectedOptions) {
            const selectedRepoId = selectedOptions.value;
            setSelectedRepo(selectedRepoId);

            const repoObject = repos.find(repo => repo.id === selectedRepoId);
            setSelectedRepo(repoObject);
        } else {
            setSelectedRepo(null);
        }
    };

    const handleAddTemplateSubmit = async (e) => {
        e.preventDefault();
        if (!templateName || !selectedRepo) {
            alert('Please provide both template name and repository.');
            return;
        }
        setLoading(true);
        try {
            const type = templateScope === 'global' ? 'global' : session.userId;
            const response = await fetch(`${AUTH_BASE_URL}templates`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: templateName, repo: selectedRepo.id, type })
            });

            if (!response.ok) {
                throw new Error('Failed to add template');
            }

            toastMessage('Template added successfully');
            resetForm();
            await fetchTemplates();
        } catch (error) {
            toastMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setTemplateName('');
        setSelectedRepo(null);
        setTemplateScope('personal');
        setIsFlipped(false);
    };

    const handleAddTemplateClick = () => setIsFlipped(!isFlipped);

    const cardContainerStyle = {
        perspective: '1000px',
        width: '100%',
        margin: '20px auto',
        minWidth: '390px'
    };

    const cardStyle = {
        width: '100%',
        height: '300px',
        transformStyle: 'preserve-3d',
        transition: 'transform 0.6s',
        position: 'relative',
        transform: isFlipped ? 'rotateY(180deg)' : 'none'
    };

    const cardSideStyle = {
        width: '100%',
        height: '100%',
        backfaceVisibility: 'hidden',
        position: 'absolute',
        top: '0',
        left: '0',
        padding: '20px',
        border: '1px solid #ced4da',
        borderRadius: '0.25rem',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center'
    };

    const backSideStyle = {
        ...cardSideStyle,
        transform: 'rotateY(180deg)'
    };

    const iconContainerStyle = {
        display: 'flex',
        alignSelf: 'center',
        justifySelf: 'center',
        gap: '20px',
        marginTop: '20px',
        width: '100%'
    };

    const buttonContainerStyle = {
        display: 'flex',
        gap: '10px',
        marginTop: '20px'
    };

    return (
        <div style={cardContainerStyle}>
            <div style={cardStyle}>
                <div style={cardSideStyle}>
                    <div>
                        <h5 className="main-content-label" style={{ color: '#343a40', fontWeight: '500', marginBottom: '10px', fontSize: '18px' }}>Start with a template.</h5>
                        <p className="card-subtitle" style={{ color: '#6c757d', fontSize: '16px' }}>Use this to set up a new application with a template of your choice.</p>
                    </div>
                    <div style={iconContainerStyle}>
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <div className="form-group" style={{ marginBottom: '20px' }}>
                                <TemplateSelect
                                    templates={templates}
                                    onChange={handleTemplateSelectChange}
                                    placeholder="Select a template"
                                />
                                <div className="input-group" style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                    <input
                                        type="text"
                                        className="form-control mg-r-10"
                                        id="subdomain"
                                        value={subdomain}
                                        onChange={handleInputChange}
                                        placeholder="Enter subdomain"
                                        style={{
                                            flex: '1',
                                            padding: '10px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '0.25rem 0 0 0.25rem'
                                        }}
                                        aria-label="Subdomain"
                                        aria-describedby="subdomain-addon"
                                    />
                                    <div className="input-group-append" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9ecef',
                                        borderRadius: '0 0.25rem 0.25rem 0',
                                        padding: '10px'
                                    }}>
                                        <span className="input-group-text" id="subdomain-addon">.pearsonct.design</span>
                                    </div>
                                </div>
                            </div>
                            <div style={buttonContainerStyle}>
                                <SpinnerButton loading={loading} buttonText="Create App" />
                                <button onClick={handleAddTemplateClick} type="button" style={{
                                    padding: '10px 20px',
                                    backgroundColor: '#007bff',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer'
                                }}>
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div style={backSideStyle}>
                    <div>
                        <h5 className="main-content-label" style={{ color: '#343a40', fontWeight: '500', marginBottom: '10px', fontSize: '18px' }}>Add a template</h5>
                        <p className="card-subtitle" style={{ color: '#6c757d', fontSize: '16px' }}>Enter the name of the template you want to add.</p>
                    </div>
                    <div style={iconContainerStyle}>
                        <form onSubmit={handleAddTemplateSubmit} style={{ width: '100%' }}>
                            <div className="form-group" style={{ marginBottom: '20px' }}>
                                <RepoSelect
                                    options={(repos || []).map(repo => ({ value: repo.id, label: repo.name }))}
                                    onChange={handleRepoSelect}
                                    placeholder="Select a repository"
                                />
                                <div className="input-group" style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                    <input
                                        type="text"
                                        className="form-control mg-r-10"
                                        id="templateName"
                                        value={templateName}
                                        onChange={handleTemplateNameChange}
                                        placeholder="Enter template name"
                                        style={{
                                            flex: '1',
                                            padding: '10px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '0.25rem'
                                        }}
                                        aria-label="TemplateName"
                                        aria-describedby="templateName-addon"
                                    />
                                </div>
                                <div className="form-group" style={{ marginTop: '20px' }}>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="templateScope" id="personal" value="personal" checked={templateScope === 'personal'} onChange={handleScopeChange} />
                                        <label className="form-check-label" htmlFor="personal">Personal</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="templateScope" id="global" value="global" checked={templateScope === 'global'} onChange={handleScopeChange} />
                                        <label className="form-check-label" htmlFor="global">Global</label>
                                    </div>
                                </div>
                            </div>
                            <div style={buttonContainerStyle}>
                                <SpinnerButton loading={loading} buttonText="Add Template" />
                                <button onClick={handleAddTemplateClick} type="button" style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                                    Back
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ session, repos }) => ({
    session, repos
});

const mapDispatchToProps = dispatch => ({
    signin: user => dispatch(signin(user)),
    toastMessage: (data) => dispatch(toastMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardComponent);
