import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const StatusDropDown = (props) => {
  const [tempStatus, setTempStatus] = useState(props?.status || props.task?.status || props.subtask?.status);
  const [showStatusMenu, setShowStatusMenu] = useState(false);

  function toggleStatusMenu(event) {
    event.preventDefault();
    setShowStatusMenu(!showStatusMenu);
  }

  function changeStatus(event, status) {
    event.preventDefault();
    setShowStatusMenu(false);
    setTempStatus(status);
    props.setValue("status", status);
  }

  useEffect(() => {
    // set initial status value
    props.setValue('status', props?.subtask?.status);
  }, []);

  return (
    <div className="form-group mg-b-0">
      <button
        className="task-status-btn status-dropdown"
        onClick={(event) => {
          event.stopPropagation();
          toggleStatusMenu(event);
        }}
      >
        <span
          className={`repos badge badge-${tempStatus}-pr`}
          data-type="link"
          style={{
            background:
              tempStatus === "active"
                ? "#0CA789"
                : tempStatus === "new"
                  ? "#ecb529"
                  : "#fd6074",
            color: "white",
          }}
        >{tempStatus}</span>
        <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
      </button>
      <div
        className={`dropdown-menu status-menu ${
          showStatusMenu === true ? "show" : ""
        }`}
      >
        <ul {...props.register(props.name)}>
          <li>
            <button
              className="dropdown-item"
              onClick={(event) => {
                event.stopPropagation();
                changeStatus(event, "new");
              }}
            >
              New
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={(event) => {
                event.stopPropagation();
                changeStatus(event, "active");
              }}
            >
              Active
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={(event) => {
                event.stopPropagation();
                changeStatus(event, "inactive");
              }}
            >
              Inactive
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={(event) => {
                event.stopPropagation();
                changeStatus(event, "closed");
              }}
            >
              Closed
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StatusDropDown;
