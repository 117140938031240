import {InteractionRequiredAuthError} from "@azure/msal-common";
import {setSsoLocalStorage} from "../../components/global/msal/setSsoLocalStorage";

export async function silentLogin(scopes, instance) {
  try {
    const loginResponse = await instance.ssoSilent(scopes);
    // If successfull, set endeavor-sso for SSO Login
    const ssoLogin =  await setSsoLocalStorage("endeavor-sso", true);
    // console.log("SilentLogin:Endeavor-SSO:", ssoLogin);
    return loginResponse;
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      await instance
        .loginPopup(scopes)
        .catch((error) => {
          // handle error
          console.log("Login Failure", err)
        });
    } else {
      // handle error
      console.log("Msal Non InteractionRequiredAuthError:", err)
    }
  }
}
