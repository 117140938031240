import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const getSubdomain = (domain) => {
    const parts = domain.split('.');
    return parts.slice(0, parts.length - 2).join('.');
};

const DomainCard = ({ domain }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newSubdomain, setNewSubdomain] = useState(getSubdomain(domain.spec.domain));
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleInputChange = (e) => {
        setNewSubdomain(e.target.value);
    };

    const handleUpdateClick = async () => {
        setLoading(true);
        try {
            const response = await fetch('/api/update-domain', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ newDomain: newSubdomain })
            });

            if (!response.ok) {
                throw new Error('Failed to update domain');
            }

            const result = await response.json();
            // Update the domain in the component state or handle the result as needed
            setIsEditing(false);
        } catch (error) {
            console.error("Failed to update domain:", error);
            // Handle error (e.g., show a toast message or alert)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="card">
            <div className="card-content">
                {!isEditing ? (
                    <>
                        <h2 className="domain-title">https://{domain.spec.domain}</h2>
                        <button
                            type="button"
                            className="btn btn-primary mg-t-10"
                            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#0056b3')}
                            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#007bff')}
                            onClick={handleEditClick}
                        >
                            Change Domain
                        </button>
                    </>
                ) : (
                    <>
                        <div className="input-group" style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="text"
                                className="form-control mg-r-10"
                                value={newSubdomain}
                                onChange={handleInputChange}
                                placeholder={getSubdomain(domain.spec.domain)}
                                style={{ flex: '1', padding: '10px', border: '1px solid #ced4da', borderRadius: '0.25rem 0 0 0.25rem' }}
                                aria-label="Subdomain"
                                aria-describedby="subdomain-addon"
                            />
                            <div className="input-group-append" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#e9ecef', borderRadius: '0 0.25rem 0.25rem 0', padding: '10px' }}>
                                <span className="input-group-text" id="subdomain-addon">.pearsonct.design</span>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="btn btn-success mg-t-10"
                            onClick={handleUpdateClick}
                            disabled={loading}
                        >
                            {loading ? 'Updating...' : 'Update Domain'}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default DomainCard;
