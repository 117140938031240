// Check if user is registered in Endeavor and setup the user session:
import { signin as signInUtil } from "../../util/session/ssoSignIn";
import { setSsoLocalStorage } from "../../components/global/msal/setSsoLocalStorage";
import updateSsoRole from "../../util/users/updateSsoRole";
import { getAccessTokenFromSessionStorage } from "../../util/helpers/getSsoAccessToken";
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER";

export const receiveCurrentUser = (data) => {
  return {
    type: RECEIVE_CURRENT_USER,
    user: { ...data },
  };
};
// If user is in DB start Sessionizing user
const startSessionizeUser = async (user, dispatch, response) => {
  // Set ssoLogin to false, resume sign in
  await setSsoLocalStorage("endeavor-sso", false);
  // console.log("ssoSignIn Action: endeavorSso:", endeavorSso);
  const userData = await response.json();
  // console.log("USERDATA:", userData);
  // set CurrentUser to User response
  return await dispatch(receiveCurrentUser(userData));
};

/**
 * Call ssoSignIn to check if user is registered in the Database,
 * If user in database then sessionize the user,
 * Else if the user is not in the database reroute to the sign-up page
 */
export const ssoSignin = (user) => async (dispatch) => {
  setSsoLocalStorage("endeavor-signed-in", true)
  document.cookie = "logged_in=true; path=/; domain=.pearsonct.design; max-age=36000; secure; samesite=strict";

  // If user is found then session user
  if (user && user.email) {
	  let bearerToken = await getAccessTokenFromSessionStorage();
    const response = await signInUtil(user.email, bearerToken);
    // console.log("ssoSignin: Is user in DB Response:", response);
    const isUserInDB = response.ok;
    // console.log("ssoSignIn: USER:", user?.ssoRole, user?.role);

    // If ssoUser in Db update role
    if (isUserInDB) {
      // console.log("ssoSignIn: User is in DB, SSOROLE:", user.ssoRole);
      const loginType = "sso";
      // Update User SsoRole
      /**
       *June 10, 2024:  Update localStorage  
       and ssoRole, once user has logged in
       **/
      updateSsoRole(
        user.email,
        user.department,
        user.jobTitle,
        user.role,
        user.ssoDirectReports,
        user.ssoIsManager,
        user.ssoManager,
        user.ssoDepartmentMembers,
        loginType).then((ssoRoleStatus) => {
          localStorage.setItem("endeavor_user", JSON.stringify(user));
          // console.log("ssoSignIn: User object stored in local storage.");
      });
      
      // Sessionize User
      await startSessionizeUser(user, dispatch, response);

      // Not a native user (not found in Endeavor), redirect user to SignUp Page for SSO
    }
    /* If user not in DB, set endeavor-sso to true, so user can be created on signUp*/
     else {
      // console.log("ssoSignIn: Error in Response:", response);
      await setSsoLocalStorage("endeavor-sso", true);
      return { user };
    }
  }
};
