import React, {useEffect, useState} from "react";
import {useDebounce} from "../hooks/useDebounce";

/**
 * Description: Receives input and returns the search
 * value after the user has stopped entering data.
 * @param {*} hideBottomBorder - Trackes when entries are displayed
 *  in the DropDown, to hide the search component's bottom border.
 * @param {*} placeholderText - placeholder text to display in input element
 * @param {*} setParentTerm - sets the input value  in the parent
 * @param {*} itemClickedToggle - Tracks when an item has been clicked in
 * the dropdown, so the searchTerm can be cleared in the input field
 * @parm {optional} label - add a text label
 * @returns input component
 */

const Search = ({
  hideBottomBorder,
  placeholderText,
  setParentTerm = (data) => {},
  label = null,
  itemClickedToggle,
  style,
}) => {
  const [term, setTerm] = useState("");
  const [debouncedTerm] = useDebounce(term, 500);

  useEffect(() => {
    // console.log("ITEMCLICKED1:", itemClickedToggle);
    setTerm("");
  }, [itemClickedToggle]);

  function handleChange(event) {
    const value = event.target.value;
    setTerm(value);
  }

  useEffect(() => {
    setParentTerm(debouncedTerm);
  }, [debouncedTerm]);

  return (
    <>
      <div className="fields">{label ? <label>{label}</label> : null}</div>
      <input
        type="search"
        className={`search-input btn btn-icon-text mr-2  mg-r-8 .bd-l-0  pd-6-f`}
        placeholder={placeholderText}
        onChange={handleChange}
        value={term}
        style={{borderRadius: '3px', borderColor: '#c8c8d3'}}
      ></input>
    </>
  );
};

export default Search;
