import React, {useEffect, useState} from 'react'
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";

import gitlabAvatar from "../../../assets/images/gitlab-avatar.png";
import reactLogo from '../../../assets/images/framework/react.png'
import vueLogo from '../../../assets/images/framework/vue.png'
import htmlLogo from '../../../assets/images/framework/html.png'
import gulpLogo from '../../../assets/images/framework/gulp.png'
import docuLogo from '../../../assets/images/framework/docusaurus.svg'
import astroLogo from '../../../assets/images/framework/astro.svg'
import eleventyLogo from '../../../assets/images/framework/11ty.png'
import storybookLogo from '../../../assets/images/framework/storybook.png'
import validateToken from "../../../util/dev-ops/validateToken";
import {connect} from "react-redux";

// Your function to get the correct framework logo
function getFrameworkLogo(framework) {
    if (framework) {
        const frameworkName = framework.toLowerCase();

        const logos = {
            'Create React App': reactLogo,
            'create-react-app': reactLogo,
            'vue.js': vueLogo,
            'html': htmlLogo,
            'gulp.js': gulpLogo,
            'docusaurus 2': docuLogo,
            'astro': astroLogo,
            'eleventy': eleventyLogo,
            'parcel': htmlLogo,
            'grunt': htmlLogo,
            'react-storybook': storybookLogo,
            'storybook react app': storybookLogo
        };

        return logos[frameworkName] || ''; // Default to gitlabAvatar
    }

}


function AppWidget({session, gitlabProjectIds, label, projectId}) {
    const [data, setData] = useState([])

    useEffect(async () => {
        // console.log('THE GITLAB ID', gitlabProjectIds)
        if (gitlabProjectIds && gitlabProjectIds.length !== 0) {
            fetch(`${AUTH_BASE_URL}dev-ops/gitlab/app-data`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({gitlabProjectIds, token: process.env.REACT_APP_GL_TOKEN })
            }).then(response => {
                return response.json()
            }).then(data => {
                setData(data)
            })
        } else {
            setData(['There are no apps to display' ])
        }


    }, [gitlabProjectIds])
        // console.log('THE DATA', data)
    return (
        <div className="card pd-8 flex-grow-1">
            <div className="card-body">
                <h4 className="mg-b-0 pd-8 mg-l-0">{label}</h4>
                <div className="mg-b-40  pd-b-20">
                    {
                       data.map((app, index) => {
                           console.log(app)
                                // console.log('type of app', typeof app)
                           if (typeof app !== 'string'){
                               return (
                                   <div key={index} className={'pd-15 bd-b'}>
                                       <div className="row pd-l-8 mt-1 d-flex justify-content-between pd-5 ">
                                           <span className="">Gitlab Repo</span>
                                           <b>
                                               <a
                                                   href={app.repoUrl}
                                                   target="_blank"
                                               >
                                                   {app.repoName}
                                               </a>
                                           </b>
                                       </div>
                                       <div className="row pd-l-8 mt-1 d-flex justify-content-between pd-5">
                                           <span className="">URL</span>
                                           <b>
                                               <a href={app.url} target={"_blank"}>{app.url}</a>
                                           </b>
                                       </div>
                                       <div className="row mt-1 pd-l-8 d-flex justify-content-between pd-5">
                                           <span className="">Creator</span>
                                           <b>
                                               {app.creator}
                                           </b>
                                       </div>
                                       <div className="row mt-1 pd-l-8 d-flex justify-content-between pd-5">
                                           <span className="">UX Server</span>
                                           <b>
                                               {app.server}
                                           </b>
                                       </div>

                                       <div className="row mt-1 pd-l-8 d-flex justify-content-between pd-5">
                                           <span className="">Code</span>
                                           {getFrameworkLogo(app.framework) && (
                                               <img src={getFrameworkLogo(app.framework)} alt={app.framework} width={30} height={30}/>
                                           )}
                                       </div>

                                       <div className="row mt-1 pd-l-8 d-flex justify-content-between pd-5">
                                           <span className="">Updated</span>
                                           <b>
                                               {app.updated}
                                           </b>
                                       </div>
                                   </div>
                               )
                           } else {
                               return (
                                   <div key={index} className={'pd-15 bd-b'}>
                                       <div className="row pd-l-8 mt-1 d-flex justify-content-between pd-5 ">
                                           There are no apps to display
                                       </div>
                                   </div>
                               )
                           }

                        })
                    }

                </div>
            </div>
        </div>

    )
}
const mapStateToProps = ({session}) => ({
    session
});

export default connect(
    mapStateToProps
)(AppWidget);
