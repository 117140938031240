import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { v4 as uuidv4 } from "uuid"; // Import the v4 function from the uuid library
import allUsers from "../../../util/users/allusers";
import ProjectTaskWidget from "./DisplayWidget";
import { allUserData } from "../../../actions/allUsers";
import { connect } from "react-redux";
import { toastMessage } from "../../../actions/toast";
import { updateSession } from "../../../actions/session/updateSession";
import WidgetTabPanel from "../widgets/WidgetTabPanel";
import WidgetTabs from "../widgets/WidgetTabs";

/**
 * Dashboard assembles the data in
 * ProjectDisplayWidget, which renders the
 * data to the Dom.
 * @param {*} param0 
 * @returns 
 */
const Controller = ({
  endeavorUsers,
  getData,
  type,
  allUserData,
  session,
}) => {
  const [dashboardData, setDashboardData] = useState(null);

  /**
   * Display a component for a Project/Task/Subtask data item
   * @param {*} key
   * @param {*} index
   * @param {*} data
   * @param {*} Component
   * @returns
   */
  const displayProjectTaskData = (key, index, data, Component) => {
    return (
      <>
        {" "}
        {/* Generate a unique key for the div */}
        {/* {console.log("Data:", data[index], "Key:", key)} */}
        {data[index] && Object.keys(data[index]).includes(key) ? (
          <Component
            session={session}
            header={data[index]?.meta?.header}
            items={data[index][key]}
            type={key}
            key={uuidv4()}
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  /**
   * Display a swiper component for a Project/Task/Subtask data item
   */
  const renderSwiperComponents = () => {
    const renderedComponents = [];

    for (let index = 0; index < dashboardData?.length; index++) {
      renderedComponents.push(
        <WidgetTabs
          key={uuidv4()}
          spaceBetween={10}
          direction={"vertical"}
          autoHeight={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
          onTransitionEnd={(s) => {
            var heightsSumUpToCurrentIndex = s.slides
              .slice(0, s.realIndex)
              .reduce((sum, div) => sum + div.clientHeight, 0);

            const newTransformWellCalculated =
              -heightsSumUpToCurrentIndex - s.params.spaceBetween * s.realIndex;

            const match = /translate3d\(([^,]+),\s*([^,]+),\s*([^)]+)\)/.exec(
              s.wrapperEl.style.transform
            );
            if (match) {
              s.wrapperEl.style.transform = `translate3d(${match[1]}, ${newTransformWellCalculated}px, ${match[3]})`;
            }
          }}
        >
          {Object.keys(dashboardData[index]).map((key, idx) => {
            if (key !== "meta" && key !== "users") {
              return (
                <WidgetTabPanel key={uuidv4()} label={key}>
                  {" "}
                  {displayProjectTaskData(
                    key,
                    index,
                    dashboardData,
                    ProjectTaskWidget
                  )}
                </WidgetTabPanel>
              );
            } else {
              return <></>;
            }
          })}
        </WidgetTabs>
      );
    }
    // Return the array of components
    return renderedComponents;
  };

  // Fetch and assemble dashboard data
  const fetchDashboardData = async () => {
    const dataObject = await getData(session.userId);
    // console.log("Dashboard: Tasks:", dataObject);

    const dashboardData = [];
    if (type === "pinned" && dataObject) {
      dataObject.meta = {
        header: "",
      };
      if (dataObject) {
        dataObject.meta.header = "Pinned";
        dataObject.meta.renderComponent = ProjectTaskWidget;
        dashboardData.push(dataObject);
      }
    }
    else if (type === "tasks" && dataObject) {
      dataObject.meta = {
        header: "",
      };
      if (dataObject) {
        dataObject.meta.header = "My";
        //   dataObject.meta.renderComponent = ProjectTaskWidget;
        dashboardData.push(dataObject);
      }

    }

    setDashboardData(dashboardData);
  };

  /**
   * Populate all user if none exists, and fetch
   *  intial tasks for logged in user by calling
   *  fetchDashboardData
   * NOTE: FetchDashboardData can not be called
   * unless endeavor user are populated
   */
  useEffect(() => {
    //if users is not available from redux
    // get them from the database and store them
    if (!endeavorUsers) {
      allUsers().then(async (userResponse) => {
        // console.log("UserResponse:", userResponse);
        allUserData(await userResponse);
      });
    } else {
      fetchDashboardData();
    }
  }, []);

  /**
   * Refresh the data whenever 
   * the list of employees changes
   *  NOTE: FetchDashboardData can not be called
   * unless endeavor user are populated
   */
  useEffect(() => {
    // console.log("USERS:", props.users);
    if (endeavorUsers) {
      fetchDashboardData();
    }
  }, [endeavorUsers]);

  return (
    <div className="project-container">
      {dashboardData !== null && session?.userId !== null ? (
        
        renderSwiperComponents()
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = ({ session, users, wsClient, score }) => ({
  session,
  users,
  wsClient,
  score,
});

// props.toastMessage('Hi from the global toast system.')
const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
  updateSession: (user) => dispatch(updateSession(user)),
  toastMessage: (data) => dispatch(toastMessage(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
