import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import validateToken from "../../../util/dev-ops/validateToken";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";

const timeSince = (date) => {
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;

    if (secondsPast < 60) {
        return `${parseInt(secondsPast)} seconds ago`;
    }
    if (secondsPast < 3600) {
        return `${parseInt(secondsPast / 60)} minutes ago`;
    }
    if (secondsPast <= 86400) {
        return `${parseInt(secondsPast / 3600)} hours ago`;
    }
    if (secondsPast <= 604800) {
        return `${parseInt(secondsPast / 86400)} days ago`;
    }

    return `${parseInt(secondsPast / 604800)} weeks ago`;
};


function DisplayMyActivity({session}) {
    let [data, setData] = useState();
    let [commitData, setCommitData] = useState();
    let [view, setView] = useState('status');
    let [name, setName] = useState('');


    useEffect(() => {
        let isMounted = true; // Flag to indicate the component is still mounted

        (async () => {
            try {
                let tokenData = {
                    token: session.token,
                    email: session.email,
                };

                const token = await validateToken(tokenData);
                const response = await fetch(`${AUTH_BASE_URL}team/gitlab/user-activity/${session.gitLab[0].username}/${token.token}`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();

                if (isMounted) { // Only update state if the component is still mounted
                    setData(responseData);
                }
            } catch (error) {
                console.error('Error fetching GitLab data:', error);
                // Optionally, you can also check isMounted before setting an error state or performing other actions
            }
        })();

        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            isMounted = false;
        };
    }, [session.token]); // Ensure useEffect runs only when session.token changes


    function changeView(event, project) {
     event.stopPropagation();
     setName(project.name)
     setCommitData(project.relatedActivities)
     setView('commit')
    }

    function changeViewStatus(event, project) {
        event.stopPropagation();
        console.log('hi', project)
        setView('status')
    }

    
    if (data) {
        console.log('THE DATA', data)
        const sortedData = [...data.gitlab].sort((a, b) => {
            return new Date(b.relatedActivities[0].created_at) - new Date(a.relatedActivities[0].created_at);
        });
        if (data.gitlab.length === 0) {
            return (
                <div className={'pd-20 mg-b-20 bd'}>
                    <i className="task-icon bg-primary"></i>
                    <h6 className={"mg-b-4"}><div>No activity in the last 30 days.</div></h6>
                </div>

            )
        } else {
            if (view === 'status') {
                return (
                    <div>
                         <div>
                            {sortedData ? sortedData.map(project => {
                                console.log('the project find id', project)
                                const link = project.relatedActivities[0].projectData.data.http_url_to_repo;
                                return (
                                    <div className={'pd-20 mg-b-20 bd'}>
                                        <i className="task-icon bg-primary"></i>
                                        <h6 className={"mg-b-4"}>{project.name ? project.name : 'Miscellaneous'}</h6>
                                        <p className="fs-12 mg-b-4">
                                            Last updated: <strong>{timeSince(new Date(project.relatedActivities[0].created_at))}</strong>
                                        </p>
                                        <p className="fs-12 mg-b-4">Pushed a commit to: <strong><a target="_blank" href={link} className="status-activity" style={{color: '#a161ee'}}>{project.relatedActivities[0].projectName}</a></strong></p>
                                        <p className="fs-12 mg-b-4">Commit message: <strong>{project.relatedActivities[0].push_data?.commit_title}</strong></p>
                                        <p className="fs-12 mg-t-0">Branch commited: <strong>{project.relatedActivities[0].push_data?.ref}</strong></p>
                                        <button onClick={(event)=>{changeView(event, project)}} className="btn ripple btn-outline-primary bd-1 btn-sm" style={{border: '1px solid #a161ee', color: '#a161ee', borderRadius: '4px'}}> View {project.relatedActivities.length} Commits</button>
                                        <div>

                                        </div>

                                    </div>
                                );
                            }) : <div>There is no activity to display</div>
                            }
                        </div>
                        {/*<a onClick={() => { alert('Feature is being worked on') }} className="font-weight-semibold wd-100p d-flex align-items-center justify-content-end" style={{color: '#a161ee'}}>View All Activity</a>*/}
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className={'d-flex justify-content-between align-items-center mg-b-10 bd-1 pd-b-20'} style={{borderBottom: '1px solid #cecece'}} >
                            <h5 className={"mg-b-0"}>Project: {name}</h5>
                            <button onClick={(event)=>{changeViewStatus(event, '')}} className="btn ripple btn-outline-primary bd-1 btn-sm" style={{border: '1px solid #a161ee', color: '#a161ee', borderRadius: '4px'}}>Back</button>
                        </div>
                        
                        <div className={'mg-t-20'}>
                            {commitData ? commitData.map(commit => {

                                return (
                                    <div id={'commit-' + commit.id} className={'pd-20 mg-b-20 bd shadow'}>
                                        <i className="task-icon bg-primary"></i>
                                        <p className="fs-12 mg-b-4 d-flex">

                                            <div style={{width:'17px', marginRight:'10px'}}>
                                                {
                                                    commit.action_name === 'pushed to' || commit.action_name === 'pushed new' ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                            <path d="M320 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm156.8-48C462 361 397.4 416 320 416s-142-55-156.8-128H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H163.2C178 151 242.6 96 320 96s142 55 156.8 128H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H476.8z" />
                                                        </svg>  :
                                                        commit.action_name === 'created' ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" id="status_open">
                                                        <path d="M0 7c0-3.866 3.142-7 7-7 3.866 0 7 3.142 7 7 0 3.866-3.142 7-7 7-3.866 0-7-3.142-7-7z" />
                                                        <path
                                                            d="M1 7c0 3.309 2.69 6 6 6 3.309 0 6-2.69 6-6 0-3.309-2.69-6-6-6-3.309 0-6 2.69-6 6z"
                                                            fill="var(--svg-status-bg, #fff)"
                                                        />
                                                        <path d="M7 9.219a2.218 2.218 0 1 0 0-4.436A2.218 2.218 0 0 0 7 9.22zm0 1.12a3.338 3.338 0 1 1 0-6.676 3.338 3.338 0 0 1 0 6.676z" />
                                                    </svg> :
                                                            commit.action_name === 'imported' ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                                </svg> : <></>

                                                }
                                               </div>



                                            {
                                                commit.action_name === 'pushed to' |  commit.action_name === 'pushed new'? <span>Pushed to branch <strong>{commit.push_data?.ref}</strong> at {commit.projectData.data?.name_with_namespace}</span> :
                                                    commit.action_name === 'created' ?  <span>Created project <strong>{commit.projectData.data?.name_with_namespace}</strong></span> :
                                                        commit.action_name === 'imported' ? <span>Imported project <strong>{commit.projectData.data?.name_with_namespace}</strong>t</span> : <></>

                                            }

                                        </p>

                                        { commit.action_name === 'pushed to' ? <p className="fs-12 mg-b-4"><strong><a href={'#'}>{commit.push_data?.commit_to.slice(0, 8)}</a></strong> - {commit.push_data?.commit_title}</p> : <></> }
                                        <p className="fs-12 mg-b-4">Last updated: <strong>{timeSince(new Date(commit.created_at))}</strong></p>
                                    </div>
                                );
                            }) : <div>There is no activity to display</div>
                            }
                        </div>
                        {/*<a onClick={() => { alert('Feature is being worked on') }} className="font-weight-semibold wd-100p d-flex align-items-center justify-content-end" style={{color: '#a161ee'}}>View All Activity</a>*/}
                    </div>
                )
            }

        }

    } else {
        return (
            <div className={'pd-20 mg-b-20 bd'}>
                <i className="task-icon bg-primary"></i>
                <h6 className={"mg-b-4"}><div className="skeleton-text mb-1" style={{width:"100%", height:"1em"}}/></h6>
                <p className="fs-12 mg-b-4">
                <div className="skeleton-text mb-1" style={{width:"100%", height:"1em"}}/>
                </p>
                <p className="fs-12 mg-b-4"><div className="skeleton-text mb-1" style={{width:"100%", height:"1em"}}/></p>
                <p className="fs-12 mg-b-4"><div className="skeleton-text mb-1" style={{width:"100%", height:"1em"}}/></p>
                <p className="fs-12 mg-t-0"><div className="skeleton-text mb-1" style={{width:"100%", height:"1em"}}/></p>
                
            </div>
        );
    }
}

const mapStateToProps = ({ session }) => ({
    session,
});

export default connect(mapStateToProps)(DisplayMyActivity);
