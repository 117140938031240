import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ToggleStatus = ({ showStatus, setShowStatus }) => {
  // console.log("ShowStatus2:", showStatus, "SetShowStatus:", setShowStatus);
  const toggleStatus = (event) => {
    event.preventDefault();
    setShowStatus(!showStatus);
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-link pd-0 mg-0"
        onClick={(event) => {
          toggleStatus(event);
        }}
      >View Recent Activity
     <FontAwesomeIcon
          className="mg-l-4"
          icon={
            showStatus ? "fa-solid fa-chevron-right" : "fa-solid fa-chevron-right"
          }
        />
      </button>
    </div>
  );
};

export default ToggleStatus;
