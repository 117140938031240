import React, {useEffect, useState} from "react";
import FilteredAssets from "./FilteredAssets";

const FileTypeFilter = (props) => {

  const initSelectedFileType = "All";
  const [linkData, setLinkData] = useState(props.linkData)
  const [selectedFileType, setSelectedFileType] = useState("All")
  const [fileTypes, setFileTypes] = useState([])

  useEffect(() => {
    // Populate selectedFileTypes and setlinkData
      setLinkData(retrieveUniqueFileTypes(props.linkData));
  }, [props.linkData, selectedFileType]);

  const selectFileType = (event) => {
    const fileType = event.target.value;
    setSelectedFileType(fileType);
  }

  const retrieveUniqueFileTypes = (curDataRecords) => {
    const fileTypeSet = new Set([initSelectedFileType]);
    curDataRecords.map((record) => {
      const typeStartIdx = record.type.lastIndexOf("/") + 1;
      const type = record.type.substring(typeStartIdx);
      const capitalType = type[0].toUpperCase() + type.substring(1);
      fileTypeSet.add(capitalType);
    });
    const types = [...fileTypeSet];

    // Set the file types for the dropDown
    setFileTypes(types);

    return curDataRecords;
  }

  return (
    <>
      <div className={"y-align filter-group-item"}>
        {
          fileTypes && fileTypes.length > 2 ?
              <div className="type-filter--offset">
                <label className="mg-8" htmlFor="type-filter">
                  <p className="mg-b-0 pd-r-10">Filter by File Type:</p>
                </label>
                <select
                    name="type-filter"
                    id="type-filter"
                    onChange={selectFileType}
                    value={selectedFileType}
                >
                  {fileTypes.map((fileType, index) => (
                      <option key={index} accessKey={fileType === "all" ? "a" : ""}>
                        {fileType}
                      </option>
                  ))}
                </select>
              </div>
              : null
        }
        <div className="mg-8">
          <FilteredAssets
            linkRecords={linkData}
            filter={selectedFileType}
            handleOpenEditAssetModal={props.handleOpenEditAssetModal}
            handleOpenDeleteAssetModal={props.handleOpenDeleteAssetModal}
          />
        </div>
      </div>
    </>
  );
};

export default FileTypeFilter;
