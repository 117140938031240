import {ALL_CLASS_TESTS} from "../actions/allClassTests";
export default (state = null, {type, allClassTestData = null }) => {
	Object.freeze(state);
	switch (type) {
		case ALL_CLASS_TESTS:
			return allClassTestData;
		default:
			return state;
	}
};
