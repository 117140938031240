import { combineReducers } from 'redux';
import signIn from './signIn';
import allUsers from './allUsers';
import allGitLabRepos from './allGitlabRepos';
import notConnectedPipelines from './notConnectedPipelines';
import connectedPipelines from './connectedPipelines';
import allPrototypes from './allPrototypes';
import allProjects from './allProjects';
import ssoUserData from './ssoUserData';
import myNotifications from './myNotifications';
import gitlabGroups from './gitlabGroups';
import gitlabGroupId from './gitlabGroupId';
import gitlabSubGroups from './gitlabSubGroups';
import gitlabSubGroupId from './gitlabSubGroupId';
import gitlabRenderRepos from './gitlabRenderRepos';
import projectHasCiCd from './projectHasCiCd';
import wsClient from './wsclient';
import hasAuthentication from './hasAuthentication';
import creators from './creators';
import filterBy from './filterBy';
import filteredData from './filteredData';
import repoLoading from './repoLoading';
import assets from './allAssets';
import allTests from './allUserTests';
import preQuestionData from './preQuestions';
import ghostUser from './ghostUser';
import sidebarData from './sidebar';
import toast from './toast';
import figmaTeamData from './figmaTeam';
import figmaProjectData from './figmaProjectData';
import figmaComponentData from './figmaComponentData';
import {figmaTeamIdReducer, figmaProjectsTeamIdReducer, figmaComponentsTeamIdReducer } from './figmaTeamId';
import allClassTestData from "./allClassTestData";
import projectFilters from './projectFilters';
import projectSort from './projectSortedBy'
export default combineReducers({
	session: signIn,
	users: allUsers,
	repos: allGitLabRepos,
	notConnectedPipelines,
	connectedPipelines,
	prototypes: allPrototypes,
	projects: allProjects,
	ssoUserData,
	notifications: myNotifications,
	gitlabGroups,
	gitlabSubGroups,
	gitlabGroupId,
	gitlabSubGroupId,
	gitlabRenderRepos,
	wsClient,
	hasCiCd: projectHasCiCd,
	hasAuthentication,
	creators,
	filterBy,
	filteredData,
	repoLoading,
	assets,
	allTests,
	ghostUser,
	preQuestionData,
	sidebarData,
	toast,
	figmaTeamData,
	figmaTeamId: figmaTeamIdReducer,
	figmaProjectData,
	figmaComponentData,
	figmaProjectsTeamId: figmaProjectsTeamIdReducer,
	figmaComponentsTeamId: figmaComponentsTeamIdReducer,
	allClassTestData: allClassTestData,
	project_filters: projectFilters,
	project_sort: projectSort
});
