import React, { useRef, useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { v4 as uuidv4 } from "uuid"; // Import the v4 function from the uuid library
import moment from "moment";
import { Link } from "react-router-dom"; // Import the Link component
import truncateDescription from "../../../util/tasks/truncateDescription";
// import { getUsersByEmails } from "../../util/users/getUsersByEmails";
import GraphSearchSelect from "../../global/inputs/graphSearchSelect/GraphSearchSelect";
import EmployeeTaskRenderer from "./DisplayWidet";

const titleCharLimit = 17;
/**
 * Formats the pinned employee data (taskObjArray) as
 *  a Swiper JSX Element for all Pinned Employees
 * and passes the JSX Element back to the
 * PinnedEmployeeSelector for display in the DOM
 * @param {*} param0
 * @returns
 */
const PinnedEmployeeController = ({
  type,
  endeavorUsers,
  setTaskComponents,
  control,
  taskObjArray,
  pinnedEmployeesNum,
  setPinnedEmployeesNum,
  handlePinnedEmployees,
}) => {
  // console.log("responsiblity:", responsibleOptions);
  // console.log("Items:", items,";type:", type,";header:", header);
  const charLimit = 55;

  /************GraphSearchSelect*******/
  const searchSelectName = "mail";
  const selectFields = "displayName,userPrincipalName";
  const searchPlaceHolderText = "Search by User";
  const msGraphName = "graphPeople";
  /********************************** */

  const getItemHeader = (header, type) => {
    let itemHeader = "";
    if (header) {
      // console.log("Header:", JSON.stringify(header));
      itemHeader =
        header + " " + type?.charAt(0).toUpperCase() + type?.slice(1);
    } else {
      itemHeader = type?.charAt(0).toUpperCase() + type?.slice(1);
    }

    return itemHeader;
  };
  const displayItems = (key, index, data) => {
    const employeeName =
      data[index].firstName + " " + data[index].lastName + "'s";
    // console.log("employee2", data[index])
    return (
      <>
        {data[index] &&
        Object.keys(data[index]).includes(key) &&
        key !== "projects" &&
        key !== "meta" ? (
          <div className="card custom-card card-border" key={uuidv4()}>
            <div className="card-header border-bottom-0 pb-0 d-flex pl-3 ml-1">
              <div className="left-header">
                <label className="main-content-label mb-2">
                  {getItemHeader(employeeName, key)}
                </label>{" "}
                <span className="d-block tx-12 mb-2 text-muted">
                  Current status
                </span>
              </div>
            </div>
            <div className="card-body pt-2 mt-0">
              <div className="list-group border projects-list">
                {displayList(data[index], key)}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const displayList = (dataObject, key) => {
    // console.log("dataObject:", dataObject, "Type:", typeof items, "Key:", key);
    if (
      dataObject &&
      Object.keys(dataObject).includes(key) &&
      key !== "projects" &&
      key !== "meta" &&
      key !== "firstName" &&
      key !== "lastName"
    ) {
      return dataObject[key]?.map((item, index) => {
        // console.log("Item:", item);
        let startDate;
        let endDate;
        let statusColor = "";
        let daysDifference = 0;
        startDate =
          type !== "projects" ? moment(item?.startDate) : moment(item?.created);
        endDate = type !== "projects" ? moment(item?.endDate) : moment();
        const currentDate = moment();
        daysDifference =
          type !== "projects"
            ? endDate.diff(currentDate, "days")
            : currentDate.diff(moment(item?.created), "days");
        if (daysDifference < 0) {
          statusColor = "text-margin text-danger";
        } else {
          statusColor = "text-margin text-success";
        }
        return (
          <Link
            key={uuidv4()}
            to={type && item ? `/${key}/${item?._id}` : "/#"} // Use the "to" prop for navigation
            className="list-group-item list-group-item-action flex-column border-right-0 border-left-0 align-items-start p-3 border-top-0"
          >
            <div className="d-flex w-100 justify-content-between align-baseline">
              <h6 className="mb-1 font-weight-semibold">
                {item && item.name
                  ? truncateDescription(item.name, titleCharLimit)
                  : ""}
              </h6>
              <small className={statusColor}>
                <i className="fa fa-caret-down mr-1" />
                {Math.abs(daysDifference) + " days"}
              </small>
            </div>
            <p className="left-header mb-0 text-muted mb-0 tx-12">
              {"Started: "}
              {startDate?.format("MM-DD-YY")}
            </p>
            <div className="left-header">
              <small className="text-muted">
                {item?.description
                  ? truncateDescription(item?.description, charLimit)
                  : "N/A"}
              </small>
            </div>
          </Link>
        );
      });
    }
  };
  /**
   * Push all Pinned employee Swiper JSX elements
   * into an array to be rendered to the DOM
   * @returns
   */
  const componentsToRender = () => {
    const renderedComponents = [];
    // console.log(
    //   "DashboardData:",
    //   taskObjArray,
    //   "Num of Components:",
    //   pinnedEmployeesNum
    // );
    for (let index = 0; index < taskObjArray.length; index++) {
      if (
        Array.isArray(taskObjArray) &&
        (taskObjArray[index]?.tasks?.length > 0 ||
          taskObjArray[index]?.subtasks?.length > 0)
      ) {
        renderedComponents?.push(
          <EmployeeTaskRenderer key={uuidv4()}>
            <Swiper
              key={uuidv4()}
              spaceBetween={10}
              direction={"vertical"}
              autoHeight={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
              onTransitionEnd={(s) => {
                var heightsSumUpToCurrentIndex = s.slides
                  .slice(0, s.realIndex)
                  .reduce((sum, div) => sum + div.clientHeight, 0);

                const newTransformWellCalculated =
                  -heightsSumUpToCurrentIndex -
                  s.params.spaceBetween * s.realIndex;

                const match =
                  /translate3d\(([^,]+),\s*([^,]+),\s*([^)]+)\)/.exec(
                    s.wrapperEl.style.transform
                  );
                if (match) {
                  s.wrapperEl.style.transform = `translate3d(${match[1]}, ${newTransformWellCalculated}px, ${match[3]})`;
                }
              }}
            >
              {taskObjArray?.length > 0 &&
                Object.keys(taskObjArray[index]).map((key, idx) => {
                  if (
                    key !== "meta" &&
                    key !== "projects" &&
                    key !== "firstName" &&
                    key !== "lastName"
                  ) {
                    // console.log("Key:", key, "tyoeof:", typeof key);
                    return (
                      <SwiperSlide key={uuidv4()}>
                        {" "}
                        {/* Generate a unique key for the SwiperSlide */}
                        {displayItems(
                          key,
                          index,
                          taskObjArray,
                          taskObjArray[index]?.meta?.renderComponent
                        )}
                      </SwiperSlide>
                    );
                  } else {
                    return <></>;
                  }
                })}
            </Swiper>
          </EmployeeTaskRenderer>
        );
      }
    }
    return renderedComponents; // Return the array of employees
  };

  useEffect(() => {
    // console.log("DashboardData:", taskObjArray);
    setPinnedEmployeesNum(taskObjArray?.length);
  }, [taskObjArray]);

  useEffect(() => {
    // Update the employees components when the length of taskObjArray changes
    if (pinnedEmployeesNum > 0 && taskObjArray?.length > 0) {
      const employees = componentsToRender();

      /* Set all pinned employee Swiper JSX Elements to be 
    rendered in PinnedEmployeeSelector */
      // console.log("More than zero:", pinnedEmployeesNum);
      setTaskComponents(employees); // Pass the employees array
    } else if (pinnedEmployeesNum === 0) {
      setTaskComponents([]);
    }
  }, [taskObjArray, pinnedEmployeesNum]); // Include pinnedEmployeesNum in

  return (
    <div className="card custom-card card-border">
      <div className="card-header border-bottom-0 pb-0 d-flex pl-3 ml-1">
        <div className="left-header">
          <label
            style={{ paddingTop: "7.17px" }}
            className="main-content-label mb-2"
          >
            {getItemHeader("Employee", "Tasks")}
          </label>{" "}
          <span className="d-block tx-12 mb-2 text-muted">
            Select an Employee
          </span>
        </div>
      </div>
      <div className="card-body pt-2 mt-0 interactive-card-body">
        <GraphSearchSelect
          name={searchSelectName}
          control={control}
          placeholderText={searchPlaceHolderText}
          msGraphName={msGraphName}
          selectFields={selectFields}
          userEmails={endeavorUsers?.map((user) => user.email)}
          onChange={handlePinnedEmployees}
          pullEndeavorUsers={true}
        />

        {/* <div className="list-group projects-list mg-t-20">{componentsToRender()}</div> */}
      </div>
    </div>
  );
};

export default PinnedEmployeeController;
