import validateToken from "../dev-ops/validateToken";
import compareDates from "../dev-ops/compareDates";
import fetchAndSetUserData from "../dev-ops/fetchAndSetUserData";

/**
 * Description: Find Repo Project Apps (with appUrl)
 * and set to projectData if found
 * @param {*} sessionData
 * @param {*} setProjectData
 * @param {*} ids - App Rep Ids that are on the task/subtask/project
 * @param {*} allGitLabRepos
 * @param {*} filteredGitLabRepos
 * @param {*} getGitlabGroupData
 * @param {*} getGitlabSubGroupData
 * @param {*} initProjectData
 */
export async function getProjectData(
    sessionData,
    setProjectData,
    ids,
    allGitLabRepos,
    filteredGitLabRepos,
    getGitlabGroupData,
    getGitlabSubGroupData,
    initProjectData
) {
  // let currentSubGroup;
  // console.log("getProjectData: IDS", ids, ";InitProject:", initProjectData);
  const data = await validateToken(sessionData);
  let response = await fetchAndSetUserData(data);
  let userData = await response.json()
  const userRepos = userData.repos?.filter((repo) => repo.creator_id === parseInt(sessionData.gitLab[0].id));

  allGitLabRepos(userData.repos);
  filteredGitLabRepos(userRepos);

  const filteredData = userData.repos?.filter((repo) => {
    return ids.includes(repo.id);
  });

  if (filteredData?.length > 0) {
    filteredData.project = initProjectData;
    setProjectData(filteredData);
  }
}

export const setActivityColor = (lastActivityAt) => {
  return Number(compareDates(lastActivityAt));
};

export function handleCloneRepo(text, toastMessage) {
  navigator.clipboard.writeText(text).then(
      function () {
        toastMessage(`${text} has been saved to your clipboard`);
      },
      function (err) {
        toastMessage("Could not copy text: ", err);
      }
  );
}
