import React from 'react';
import {connect} from "react-redux";
import ExecutiveSidebar from "./sidebarRoles/ExecutiveSidebar";
import AdminSidebar from "./sidebarRoles/AdminSidebar";
import DeveloperSidebar from "./sidebarRoles/DeveloperSidebar";
import MaintainerSidebar from "./sidebarRoles/MaintainerSidebar";
import GuestSidebar from "./sidebarRoles/GuestSidebar";
const Sidebar = (props) => {
    let role = props.session.ssoRole;

    switch (role) {
        case 'admin':
            return <AdminSidebar />;
        case 'executive':
            return <ExecutiveSidebar/>;
        case 'developer':
            return <DeveloperSidebar />;
        case 'maintainer':
            return <MaintainerSidebar />;
        default:
            return <GuestSidebar />;
    }
};
const mapStateToProps = ({session}) => ({
    session
});

export default connect(mapStateToProps)(Sidebar);
