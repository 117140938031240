import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ProjectsLink = () => {
    return (
        <Link className='nav-link' to={'/projects'}>
            <span className="shape1"/>
            <span className="shape2"/>
            <FontAwesomeIcon className='sidemenu-icon' icon='folder'/>
            <span className="sidemenu-label">Projects</span>
        </Link>
    );
};

export default ProjectsLink;
