import React, {memo, useEffect, useState, lazy, Suspense} from "react";

import Card from './Card';
import {signin} from "../../../actions/session/signIn";
import {connect} from "react-redux";
import Wrapper from "../../global/Wrapper";
import {getFigmaTeamData} from "../../../actions/figmaTeam";
import TeamSelect from "../../global/figma/TeamSelect";
import {getFigmaProjectData} from "../../../actions/figmaProjectData";
import compareDates from "../../../util/dev-ops/compareDates";



import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import FigmaSettings from "../../settings/Figma";
function Project(props) {
	const [loading, setLoading] = useState(false);
	const [start, setStart] = useState(false)
	useEffect(()=> {
		console.log(window.location.pathname)
		if (props.figmaProjectsTeamId && props.session.figmaAccessToken) {
			if (start) {
				setLoading(true)
				fetch(`${AUTH_BASE_URL}auth/figma/project/${props.figmaProjectsTeamId}/${props.session.figmaAccessToken}/${props.session.email}`, {
					method: 'POST',
				}).then(response => {
					return response.json()
				}).then(data => {

					props.getFigmaProjectData(data)
					setLoading(false)
					setStart(false)
					return data
				}).then(dataToSend => {
					console.log('DATA to SEND')
					fetch(`${AUTH_BASE_URL}auth/figma/versions/${props.figmaProjectsTeamId}/${props.session.figmaAccessToken}/${props.session.email}`, {
						method: 'POST',
						body: JSON.stringify(dataToSend),
						headers: {
							'Content-Type': 'application/json',
						}
					}).then(response => {
						return response.json()
					}).then(data => {
						props.getFigmaProjectData(data)
					})
				})
			}

		} else {
			if (!props.figmaProjectData) {
				setLoading(true)
				fetch(`${AUTH_BASE_URL}auth/figma/project/${props.session.figmaTeamId}/${props.session.figmaAccessToken}/${props.session.email}`, {
					method: 'POST'
				}).then(response => {
					return response.json()
				}).then(data => {
					props.getFigmaProjectData(data)
					setLoading(false)
					return data
				}).then(dataToSend => {

					fetch(`${AUTH_BASE_URL}auth/figma/versions/${props.session.figmaTeamId}/${props.session.figmaAccessToken}/${props.session.email}`, {
						method: 'POST',
						body: JSON.stringify(dataToSend),
						headers: {
							'Content-Type': 'application/json',
						}
					}).then(response => {
						console.log('DATA to SEND', response)
						return response.json()
					}).then(data => {
						props.getFigmaProjectData(data)
					})
				})
			}

		}
	},[props.figmaProjectsTeamId]);

	const setNewBadge = (createdAt) => {
		return Number(compareDates(createdAt));
	};

	console.log(loading)
	if (loading) {
		return (
			<Wrapper>
				<div className="main-content pt-0 figma">
					<div className="container-fluid">
						<div className="inner-body users">
							{/* Page Header */}
							<div className="page-header">
								<div>
									<h2 className="main-content-title tx-24 mg-b-5">Design Ops - Figma Projects</h2>
								</div>
								{
									props.session.figmaAccessToken ?
								<div className='page-group-controls'>
									<span className="d-block tx-13 mg-b-10">Select a team.</span>
									<div>
										<TeamSelect type={'projects'} start={setStart}/>
									</div>
								</div> : <></>
								}

							</div>
							<div className="row row-sm">
								{
									props.session.figmaAccessToken ?
										<div className="col-sm-12 col-md-12 col-xl-12 figma grid-container">
											Loading....
										</div> : <div className={'figma-auth'}>		<FigmaSettings /></div>
								}
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
		)
	} else {
		return (
			<Wrapper>
				<div className="main-content pt-0 figma">
					<div className="container-fluid">
						<div className="inner-body users">
							{/* Page Header */}
							<div className="page-header">
								<div>
									<h2 className="main-content-title tx-24 mg-b-5">Design Ops - Figma Projects</h2>
								</div>

								{
									props.session.figmaAccessToken ?
										<div className='page-group-controls'>
											<span className="d-block tx-13 mg-b-10">Select a team.</span>
											<div>
												<TeamSelect type={'projects'} start={setStart}/>
											</div>
										</div> : <></>
								}

							</div>
							<div className="row row-sm">
								<div className="col-sm-12 col-md-12 col-xl-12 figma grid-container">
									{
										props.figmaProjectData ? props.figmaProjectData.map(project => {
											if (project.original) {
												return (
													<Suspense fallback={<div>Loading...</div>}>
														<h5 className={'project-head'}>{project.title}</h5>
														{
															!project.error ? <Card data={project} /> : <></>
														}

													</Suspense>
												)
											}
											return (
												<>
													<Card data={project} />
												</>
											)
										}): <>Loading...</>
									}

								</div>
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
		);

	}

}

const mapStateToProps = ({ session, figmaTeamData, figmaTeamId, figmaProjectData, figmaComponentData, figmaProjectsTeamId }) => ({
	session,
	figmaTeamData,
	figmaTeamId,
	figmaProjectData,
	figmaComponentData,
	figmaProjectsTeamId
});

const mapDispatchToProps = (dispatch) => ({
	signin: (user) => dispatch(signin(user)),
	getFigmaTeamData: (token) => dispatch(getFigmaTeamData(token)),
	getFigmaProjectData: (data) => dispatch(getFigmaProjectData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Project));
