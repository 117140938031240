import React from 'react';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CALLBACK_URL_DO from "../../util/CALLBACK_URL_DO";

function DigitalOcean(props) {
    const email = props.session.email; // Assume email is stored in session

    const handleConnect = () => {
        const state = encodeURIComponent(JSON.stringify({ email, csrfToken: 'random-string' }));
        const authorizationUrl = `https://cloud.digitalocean.com/v1/oauth/authorize?client_id=${process.env.REACT_APP_DIGITALOCEAN_CLIENT_ID}&redirect_uri=${encodeURIComponent(`${CALLBACK_URL_DO}auth/digital-ocean/callback`)}&response_type=code&scope=read%20write&state=${state}`;
        window.open(authorizationUrl, '_blank');
    };

    return (
        <div className="card custom-card">
            <div className="card-body">
                <div className="card-item">
                    <div className="card-item-title mb-2">
                        <label className="main-content-label tx-14 font-weight-bold mb-1">Digital Ocean App / Web Hosting</label>
                        <span className="d-block tx-13 mb-0">You'll need to connect your Digital Ocean account to use hosting operations</span>
                    </div>
                    <div className="card-item-body d-flex">
                        <div className="card-item-stat">
                            <div className="btn-list">
                                <button className="btn ripple btn-secondary" onClick={handleConnect}>
                                    <FontAwesomeIcon icon={`fa-brands fa-digital-ocean`} className={'mg-r-8'} />Connect to Digital Ocean
                                </button>
                            </div>
                            {
                                !props.session.digitalAccessToken ?
                                    <span className="label text-danger d-flex align-items-center mg-l-8">
                                        <span className="dot-label bg-danger mr-1"></span>
                                        <span className={'text-danger'} style={{ fontSize: '12px' }}>Not Connected</span>
                                    </span> :
                                    <span className="label text-success d-flex align-items-center mg-l-8">
                                        <span className="dot-label bg-success mr-1"></span>
                                        <span className={'text-success'} style={{ fontSize: '12px' }}>Connected</span>
                                    </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ session }) => ({
    session
});

export default connect(
    mapStateToProps
)(DigitalOcean);
