import React from "react";
import TemplateRow from "./TableRow";

function Table({ loading, templates, fetchData }) {

    if (loading) {
        return (
            <div className="table-responsive userlist-table">
                <table className="table card-table table-striped table-vcenter mb-0">
                    <thead>
                    <tr>
                        <th className={'wd-lg-15p'}><span>Name</span></th>
                        <th className={'wd-lg-30p'}><span>Repository</span></th>
                        <th className={'wd-lg-15p'}><span>Owner</span></th>
                        <th className={'wd-lg-35p'}><span>Actions</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan={5}>
                            <div className={`skeleton-text`} style={{ width: '100%', height: '10px' }}></div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5}>
                            <div className={`skeleton-text`} style={{ width: '100%', height: '10px' }}></div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5}>
                            <div className={`skeleton-text`} style={{ width: '100%', height: '10px' }}></div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={5}>
                            <div className={`skeleton-text`} style={{ width: '100%', height: '10px' }}></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <div className="table-responsive userlist-table">
                <table className="table card-table table-striped table-vcenter mb-0">
                    <thead>
                    <tr>
                        <th className={'wd-lg-25p'}><span>Name</span></th>
                        <th className={'wd-lg-30p'}><span>Repository</span></th>
                        <th className={'wd-lg-35p'}><span>Type</span></th>
                        <th className={'wd-lg-35p'}><span>Actions</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {templates.length === 0 ? (
                        <tr>
                            <td colSpan={5}>No templates found.</td>
                        </tr>
                    ) : (
                        templates.map((template, index) => (
                            <TemplateRow fetchData={fetchData} key={index} template={template} />
                        ))
                    )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Table;
