import React from "react";
import Select from "react-select";

const CustomSelect = (props) => {
console.log('CUSTOM SELECT', props)
  // console.log("CustomSelect: Props.isMulti:", props.isMulti, "!props.isMulti", !props.isMulti, "required:", props.required);
  const displaySelect = (props?.isMulti === undefined   && props?.required === undefined) ?
  <Select
  isMulti
  name={props.type}
  options={props.data}
  className="basic-multi-select"
  classNamePrefix="select"
  onChange={props.handler}
  value={props.value}
/>
: !props.isMulti ? (
    <Select
      name={props.type}
      options={props.data}
      className={`basic-multi-select ${props.card ? "card-select" : null}`}
      classNamePrefix="select"
      onChange={props.handler}
      // value={props.value}
      defaultValue={
        props.value?.length === 1 ? props.value : props.defaultValue
      }
      required={props.required? props.required : false}
    />
  ) : props.required ? (
    <Select
      isMulti
      name={props.type}
      required
      options={props.data}
      className={`basic-multi-select ${props.card ? "card-select" : null}`}
      classNamePrefix="select"
      onChange={props.handler}
      value={
        props.defaultValue
      }
    />
  ) : (
    <Select
      isMulti
      name={props.type}
      options={props.data}
      className={`basic-multi-select ${props.card ? "card-select" : null}`}
      classNamePrefix="select"
      onChange={props.handler}
      value={props.value}
      defaultValue={props.value.length === 1 ? props.value : props.defaultValue}
    />
  );

  return <>{displaySelect}</>;
};

export default CustomSelect;
