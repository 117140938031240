import React, {useEffect, useState} from "react";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import ScaleLineChart from "./ScaleLineChart";

function Scale (props) {
	const [data, setData] = useState();
	useEffect(()=>{
		if (props.data) {
			fetch(`${AUTH_BASE_URL}usertesting/results/${props.data.name}/answers?type=${props.resultType}`, {
				method: 'GET',
				headers: {
					"Content-Type": "application/json",
				},
			}).then(response => response.json()).then(data => {
				setData(data)
			})
		}
	},[props.data])
	if (data && data.filter(val => val.type === '1-10 Scale').length > 0) {
		return (
			<div className="col-sm-12 col-md-6 col-xl-4">
			<div className="card custom-card pd-0 ">
				<div className="card-header border-bottom-0 pb-0 d-flex align-content-between justify-content-between">
					<label className="main-content-label mb-2 pt-1">1-10 Scale Questions</label>
				</div>
				<div className="card-body sales-product-info ot-0 pt-0 pb-0" style={{position: 'relative'}}>
					<div id="recentorders">
						<ScaleLineChart data={data} type={"True / False"}/>
					</div>
				</div>
			</div>
			</div>
		)
	} else {
		return <></>
	}
}

export default Scale
