import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useParams} from "react-router-dom"
import allUsers from "../../util/users/allusers";
import {allUserData} from "../../actions/allUsers";
import {updateProject} from "../../util/projects/updateProject"
import {addData} from "../../util/data/addData"
import {removeData} from "../../util/data/removeData"
import Select from "../global/inputs/CustomSelect"


const ManageUsers = props => {

    const params = useParams();

    const [projectUsers, setProjectUsers] = useState([])
    const [allAvailableUsers, setAllAvailableUsers] = useState([])

    useEffect(()=> {
        //get the users that are already in this project from ProjectDetail
        if(props.projectDetails.users){
           props.projectDetails.users.forEach((user)=>{
                setProjectUsers((prevState)=>{
                    user.label = user.email
                    user.value = user._id
                    return [...prevState, user]
                })
            })
        }

        //if users is not available from redux
        // get them from the database and store them
        if(!props.users){
            allUsers().then(async data => {
                props.allUserData(await data)
            })
        }
    }, [])

    //once users are available in redux save them to state
    useEffect(()=>{
        if(props.users && props.users.length > 0){
            props.users.forEach((user)=>{
                if(user._id !== props.projectDetails.creator._id){
                    setAllAvailableUsers((prevState)=>{
                        user.label = user.email
                        user.value = user._id
                        return [...prevState, user]
                    })
                }
            })
        }
    },[props.users])

    const handleSave = () => {

        let project_data = {
            users: projectUsers
        }

        updateUserData()

        updateProject(params.id, project_data).then(()=>{
            props.getProjectDetails()
            props.handleCloseDetailModal()
        })
    }

    const updateUserData = () => {
        let projectId = props.projectDetails._id

        //returns users who have been added
        let newUsers = projectUsers.filter(user => !props.projectDetails.users.includes(user));
        //returns users who have been removed
        let removedUsers = props.projectDetails.users.filter(user => !projectUsers.includes(user));

        //if the user is new to the project
        //get the new user's data id
        //push the project's id to the data document's projects array
        newUsers.forEach(user=>{
            if(user.data){
                let dataDoc = user.data
                let data = {
                    project: projectId
                }
                addData(dataDoc, data).then(response=>{
                    return response.json()
                }).then(responseData=>{
                    // console.log(responseData)
                })
            }
        })

        //if the user has been removed from the project
        //get the user's data id
        //remove the project's id from the data document's project array
        removedUsers.forEach(user=>{
            if(user.data){
                let dataDoc = user.data
                let data = {
                    project: projectId
                }
                removeData(dataDoc, data).then(response=>{
                    return response.json()
                }).then(responseData=>{
                    // console.log(responseData)
                })
            }
        })
    }

    return(
        <div className="modal d-block">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow">
                    <div className="modal-header">
                        <span>{props.projectDetails.name}</span>
                        <h6 className="modal-title">Manage Team</h6>
                    </div>

                    <div className='detail-modal-body'>
                        <div className="form-group">
                            <label><p><b>Important note:</b> You cannot remove the owner
                                {props.projectDetails.creator ?
                                    <>, {props.projectDetails.creator.firstName} {props.projectDetails.creator.lastName}, </>
                                : <> </>}
                                from this project.</p></label>
                            <Select
                                value={projectUsers}
                                handler={setProjectUsers}
                                data={allAvailableUsers}
                                type={'users'}
                                card
                            />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="button" onClick={handleSave}>Save</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseDetailModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({session, users}) => ({
    session, users
});

const mapDispatchToProps = dispatch => ({
    allUserData: (data) => dispatch(allUserData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageUsers);
