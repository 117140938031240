import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ProjectSubmenu = () => {
    const [location, setLocation] = useState(useLocation());
    const navigate = useNavigate();
    return (
        <>
            <Link className='nav-link' to={'/projects'}>
                <span className="shape1"/>
                <span className="shape2"/>
                <FontAwesomeIcon className='sidemenu-icon' icon='folder'/>
                <span className="sidemenu-label">Projects</span>
            </Link>
            <ul className="nav-sub"
                style={location.pathname.split('/')[1] === 'projects' ? {overflow: 'visible'} : {overflow: 'hidden'}}>
                <li className="nav-sub-item">
                    <button onClick={() => {
                        navigate('/projects')
                    }} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Projects</span>
                    </button>
                </li>
                <li className="nav-sub-item">
                    <button onClick={() => {
                        navigate('/projects/planning')
                    }} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Planning</span>
                    </button>
                </li>
            </ul>
        </>
    )

};

export default ProjectSubmenu;
