import React, { useEffect, useState, useRef, createRef } from "react";
import DropDown from "../../global/DropDown";
import { connect } from "react-redux";
import { projectFilters } from "../../../actions/projectFilters";
import allUsers from "../../../util/users/allusers";
import {allUserData} from "../../../actions/allUsers";
import { fetchProducts } from "../../../util/helpers/projectUtil";

const Filter = (props) => {

  const   {
    setFilteredProjects, //sets state in Projects
  } = props

  //these are also in redux
  //making them available here for the reset function
  const preloadedFilters = [
    {filter:"active", group:"status"},
    {filter:"new", group:"status"},
  ]
  const [projectFiltersLocal, setProjectFiltersLocal] = useState([]);
  const [allAvailableUsers, setAllAvailableUsers] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [showFilter, setShowFilter] = useState(true);
  const filterData = [
    {
      title: 'Project Status',
      databaseField:'status',
      options: ["new", "active", "inactive", "closed"].sort()
    },
    {
      title: 'Data Access',
      databaseField:'dataAccess',
      options: ["public", "private"].sort()
    },
    {
      title: 'Users',
      databaseField:[
        {
          field:'users',
          by:'_id'
        },
        {
          field:'creator',
          by:'_id'
        }
      ],
      options: allAvailableUsers ? allAvailableUsers.sort((a, b)=>{
          if ( a.label < b.label){
            return -1;
          }
          if ( a.label > b.label ){
            return 1;
          }
          return 0;
        }) : []
    },
    {
      title: 'Products',
      databaseField:'product',
      options: productOptions ? productOptions.sort() : []
    }
  ]
  const filterAllRef = useRef(filterData.map(()=>createRef()))
  const filterOptionsRef = useRef(filterData.map((filter) => {
    return filter.options.map(()=>createRef())
  }));

  //get the list of product options to send to Filter
  useEffect(()=>{
    fetchProducts(setProductOptions);
  },[])

  //check to see if props.users are already loaded in redux
  //if they aren't fetch them
  useEffect(()=>{
    if(!props.users){
        allUsers().then(async data => {
            props.allUserData(await data)
        })
    }
  },[])

  //get only the users that are on the projects available
  useEffect(()=>{
      if(props.users && props.projects && props.users.length > 0 && props.projects.length > 0){
        let availableUsers = [...props.users]
        availableUsers = availableUsers.filter(user=>{
          for(let i = 0; i < props.projects.length - 1; i++){
              if(
                props.projects[i].users.some((projectUser)=>projectUser._id === user._id)
                || 
                (props.projects[i].creator && props.projects[i].creator._id === user._id)
                ){
                return user
              }
          }
        })
        let formatedUsers = []
        availableUsers.forEach(user=>{
          formatedUsers.push({
            label: user.firstName + ' ' + user.lastName,
            key: user._id
          })
        })
        setAllAvailableUsers(formatedUsers)
      }
  },[props.users, props.projects])

  useEffect(()=>{
      setProjectFiltersLocal(props.project_filters)
  },[props.project_filters])

  //filter the local projects based on the selected filters 
  useEffect(()=>{
    if(props.projects && props.projects.length > 0 && props.project_filters){
      //run projects through the filter (props.project_filters comes from redux)
      setFilteredProjects(filterProjects(props.projects))
    }
  },[props.project_filters, props.projects])

  //render the filters UI based on filter data
  const displayFilters = () => {
      let continuousIndex = 0
      return filterData.map((filter, i)=>{
          //databaseField could be an array in which case turn it into a comma separated string with field names only to add as html attributes
          let databaseField = Array.isArray(filter.databaseField) ? filter.databaseField.reduce((accumulator,currentValue, currentIndex, array)=>accumulator + (`${currentValue.field}:${currentValue.by}${currentIndex != array.length - 1 ? ',' : '' }`), '') : filter.databaseField
          return (
            <div key={i}>
              <p>{filter.title}</p>
              <ul>
                <li>
                  <label class="ckbox">
                      <input
                          id={`data-filter-all-${databaseField}`}
                          data-filtergroup={databaseField}
                          type="checkbox"
                          value='all'
                          onChange={handleSelectFilter}
                          checked={projectFiltersLocal.some(selected=>selected.group === databaseField) ? false : true}
                          disabled={projectFiltersLocal.some(selected=>selected.group === databaseField) ? false : true}
                          ref={filterAllRef.current[i]}
                      />
                      <span>All</span>
                    </label>
                </li>
                {
                  filter.options.map((item, j) => {
                    let k = continuousIndex++
                    //item could be an object for example users, the filter label should be the users name but the
                    //value to search the database for would be the users id
                    //or item could just be a string in which case the label and value would be the same
                    let label = item
                    let value = null
                    if(typeof item === 'object'){
                        label= item.label
                        value = item.key
                    }
                    const firstLetter = label.charAt(0);
                    const firstLetterCap = firstLetter.toUpperCase();
                    const remainingLetters = label.slice(1);
                    const capitalizedWord = firstLetterCap + remainingLetters;
            
                    return (
                      <li key={j}>
                        <label class="ckbox">
                          <input
                              id={`data-filter-${databaseField}-${j}`}
                              data-filtergroup={databaseField}
                              type="checkbox"
                              value={value ? value : item}
                              onChange={handleSelectFilter}
                              checked={projectFiltersLocal.some(selected=>selected.filter === item || selected.filter === value) ? true : false}
                              ref={ref => {
                                filterOptionsRef.current[k] = ref
                              }}
                            />
                            <span>{capitalizedWord}</span>
                        </label>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          )
      })
  }

  //sets the selected filters to whatever is chosen by the user
  //this triggers the useEffect on props.project_filters
  const handleSelectFilter = (event) => {
    let selected = event.target.value
    let selectedChecked = event.target.checked
    let group = event.target.getAttribute('data-filtergroup')
    if(selected === "all"){
      props.setProjectFilters(
        props.project_filters.filter(filter=>{
              return filter.group !== group
          })
      )
      //get all the "non All" refs from the appropriate group
      //and set them to unchecked
      filterOptionsRef.current.forEach(ref=>{
        if(ref.getAttribute('data-filterGroup') === group && ref.checked === true){
            ref.checked = false
        }
      })
      //get the 'All' ref that was clicked
      let allRef = filterAllRef.current.filter(ref=>{
        if(ref.current.getAttribute('data-filterGroup') === group){
          return ref
        }
      })[0].current
      //set it to disabled
      if(allRef.checked === true){
          allRef.setAttribute('disabled', 'true')
      }
    }else{
      let selectedObj = {
        group:group,
        filter:selected
      }
      if(selectedChecked === true){
        props.setProjectFilters([...props.project_filters, selectedObj])
      }else{
        let newState = props.project_filters.toSpliced(props.project_filters.findIndex(obj=>obj.group === group && obj.filter === selected), 1)
        props.setProjectFilters(newState)
      }
      
      //check to see if this is the last filter being deselected
      //get all the "non All" refs from the appropriate group
      let groupRefs = filterOptionsRef.current.filter(ref=>{
        if(ref.getAttribute('data-filterGroup') === group){
            return ref
        }
      })

      //get the 'All' ref from the appropriate group 
      let allRef = filterAllRef.current.filter(ref=>{
        if(ref.current.getAttribute('data-filterGroup') === group){
          return ref
        }
      })[0].current

      if(groupRefs.every(ref=>ref.checked === false)){
          allRef.checked = true
          allRef.setAttribute('disabled', 'true')
      }else{
           //set it to unchecked
          if(allRef.checked === true){
            allRef.checked = false
            //and make sure to re enable it
            allRef.removeAttribute('disabled')
           }
      }
    }
  }

  //this is the function that actually filters the projects array
  const filterProjects = (projects) => {
    if(projects && projects.length > 0 && Array.isArray(props.project_filters)){
      //organize the props.project_filters by group
      //first get the groups
      let groups = []
      filterData.forEach(filter=>{
         //databaseField could be an array in which case turn it into a string to match the html attribute
        groups.push(Array.isArray(filter.databaseField) ? filter.databaseField.reduce((accumulator,currentValue, currentIndex, array)=>accumulator + (`${currentValue.field}:${currentValue.by}${currentIndex != array.length - 1 ? ',' : '' }`), '') : filter.databaseField)
      })
      //then organize by group (returns an array of arrays)
      let filtersbyGroup = []
      groups.forEach(group=>{
          let bygroup = props.project_filters.filter(filter=>{
            return filter.group === group 
          })
          if(bygroup.length > 0){
            filtersbyGroup.push(bygroup)
          }
              
      })
      //filter the projects data for only projects that match the filters
      return projects.filter((project) => {
        if(filtersbyGroup.length > 0){
          let match = []
          for(let i = 0; i < filtersbyGroup.length; i++){
              //groupName could be just a single string but it could also be a comma separated list so if it is, split if into an array otherwise leave it as a string
              let groupName = filtersbyGroup[i][0].group.includes(',') ? filtersbyGroup[i][0].group.split(',') : filtersbyGroup[i][0].group
              let filters = []
              filtersbyGroup[i].forEach(filter=>{
                filters.push(filter.filter)
              })
              if(Array.isArray(groupName)){
                  let subGroup = [...groupName]
                  let subMatch = []
                  subGroup.forEach(subGroup=>{
                      let subGroupName = subGroup.split(':')[0]
                      let identifier = subGroup.split(':')[1]
                      let projectProperty = project[subGroupName]
                      subMatch.push(checkMatch(projectProperty, identifier, filters))
                  })
                  if(subMatch.some(item=>{return item === true})){
                    match.push(true)
                  }else{
                    match.push(false)
                  }
              }else{
                let projectProperty = project[groupName]
                match.push(checkMatch(projectProperty, null, filters))
              }
          }
          //if more than one filter group has been selected than a projects property must be equal to at least 1 item in all filter groups
          //ex: A project can be "closed" OR "inactive" AND it also must match the product "Nautilus"
          if(match.every(item=>{return item === true})){
              return project
          }
        }else{
          return project
        }
      });
    }else return []
  }

  //checks the list of filters for the existance of at least 1 project property value in an array
  //checks the list of filters for the existance of a single project property value
  //example does the filters list include at least 1 user from projects.users
  //example does the filters list include "new"
  const checkMatch = (projectProperty, identifier, filters) => {
    console.log("ProjectProperty:", projectProperty, " Identifier:", identifier, " Filter:", filters)
      //if the projects property is an array then
      //at least 1 item in that array must be in the filter group
      if(Array.isArray(projectProperty)){  
        let identifiers = []
        for(let i = 0; i < projectProperty.length; i++){
            identifiers.push(projectProperty[i][identifier])
        }
        if(filters.some(filter=>identifiers.includes(filter))){
            return true 
        }else{
          return false
        }
      }else{
          //a projects property must be equal to at least 1 item in the filter group
          if(identifier){
            // if((projectProperty !== null) && filters.includes(projectProperty[identifier])){
              if((projectProperty !== null) && filters.includes(projectProperty[identifier])){
              return true
            }else{
              return false
            }
          }else{
            if(filters.includes(projectProperty)){
              return true
            }else{
              return false
            }
          }
          
      }
  }

  const handleReset = () => {
    props.setProjectFilters(preloadedFilters)
  }

  const handleSave = () => {

  }

  return (
    <>
      {showFilter ? (
        <DropDown
          icon={"fa-filter"}
          label={"Filter"}
          classOptions="projects-filter-menu"
          type="filter"
        >
          {displayFilters()}
          <div className='filter-menu-footer'>
              <button className='btn btn-secondary' onClick={handleReset}>
                  Reset
              </button>
              <button className='btn btn-secondary' onClick={handleSave}>
                  Save
              </button>
          </div>
        </DropDown>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = ({ users, projects, project_filters, project_sort }) => ({
  users, 
  projects,
  project_filters,
  project_sort
});

const mapDispatchToProps = (dispatch) => ({
  setProjectFilters: (data) => dispatch(projectFilters(data)),
  allUserData: (data) => dispatch(allUserData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Filter));
