import React, { useEffect, useState } from 'react';
import AUTH_BASE_URL from "../../../../util/AUTH_BASE_URL";
import {toastMessage} from "../../../../actions/toast";
import {connect} from "react-redux";
function LiveBuilds(props) {
    const [deployments, setDeployments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDeployments = async () => {
            try {
                console.log('Fetching deployments...');
                const response = await fetch(`${AUTH_BASE_URL}auth/digital-ocean/deployments`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${props.session.digitalAccessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log('Deployments fetched:', data);

                // Filter for deployments that are not complete or done
                const activePhases = ['PENDING_BUILD', 'BUILDING', 'PENDING_DEPLOY', 'DEPLOYING'];
                const activeDeployments = data.deployments.filter(deployment => activePhases.includes(deployment.phase));

                console.log('Active deployments:', activeDeployments);

                setDeployments(activeDeployments);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching deployments:', error);
                setLoading(false);
            }
        };

        fetchDeployments();

        // const intervalId = setInterval(fetchDeployments, 15000);
        // return () => clearInterval(intervalId); // Cleanup on unmount
    }, [props.session.digitalAccessToken]);

    if (loading) {
        console.log('Loading live deployments...');
        return <div>Loading live deployments...</div>;
    }

    if (deployments.length === 0) {
        console.log('No live deployments currently.');
        return <div>No live deployments currently.</div>;
    }

    return (
        <div>
            <h3>Live Deployments</h3>
            <ul>
                {deployments.map((deployment) => (
                    <li key={deployment.id}>
                        <div>Deployment ID: {deployment.id}</div>
                        <div>Status: {deployment.phase}</div>
                        <div>Started At: {new Date(deployment.created_at).toLocaleString()}</div>
                        <div>Updated At: {new Date(deployment.updated_at).toLocaleString()}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

const mapStateToProps = ({ session }) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveBuilds);
