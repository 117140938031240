import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserLinks from "../links/UserLink";
import { connect } from "react-redux";

const HostingSubmenu = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <>
            <Link className='nav-link' to={'/hosting/apps'}>
                <span className="shape1"/>
                <span className="shape2"/>
                {
                    props.session.digitalAccessToken ?
                        <FontAwesomeIcon className='sidemenu-icon' style={{color: '#0080FF'}} icon='fa-brands fa-digital-ocean'/> :
                        <FontAwesomeIcon className='sidemenu-icon' icon='fa-brands fa-digital-ocean'/>
                }
                <span className="sidemenu-label">Hosting</span>
            </Link>
            <ul className="nav-sub"
                style={location.pathname.split('/')[1] === 'hosting' ? { overflow: 'visible' } : { overflow: 'hidden' }}>
                <li className="nav-sub-item">
                    <button onClick={() => navigate('/hosting/apps')} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Apps</span>
                    </button>
                </li>
                <li className="nav-sub-item">
                    <button onClick={() => navigate('/hosting/droplets')} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Droplets</span>
                    </button>
                </li>
                <li className="nav-sub-item">
                    <button onClick={() => navigate('/hosting/database')} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Databases</span>
                    </button>
                </li>
                {/*<li className="nav-sub-item">*/}
                {/*    <button onClick={() => navigate('/hosting/storage')} className={'nav-sub-link text-white'}>*/}
                {/*        <span className="sidemenu-label pd-l-20">Storage</span>*/}
                {/*    </button>*/}
                {/*</li>*/}
            </ul>
        </>
    );
};

const mapStateToProps = ({ session }) => ({
    session
});

export default connect(mapStateToProps)(HostingSubmenu);
