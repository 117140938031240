import Modal from '../../global/templates/Modal'
import FileUpload from "../../global/inputs/FileUpload"
import { useForm } from "react-hook-form";
import AUTH_BASE_URL from '../../../util/AUTH_BASE_URL';
import exampleCSV from '../../../assets/data/test_users_example.csv'

function ImportUsersModal(props) {


  const {
    register,
    // control,
    handleSubmit,
    // formState: { errors },
    // reset,
    // setValue,
  } = useForm();

    const handleSave = async (data) => {
        let file = data.testusers[0]
        let reader = new FileReader()
        let dataToSend;
        reader.addEventListener(
            "load",
            () => {
                let result = reader.result;
            
                //https://stackoverflow.com/questions/7431268/how-to-read-data-from-csv-file-using-javascript
                var allTextLines = result.split(/\r\n|\n/);
                var headers = allTextLines[0].split(',');
                var lines = [];

                for (var i=1; i<allTextLines.length; i++) {
                    var data = allTextLines[i].split(',');
                    if (data.length == headers.length) {

                        var tarr = {};
                        for (var j=0; j<headers.length; j++) {
                            let key = headers[j].toLowerCase()
                            tarr[key] = data[j].toLowerCase()
                        }
                        lines.push(tarr);
                    }
                }

                // lines.forEach(testuser=>{
                fetch(`${AUTH_BASE_URL}usertesting/add_test_users`, {
                    method: 'POST',
                    body: JSON.stringify({testusers: lines, test: props.testId, }),
                    headers: new Headers({
                    "Content-Type": "application/json",
                    }),
                })
                // })

            },
            false
          );
          if (file) {
            reader.readAsText(file);
          }
        props.handleCloseNewModal()
    }

    return(
        <Modal
            header={'Import Test Users'}
            handleCloseModal={props.handleCloseNewModal}
            handleSubmit={handleSubmit}
            submitForm={handleSave}
            // searchSelectName={searchSelectName ? searchSelectName : ""}
            // message={'test message'}
            modalClass={"import-users-modal"}
        >
            <FileUpload
                label={'Upload CSV'}
                name={'testusers'}
                register={register}
            />
            <p>Uploaded CSV must contain 'Name' and 'Email' fields</p>
            <a href={exampleCSV} download>Download example</a>
        </Modal>
    )

}

export default ImportUsersModal