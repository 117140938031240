import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { allTestData } from "../../../actions/allUserTests";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
const WebUrlModal = (props) => {
    console.log('web url props', props)
    const [urlData, setUrlData] = useState(props.data.data.url ? props.data.data.url : '');
    const [id, setId] = useState('');
    const [data, setData] = useState('');

    useEffect(() => {
        console.log('12', props)
        if (props.data) {
            setId(props.data.id);
            setData(props.data.data);
        }
    }, [props]);

    const handleUrlChange = (event) => {
        setUrlData(event.target.value);
    };

    const handleSave = async () => {
        const updateData = { id, data: { ...data, url: urlData }, url: urlData };
        const url = `${AUTH_BASE_URL}dev-ops/gitlab/repos/update`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
            });
            const jsonResponse = await response.json();
            console.log('Response from server:', jsonResponse);
            props.fetchData()
            props.handleCloseNewModal()
            // Additional actions based on response can be performed here
        } catch (error) {
            console.error('Error updating repo:', error);
        }

        // Add any other save logic here if necessary
    };



    console.log(props)
    return (
        <div className="modal d-block">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow">
                    <div className="modal-header">
                        <h6 className="modal-title">Add Live URL Link to {data?.name}</h6>
                    </div>
                    <div className="modal-body">
                        <div className="mg-b-20">
                            <label>Web Url</label>
                            <input value={urlData} onChange={handleUrlChange} className="form-control"/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="button" onClick={handleSave}>Save</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseNewModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ session }) => ({
    session
});

const mapDispatchToProps = (dispatch) => ({
    allTestData: (data) => dispatch(allTestData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebUrlModal);
