import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const UserLinks = (props) => {
    return (
        <Link className='nav-link' to={'/users'}>
            <span className="shape1"/>
            <span className="shape2"/>
            <FontAwesomeIcon className='sidemenu-icon' icon='users'/>
            <span className="sidemenu-label">Users</span>
        </Link>
    );
};

export default UserLinks ;
