import { useForm } from "react-hook-form";
import React, { useEffect, useRef, useState, useContext } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getProjectDetail } from "../../util/projects/getProjectDetail";
import { deleteProject } from "../../util/projects/deleteProject";
import Wrapper from "../global/Wrapper";
import ConfirmationModal from "../global/ConfirmationModal";
import ManageUsers from "./ManageUsers";
import ManagePrototypes from "./ManagePrototypes";
import ManageAssets from "../assets/ManageAssets";
import EditAsset from "../assets/EditAsset";
import DeleteAsset from "../assets/DeleteAsset";
import ProjectSettings from "./ProjectSettings";
import Avatar from "../global/Avatar";
import FileTypeFilter from "../assets/FilterFiles/FileTypeFilter";
import { removeData } from "../../util/data/removeData";
import CreateTask from "./modals/CreateTask";
import { saveTask } from "../../util/tasks/saveTask";
// import DisplayTasks from "../tasks/DisplayTasks";
import retrieveDisplayGroup from "../../util/projects/retrieveDisplayGroup";
import moment from "moment";
import TaskTable from "./TaskTable/TaskTable";
import DropDown from "../dev-ops/gitlab-repos/DropDown";
import validateToken from "../../util/dev-ops/validateToken";
import deletePrototype from "../../util/deletePrototype";
import setCiConfigPath from "../../util/dev-ops/setCiConfigPath";
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import checkForWebHooks from "../../util/dev-ops/checkForWebHooks";
import commitCiCd from "../../util/dev-ops/commitCiCd";
import addWebHook from "../../util/dev-ops/addWebHook";
import addAuthentication from "../../util/dev-ops/addAuthentication";
import removeAuthentication from "../../util/dev-ops/removeAuthentication";
import { signin } from "../../actions/session/signIn";
import { allGitLabRepos } from "../../actions/allGitlabRepos";
// import { hasAuthentication } from "../../actions/hasAuthentication";
import { filteredGitLabRepos } from "../../actions/filteredData";
import { toastMessage } from "../../actions/toast";
// import { checkForCiCd } from "../../actions/projectHasCiCd";
// import getGitlabGroups from "../../util/dev-ops/getGitlabGroups";
// import getGitlabSubGroups from "../../util/dev-ops/getGitlabSubGroups";
// import getAllRepos from "../../util/dev-ops/getAllRepos";
// import appendRepoData from "../../util/dev-ops/appendRepoData";
import { getGitlabGroupData } from "../../actions/gitlabGroups";
import { getGitlabGroupIdData } from "../../actions/gitlabGroupId";
import { getGitlabSubGroupData } from "../../actions/gitlabSubGroups";
import { getGitlabSubGroupIdData } from "../../actions/gitlabSubGroupId";
import { setFilter } from "../../actions/filterBy";
import { allCreators } from "../../actions/creators";
import { setRepoLoading } from "../../actions/repoLoading";
import compareDates from "../../util/dev-ops/compareDates";
import { v4 as uuidv4 } from "uuid";
// import { TasksContext } from "../global/Providers/TaskProvider";
import ItemBadge from "../global/ItemBadge";
import { getProjectData } from "../../util/helpers/getProjectData";
import truncateDescription from "../../util/tasks/truncateDescription";
import AppWidget from "../dev-ops/gitlab/AppWidget";
import { useGetAndSetProjects } from "../../util/projects/useGetAndSetProjects";

const deploymentRootAddress = "https://pearsonct.design";

const ProjectDetail = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const actionsRef = useRef(null);
  const [projectData, setProjectData] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [showManageSettings, setShowManageSettings] = useState(false);
  const [showManageUsers, setShowManageUsers] = useState(false);
  const [showManagePrototypes, setShowManagePrototypes] = useState(false);
  const [showManageAssets, setShowManageAssets] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTask, setShowTask] = useState(false);
  const [showEditAsset, setShowEditAsset] = useState(null);
  const [showDeleteAssetModal, setShowDeleteAssetModal] = useState(null);
  const [assetToDelete, setAssetToDelete] = useState(null);
  const [designAssets, setDesignAssets] = useState([]);
  const [nonDesignAssets, setNonDesignAssets] = useState([]);
  const [gitlabId, setGitlabId] = useState([]);
  // const [group, setGroup] = useState();
  // const [subGroup, setSubGroup] = useState();
  const [ids, setIds] = useState();
  const {getAndSetProjects} = useGetAndSetProjects()

  const foundUser = projectDetails?.users?.filter(
    (obj) => obj._id === props.session?.userId
  );
  const isProjectMember =
    (projectDetails && projectDetails.creator?._id === props.session?.userId) ||
    foundUser?.length > 0;
  const parseDescription = (description) => {
    return description?.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  };
  /********NEWTASK START*******/
  // const { isTaskUpdated, setIsTaskUpdated } = useContext(TasksContext);
  const [toggleTaskRefresh, setToggleTaskRefresh] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      status: {
        value: "new",
        label: "New",
      },
    },
  });

  /// handle Add Task form submission
  const handleTaskSave = async (data) => {
    // console.log("ProjectDetail:NewTask:Data:", data);
    const endDate = data?.endDate && moment(data.endDate).toISOString();
    const startDate = data?.startDate && moment(data.startDate).toISOString();
    const responsibleArray =
    data?.responsible && data.responsible.length > 0
      ? data.responsible.map((res) => res.value)
      : [];

    const taskData = {
      name: data?.name,
      version: data?.version,
      description: data?.description,
      creator: props.session?.userId,
      responsible: responsibleArray,
      startDate,
      endDate,
      project: projectDetails?._id,
      status: data?.status?.value,
      prototypes:
        data?.prototypes !== undefined
          ? data?.prototypes?.map((prototype) => {
              return { id: prototype._id };
            })
          : null,
      assets: data?.assets?.map((asset) => {
        return { id: asset.value };
      }),
      displayGroup: retrieveDisplayGroup(
        data?.status?.value,
        data?.responsible?.value
      ),
      note: {
        message: data?.additionalNotes,
        updateBy: props.session?.userId,
        lastUpdated: new Date(),
      },
    };


    saveTask(taskData)
    .then(()=>{

       // Rerender Screen
       setToggleTaskRefresh(!toggleTaskRefresh);
 
       delete (
         // Close modal
         handleCloseDetailModal()
       );
       // reset selectedValues
       reset();

      getProjectDetails();
      getAndSetProjects(props.session)
    });



    /// Step1: Create Task
    // const task = await saveTask(taskData);
    // if (task.ok) {
    //   const response = await task.json();
      // console.log("ProjDetail: Response:", response);


      // // Step2: Create a Matching User Data Record
      // let userDoc = props.session.userId;
      //   let taskData = {
      //     task: response._id,
      //   };

      //   const newTaskRepo = await addData(userDoc, taskData);

      // // Step3: Rerender Screen
      // setToggleTaskRefresh(!toggleTaskRefresh);

      // /**
      //  * Notify Project
      //  */
      // setIsTaskUpdated(!isTaskUpdated);

      // delete (
      //   // Close modal
      //   handleCloseDetailModal()
      // );
      // // reset selectedValues
      // reset();
    //}
  };
  /*******************************/

  useEffect(() => {
    getProjectDetails();
    // console.log("ProjectDetail: session:", props.session);
  }, []);

  useEffect(() => {
    setDesignAssets([]);
    setNonDesignAssets([]);
    if (projectDetails && projectDetails.assets.length > 0) {
      projectDetails.assets.forEach((link) => {
        if (link.figma === true) {
          setDesignAssets((prevState) => {
            return [...prevState, link];
          });
        } else {
          setNonDesignAssets((prevState) => {
            return [...prevState, link];
          });
        }
      });
    }
  }, [projectDetails]);

  const getProjectDetails = () => {
    //get project details
    getProjectDetail(params.id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log('test get details')
        console.log(data.project)
        // console.log("ProjectDetail: Data:", data);
        setProjectDetails(data.project);
      });
  };

  const handleDeleteProject = () => {
    deleteProject(params.id)
      .then(()=>{
          getAndSetProjects(props.session)
          handleCloseDeleteModal();
          navigate("/projects");
      })
  }

  const handleActionsMenu = () => {
    if (actionsRef.current.classList.contains("show")) {
      actionsRef.current.classList.remove("show");
    } else {
      actionsRef.current.classList.add("show");
    }
  };

  const handleOpenDetailModal = (type) => {
    switch (type) {
      case "settings":
        setShowManageSettings(true);
        break;
      case "users":
        setShowManageUsers(true);
        break;
      case "prototypes":
        setShowManagePrototypes(true);
        break;
      case "assets":
        setShowManageAssets(true);
        break;
      case "task":
        setShowTask(true);
        break;
    }
    handleActionsMenu();
  };

  const handleCloseDetailModal = () => {
    setShowManageSettings(false);
    setShowManageUsers(false);
    setShowManagePrototypes(false);
    setShowManageAssets(false);
    // setShowOracleCode(false);
    setShowTask(false);
  };

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
    handleActionsMenu();

    // Update ToggleTask
    // setToggleTaskUpdated();
    // setIsTaskUpdated(!isTaskUpdated);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleOpenEditAssetModal = (asset) => {
    setShowEditAsset(asset);
  };

  const handleCloseEditAssetModal = () => {
    setShowEditAsset(null);
  };

  const handleOpenDeleteAssetModal = (asset) => {
    setShowDeleteAssetModal(true);
    setAssetToDelete(asset);
  };

  const handleCloseDeleteAssetModal = () => {
    setShowDeleteAssetModal(false);
    setAssetToDelete(null);
  };

  useEffect(() => {
    if (projectDetails && projectDetails.prototypes) {
      let repoData = projectDetails.prototypes.map((val) => val.data);
      // console.log("ProjectDetail:RepoData:", repoData);
      let ids = repoData.map((val) => val?.id);
      setIds(ids);
    }
  }, [projectDetails]);

  useEffect(() => {
    // console.log("IDS:", ids);
    // if no repos have been set, set the repos
    if (ids?.length > 0) {
      getProjectData(
        props.session,
        setProjectData,
        ids,
        props.allGitLabRepos,
        props.filteredGitLabRepos,
        props.getGitlabGroupData,
        props.getGitlabSubGroupData
      );
    }
  }, [ids]);

  useEffect(() => {
    const fetchData = async () => {
      // console.log('324, projectDetails', projectDetails);
      if (projectDetails && projectDetails.prototypes) {
        // Assuming that getting each gitlabId is an async operation
        let gitlabIds = await Promise.all(
          projectDetails.prototypes.map(async (val) => {
            // Your async operation here
            return val.id; // replace this with the actual async operation if needed
          })
        );
        setGitlabId(gitlabIds);
      }
    };

    fetchData();
  }, [projectDetails]);

  console.log('THE APPS', )
  return (
    <Wrapper>
      <div className="main-content pt-0">
        <div className="container-fluid">
          <div className="inner-body project-detail">
            {showManageSettings === true && projectDetails != null ? (
              <ProjectSettings
                projectDetails={projectDetails}
                handleCloseDetailModal={handleCloseDetailModal}
                getProjectDetails={getProjectDetails}
              />
            ) : null}
            {showManageUsers === true && projectDetails != null ? (
              <ManageUsers
                projectDetails={projectDetails}
                handleCloseDetailModal={handleCloseDetailModal}
                getProjectDetails={getProjectDetails}
              />
            ) : null}
            {showManagePrototypes === true && projectDetails != null ? (
              <ManagePrototypes
                projectDetails={projectDetails}
                handleCloseDetailModal={handleCloseDetailModal}
                getProjectDetails={getProjectDetails}
              />
            ) : null}
            {showManageAssets === true && projectDetails != null ? (
              <ManageAssets
                dataLevel={"project"}
                details={projectDetails}
                projectId={projectDetails._id}
                handleCloseDetailModal={handleCloseDetailModal}
                getDetails={getProjectDetails}
              />
            ) : null}
            {showTask === true ? (
              <CreateTask
                header="New Task"
                handleClose={handleCloseDetailModal}
                submitForm={handleTaskSave}
                handleSubmit={handleSubmit}
                control={control}
                register={register}
              />
            ) : null}
            {showDeleteModal === true ? (
              <ConfirmationModal
                message="Are you sure you want to delete this project?"
                save={handleDeleteProject}
                close={handleCloseDeleteModal}
              />
            ) : null}
            {showEditAsset !== null ? (
              <EditAsset
                handleClose={handleCloseEditAssetModal}
                asset={showEditAsset}
                details={projectDetails}
                getDetails={getProjectDetails}
              />
            ) : null}
            {assetToDelete !== null && showDeleteAssetModal === true ? (
              <DeleteAsset
                message="This action will permanently delete the asset from all projects and tasks. Try Manage Assets to remove it from this project only. Are you sure you want to delete this asset?"
                assetToDelete={assetToDelete}
                getDetails={getProjectDetails}
                close={handleCloseDeleteAssetModal}
              />
            ) : null}

            {/* Page Header */}
            {projectDetails != null ? (
              <>
                <div className="page-header">
                  <div style={{ position: "relative", zIndex: "9" }}>
                    <Link to="/projects">Back to Projects</Link>
                    <h2 className="main-content-title tx-24 mg-b-8 mg-t-8">
                      <span title={projectDetails.name}>{truncateDescription(projectDetails.name, 20)}</span>
                      <ItemBadge type={projectDetails?.status} />
                      <ItemBadge type={projectDetails?.dataAccess} />
                    </h2>
                    {projectDetails.creator ? (
                      <p>
                        This project was created by{" "}
                        <b>
                          {projectDetails.creator?.firstName}{" "}
                          {projectDetails.creator?.lastName}
                        </b>
                      </p>
                    ) : null}
                    {projectDetails.oracleProjectCode &&
                    projectDetails.oracleProjectCode.code !== 0 &&
                    projectDetails.oracleProjectCode.task !== 0 ? (
                      <p>
                        Use Oracle project code:{" "}
                        <b>{projectDetails.oracleProjectCode.code}</b> and task:{" "}
                        <b>{projectDetails.oracleProjectCode.task}</b> for
                        development.
                      </p>
                    ) : null}
                  </div>
                  <div className="d-flex">
                    <div className="justify-content-center table-controls">
                      {(props.session.systemAccess >= 2 && isProjectMember) ||
                      props.session.systemAccess === 4 ? (
                        <button
                          type="button"
                          className="btn btn-primary my-2 btn-icon-text"
                          onClick={handleActionsMenu}
                        >
                          Manage
                          <FontAwesomeIcon
                            className="mg-l-4"
                            icon="fa-solid fa-chevron-down"
                          />
                        </button>
                      ) : (
                        <></>
                      )}

                      <div
                        ref={actionsRef}
                        className="dropdown-menu animated-menu actions-menu"
                      >
                        <ul>
                          <li>
                            <button
                              onClick={() => {
                                handleOpenDetailModal("settings");
                              }}
                            >
                              Project Settings
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                handleOpenDetailModal("users");
                              }}
                            >
                              Team
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                handleOpenDetailModal("assets");
                              }}
                            >
                              Assets
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                handleOpenDetailModal("task");
                              }}
                            >
                              Add Task
                            </button>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <button
                              className="delete-text"
                              onClick={handleOpenDeleteModal}
                            >
                              Delete Project
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Page Header */}
                {/* Start Container 1*/}
                <div className="col-container d-flex justify-content-between project-detail">
                  {/* Col 1 Start  - Tasks*/}
                  <div
                    className="col col-sm-12 col-md-8 col-lg-8 col-xl-8 grid-margin"
                    // style={{ border: "1px solid red"}}
                  >
                    
                   {/* start description */}
                   <div className="row">
                      <div className="card pd-8 flex-grow-1">
                        <div className="card-body">
                          <h4 className="mg-b-0 pd-b-8">Description</h4>
                          {projectDetails.description ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: parseDescription(
                                  projectDetails.description
                                ),
                              }}
                            />
                          ) : (
                            "Add a description for this project in Project Settings."
                          )}
                        </div>
                      </div>
                    </div>
                   {/* end description */}

                    {/* Row 1 Start */}
                    <div className="row">
                      <TaskTable
                        projectId={projectDetails?._id}
                        toggleTaskRefresh={toggleTaskRefresh}
                      />
                    </div>
                    {/* Row 1 End */}
                  </div>
                  {/* Col 1 End */}
                  {/* Col 2 Start  - Side Bar*/}
                  <div
                    className="col col-sm-12 col-md-4 col-lg-4 col-xl-4 grid-margin"
                    // style={{ border: "1px solid pink"}}
                  >
                    {/* Row 1 Start Description */}
                    {/* <div className="row">
                      <div className="card pd-8 flex-grow-1">
                        <div className="card-body">
                          <h4 className="mg-b-0 pd-8">Description</h4>
                          {projectDetails.description ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: parseDescription(
                                  projectDetails.description
                                ),
                              }}
                            />
                          ) : (
                            "Add a description for this project in Project Settings."
                          )}
                        </div>
                      </div>
                    </div> */}
                    {/* Row 1 End Description */}
                    
                    {/* Row 2 Start Teams */}
                    <div className="row">
                      <div className="card pd-8 flex-grow-1">
                        <div className="card-body ">
                          <h4 className="mg-b-0 pd-8 mg-b-8">Team</h4>
                          <table className="table table-bordered mg-b-0 project-team-table">
                            <tbody>
                              <tr>
                                <td className="bd-t-0 bd-l-0">
                                  <div className="main-img-user">
                                    <Avatar
                                      user={projectDetails.creator}
                                      size="sm"
                                    />
                                  </div>
                                </td>
                                <td className="bd-t-0 bd-l-0">
                                  <h6 className="mg-b-0">
                                    {" "}
                                    {projectDetails.creator?.firstName}{" "}
                                    {projectDetails.creator?.lastName}
                                  </h6>
                                  <small className="tx-11 tx-gray-500 d-flex">
                                    {projectDetails.creator?.role} -{" "}
                                  </small>
                                  <small className="tx-11 tx-gray-500 d-flex">
                                    {projectDetails.creator?.team}
                                  </small>
                                </td>
                                <td className="bd-t-0 bd-l-0">
                                  {projectDetails.lead &&
                                  projectDetails.creator?._id ===
                                    projectDetails.lead ? (
                                    <span
                                      className="repos badge badge-gradient mg-l-4"
                                      style={{
                                        marginRight: "0",
                                        position: "relative",
                                        // top: "-27px",
                                      }}
                                    >
                                      Lead
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                              {projectDetails &&
                              projectDetails.users.length > 0 ? (
                                <>
                                  {projectDetails.users.map((user, index) => {
                                    return (
                                      <tr key={uuidv4()}>
                                        <td className="bd-l-0">
                                          <div className="main-img-user">
                                            <Avatar user={user} size="sm" />
                                          </div>
                                        </td>
                                        <td className="bd-l-0">
                                          <h6 className="mg-b-0">
                                            {" "}
                                            {user.firstName} {user.lastName}
                                          </h6>
                                          <small className="tx-11 tx-gray-500 d-flex">
                                            {user.role}
                                          </small>
                                          <small className="tx-11 tx-gray-500 d-flex">
                                            {user.team}
                                          </small>
                                        </td>
                                        <td className="bd-l-0">
                                          {projectDetails.lead &&
                                          user._id === projectDetails.lead ? (
                                            <span className="repos badge badge-gradient mg-l-4">
                                              Lead
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              ) : (
                                <p className="empty">
                                  There are no users (other than the project
                                  creator) in this project.
                                </p>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Row 2 End Teams */}
                    {/* Row 3 Start Apps*/}
                    <div className="row">
                      <AppWidget projectId={projectDetails._id} label={"Project Apps"} gitlabProjectIds={projectDetails?.apps.map(val => val.value)}/>
                    </div>
                    {/* Row 3 End Apps*/}
                    {/* Row 4 Start */}
                    <div className="row">
                      <div className="card pd-8 flex-grow-1">
                        <div className="card-body">
                          <h4 className="mg-b-0 pd-8">Assets</h4>
                          <p className="mg-b-0 pd-8">Designs</p>
                          <ul className="pd-8">
                            {designAssets && designAssets.length > 0 ? (
                              <FileTypeFilter
                                linkData={designAssets}
                                handleOpenEditAssetModal={
                                  handleOpenEditAssetModal
                                }
                                handleOpenDeleteAssetModal={
                                  handleOpenDeleteAssetModal
                                }
                              />
                            ) : (
                              <p className="empty">
                                There are no designs in this project.
                              </p>
                            )}
                          </ul>
                          <p className="mg-b-0 pd-8">Other assets</p>
                          <ul className="pd-8">
                            {nonDesignAssets && nonDesignAssets.length > 0 ? (
                              <FileTypeFilter
                                linkData={nonDesignAssets}
                                handleOpenEditAssetModal={
                                  handleOpenEditAssetModal
                                }
                                handleOpenDeleteAssetModal={
                                  handleOpenDeleteAssetModal
                                }
                              />
                            ) : (
                              <p className="empty">
                                There are no other assets in this project.
                              </p>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* Row 4 End */}
                  </div>
                  {/* Col 2 End - Side Bar*/}
                </div>
                {/* End Container 1*/}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = ({
  session,
  users,
  repos,
  hasCiCd,
  gitlabGroupId,
  hasAuthentication,
  filteredData,
  gitlabGroups,
  gitlabSubGroups,
  filterBy,
  creators,
  gitlabSubGroupId,
}) => ({
  session,
  users,
  repos,
  hasCiCd,
  gitlabGroupId,
  hasAuthentication,
  filteredData,
  gitlabGroups,
  gitlabSubGroups,
  filterBy,
  creators,
  gitlabSubGroupId,
});

const mapDispatchToProps = (dispatch) => ({
  signin: (user) => dispatch(signin(user)),
  allGitLabRepos: (repos) => dispatch(allGitLabRepos(repos)),
  filteredGitLabRepos: (repos) => dispatch(filteredGitLabRepos(repos)),
  toastMessage: (data) => dispatch(toastMessage(data)),
  getGitlabGroupData: (groups) => dispatch(getGitlabGroupData(groups)),
  getGitlabGroupId: (id) => dispatch(getGitlabGroupIdData(id)),
  getGitlabSubGroupData: (groups) => dispatch(getGitlabSubGroupData(groups)),
  getGitlabSubGroupId: (id) => dispatch(getGitlabSubGroupIdData(id)),
  setFilter: (id) => dispatch(setFilter(id)),
  allCreators: (arr) => dispatch(allCreators(arr)),
  setRepoLoading: (bool) => dispatch(setRepoLoading(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
