import AUTH_BASE_URL from "../AUTH_BASE_URL";
/**
 * Create User: Send Post request
 * to  sso/session/ route to sessionize
 * the user record, it it exists during sign-in
 *  button clicked
 * @param {*} email
 * @returns
 */
export const signin = async (email, token) => (
  fetch(AUTH_BASE_URL + `auth/sso/session?token=${token}`, {
    method: "POST",
    body: JSON.stringify({data:{email}}),
    credentials: 'include',
    headers: {
      "Content-Type": "application/json"
    }
  })
);
