import React from "react";
import {connect} from "react-redux";
import {allUserData} from "../../../actions/allUsers";

/**
 * Description: Wrapper to display props.children  as a Modal
 * @param {*} header - Modal title
 * @param {*} handleCloseModal - Cancel method for button
 * @param {*} handleSubmit - react-use-form submit call back
 * @param {*} searchSelectName - Name of react-select component
 * @param {*} message - Instruction to display to the user
 * @param {Optional} modalClass - Place modal class name on component
 * @param {Optional} primaryText - Button label text, defaults to save in not passed
 * @returns
 */
const Modal = (props) => {
  return (
    <div className="main-content pt-0">
      <div className="container-fluid">
        <form onSubmit={props.handleSubmit(props.submitForm)}>
          <div className={props.modalClass? `modal d-block ${props.modalClass}`: "modal d-block"}>
            <div className="modal-dialog" role="dialog">
              <div className="modal-content modal-content-demo shadow">
                <div className="modal-header">
                  <span>{props.itemName}</span>
                  <h6 className="modal-title">{props.header}</h6>
                </div>
                <div
                  className="detail-modal-body"
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  <div className="form-group">
                    {props.searchSelectName?<label htmlFor={props.searchSelectName}></label>:<></>}
                    {props.message?<p>{props.message}</p>:<></>}
                    {props.children}
                  </div>
                </div>

                <div className="modal-footer">
                  <button className="btn ripple btn-primary" type="submit">
                    {props.primaryText ? props.primaryText : 'Save'}
                  </button>
                  <button
                    className="btn ripple btn-secondary muted"
                    type="button"
                    onClick={props.handleCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ session, users }) => ({
  session,
  users,
});

const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
