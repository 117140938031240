import React, {useEffect, useRef} from 'react';

const SpinnerButton = props => {

    const submitRef = useRef(null)
    const loaderRef = useRef(null)

    useEffect(()=>{
        if(props.loading === true){
            handleLoading()
        }else{
            handleReset()
        }
    },[props.loading])

	useEffect(()=>{
		if(props.deleteloading === true){
			submitRef.current.setAttribute('disabled', true)
		}else{
			submitRef.current.removeAttribute('disabled')
		}
	},[props.deleteloading])

	const handleLoading = () => {
		if (submitRef.current && loaderRef.current) {
			submitRef.current.setAttribute('disabled', true)
			loaderRef.current.style.display = 'inline-block'
		}
	}

	const handleReset = () => {
		if (submitRef.current && loaderRef.current) {
			submitRef.current.removeAttribute('disabled')
			loaderRef.current.style.display = 'none'
		}
	}


    return(
        <button type="buttons" ref={submitRef} onClick={props.onClick} className="btn ripple btn-primary btn-block wd-lg-auto">
            <span>{props.buttonText}</span>
            <span ref={loaderRef} aria-hidden="true" className="spinner-border spinner-border-sm margin-left" role="presentation"></span>
        </button>
    )
}


export default SpinnerButton
