import React from "react";
import ContentToggle from "../../../global/ContentToggle";

const Descriptions = ({ taskDetails, type, parentDetails }) => {
  const sectionName = "Description";
  const header = "Project";
  const parseDescription = (description) => {
    return description?.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  };
  const displayProject = (type) => {
    // console.log("Type:", type, "Description:", parentDetails?.description)
    const projectDescription =
      type && (type === "subtask" || type === "task")
        ? parentDetails?.description
        : taskDetails?.parentDetails?.description;
    // console.log("ProjectDetails:", projectDescription);
        return (
      <p className="pd-8">
        {projectDescription ? (
          <div
            dangerouslySetInnerHTML={{
              __html: parseDescription(projectDescription),
            }}
          />
        ) : (
          "Add a description for this project in Project Settings."
        )}
      </p>
    );
  };

  return (
    <div className="row">
      <div className="card pd-8 flex-grow-1">
        <div className="card-body">
          <ContentToggle
            sectionName={sectionName}
            header={header}
            showContent={
              type && ((type === "subtask") || (type === "task"))
                ? parentDetails?.description
                : taskDetails?.parentDetails?.description
            }
          >
            <div className="mg-b-40 bd-b pd-b-20">{displayProject(type)}</div>
          </ContentToggle>
          {/*Task Description */}
          <h4 className="mg-b-0 pd-8">
            {type === "task"
              ? "Task Description"
              : type === "subtask"
              ? "Subtask Description"
              : ""}
          </h4>
          <p className="pd-8">
            {taskDetails?.description ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: parseDescription(taskDetails?.description),
                }}
              />
            ) : (
              "Add a description for this project in Project Settings."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Descriptions;
