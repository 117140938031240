import Wrapper from "../../global/Wrapper";
import React, {useEffect, useRef, useState} from "react";
import {allTestData} from "../../../actions/allUserTests";
import {connect} from "react-redux";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import {Link, useNavigate} from "react-router-dom";
import Table from "./Table";

function All (props) {
	const [result, setResult] = useState()
	const [resultData, setResultData] = useState();
	const yesRef = useRef()
	const navigate = useNavigate();
	useEffect(()=> {
		const parts = window.location.pathname.split("/"),
			result = parts.pop(),
			name = parts.pop();
		setResult(name)
		fetch(`${AUTH_BASE_URL}usertesting/results/${name}`, {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		}).then(response => response.json()).then(data => {
			setResultData(data)
		})
	},[])

	const handleExport = (questions, fileName) => {

		//Create csv
		let compeleteCSV = ''
		questions.forEach((question, index)=>{
			let table;
			if(fileName === '_pretest.csv'){
				table = document.getElementById(`preTestTable-${index}`);
			}else{
				table = document.getElementById(`postTestTable-${index}`);
			}
			let csv = toCsv(question.question, table);
			compeleteCSV += csv
		})

		 // Download it
		download(compeleteCSV, fileName);
	}

	const toCsv = function (question, table) {
		// Query all rows
		const rows = table.querySelectorAll('tr');
		//https://stackoverflow.com/questions/2125714/explanation-of-slice-call-in-javascript
		return [].slice
			.call(rows)
			.map(function (row, index) {
				// Query all cells
				const cells = row.querySelectorAll('th,td');
				let cellsString = (index === 0 ? 'Question, ' : question + ', ') + [].slice
					.call(cells)
					.map(function (cell) {
						return cell.textContent;
					})
					.join(',');
				return cellsString
			})
			.join('\n');
	};

	const download = function (text, fileName) {
		const link = document.createElement('a');
		link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
		link.setAttribute('download', fileName);

		link.style.display = 'none';
		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};
	console.log('the result data', resultData)
	if (resultData) {
		return (
			<Wrapper>
				<div className="main-content pt-0">
					<div className="container-fluid">
						<div className="inner-body project-detail">
							{/* Page Header */}
							<div className="page-header">
								<div className={"project-detail"}>
									<Link to={`/usertesting/online/results/${result}`}> Back to Overview</Link>
									<div>
										<h2 className="main-content-title tx-24 mg-b-5 mg-t-10">Detailed Results for {result}</h2>
									</div>
								</div>
								<div className="d-flex">
									<div className="justify-content-center table-controls">
										<button onClick={function(){window.location.href = 'https://app.fullstory.com/ui/171DYF/home'}} type="button" className="btn btn-primary my-2 btn-icon-text">
											Full Story Analytics
										</button>
									</div>
								</div>
							</div>
							{
								resultData.answers !== 0 ?		<div className="row row-sm">
									<div className="col-sm-12 col-md-6 col-xl-12">
										<div className="d-flex">
											<h3>Test Videos</h3>
										</div>
										<Table data={resultData} type='video'/>

									</div>
								</div> : <></>
							}
							{
								resultData.postTestData.length !== 0 ? 		<div className="row row-sm">
									<div className="col-sm-12 col-md-6 col-xl-12">
										<div className="d-flex">
											<h3>Post Test Survey</h3>
											<button onClick={()=>{handleExport(resultData.postTestData, resultData.name + '_posttest.csv')}} type="button" className="btn btn-primary mg-l-20">
												Export as CSV
											</button>
										</div>
										{
											resultData.postTestData.map((question, key) => {
												return <Table data={resultData} key={key} index={key} question={question} type='postTest'/>
											})
										}

									</div>
								</div> : <></>
							}
							{
								resultData.preTestData.length !== 0 ?		<div className="row row-sm">
									<div className="col-sm-12 col-md-6 col-xl-12">
										<div className="d-flex">
											<h3>Pre Test Question</h3>
											<button onClick={()=>{handleExport(resultData.preTestData, resultData.name + '_pretest.csv')}} type="button" className="btn btn-primary mg-l-20">
												Export as CSV
											</button>
										</div>
										{
											resultData.preTestData.map((question, key) => {
												return <Table data={resultData} key={key} index={key} question={question} type='preTest'/>
											})
										}

									</div>
								</div> : <></>
							}

						</div>
					</div>
				</div>

			</Wrapper>
		)
	} else {
		return <></>
	}

}
const mapStateToProps = ({session, users, allTests}) => ({
	session, users, allTests
});

const mapDispatchToProps = dispatch => ({
	allTestData: (data) => dispatch(allTestData(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(All);

