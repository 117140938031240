import { callMsGraph } from "./callMsGraph";
import { graphConfig } from "./authConfig";

/**
 * Pulls Ms Graph Data after AccessToken
 * recieved from useAuthFetch, for all Ms
 * Graph collections
 */
export const fetchUserData = async (
  accountFound,
  type,
  instance,
  role,
  endpoint,
  searchTerm,
  fields,
  email,
  ssoResponse
) => {
  // console.log(
  //   "FetchUserData: Type:",
  //   type,
  //   type === "me",
  //   "; SearchTerm:",
  //   searchTerm,
  //   ";Fields:",
  //   fields,
  //   "; Instance:",
  //   instance,
  //   "; SSOResponse:",
  //   ssoResponse,
  //   ";ROLE:",
  //   role
  // );
  let msGraphQueryResults;
  switch (true) {
    case type === "people":
      if (searchTerm) {
        // console.log("In Type:", type, "searchTerm:", searchTerm);
        const query = `${endpoint}?$search=${searchTerm}&select=${fields}`;
        // Send MsGraph Request
        msGraphQueryResults = await callMsGraph(
          query,
          ssoResponse?.accessToken,
          instance
        );
        if (accountFound && msGraphQueryResults && msGraphQueryResults.value) {
          msGraphQueryResults.value.role = role;
          localStorage.setItem("endeavor_people_role", role);
          // console.log("fetchUserData: People: Role stored in local storage.");
        }
        // console.log("FetchUserData: People Collection:", result.value);
        msGraphQueryResults = msGraphQueryResults?.value;
      }
      break;
    case type === "me":
      // console.log("In ME switch");
      if (!fields) {
        // console.log(`FetchUserData: Me Collection: Type: ${type}, Endpoint: ${endpoint}`);
        msGraphQueryResults = await callMsGraph(
          endpoint,
          ssoResponse?.accessToken,
          instance
        );
        //  console.log("FetchUserData: Me Results:", msGraphQueryResults);
      } else if (fields) {
        const meQuery = `${endpoint}/?$select=${fields}&$expand=manager($select=displayName,userPrincipalName)`;
        msGraphQueryResults = await callMsGraph(
          meQuery,
          ssoResponse?.accessToken,
          instance
        );
        const meDirectReportsQuery = `${endpoint}/directReports?$select=mail`;
        const directReports = await callMsGraph(
          meDirectReportsQuery,
          ssoResponse?.accessToken,
          instance
        );
        // console.log("msGraphQueryResults", msGraphQueryResults);
        const manager =
          msGraphQueryResults &&
          msGraphQueryResults.manager &&
          msGraphQueryResults.manager.userPrincipalName.toLowerCase();
        if (manager) {
          const managerDirectReportsQuery = `${graphConfig.graphUsers.endpoint}/${manager}/directReports?$select=mail`;
          // Send MsGraph Request
          let departmentMembers = await callMsGraph(
            managerDirectReportsQuery,
            ssoResponse?.accessToken,
            instance
          );

          departmentMembers = departmentMembers.value.map((item) =>
            item["mail"].toLowerCase().trim()
          );

          departmentMembers.push(manager);
          msGraphQueryResults.departmentMembers = departmentMembers;
        } // profile.manager
        // console.log("fetchProfileData: msGraphQueryResults:", msGraphQueryResults);
        msGraphQueryResults.directReports = directReports.value;
        msGraphQueryResults.isManager = directReports?.value?.length > 0;
      }
      if (accountFound && msGraphQueryResults) {
        msGraphQueryResults.role = role;
        localStorage.setItem("endeavor_me_role", role);

        /************************************************ */
        // if (msGraphQueryResults.mail === "tony.lockhart@pearson.com") {
        //   console.log("Before: msGraphResults:", msGraphQueryResults);
        //   console.log(
        //     "Before: fetchUserData: Me: Role stored in local storage:",
        //     msGraphQueryResults.role
        //   );
        //   msGraphQueryResults.jobTitle = "Sr. Design Operations Strategist";
        //   msGraphQueryResults.department = "DTC Operations";
        //   msGraphQueryResults.role = ["admin", "maintainer"];
        //   localStorage.setItem("endeavor_me_role", msGraphQueryResults.role);
        //   console.log("After: msGraphResults: ", msGraphQueryResults);
        //   console.log(
        //     "After: fetchUserData: Me: Role stored in local storage:",
        //     msGraphQueryResults.role
        //   );
        // }
        /************************************************** */
      }
      break;
    case type === "users":
      // Call MsGraph with query
      if (email) {
        let profile = {};
        let departmentMembers = {};
        const employeeDirectReportsQuery = `${endpoint}/${email}/directReports?$select=mail`;
        let employeeDirectReports = await callMsGraph(
          employeeDirectReportsQuery,
          ssoResponse?.accessToken,
          instance
        );
        if (fields) {
          const profileQuery = `${endpoint}/${email}/?$select=${fields}&$expand=manager($select=displayName,userPrincipalName)`;
          // Send MsGraph Request
          profile = await callMsGraph(
            profileQuery,
            ssoResponse?.accessToken,
            instance
          );
          // console.log("fetchProfileData: profile:", profile);
          if (profile) {
            if (profile?.manager?.userPrincipalName) {
              const managerDirectReportsQuery = `${endpoint}/${profile.manager.userPrincipalName}/directReports?$select=mail`;
              // Send MsGraph Request
              departmentMembers = await callMsGraph(
                managerDirectReportsQuery,
                ssoResponse?.accessToken,
                instance
              );
              // console.log(
              //   "fetchProfileData: departmentMembers:",
              //   departmentMembers
              // );
            } // profile.manager
          } // profile
          msGraphQueryResults = {
            ...profile,
            departmentMembers: departmentMembers,
            isManager: employeeDirectReports?.value?.length > 0,
            directReports: employeeDirectReports?.value,
          };
        } // fields
      } // email
      break;
    default:
  }
  return msGraphQueryResults;
};
