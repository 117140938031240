// KanbanCardBack.jsx

import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { calculateCardCapacity } from '../../../../util/helpers/calculateCardCapacity';

const KanbanCardBack = ({
                            task,
                            columns,
                            editableFields,
                            handleFieldChange,
                            setEditableFields,
                            handleSave,
                            handleCancel,
                            handleDelete,
                        }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [capacity, setCapacity] = useState(0);

    // Function to add 14 days to a given date for the end date default
    const addTwoWeeks = (startDate) => {
        const date = new Date(startDate);
        date.setDate(date.getDate() + 14);
        return date;
    };

    // Function to format date from DatePicker to YYYY-MM-DD for Smartsheet
    const formatDateForSmartsheet = (date) => {
        return date ? date.toISOString().split('T')[0] : '';
    };

    // Function to parse date from Smartsheet format (YYYY-MM-DD) to a Date object
    const parseDateFromSmartsheet = (dateString) => {
        return dateString ? new Date(dateString) : null;
    };

    // Get today's date
    const getToday = () => {
        return new Date();
    };

    // Helper function to identify relevant column IDs
    const getColumnIds = () => {
        const effortCell = task.cells.find((c) =>
            columns[c.columnId]?.title.toLowerCase().includes('priority')
        );
        const dueDateCell = task.cells.find((c) =>
            columns[c.columnId]?.title.toLowerCase().includes('end')
        );
        const complexityCell = task.cells.find((c) =>
            columns[c.columnId]?.title.toLowerCase().includes('complexity')
        );
        const startDateCell = task.cells.find((c) =>
            columns[c.columnId]?.title.toLowerCase().includes('start')
        );

        return {
            effortColumnId: effortCell ? effortCell.columnId : null,
            dueDateColumnId: dueDateCell ? dueDateCell.columnId : null,
            complexityColumnId: complexityCell ? complexityCell.columnId : null,
            startDateColumnId: startDateCell ? startDateCell.columnId : null,
        };
    };

    // Helper function to merge task.cells with editableFields
    const getMergedTask = () => {
        const { effortColumnId, dueDateColumnId, complexityColumnId, startDateColumnId } = getColumnIds();

        const mergedCells = task.cells.map((cell) => {
            let newValue = cell.value;
            if (cell.columnId === effortColumnId && editableFields[effortColumnId]) {
                newValue = editableFields[effortColumnId];
            }
            if (cell.columnId === dueDateColumnId && editableFields[dueDateColumnId]) {
                newValue = editableFields[dueDateColumnId];
            }
            if (cell.columnId === complexityColumnId && editableFields[complexityColumnId]) {
                newValue = editableFields[complexityColumnId];
            }
            // Handle start date separately without affecting capacity
            if (cell.columnId === startDateColumnId && editableFields[startDateColumnId]) {
                newValue = editableFields[startDateColumnId];
            }
            return { ...cell, value: newValue };
        });

        return { ...task, cells: mergedCells };
    };

    // Set default dates and initial capacity when the component mounts or task changes
    useEffect(() => {
        const { effortColumnId, dueDateColumnId, complexityColumnId, startDateColumnId } = getColumnIds();

        // Set start date logic
        if (startDateColumnId && !editableFields[startDateColumnId]) {
            const today = getToday();
            setStartDate(today);
            handleFieldChange(startDateColumnId, formatDateForSmartsheet(today)); // Format for Smartsheet
        } else if (startDateColumnId && editableFields[startDateColumnId]) {
            setStartDate(parseDateFromSmartsheet(editableFields[startDateColumnId])); // Parse from Smartsheet format
        }

        // Set end date logic
        if (dueDateColumnId && !editableFields[dueDateColumnId]) {
            // Default to two weeks later if no end date exists
            const twoWeeksLater = addTwoWeeks(getToday());
            setEndDate(twoWeeksLater);
            handleFieldChange(dueDateColumnId, formatDateForSmartsheet(twoWeeksLater)); // Format for Smartsheet
        } else if (dueDateColumnId && editableFields[dueDateColumnId]) {
            // Use the saved end date if it exists
            setEndDate(parseDateFromSmartsheet(editableFields[dueDateColumnId])); // Parse from Smartsheet format
        }

        // Initial capacity calculation using merged task
        const mergedTask = getMergedTask();
        const capacityValue = calculateCardCapacity(mergedTask, {
            effortColumnId,
            dueDateColumnId,
            complexityColumnId,
        });

        setCapacity(capacityValue);
    }, [task, columns]); // Removed editableFields from dependencies to prevent redundant calls

    // Recalculate capacity when relevant fields change
    useEffect(() => {
        const { effortColumnId, dueDateColumnId, complexityColumnId } = getColumnIds();

        if (effortColumnId && dueDateColumnId && complexityColumnId) {
            const mergedTask = getMergedTask();
            const capacityValue = calculateCardCapacity(mergedTask, {
                effortColumnId,
                dueDateColumnId,
                complexityColumnId,
            });
            setCapacity(capacityValue);
        }
    }, [
        editableFields[getColumnIds().effortColumnId],
        editableFields[getColumnIds().dueDateColumnId],
        editableFields[getColumnIds().complexityColumnId],
    ]);

    const handleStartDateChange = (date) => {
        setStartDate(date);

        const { startDateColumnId, dueDateColumnId } = getColumnIds();

        if (startDateColumnId) {
            handleFieldChange(startDateColumnId, formatDateForSmartsheet(date)); // Format for Smartsheet
        }

        // If end date hasn't been set yet, update it to be 2 weeks from the new start date
        if (dueDateColumnId && !editableFields[dueDateColumnId]) {
            const newEndDate = addTwoWeeks(date);
            setEndDate(newEndDate);
            handleFieldChange(dueDateColumnId, formatDateForSmartsheet(newEndDate)); // Format for Smartsheet
        }
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);

        const { dueDateColumnId } = getColumnIds();

        if (dueDateColumnId) {
            const formattedDate = formatDateForSmartsheet(date);
            handleFieldChange(dueDateColumnId, formattedDate); // Format for Smartsheet
        }
    };

    const handleSelectChange = (cell, value) => {
        handleFieldChange(cell.columnId, value);

        // No need to manually set capacity here as useEffect handles it
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="kanban-card-back">
                {task?.cells
                    ?.filter((cell) => columns[cell.columnId])
                    .map((cell) => {
                        const column = columns[cell.columnId];

                        // Render a date picker for start date fields
                        if (column.title.toLowerCase().includes('start')) {
                            return (
                                <div key={cell.columnId} className="kanban-card-edit-field">
                                    <label>{column.title}</label>
                                    <DatePicker
                                        value={startDate}
                                        onChange={(date) => handleStartDateChange(date)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        className="kanban-card-input date-picker"
                                    />
                                </div>
                            );
                        }

                        // Render a date picker for end date fields
                        if (column.title.toLowerCase().includes('end')) {
                            return (
                                <div key={cell.columnId} className="kanban-card-edit-field">
                                    <label>{column.title}</label>
                                    <DatePicker
                                        value={endDate}
                                        onChange={(date) => handleEndDateChange(date)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        className="kanban-card-input"
                                    />
                                </div>
                            );
                        }

                        // Render capacity meter right above the "priority" field
                        if (column.title.toLowerCase() === 'priority') {
                            return (
                                <React.Fragment key={cell.columnId}>
                                    {/* Capacity Meter */}
                                    <div className="capacity-display mg-b-20">
                                        <span></span>
                                        <div className="capacity-bar">
                                            <div
                                                className="capacity-fill"
                                                style={{
                                                    width: `${capacity}%`,
                                                    backgroundColor: getCapacityColor(capacity),
                                                }}
                                            ></div>
                                        </div>
                                        <span>{capacity}%</span>
                                    </div>

                                    {/* Priority Field */}
                                    <div className="kanban-card-edit-field">
                                        <label>{column.title}</label>
                                        <select
                                            value={editableFields[cell.columnId] || ''}
                                            onChange={(e) => handleSelectChange(cell, e.target.value)}
                                            className="form-control select mg-r-8 mg-b-15 kanban-card-select"
                                        >
                                            <option value="">Unassigned</option>
                                            {column.options.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </React.Fragment>
                            );
                        }

                        // Render select options for columns with predefined options
                        if (column.options && column.options.length > 0) {
                            return (
                                <div key={cell.columnId} className="kanban-card-edit-field">
                                    <label>{column.title}</label>
                                    <select
                                        value={editableFields[cell.columnId] || ''}
                                        onChange={(e) => handleSelectChange(cell, e.target.value)}
                                        className="form-control select mg-r-8 mg-b-15 kanban-card-select"
                                    >
                                        <option value="">Unassigned</option>
                                        {column.options.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            );
                        }

                        // Render regular text input for other fields
                        return (
                            <div key={cell.columnId} className="kanban-card-edit-field">
                                <label>{column.title}</label>
                                <input
                                    type="text"
                                    value={editableFields[cell.columnId] || ''}
                                    onChange={(e) => handleFieldChange(cell.columnId, e.target.value)}
                                    className="kanban-card-input"
                                />
                            </div>
                        );
                    })}
                <div className="kanban-card-actions d-flex flex-column">
                    <button className="btn btn-sm btn-secondary" onClick={handleSave}>
                        Save
                    </button>
                    <button className="btn btn-sm" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button className="btn btn-sm delete-button" onClick={handleDelete}>
                        Delete
                    </button>
                </div>
            </div>
        </LocalizationProvider>
    );
};

// Helper function to determine the color based on capacity
const getCapacityColor = (capacity) => {
    if (capacity > 75) return '#4caf50'; // Green
    if (capacity > 50) return '#ffeb3b'; // Yellow
    if (capacity > 25) return '#ff9800'; // Orange
    return '#f44336'; // Red
};

export default KanbanCardBack;
