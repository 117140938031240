import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { signin } from "../../../actions/session/signIn";

import { Link } from "react-router-dom";
import Avatar from "../../global/Avatar";
import truncateString from "../../../util/tasks/truncateString";
import truncateDescription from "../../../util/tasks/truncateDescription";
import { addPinnedItems } from "../../../util/users/addPinnedItems";
import { removePinnedItems } from "../../../util/users/removePinnedItems";
import getUserById from "../../../util/users/getUserById";

const color = {
  TEAL: "rgb(78,163,146)",
  BLACK: "rgb(119,119,119)"
};
const nameCharLimit = 30;

const Subtask = (props) => {
  const [isItemPinned, setIsItemPinned] = useState(false); // State to track checkbox
  const thumbtackColor = isItemPinned ?  color.TEAL : color.BLACK; // Color based on checkbox state
  const [user, setUser] = useState(null);

   /**
   * getUser: Responsible for tracking if item is Pinned
   * @param {*} userId
   * @returns
   */
  const getUser = async (id) => {
    const user = await getUserById(id);
    // console.log("User:", user);

    if (user) {
      setUser(user);
      setIsItemPinned(user.pinnedSubtasks.includes(props.subtask?._id));
    }
    return user
  };

  const toggleChecked = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    // Remove pinnedProject, that is checked:
    if (isItemPinned) {
      const data = {
        userId: props.session.userId,
        itemId: props.subtask._id,
        type: "subtask",
      };
      const removedPinResponse = await removePinnedItems(data);
      // console.log("RemovePinned: response:", removedPinResponse);
    } else {
      const data = {
        userId: props.session?.userId,
        itemId: props.subtask._id,
        type: "subtask",
      };
      // AddedPinnedItem
      const addedPinnedResponse = await addPinnedItems(data);
      // console.log("AddPinned: response:", addedPinnedResponse);
      if (addedPinnedResponse.ok) {
        // console.log("Subtask1:", props.session.userId);
        const user = await getUser(props.session.userId);
        // console.log("Add Pinned User:", user);
      }
    }
    // If project is currently pinned, unpin it
    setIsItemPinned(!isItemPinned);
  };

  // Set Initial Project Checked InitialState
  useEffect(() => {
    getUser(props.session?.userId);
  }, []);

  useEffect(() => { 
  }, [user]);
  return (
    <>
      <td  className="font-weight-semibold d-flex">
          <span
          className="mg-l-4"
          style={{
            color: thumbtackColor,
            cursor: "pointer",
          }}
          onClick={(e) => toggleChecked(e)}
        >
          <FontAwesomeIcon
            className="pinned-icon"
            icon="fa-solid fa-bookmark"
          />
        </span>
        {!props.isText ? (
          <Link to={`/subtasks/${props.subtask._id}`}>
            {props.subtask?.name && props.subtask.version
              ? truncateDescription(props.subtask.name, nameCharLimit) +
                "_v" +
                props.subtask.version
              : truncateDescription(props.subtask?.name, nameCharLimit)
              ? truncateDescription(props.subtask.name, nameCharLimit)
              : ""}
          </Link>
        ) : props.subtask?.name && props.subtask.version ? (
          truncateString(props.subtask.name, nameCharLimit) + "_v" + props.subtask.version
        ) : props.subtask?.name ? (
          truncateString(props.subtask.name, nameCharLimit)
        ) : (
          ""
        )}
      </td>
      {props.subtask.responsible.length > 0 ? (
        <td style={{display: "tableCell"}}>
          <ul className="user-row">
          {props.subtask.responsible.map((user, index) => {
            if (index <= 3) {
              return (
                <li key={index}>
                  <Avatar user={user} size="sm" />
                </li>
              );
            }
          })}
        </ul>
          {/* <Avatar user={props.subtask.responsible} size="sm" />{" "}
          <Avatar user={props.subtask.responsible} size="sm" />{" "} */}
        </td>
        ) : 
          <td style={{display: "tableCell"}}>
              Not assigned
          </td>
        }
      {/* // ) : (props.subtask.status === "new") | (props.subtask.status === "inactive") ? (
      //   <></>
      // ) : (
      //   <td>
      //     <Avatar size="sm" />{" "}
      //   </td>
      // )} */}
      <td>
        {props.subtask.endDate ? new Date(props.subtask.endDate).toLocaleDateString() : "-"}
      </td>
      <td>
        <span
          className={`repos badge badge-${props.subtask.status}-pr`}
          data-type="link"
          style={{
            background:
              props.subtask.status === "active"
                ? "#0CA789"
                : props.subtask.status === "new"
                ? "#ecb529"
                : props.subtask.status === "inactive"
                ? "#777"
                : "#fd6074",
            color:
              props.subtask.status === "active"
                ? "white"
                : props.subtask.status === "new"
                ? "#333333"
                : props.subtask.status === "inactive"
                ? "white"
                : "white",
            color: "white",
          }}
        >
          {props.subtask.status}
        </span>
      </td>
    </>
  );
};
const mapStateToProps = ({ session }) => ({
  session,
});

const mapDispatchToProps = (dispatch) => ({
  signin: (user) => dispatch(signin(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subtask);
