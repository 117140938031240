import { nativeSignUp } from "../../util/session/nativeSignUp";
import ssoSignUp from "../../util/session/ssoSignUp";
import { asyncLocalStorage } from "../../components/global/msal/setSsoLocalStorage";

// Anonymous dispatch: call getItem by dipatching the function returned by getItem to the store
export const signUp = (data) => async (dispatch) => {
  let response;

  /**
   * If ssoLogin call auth/sso/user route
   * else call auth/user route
   */
  await asyncLocalStorage.getItem("endeavor-sso").then(async (ssoLogin) =>  {
    // console.log("signUp: ssoLogin:", ssoLogin);
    if (ssoLogin) {
      data.isSso = ssoLogin;
      // if sso user create a new user account
      response = await ssoSignUp(data);
    } else {
      response = await nativeSignUp(data);
    }
    return response;
  });

// return response from caller
  return response;
};
