import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { v4 as uuidv4 } from "uuid"; // Import the v4 function from the uuid library
import PinnedEmployeeDataController from "./DataController";
import {
  getPinnedDefaultUserTasks,
  getUserTasks,
} from "../../../util/users/getUserData";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { updateSession } from "../../../actions/session/updateSession";
import { allUserData } from "../../../actions/allUsers";
import { toastMessage } from "../../../actions/toast";

// const testValues = {
//   mail: [
//     { id: "111", label: "tony.lockhart@pearson.com", value: "111" },
//     { id: "111", label: "toby.lockhart@pearson.com", value: "111" },
//   ],
// };
// const testValues2 = {
//   mail: [{ id: "111", label: "toby.lockhart@pearson.com", value: "111" }],
// };
/**
 * Allows selection (Pinned) of employees. Retrieves the
 * task data of pinned employees if tasks have been assigned.
 * Passes the data to the PinnedEmployeeDataController to
 * format the data as Swiper JSX elements, which are
 * returned for display in the DOM.
 *
 * @param {*} param0
 * @returns ComponentsToRender - An array of all
 */
const SelectorController = ({ session, endeavorUsers }) => {
  // The actual Tasks/Subtasks JSX element used to render a componet
  const [pinnedTaskComponents, setPinnedTaskComponents] = useState([]); // State variable to store user task components
  // Then
  const [pinnedEmployeesEmailObjValues, setPinnedEmployeesEmailObjValues] =
    useState(null);
  // New selected Pinned Employees
  const [pinnedEmployeesTaskObjArray, setPinnedEmployeesTaskObjArray] =
    useState(null);
  const [pinnedEmployeesNum, setPinnedEmployeesNum] = useState(0);

  /**
   *Fetch task and subtask data for selected pinnedEmployee 
   if they have been assigned tasks,  new TaskData and add to pinnedEmployeeTaskData Array
   * @param {*} emails
   */
  const fetchpinnedEmployeesTaskObjArrayOnSelect = async (emails) => {
    // console.log("Emails:", scraper);
    const employeeTasksResponse = await getPinnedEmployeeTasksArray(emails);
    // console.log("Dashboard: Tasks2:", employeeTasksResponse);

    const pinnedEmployeesTaskObjArray = [];

    if (employeeTasksResponse?.length > 0) {
      for (const employeeTaskObject of employeeTasksResponse) {
        // console.log("employeeTaskObject:", employeeTaskObject);
        pinnedEmployeesTaskObjArray.push(employeeTaskObject);
      }
    }
    setPinnedEmployeesTaskObjArray([...pinnedEmployeesTaskObjArray]);
    // console.log("PinnedEmployees:", pinnedEmployeesTaskObjArray);
  };

  /**
   * Set the defaultValues for Graph Select
   * component, by setting the initial default values if
   * pinnedEmployeesEmailObjValues is not null
   * @param {*}or the useForm
   * @returns
   */
  const getPinnedEmployeesDefaultValue = () => {
    const keys = pinnedEmployeesEmailObjValues
      ? Object.keys(pinnedEmployeesEmailObjValues)
      : [];
    const returnValue =
      keys.length > 0 ? { defaultValues: pinnedEmployeesEmailObjValues } : {};
    // console.log("RETURNVALUE:", returnValue);
    return returnValue;
  };

  /**
   * Retrieves a user's pinnedEmployees scraper
   * and saves in pinnedEmployeesEmailObjValues
   * to be displayed in the Graph Search Selector.
   * Returns the scraper of default Pinned employees
   * in order to pull the associated task data
   */
  const setSelectorDefaultPinnedEmployees = async () => {
    try {
      const defaultEmployeeTasksResponse = await getPinnedDefaultUserTasks(
        session.userId
      );
      // console.log("Default Dashboard: Tasks:", defaultEmployeeTasksResponse);

      // Access the data directly here
      const data = await defaultEmployeeTasksResponse.json();
      // console.log("Data:", data);
      // Create a new object using spread operator
      const newDefaultPinnedEmployeeValues = data.pinnedEmployeeUsers;
      setPinnedEmployeesEmailObjValues(newDefaultPinnedEmployeeValues);

      const employeeEmails = newDefaultPinnedEmployeeValues
        ? newDefaultPinnedEmployeeValues?.map((item) =>
            item.label.toLowerCase()
          )
        : [];
      return employeeEmails;
      // Now you can work with the data as needed.
    } catch (error) {
      console.error("Error fetching default pinned employees:", error);
    }
  };

  /** 
   * Set PinnedEmployeesTaskObjArray to render 
   * default Pinned Employee Swiper JSX element 
   * during the initial component load
   */
  useEffect(() => {
    if (pinnedEmployeesEmailObjValues === null) {
      // console.log("INITIAL PAGE!", pinnedEmployeesEmailObjValues);
      // Call the async function inside the useEffect and set the result in state
      setSelectorDefaultPinnedEmployees().then((employeeEmails) => {
        // setEmployeeEmails(result); // Set the result in state
        // console.log("DEFAULTEMAILS:", employeeEmails);

        fetchpinnedEmployeesTaskObjArrayOnSelect(employeeEmails);
      });
    }
  }, []);

  /**
   * Set Graph Selector default values when
   * pinnedEmployeesEmailObjValues
   * have been updated
   */
  useEffect(() => {
    // console.log("REFRESH PAGE: DEFAULTVALUES:", pinnedEmployeesEmailObjValues);
    if (
      pinnedEmployeesEmailObjValues &&
      Array.isArray(pinnedEmployeesEmailObjValues)
    ) {
      const newDefaultValues = pinnedEmployeesEmailObjValues;
      // console.log("NewDefaultValues:", newDefaultValues);
     
      // set the default values in the GraphSelector
      setValue("mail", newDefaultValues);
    }
  }, [pinnedEmployeesEmailObjValues]);

  // Set pinnedEmployeesEmailObjValues on Pinned Employee update
  useEffect(() => {
  
    //9/25/2023: HAVE TO SEND A REQUEST TO GETTASKUPDATE
    setSelectorDefaultPinnedEmployees();
  }, [pinnedEmployeesTaskObjArray]);

  // useEffect(() => {;

  // Default Values:
  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
    setValue,
  } = useForm(getPinnedEmployeesDefaultValue());

  /**
   * getPinnedEmployeeTasksArray - Get and array of pinned
   * employee tasks, if the employee has tasks assigned
   * @param {*} emails
   * @returns
   */
  const getPinnedEmployeeTasksArray = async (emails) => {
    const employeeTasksResponse = await getUserTasks(emails, session.userId);
    // console.log("employeeTaskResponse:", employeeTasksResponse);
    if (employeeTasksResponse.ok) {
      const employeeTasks = await employeeTasksResponse.json();
      // console.log("EmployeeTasks:", employeeTasks);
      return employeeTasks;
    }
  };

  /**
   * Reset DefaultValues when item are deleted
   */
  const resetDefaultValues = () => {
    setPinnedEmployeesTaskObjArray([]);
    setPinnedEmployeesNum(0);
  }

  /**
   * Fetch Employees Emails from the from the selector
   * @param {*} data
   */
  const handlePinnedEmployees = async (data) => {
    // console.log("handlePinnedEmployees:", data);

    /*****************************************
     * Set the new defaults once the data has been retrieved
     *******************************************/
    if (Array.isArray(data)) {
      setPinnedEmployeesNum(data.length);
      // Check if data is an array
      const emailLabels = data.map((item) => item.label.toLowerCase());
      // console.log("emailLabels:", emailLabels);
      await fetchpinnedEmployeesTaskObjArrayOnSelect(emailLabels); // Pass the array of email labels
    } else if (data?.label) {
      // Handle single email case
      await fetchpinnedEmployeesTaskObjArrayOnSelect([
        data.label.toLowerCase(),
      ]);
    }
  };

  return (
    <>
      {/* {console.log("pinnedEmployeesTaskObjArray:", pinnedEmployeesTaskObjArray)} */}
      <div className="project-container">
        {
          pinnedEmployeesTaskObjArray !== null &&
          session?.userId !== null && session.systemAccess === 4 ? (
            // && session.ssoIsManager
            
            // Display the Pinned Employee Data
            <PinnedEmployeeDataController
              type={"Tasks"}
              endeavorUsers={endeavorUsers}
              setTaskComponents={setPinnedTaskComponents}
              control={control}
              taskObjArray={pinnedEmployeesTaskObjArray}
              pinnedEmployeesNum={pinnedEmployeesNum}
              setPinnedEmployeesNum={setPinnedEmployeesNum}
              handlePinnedEmployees={handlePinnedEmployees}
            />
          ) : (
            <></>
          )
        }
        {/* Render all components from AllDisplayComponents */}
        {/* {console.log("PinnedTaskComponents:", pinnedTaskComponents)} */}
        {pinnedTaskComponents?.map((Component) => (
          <div key={uuidv4()}>
            {Component /* Render the user task component */}
          </div>
        ))}
      </div>
    </>
  );
};

const mapStateToProps = ({ session, users, wsClient, score }) => ({
  session,
  users,
  wsClient,
  score,
});

// props.toastMessage('Hi from the global toast system.')
const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
  updateSession: (user) => dispatch(updateSession(user)),
  toastMessage: (data) => dispatch(toastMessage(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectorController);
