import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Wrapper from "../global/Wrapper";
import { allUserData } from "../../actions/allUsers";
import NewTest from "../usertesting/self-lead/NewTest";
import {useNavigate} from "react-router-dom";
import GenerateReport from "./GenerateReport";
import {toastMessage} from "../../actions/toast";
import GitlabSettings from "../settings/GitLab";
import Table from "./Table";
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import ViewReport from "./ViewReport";
const Reporting = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [reports, setReports] = useState([]);
 const [loading, setLoading] = useState(true);
 const [newLoading, setNewLoading] = useState(false);
  const navigate = useNavigate();

  const fetchReports = async () => {
    try {
      const creatorId = props.session.userId; // Assuming the user's ID is stored in props.session.userId
      const response = await fetch(`${AUTH_BASE_URL}reporting/${creatorId}`); // Adjust the URL as per your API endpoint

      if (!response.ok) {
        throw new Error('Network response was not OK');
      }

      const data = await response.json();

      if (data) {
        setReports(data);
      }
      setLoading(false)
    } catch (error) {
      console.error('Error fetching reports:', error);
      props.toastMessage('Failed to fetch reports'); // Using your existing toastMessage action
    }
  };

  useEffect(() => {
    fetchReports();
  }, [newLoading]); // Empty dependency array means this effect runs once when the component mounts


  const handleOpenNewModal = (path) => {
    navigate('/reporting/generate')
    setShowModal(true)

  }
  const handleCloseNewModal = () => {
    setShowModal(false)
    navigate('/reporting')

  }


  return (
    <Wrapper>
      <div className="main-content pt-0">
        <div className="container-fluid">
          <div className="inner-body team-status">
            {showModal === true ? <GenerateReport setLoading={setNewLoading} handleOpenNewModal={handleOpenNewModal} handleCloseNewModal={handleCloseNewModal} /> : null}
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Reporting - {props.session.team}
                </h2>
              </div>
              <div className="d-flex">
                <div className="justify-content-center table-controls"><button onClick={handleOpenNewModal} type="button" className="btn btn-primary my-2 btn-icon-text"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" className="svg-inline--fa fa-plus mg-r-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path></svg> Generate Report</button></div>
              </div>
            </div>
            <div className="row row-sm">
              <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin"}>
                <div className="card custom-card pd-0">
                  <Table newLoading={newLoading} loading={loading} data={reports}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = ({ session, users, toast }) => ({
  session,
  users,
  toast,
});

const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
  toastMessage: (data) => dispatch(toastMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);
