const getDigitalOceanAppInfo = async (appId, token) => {
    try {
        const response = await fetch(`https://api.digitalocean.com/v2/apps/${appId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Network response was not ok: ${JSON.stringify(errorData)}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching app info:', error);
        return null;
    }
};

export default getDigitalOceanAppInfo;
