import React from "react";
import {Navigate, Route} from "react-router-dom";

export const AuthRoute = (path, Component, loggedIn) => {
    console.log("PROTECTED AUTH ROUTE", path, loggedIn);
  return (
        <Route
          exact
          path={path}
          element={loggedIn ? <Navigate to="/dashboard" /> : <Component />}
        />
  );
};
