import React, { useState, useEffect } from "react";
import TableRow from "./TableRow";

function Table({ loading, repos, onDelete }) {
    if (loading) {
        return (
            <div className="table-responsive userlist-table">
                <table className="table card-table table-striped table-vcenter mb-0">
                    <thead>
                    <tr>
                        <th className={'wd-lg-15p'}><span>Component Name</span></th>
                        <th className={'wd-lg-35p'}><span>CDN Install</span></th>
                        <th className={'wd-lg-35p'}><span>React Install</span></th>
                        <th className={'wd-lg-20p'}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {[...Array(9)].map((_, index) => (
                        <tr key={index}>
                            <td>
                                <div className={`skeleton-text`} style={{width: '100%', height: '10px'}}></div>
                            </td>
                            <td>
                                <div className={`skeleton-text`} style={{width: '100%', height: '10px'}}></div>
                            </td>
                            <td>
                                <div className={`skeleton-text`} style={{width: '92px', height: '38px'}}></div>
                            </td>
                            <td>
                                <div className={`skeleton-text`} style={{width: '92px', height: '38px'}}></div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <div className="table-responsive userlist-table">
                <table className="table card-table table-striped table-vcenter mb-0">
                    <thead>
                    <tr>
                        <th className={'wd-lg-15p'}><span>Component Name</span></th>
                        <th className={'wd-lg-35p'}><span>CDN Install</span></th>
                        <th className={'wd-lg-35p'}><span>React Install</span></th>
                        <th className={'wd-lg-20p'}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {repos.length === 0 ? (
                        <>
                            <tr>
                                <td colSpan={5}>
                                    <div>There are no web components available.</div>
                                </td>
                            </tr>
                        </>
                    ) : (
                        repos.map((data, index) => (
                            <TableRow
                                key={index}
                                data={data}
                                onDelete={onDelete}
                            />
                        ))
                    )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Table;
