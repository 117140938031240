import React, {useEffect} from "react";
import TableRow from "./TableRow";
import {connect} from "react-redux";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
function Table (props) {
	if (props.type !== 'video') {
		return (
			<>
				<h6 id={`${props.type}Question-${props.index}`} >{props.question.question}</h6>
				<table id={`${props.type}Table-${props.index}`} className="table card-table table-striped table-vcenter mg-b-45">
					<thead>
					<tr>
						<th className={'wd-lg-5p'}><span>Username</span></th>
						<th className={'wd-lg-5p'}><span>Full Name</span></th>
						<th className={'wd-lg-5p'}><span>Email</span></th>
						<th className={'wd-lg-5p'}><span>Question Type</span></th>
						<th className={'wd-lg-5p'}><span>Answer</span></th>
					</tr>


					</thead>
					<tbody>
					{
						props.data.answers.map( (item, key) => {
							return (
								<TableRow question={props.question.question} name={props.data.name} tableData={props.data} key={key} data={item} type={props.question.answer}/>
							)
						})
					}
					</tbody>
				</table>
			</>
		)
	} else {
		return (
			<>
				{/*<h6 id={`${props.type}Question-${props.index}`} >{props.question.question}</h6>*/}
				<table id={`${props.type}Table-${props.index}`} className="table card-table table-striped table-vcenter mg-b-45">
					<thead>
					<tr>
						<th className={'wd-lg-5p'}><span>Username</span></th>
						<th className={'wd-lg-5p'}><span>Video Link</span></th>
					</tr>


					</thead>
					<tbody>
					{
						props.data.answers.map( (item, key) => {
							return (
								<TableRow name={props.data.name} tableData={props.data} key={key} data={item} type={'video'}/>
							)
						})
					}
					</tbody>
				</table>
			</>
		)
	}

}
const mapStateToProps = ({allTests}) => ({
	allTests
});


export default connect(
	mapStateToProps
)(Table);
