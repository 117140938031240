import React from "react";
import TableRow from "./TableRow";
import {connect} from "react-redux";

function Table (props) {
	return (
		<table className="table card-table table-striped table-vcenter mb-0" ref={props.tableRef}>
			<thead>
			<tr>
				<th className={'wd-lg-50p'}><span>Name</span></th>
				<th className={'wd-lg-25p'}><span>Email</span></th>
				<th className={'wd-lg-25p'}><span>Email sent</span></th>
				<th className={'wd-lg-25p'}><span>Username assigned</span></th>
				<th className={'wd-lg-25p'}><span>Finished test</span></th>
			</tr>
			</thead>
			<tbody>
			{
				props.userData ? props.userData.map((user,index) => {
					return (
						<TableRow  key={index} user={user}/>
					)
				}) : []
			}

			</tbody>
		</table>
	)
}
const mapStateToProps = ({}) => ({
	
});


export default connect(
	mapStateToProps
)(Table);
