import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {ssoSignin} from "../../actions/session/ssoSignIn";
import {asyncLocalStorage} from "../global/msal/setSsoLocalStorage";
import {getAccessTokenFromSessionStorage} from "../../util/helpers/getSsoAccessToken"

const SingleSignOn = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [firstName] = useState(location.state?.firstName);
  const [lastName] = useState(location.state?.lastName);
  const [email] = useState(location.state?.email);
  const [role] = useState(location.state?.role);

  /**
   * Check if User has pre-registered
   * If so, the sessionize.  Note: AuthRoute
   * will redirect to /dashboard route after
   * user has been sessionized during sign in.
   **/
  useEffect(() => {
    /** If user is in the database updateSsoRole and sessionize the user, else
     * redirect to sign-up page and pass the location.state as the user
     */
    const retrieveUserRegistration = async () => {
      // console.log("Location STATE:", location.state);
      props.ssoSignin(location.state).then((response) => {
        console.log("SingleSignOn: Response:", response);
        // Check if login is sso only
        asyncLocalStorage.getItem("endeavor-sso").then((ssoLogin) => {
          // console.log("SingleSignOn: ssoLogin:", ssoLogin);

          // Redirect to /sign-up page
          if (ssoLogin) {
            // Create sso user
            navigate("/sign-up", { state: response });
          }
        });
      });
    };

    // Sessionize pre-registered user with location data
    retrieveUserRegistration();
  }, []);

  useEffect(async () => {
	  	console.log("LETS SAVE SOME TOKENS: Props:", await getAccessTokenFromSessionStorage());
  })
  return (
    <>
      {/* <h1>Single Sign-On Page</h1> */}
      {/* <div style={{ paddingLeft: "20px" }}>
            Name: {firstName + " " + lastName}
            <br />
            Email: {email}
          </div> */}
    </>
  );
};

// Maps the data inside our redux store, to props
const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = (dispatch) => ({
  ssoSignin: (user) => dispatch(ssoSignin(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleSignOn);
