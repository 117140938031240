import React, {useEffect, useState} from 'react'
import { getTemplateByName } from "../../util/mailgun/getTemplates";
import { sendMail } from "../../util/mailgun/sendMail";

function EmailModal (props) {

    const [testData, setTestData] = useState([])
	const [testDataComplete, setTestDataComplete] = useState({})
	const [email, setEmail] = useState('')
	const [subject, setSubject] = useState('')

	useEffect(()=> {
		handleSelectTemplate()
    },[])

    const handleSelectTemplate = async (e) => {

        let newTemplate = props.selectedTemplate
		setTestData([])
        // setSelectedTemplate(newTemplate)
		
		getTemplateByName(newTemplate)
			.then(response => response.json())
			.then(data=>{
				// console.log(data)
				//get data fields by parsing the handlebars template
				let templateCode = data.version.template
				let dataSubject = (data.version.headers && data.version.headers.Subject ? data.version.headers.Subject : '')
				setSubject(dataSubject)
				let variableList = extractHandlebarsVariables(templateCode);
				setTestData(variableList)
				setTestDataComplete({})
				let newCompleteObj = {}
				variableList.forEach(variable=>{
					newCompleteObj.variable = ''
				})
			})

    }

	const extractHandlebarsVariables = (htmlContent) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlContent, 'text/html');
		
		const variables = new Set();

		const allNodes = doc.body.querySelectorAll('*');
  
		allNodes.forEach(node => {
			// Check text content for Handlebars expressions
			const textContent = node.textContent;
			const handlebarsExpressions = textContent.match(/{{(.*?)}}/g) || [];
			
			handlebarsExpressions.forEach(expression => {
			const variableName = expression.match(/{{(.*?)}}/)[1].trim();
			variables.add(variableName);
			});
			
			// Check attributes for Handlebars expressions
			Array.from(node.attributes).forEach(attribute => {
			const attributeValue = attribute.value;
			const handlebarsExpressionsInAttribute = attributeValue.match(/{{(.*?)}}/g) || [];
			
			handlebarsExpressionsInAttribute.forEach(expression => {
				const variableName = expression.match(/{{(.*?)}}/)[1].trim();
				variables.add(variableName);
			});
			});
		});
		
		return Array.from(variables);
	}

	const handleAddValues = (e) => {
		let variable = e.target.getAttribute('data-variable')
		let value = e.target.value
		let updatedPart = {}
		updatedPart[variable] = value
		setTestDataComplete(prevState=> {return {...prevState, ...updatedPart}})
	}

	const handleParseFlatData = () => {

		let parsed

		//check for any data that might have been flattened when parsing the handlebars variables	
		let flattened = Object.keys(testDataComplete).reduce((newObj, key) => {
			let value = testDataComplete[key];
			if (key.includes('.')) {
				//add to a new object to be processed into a nested object
				newObj[key] = value;
				//delete the flat version from testDataComplete
				delete testDataComplete[key]
			}
			parsed = {...testDataComplete}
			return newObj;
		}, {});


		//if there are any flattened variables turn them back into a nested object
		//and add them back into testDataComplete state
		if(Object.keys(flattened).length > 0){
		for (const key in flattened) {
			if (flattened.hasOwnProperty(key)) {
				let firstPart = key.split('.')[0]
				let secondPart = key.split('.')[1]
				if(!window[firstPart]){
					window[firstPart] = {}
				}
				window[firstPart][secondPart] = flattened[key]
				parsed = {...parsed}
				parsed[firstPart] = {...window[firstPart]}
			}
		}
		}
		return parsed
	}

	const handleSendEmail = (e) => {
		e.preventDefault()

		let parsed = handleParseFlatData()
		 
		if(parsed){
			// console.log(parsed)
			// put together all the data to send to the backend
			let mailData = {
				template: props.selectedTemplate,
				testDataComplete:parsed,
				email: email,
				subject: subject
			}
			sendMail(mailData).then(response=>console.log(response))
			props.handleCloseModal()
		}

	}

    return (
		<div className="main-content pt-0">
		<div className="container-fluid">
			<div className={`modal d-block test-email-modal`}>
				<div className="modal-dialog" role="dialog">
				<div className="modal-content modal-content-demo shadow">
					<div className="modal-header">
					<span>{props.selectedTemplate}</span>
					<h6 className="modal-title">Send test email</h6>
					</div>
					<div
					className="detail-modal-body"
					style={{ paddingLeft: "20px", paddingRight: "20px" }}
					>
					
					<form id='emailform' onSubmit={handleSendEmail}>
						<div>
							{
								testData ? <>
									{
										testData.map((data, index)=>{
											return (
												<div key={index}>
													<label style={{marginTop:'16px'}}>{data === "url" ? data + " *include http://or https://  in the url*" : data}</label>
													<input
														type="text"
														data-variable={data}
														required
														className={`form-control card-input`}
														onChange={handleAddValues}
													/>
												</div>
											)
										})
									}
								</> : <p>test data...</p>
							}
						</div>
						<div>
							<label style={{marginTop:'16px'}}>Subject</label>
							<input
								onChange={(e)=>{setSubject(e.target.value)}}
								type="text"
								value={subject}
								required
								className={`form-control card-input`}
							/>
						</div>
						<div>
							<label style={{marginTop:'16px'}}>Send test email to</label>
							<input
								onChange={(e)=>{setEmail(e.target.value)}}
								type="email"
								required
								className={`form-control card-input`}
							/>
						</div>
					</form>

					</div>

					<div className="modal-footer">
					<button className="btn ripple btn-primary" type="submit" form="emailform">
						Send
					</button>
					<button
						className="btn ripple btn-secondary muted"
						type="button"
						onClick={props.handleCloseModal}
					>
						Cancel
					</button>
					</div>
				</div>
				</div>
			</div>
		</div>
		</div>
    )
}

export default EmailModal