import React, { useState, useEffect } from "react";
import Modal from "../global/templates/Modal";
import TextArea from "../global/inputs/TextArea";

const modalMessage = "Add your update status below.";

const Insert = ({ 
    header, 
    handleClose, 
    submitForm, 
    handleSubmit, 
    searchSelectName,
    register }) => {

  return (
    <>
      <Modal
        header={header}
        handleCloseModal={handleClose}
        handleSubmit={handleSubmit}
        submitForm={submitForm}
        searchSelectName={searchSelectName ? searchSelectName : ""}
        message={modalMessage}
      >
        <TextArea
          label="Status"
          name="status"
          register={register}
          placeholder="Add Notes"
          required={false}
        />
      </Modal>
    </>
  );
};

export default Insert;
