import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";

function TotalChart (props) {
	const [series, setSeries] = useState();
	const [options, setOptions] = useState()
	useEffect(() => {
		setSeries(   {
			label: "taken",
			data: [props.data ? props.data.answers.length : 0, (props.data ? props.data.users - props.data.answers.length : 0) ]
		})
		setOptions({
				options: {
					chart: {
						type: 'donut',
					},
					colors: ['#f1388b', '#546E7A',],
					responsive: [{
						breakpoint: 480,
						options: {
							chart: {
								width: 50
							},
						}
					}],
					labels: ['Results', 'Not Taken'],
					legend: {
						show: false
					}
				}
			}
		)
	},[props.data])
	if (options && series) {
		return (
			<div id="chart" style={{margin: '0 auto', display: 'flex', justifyContent:'center', marginBottom:'32px'}}>
				<Chart options={options.options} series={series.data} type="donut" width={300} />
			</div>
		)
	} else {
		return <></>
	}

}

export default TotalChart

