import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const allProjects = async (userId, systemAccess) =>  {
    return await fetch(`${AUTH_BASE_URL}projects/accessNew/${userId}/${systemAccess}`, {
		method: 'GET',
        headers: {
            "Content-Type": "application/json"
        }
    }).then((response => {
        return response.json()
    })).then((data => {
        return data
    }))
}