export async function createDigitalOceanApp(gitlabRepoData, digitalOceanToken, subdomain, framework) {

    const appSpec = {
        "name": subdomain,
        "region": "nyc",
        "static_sites": [
            {
                "name": subdomain,
                "build_command": framework.buildCommands[0],
                "catchall_document": "index.html",
                "environment_slug": "node-js",
                "gitlab": {
                    "branch": gitlabRepoData.default_branch,
                    "deploy_on_push": true,
                    "repo": gitlabRepoData.path_with_namespace
                },
                "source_dir": "/", // This should point to the source directory in the repo
                "output_dir": framework.outputDir // Set the output directory based on the detected framework
            }
        ],
        "domains": [
            {
                "domain": `${subdomain}.pearsonct.design`,
                "type": "PRIMARY",
                "zone": "pearsonct.design"
            }
        ],
        "ingress": {
            "rules": [
                {
                    "component": { "name": subdomain },
                    "match": { "path": { "prefix": "/" } }
                }
            ]
        }
    };

    const response = await fetch('https://api.digitalocean.com/v2/apps', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${digitalOceanToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ spec: appSpec })
    });

    const responseData = await response.json();
    if (!response.ok) {
        throw new Error(responseData.message || 'Failed to create the app on DigitalOcean');
    }

    return responseData;
}
