import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Wrapper from "../../../../global/Wrapper";
import DigitalOceanSettings from "../../../../settings/DigitalOcean";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../../../../actions/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardComponent from "./Add"; // Import the CardComponent
import ExistingCardComponent from "./AddExisting"; // Import the ExistingCardComponent
import AddTemplate from './AddTemplate';

function AddContainer(props) {
	let navigate = useNavigate();
	const [noToken, setNoToken] = useState(null);
	const [showDigitalOcean, setShowDigitalOcean] = useState(false);
	const [reposLoading, setReposLoading] = useState(true);
	useEffect(() => {
		if (!props.session.digitalAccessToken && !noToken) {
			setNoToken(true);
			setShowDigitalOcean(true);
			props.toastMessage('Your Digital Ocean token has expired. Please re-authenticate.');
		}
	}, [props.session.digitalAccessToken, noToken, props, reposLoading]);

	console.log('showDigitalOcean', showDigitalOcean);

	return (
		<Wrapper>
			<div className="main-content pt-0 digitalocean-repos">
				<div className="container-fluid">
					<div className="inner-body users">
						{/* Page Header */}
						<div className="page-header">
							<div>
								<h2 className="main-content-title tx-24 mg-b-5">Digital Ocean Hosting - Add App</h2>
							</div>
							<div className='page-group-controls'>
								<button onClick={() => {
									navigate('/hosting/apps/add')
								}} type="button" className="btn btn-primary btn-icon-text">
									<FontAwesomeIcon className='sidemenu-icon mg-r-4' icon={`fa-solid fa-plus`} /> Add App
								</button>
							</div>
						</div>
						<div className="row row-sm">
							{
								showDigitalOcean ?
									<div className={"col-sm-12 col-md-12 col-lg-12 col-xl-4 grid-margin"}>
										<div className="card custom-card pd-0">
											<div className={'figma-auth'}><DigitalOceanSettings /></div>
										</div>
									</div> :
									<div className="row card-container">
										<div className={"col-sm-12 col-md-6 col-lg-6 col-xl-4 grid-margin"}>
											<CardComponent />
										</div>
										<div className={"col-sm-12 col-md-6 col-lg-6 col-xl-4 grid-margin"}>
											<ExistingCardComponent setReposLoading={setReposLoading} reposLoading={reposLoading}  />
										</div>
										<div className={"col-sm-12 col-md-6 col-lg-6 col-xl-4 grid-margin"}>
											<AddTemplate setReposLoading={setReposLoading} reposLoading={reposLoading} />
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}

const mapStateToProps = ({ session }) => ({
	session
});

const mapDispatchToProps = dispatch => ({
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddContainer);
