
export const FIGMA_TEAM_DATA = 'FIGMA_TEAM_DATA';

export const getFigmaTeamData = (data) => {
	return {
		type: FIGMA_TEAM_DATA,
		figmaTeamData: data
	}
};


