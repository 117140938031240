import React, {useEffect, useState} from 'react';
import Wrapper from "../../global/Wrapper";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";

import {getAccessTokenFromSessionStorage} from "../../../util/helpers/getSsoAccessToken";
import DropDownEllipsis from "../../global/DropDownEllipsis";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import iconMoonToFontAwesome from "../../../util/tools/iconMoonToFontAwesome";
function Tools () {


        const [newsData, setNewsData] = useState([]);
        const [loading, setLoading] = useState(true);
        const [token, setToken] = useState(null);
        useEffect(() => {
            const fetchToken = async () => {
                const fetchedToken = await getAccessTokenFromSessionStorage();
                setToken(fetchedToken);
            };

            fetchToken();
        }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (token) {
                    const response = await fetch(`${AUTH_BASE_URL}resources/tools`, {
                        method: 'POST',
                        body: JSON.stringify({ token: token }),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`An error has occurred: ${response.status}`);
                    }

                    const data = await response.json();
                    console.log('SAVE THIS DATA', data);

                    // Sort results by title in ascending order
                    const sortedResults = data.results.sort((a, b) => {
                        const titleA = a.title.toUpperCase(); // to ensure case-insensitive comparison
                        const titleB = b.title.toUpperCase(); // to ensure case-insensitive comparison

                        if (titleA < titleB) {
                            return -1;
                        }
                        if (titleA > titleB) {
                            return 1;
                        }

                        // titles must be equal
                        return 0;
                    });

                    setNewsData(sortedResults);
                    setLoading(false);
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        fetchData();
    }, [token]);


        // Helper function to format date
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
            const year = date.getFullYear();

            return `${month}/${day}/${year}`;
        };



    return  (
        <Wrapper>
            <div className="main-content pt-0">
                <div className="container-fluid">
                    <div className="inner-body users">
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Resources - Pearson Tools</h2>
                            </div>
                        </div>
                        <div className="row row-sm">
                            {
                                newsData ? newsData.map((tool, index) => {
                                    {
                                        if (iconMoonToFontAwesome(tool.icon)) {
                                            console.log(iconMoonToFontAwesome(tool.icon))
                                        } else {
                                            console.log('NOPE')
                                        }
                                    }

                                    return (
                                        <div className="col-sm-6 col-md-6 col-xl-3 mg-b-15">
                                            <div className="card custom-card shadow-2" style={{padding: '20px', height: '100%'}}>
                                                <div className="card-body user-card text-center pd-0">
                                                    <div className="icon-service bg-primary rounded-circle text-primary  align-content-center justify-content-center"  style={{backgroundColor: `${tool.colour}`, color: 'white' , width:'4rem', height:'4rem'}}>

                                                        <FontAwesomeIcon className='sidemenu-icon d-flex align-content-center justify-content-center' icon={iconMoonToFontAwesome(tool.icon)}  style={{fontSize: '28px'}} />
                                                    </div>
                                                    <h4 className={'mg-t-10'}>{tool.openGraphSummary.title}</h4>
                                                    <p>{tool.description}</p>
                                                    <a className="btn ripple btn-primary d-inline-flex align-items-center justify-content-center" target="_blank" href={'https://hub.pearson.com' + tool.appUrl}>
                                                        View App
                                                        <i className="fe fe-arrow-right ml-1" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default Tools;