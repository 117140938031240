export const getAccessTokenFromSessionStorage = async () => {
	const clientId = "befcf72e-fb11-4125-8294-cffaefb7ba4b";
	let accessToken = null;
	Object.keys(sessionStorage).forEach((key) => {
		if (key && key.includes(clientId) && key.includes('-idtoken-')) {
			const accessTokenObject = JSON.parse(sessionStorage.getItem(key));
			accessToken = accessTokenObject.secret;
		}
	});
	return accessToken;
}
