
export const FIGMA_PROJECT_DATA = 'FIGMA_PROJECT_DATA';

export const getFigmaProjectData = (data) => {
	return {
		type: FIGMA_PROJECT_DATA,
		figmaProjectsData: data
	}
};


