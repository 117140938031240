import AUTH_BASE_URL from "../AUTH_BASE_URL";
 export const signInGhost = async (user) => {
	let response = await fetch(AUTH_BASE_URL + "auth/session/ghost", {
		method: "POST",
		body: JSON.stringify(user),
		credentials: 'include',
		headers: {
			"Content-Type": "application/json"
		}
	})
	return response;
}

