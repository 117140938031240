import {PROJECT_SORT} from "../actions/projectSort";

const preloadedSort = {
    property: 'updated',
    direction: 'ascending'
}

export default (state = preloadedSort, {type, project_sort}) => {
    Object.freeze(state);
    switch (type) {
        case PROJECT_SORT:
            return project_sort;
        default:
            return state;
    }
};
