import React, {useState} from 'react'
import Wrapper from '../../global/Wrapper'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableRow from "../gitlab/TableRow";
import {connect} from "react-redux";
import {signin} from "../../../actions/session/signIn";
import {allGitLabRepos} from "../../../actions/allGitlabRepos";
import {useNavigate} from "react-router-dom";
import Toast from "../../global/Toast";
import {getGitlabGroupIdData} from "../../../actions/gitlabGroupId";
import {getGitlabGroupData} from "../../../actions/gitlabGroups";
import {getGitlabSubGroupData} from '../../../actions/gitlabSubGroups'
import {getGitlabSubGroupIdData} from "../../../actions/gitlabSubGroupId";
import {getGitlabRenderData} from "../../../actions/gitlabRenderRepos";
import GroupControl from "../GroupControl";

function GitLabRepos (props) {
    let navigate = useNavigate();

    return (
        <Wrapper>
            <div className="main-content pt-0 gitlab-repos">
                <div className="container-fluid">
                    <div className="inner-body users">
                        {/* Page Header */}
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Dev Ops - Gitlab</h2>
                            </div>
                            <div className='page-group-controls'>

								<GroupControl groupLabel={'Change group'} subGroupLabel={'Change sub group'} showFilter={true}/>

                                <button onClick={()=> {navigate('./add')}} type="button" className="btn btn-primary btn-icon-text">
                                    <FontAwesomeIcon className='sidemenu-icon mg-r-4' icon={`fa-solid fa-plus`} /> Add Gitlab Repo
                                </button>
                            </div>
                        </div>
                        {/* End Page Header */}
                        {/*Row*/}
                        <div className="row row-sm">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                                <div className="card custom-card pd-0">
                                    <div className="card-body pd-0">
                                        <div className="table-responsive userlist-table">
                                            <table className="table card-table table-striped table-vcenter mb-0">
                                                <thead>
                                                <tr>
													<th className={'wd-lg-1p'}><span>Creator</span></th>
                                                    <th className={'wd-lg-15p'}><span>Repo Name</span></th>
													<th className={'wd-lg-15p'}><span>UX Server</span></th>
													<th className={'wd-lg-15p'}><span>Code</span></th>
													<th className={'wd-lg-10p'}><span>CI/CD</span></th>
													<th className={'wd-lg-10p'}><span>Updated</span></th>
                                                    <th className={'wd-lg-10p'}><span>Created</span></th>
                                                    <th className={'wd-lg-10p'}><span>Active</span></th>
                                                    <th className={'wd-lg-25p'}>Action</th>

                                                </tr>
                                                </thead>
                                                <tbody>
												{
													props.repoLoading ? <tr><td></td><td colSpan={9}>New repo loading...</td></tr> : <></>
												}
                                                {
                                                    props.filteredData ?  props.filteredData.map((data,index) => {
														return (
															<TableRow
																key={index}
																data={data}
															/>
														)
													}): <tr><td colSpan={9}>Loading Repositories...</td></tr>
                                                }

                                                </tbody>
                                            </table>
                                            {/*<Pagination />*/}
                                        </div>
                                    </div>
                                </div>
                            </div>{/* COL END */}
                        </div>
                        {/* row closed  */}
                    </div>
                </div>
            </div>


        </Wrapper>
    )
}

const mapStateToProps = ({session, repos, gitlabGroupId,  gitlabSubGroupId, gitlabGroups, gitlabSubGroups, gitlabRenderRepos, filteredData, repoLoading}) => ({
    session, filteredData, gitlabGroupId,  gitlabSubGroupId, gitlabGroups, gitlabSubGroups, gitlabRenderRepos, repoLoading
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user)),
    allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
    getGitlabSubGroupData: groups => dispatch(getGitlabSubGroupData(groups)),
    getGitlabGroupData: groups => dispatch(getGitlabGroupData(groups)),
    getGitlabSubGroupIdData: id => dispatch(getGitlabSubGroupIdData(id)),
    getGitlabGroupIdData: id => dispatch(getGitlabGroupIdData(id)),
    getGitlabRenderData: id => dispatch(getGitlabRenderData(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GitLabRepos);
