import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const updateToDo = async (id, data) => {
    return fetch(`${AUTH_BASE_URL}todo/update/${id}`, {
        method: "POST",
        body: JSON.stringify({data:data}),
        headers: {
            "Content-Type": "application/json"
        }
    })
}