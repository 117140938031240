import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const updateSettings = async (id, data) => {
   const response = await fetch(`${AUTH_BASE_URL}tasks/settings/${id}`, {
        method: "PUT",
        body: JSON.stringify({data}),
        headers: {
            "Content-Type": "application/json"
        }
    });
    return response;
};