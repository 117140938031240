import React from "react";

const ItemBadge = ({ type }) => {
  return (
    <>
    { type && type !== "public" ?
    <span
    className={`repos badge badge-primary badge-${type}-pr`}
    data-type="link"
    style={{
      background:
        type === "active"
          ? "#0CA789"
          : type === "new"
          ? "#ecb529"
          : "#fd6074",
      color: "white",
    }}
  >
    {type}
  </span> : <></>
  }
    </>
    
  );
};

export default ItemBadge;
