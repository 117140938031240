const iconMoonToFontAwesome = (icomoonIcon) => {

    const iconMap = {
        'icomoon-collaboration': 'fa-users',
        'icomoon-integration-jira': 'fa-brands fa-jira',
        'icomoon-link3': 'fa-link',
        'icomoon-shield-check': 'fa-shield-alt',
        'icomoon-reading': 'fa-book-reader',
        'icomoon-cloud2': 'fa-cloud',
        'icomoon-pen5': 'fa-pen',
        'icomoon-bucket2': 'fa-fill-drip',
        'icomoon-cart5': 'fa-shopping-cart',
        'icomoon-pulse': 'fa-heartbeat',
        'icomoon-earth3': 'fa-globe',
        'icomoon-database-check': 'fa-database',
        'icomoon-safe': 'fa-lock',
        'icomoon-file-presentation2': 'fa-file-powerpoint',
        'icomoon-stamp': 'fa-stamp',
        'icomoon-code': 'fa-code',
        'icomoon-touch': 'fa-hand-pointer',
        'icomoon-custom-icon-dollar': 'fa-dollar-sign',
        'icomoon-git': 'fa-brands fa-gitlab',
        'icomoon-question4': 'fa-question-circle',
        'icomoon-cash4': 'fa-wallet',
        'icomoon-book': 'fa-book',
        'icomoon-phone-wave': 'fa-phone-volume',
        'icomoon-integration-service-now': 'fa-bell-concierge', // Custom
        'icomoon-palette': 'fa-palette',
        'icomoon-bookmark': 'fa-bookmark',
        'icomoon-graduation': 'fa-graduation-cap',
        'icomoon-svgmulti-pearson-plus-on-light': 'fa-plus', // Custom
        'icomoon-file-locked2': 'fa-file-shield', // Custom combined
        'icomoon-hammer2': 'fa-hammer',
        'icomoon-cloud': 'fa-cloud',
        'icomoon-database': 'fa-database',
        'icomoon-office-excelLogo': 'fa-file-excel',
        'icomoon-office-formsLogo': 'fa-brands fa-wpforms',
        'icomoon-shield-notice': 'fa-shield-alt',
        'icomoon-office-oneDriveLogo': 'fa-cloud',
        'icomoon-chart': 'fa-chart-bar',
        'icomoon-office-onenoteLogo': 'fa-book',
        'icomoon-office-calendarLogo': 'fa-calendar-alt',
        'icomoon-office-outlookLogo': 'fa-envelope',
        'icomoon-office-powerpointLogo': 'fa-file-powerpoint',
        'icomoon-office-sharepointLogo': 'fa-share-alt',
        'icomoon-office-stream': 'fa-video',
        'icomoon-office-teamsLogo': 'fa-users',
        'icomoon-office-toDoLogoInverse': 'fa-tasks',
        'icomoon-office-visioLogo': 'fa-project-diagram',
        'icomoon-office-wordLogo': 'fa-file-word',
        'icomoon-lamp8': 'fa-lightbulb',
        'icomoon-mic5': 'fa-microphone',
        'icomoon-integration-zoom': 'fa-video',
    };

    return iconMap[icomoonIcon] || 'fa-question-circle'; // Default to 'fa-question-circle' if icon not found
};

export default iconMoonToFontAwesome
// Usage example

