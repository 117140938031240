import {FIGMA_COMPONENT_DATA} from "../actions/figmaComponentData";

// export a function that takes in state and action
export default (state = null, {type, figmaComponentData = null }) => {
	// freeze the state
	Object.freeze(state);
	// switch on the type
	switch (type) {
		// if the type is FIGMA_TEAM_ID
		case FIGMA_COMPONENT_DATA:
			// return the figmaTeamData
			return figmaComponentData;
		// otherwise
		default:
			// return the state
			return state;
	}
}
