import React, {useState, useEffect} from "react";
import {TextareaAutosize} from '@mui/base/TextareaAutosize';

const TextArea= (props) => {

  const [value, setValue] = useState(props.defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

useEffect(()=>{
  // console.log("Description:", value)
}, [value]);

  return (
    <div className="form-group">
      <label>{props.label}</label>
      <TextareaAutosize
       {...props.register(props.name)}
        className={`form-control card-input text-aria ${props.card ? 'card-input' : null}`}
        // placeholder={props.placeholder}
        onChange={handleChange}
        type="text"
        minRows={2}
        required={props.required}
        // defaultValue={props.defaultValue}
        value={value}
      />
    </div>
  );
};

export default TextArea;
