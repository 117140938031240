import AUTH_BASE_URL from "../AUTH_BASE_URL";

export default function (data) {
	return fetch(`${AUTH_BASE_URL}usertesting/stop?name=${data.name}`, {
		method: 'GET'
	})


	// show a toast letting the user know it will be a while
	// setup usernames and passwords in new database
	// push the test data also to the new database

	// create new digital ocean app
	// edit the returned app spec
		// domains - name of test
		// envs - db name
		// cors - dev.pearsonct.design
	// put the new app spec
	// add cors to platform
}
