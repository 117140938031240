import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const saveTask = (data) => {
  const response = fetch(AUTH_BASE_URL + "tasks/save", {
    method: "POST",
    body: JSON.stringify({ data }),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  return response;
};
