import React, { useEffect, useRef, useState, useContext } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { updateProject } from "../../util/projects/updateProject";
import { formatDataByEmail } from "../../util/tasks/formatData";
import CustomSelect from "../global/inputs/CustomSelect";
import { formatSelectOptions } from "../../util/tasks/formatData";
import allUsers from "../../util/users/allusers";
import { allUserData } from "../../actions/allUsers";
// import { TasksContext } from "../global/Providers/TaskProvider";
import { useGetAndSetProjects } from "../../util/projects/useGetAndSetProjects";
import RepoSelect from "../dev-ops/RepoSelect";

const ProjectSettings = (props) => {
  // enable refresh in projects page
// const { isProjectStatusUpdated, setIsProjectStatusUpdated } = useContext(TasksContext);
  // console.log("ProjectSettings PROPS:", props);
  const params = useParams();
  const [responsibleOptions, setResponsibleOptions] = useState([]);
  const [lead, setLead] = useState(props.projectDetails.lead?._id);
  const [tempStatus, setTempStatus] = useState(props.projectDetails.status);
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [projectApps, setProjectApps] = useState([]);
  const [dataAccess, setDataAccess] = useState(
    props.projectDetails.dataAccess ? props.projectDetails.dataAccess : "public"
  );
  const {getAndSetProjects} = useGetAndSetProjects()
  
//   const parsedDescription = props.projectDetails?.description
// console.log("ProjectSettings: parsedDescription:", parsedDescription)
const parseDescription = (description) =>{
  if(description) {
  // console.log("ProjectSetting: Description:", description);
  const regex1 = /</g;
  const regex2 = />/g;

  const parsedDescription = description.replace(regex1, "&lt;" )
  .replace(regex2, "&gt;" );
  // console.log("ParsedDescription:", parsedDescription);
  return parsedDescription;
  }
  return null;
}
  const [htmlContent, setHtmlContent] = useState(parseDescription(props.projectDetails?.description));
// console.log("HTMLContent:", htmlContent);
  const nameRef = useRef(null);
  const descriptionRef = useRef(null);
  const codeRef = useRef();
  const taskRef = useRef();

  const handleBlur = () => {
    setHtmlContent(descriptionRef.current.innerHTML);
  };

  // Initialize Data
  useEffect(() => {
    // if users is not available from redux
    // get them from the database and store them
    if (!props.users) {
      allUsers().then(async (data) => {
        props.allUserData(await data);
      });
    }
  }, []);

  useEffect(() => {
    if (props.users && props.users.length > 0) {
      setResponsibleOptions(formatDataByEmail(props.users, "email", "_id"));
    }
  }, [props.users]);

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  function toggleStatusMenu() {
    setShowStatusMenu(!showStatusMenu);
  }

  function changeStatus(status) {
    setShowStatusMenu(false);
    setTempStatus(status);
  }

  function handleSave() {
    let updatedCode = { ...props.projectDetails.oracleProjectCode };
    updatedCode.code = codeRef.current.value;
    updatedCode.task = taskRef.current.value;
    // console.log("HandleSave: dataAccess:", dataAccess);

    let project_data = {
      name: nameRef.current.value,
      description: descriptionRef.current.innerHTML,
      lead: lead?.value,
      status: tempStatus,
      oracleProjectCode: updatedCode,
      apps: projectApps,
      closedDate: tempStatus === "closed" ? new Date() : null,
      dataAccess: dataAccess?.value
        ? dataAccess.value
        : dataAccess
        ? dataAccess
        : "public",
    };

    // console.log("ProjectSettings: Data:", project_data);

    updateProject(params.id, project_data, props.session).then((response) => {
      // console.log("UpdateProduct Response:", response)
      props.getProjectDetails();
      props.handleCloseDetailModal();
    }).then(()=>getAndSetProjects(props.session));

    // update project
    // setIsProjectStatusUpdated(!isProjectStatusUpdated);
  }
  function onChange(event) {
    setProjectApps(event)
  }
  return (
    <div className="modal project-settings-modal d-block">
      <div className="modal-dialog" role="dialog">
        <div className="modal-content modal-content-demo shadow">
          <div className="modal-header">
            <span>{props.projectDetails.name}</span>
            <h6 className="modal-title">Project Settings</h6>
          </div>

          <div className="detail-modal-body">
            <div className="form-group">
              <label>Name</label>
              <div className="d-flex align-items-center">
                <div className="form-group mg-b-0 flex-grow-1">
                  <input
                    ref={nameRef}
                    className="form-control card-input"
                    defaultValue={props.projectDetails.name}
                    type="text"
                  />
                </div>
                <div className="form-group mg-b-0">
                  <button
                    className="status-dropdown"
                    onClick={toggleStatusMenu}
                  >
                    {/* <span className={`status-badge ${tempStatus}`}>{tempStatus}</span> */}
                    <span
                      className={`repos badge badge-${tempStatus}-pr`}
                      data-type="link"
                      style={{
                        background:
                          tempStatus === "active"
                            ? "#0CA789"
                            : tempStatus === "new"
                            ? "#ecb529"
                            : "#fd6074",
                        color: "white",
                      }}
                    >
                      {tempStatus}
                    </span>
                    <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                  </button>
                  <div
                    className={`dropdown-menu status-menu ${
                      showStatusMenu === true ? "show" : ""
                    }`}
                  >
                    <ul>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            changeStatus("new");
                          }}
                        >
                          New
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            changeStatus("active");
                          }}
                        >
                          Active
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            changeStatus("inactive");
                          }}
                        >
                          Inactive
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            changeStatus("closed");
                          }}
                        >
                          Closed
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Data Access</label>
              <CustomSelect
                value={dataAccess}
                handler={setDataAccess}
                data={formatSelectOptions(["private", "public"])}
                type="dataAccess"
                card
                required={true}
                defaultValue={
                  props.projectDetails.dataAccess
                    ? formatSelectOptions([props.projectDetails.dataAccess])
                    : formatSelectOptions(["public"])
                }
                isMulti={false}
              />
            </div>
            <div className="form-group">
              <label>Lead</label>
              <CustomSelect
                value={lead}
                handler={setLead}
                data={responsibleOptions}
                type="lead"
                card
                required={true}
                defaultValue={
                  props.projectDetails.lead?.email
                    ? {
                        label: props.projectDetails.lead.email,
                        value: props.projectDetails._id,
                      }
                    : ""
                }
                isMulti={false}
              />
            </div>
            <div className={"form-group"}>
              <label>Connect apps</label>
              <RepoSelect onChange={onChange} defaultValue={props.projectDetails.apps} value={projectApps}/>
            </div>
            <div className="form-group">
              <label>Description</label>
              {/* <textarea
                ref={descriptionRef}
                className="form-control card-input"
                defaultValue={
                  props.projectDetails.description
                    ? removeTags(props.projectDetails.description)
                    : null
                }
              /> */}
              <div
              style={{maxWidth: "821.7px", height: "auto"}}
              className="form-control card-input"
                // id="newProjectDescription"
                ref={descriptionRef}
                contentEditable={true}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
                onBlur={handleBlur}
              />
            </div>

            <label>Oracle Project Code</label>
            <p>This time code should be used for development work only.</p>

            <div className="form-group">
              <label>Code</label>
              <input
                ref={codeRef}
                className="form-control card-input"
                defaultValue={props.projectDetails.oracleProjectCode.code}
                type="text"
              />
            </div>
            <div className="form-group">
              <label>Task</label>
              <input
                ref={taskRef}
                className="form-control card-input"
                defaultValue={props.projectDetails.oracleProjectCode.task}
                type="text"
              />
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="btn ripple btn-primary"
            type="button"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="btn ripple btn-secondary muted"
            type="button"
            onClick={props.handleCloseDetailModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ session, users }) => ({ session, users });

const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
