import AUTH_BASE_URL from "../AUTH_BASE_URL";

export default function getAllPrototypes (props) {
    return fetch(`${AUTH_BASE_URL}prototype`, {
        method: 'GET'
    }).then(response => {
        return response.json()
    }).then(data => {
        return data
    })
}