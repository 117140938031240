import AUTH_BASE_URL from "../AUTH_BASE_URL";

const migrateUser = async (email, id, ownerName) => {
    const url = "auth/user/migrate/"
    // In migrate User
    // console.log(`In migrate user: email ${email}; id: ${id}; ownerName: ${ownerName}`);
    const params = {
        email,
        id,
        ownerName,
    }

    const response = await fetch(AUTH_BASE_URL + url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({data: params}),
    });

    // transform results from object to json
    const data = await response.json();

    // call search function
    return data
  };

  export default migrateUser;
