import React, {useEffect, useState} from "react";
const PodcastCard = ({ podcast }) => {
    const [isListening, setIsListening] = useState(false);

    const handleListenClick = () => {
        setIsListening(!isListening);
    };

    return (
        <div className="col-sm-6 col-md-6 col-xl-3 mg-b-15">
            <iframe
                allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                frameBorder="0"
                height="450"
                style={{ width: '100%',  overflow: 'hidden', borderRadius: '10px' }}
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                src={`https://embed.podcasts.apple.com/us/podcast/${podcast.trackId}`}
            ></iframe>
        </div>
    );
};

export default PodcastCard;