import {connect} from "react-redux";
import bannerImage from '../../assets/images/pngs/1rt.png'
import Wrapper from '../global/Wrapper.js'
import ProfilePicture from "./ProfilePicture";
import ProfileInfo from './ProfileInfo'
import Services from './Services'
import CommPrefs from "./CommPrefs";
import ThemePrefs from "./ThemePrefs";
import migrateUser from '../../util/users/migrateUser';
import {updateFirstTime} from "../../util/settings/updateFirstTime";
import {signin} from "../../actions/session/signIn";
import {useNavigate} from "react-router-dom";
import ActivityPrefs from "./ActivityPrefs";
import GhostMode from "./GhostMode";
import React from "react";
import Figma from './Figma'
import GitLab from "./GitLab";
import DigitalOcean from "./DigitalOcean";
function Settings (props) {

    let navigate = useNavigate();
    const regex = /(@pearson.com)/i;
    const isUserPearson =  regex.test(props.session.email);
    // console.log("isUserPearson:", isUserPearson, "Email:", props.session.email);
    const handleFinishSettings = () => {
        const ownerName = props.session.firstName + " " + props.session.lastName;

        // Migrate User Data if Pearson
        if (isUserPearson) {
            migrateUser(props.session.email, props.session.userId, ownerName);
        }

        updateFirstTime({email:props.session.email})
            .then(response => {
                return response.json()
            }).then( data =>{
            props.signin(data.user)
            return data.user
        }).then( user =>{
            if(user.firstTime === false){
                navigate("/dashboard");
            }
        })
    }

    return (
        <Wrapper noSidebar={props.session.firstTime} noHeader={props.session.firstTime}>
            <div className="inner-body settings">
                <div className="row row-sm">
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                        {/*Row*/}
                        {
                            props.session.firstTime === true ?          <div className="row row-sm  mt-lg-4" style={{marginBottom:'20px'}}>
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="card custom-card card-box hero-card">
                                        <div className="card-body p-4 mg-t-10">
                                            <div className="row align-items-center">
                                                <div className="banner-container offset-xl-3 offset-sm-6 col-xl-8 col-sm-8 col-12 img-bg " style={{marginLeft:'200px'}}>
                                                    <h4 className="d-flex  mb-3">
                                                        <span className="font-weight-bold">{props.session.firstName} {props.session.lastName}, you are almost ready. </span>
                                                    </h4>
                                                    <p className="tx-16 mb-1">Lets make sure all this information is correct and connect the services you will be using.  You can update your preferences at anytime by going to the settings page located in the dropdown menu in the header.
                                                    </p></div>
                                                <img src={bannerImage} alt="user-img " className={"d-md-none d-none d-sm-none d-lg-block"}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>: <></>
                        }

                        <div className="row row-sm">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 pd-10">
                                <ProfilePicture />
                            </div>
                            <div className="col-sm-12  col-md-6 col-lg-6 col-xl-4 pd-10">
                                <ProfileInfo />
                            </div>
                            {
                                props.session.systemAccess >= 3 ?      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 pd-10">
                                    <GitLab />
                                </div> : <></>
                            }
                            {
                                props.session.systemAccess >= 3 ?      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 pd-10">
                                    <DigitalOcean />
                                </div> : <></>
                            }
                            {
                                props.session.systemAccess >= 3 ?      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 pd-10">
                                    <Figma />
                                </div> : <></>
                            }
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                <div className="row row-sm">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 pd-10">
                                        <CommPrefs />
                                    </div>
                                </div>
                                <div className="row row-sm">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 pd-10">
                                        <ActivityPrefs />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 pd-10">
                                <ThemePrefs />
                            </div>
                            {
                                props.session.systemAccess === 4 ? 	<div className="col-sm-12 col-md-12 col-lg-12 col-xl-4 pd-10">
                                    <GhostMode />
                                </div> : <></>
                            }
                            {
                                props.session.firstTime ?        <div className="row wd-100p">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3 finish">
                                        <button type="button"
                                                onClick={handleFinishSettings}
                                                className="btn ripple btn-primary btn-block ">Finish & Go To Dashboard
                                        </button>
                                    </div>
                                </div> : <></>
                            }

                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>

    )
}
const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user))
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings);
