import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toastMessage } from "../../../../../actions/toast";
import { signin } from "../../../../../actions/session/signIn";
import SpinnerButton from "../../../../global/SpinnerButton";
import RepoSelect from "./RepoSelect";
import { createDigitalOceanApp } from "../../../../../util/dev-ops/createDigitalOceanApp";
import { detectFramework } from "../../../../../util/detectFramework";
import {allGitLabRepos} from "../../../../../actions/allGitlabRepos";

const CardComponent = (props) => {
    const [subdomain, setSubdomain] = useState('');
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedRepo, setSelectedRepo] = useState(null); // State for selected repository
    const [selectedRepoObjects, setSelectedRepoObjects] = useState([]); // State for selected repository objects
    const [isFetching, setIsFetching] = useState(true); // State to handle loading indicator
    const navigate = useNavigate();
    const digitalOceanToken = props.session.digitalAccessToken; // Use the token from props
    const gitlabToken = process.env.REACT_APP_GL_TOKEN; // Ensure you have the GitLab token in your environment variables

    useEffect(() => {
        const fetchPaginatedData = async (url) => {
            let results = [];
            let nextUrl = url;

            while (nextUrl) {
                const response = await fetch(nextUrl, {
                    headers: {
                        'Authorization': `Bearer ${gitlabToken}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                results = results.concat(data);

                const linkHeader = response.headers.get('link');
                const nextLinkMatch = linkHeader && linkHeader.match(/<([^>]+)>;\s*rel="next"/);
                nextUrl = nextLinkMatch ? nextLinkMatch[1] : null;
            }

            return results;
        };

        const fetchRepos = async (groupId) => {
            try {
                // Fetch repositories in the current group
                const repos = await fetchPaginatedData(`https://gitlab.com/api/v4/groups/${groupId}/projects`);

                // Fetch subgroups
                const subgroups = await fetchPaginatedData(`https://gitlab.com/api/v4/groups/${groupId}/subgroups`);

                // Fetch repositories in each subgroup recursively
                for (const subgroup of subgroups) {
                    const subgroupRepos = await fetchRepos(subgroup.id);
                    repos.push(...subgroupRepos);
                }

                return repos;
            } catch (error) {
                console.error('Error fetching subgroups or repos:', error);
                props.toastMessage('Error fetching repositories.');
                return [];
            }
        };

        const initializeFetch = async () => {
            setIsFetching(true);
            props.setReposLoading(true)
            const groupId = 10566838; // Your group ID
            const allRepos = await fetchRepos(groupId);
            setIsFetching(false);
            props.setReposLoading(false)
            // Dispatch repositories to Redux
            props.allGitLabRepos(allRepos);
        };

        // Fetch repositories only if they are not already available in Redux
        if (!props.repos) {
            initializeFetch();
        } else {
            setIsFetching(false);
            props.setReposLoading(false)
        }
    }, [gitlabToken, props]);

    const handleInputChange = (e) => {
        setSubdomain(e.target.value);
    };

    const handleRepoSelect = (selectedOptions) => {
        if (selectedOptions) {
            const selectedRepoId = selectedOptions.value;
            setSelectedRepo(selectedRepoId);

            // Find the corresponding repository object
            const repoObject = props.repos.find(repo => repo.id === selectedRepoId);
            setSelectedRepoObjects([repoObject]);
        } else {
            setSelectedRepo(null);
            setSelectedRepoObjects([]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedRepo) {
            props.toastMessage('Please select a repository.');
            return;
        }
        setLoading(true); // Start loading
        try {
            let framework = await detectFramework(selectedRepoObjects[0], gitlabToken);
            const digitalOceanResponse = await createDigitalOceanApp(selectedRepoObjects[0], digitalOceanToken, subdomain, framework);
            props.toastMessage(`App created successfully on DigitalOcean: ${subdomain}`);
            navigate('/hosting/apps');
        } catch (error) {
            props.toastMessage(error.message);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleCardClick = () => {
        setShowForm(true);
    };

    const cardContainerStyle = {
        perspective: '1000px',
        width: '100%', // Adjust width as needed
        margin: '20px auto',
        minWidth:'390px'
    };

    const cardStyle = {
        width: '100%',
        height: '300px', // Set a fixed height
        transformStyle: 'preserve-3d',
        transition: 'transform 0.6s',
        position: 'relative',
        transform: 'none'
    };

    const cardSideStyle = {
        width: '100%',
        height: '100%',
        backfaceVisibility: 'hidden',
        position: 'absolute',
        top: '0',
        left: '0',
        padding: '20px',
        border: '1px solid #ced4da',
        borderRadius: '0.25rem',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around', // Center content vertically
        alignItems: 'center', // Center content horizontally
        textAlign: 'center', // Center text
    };

    const iconContainerStyle = {
        display: 'flex',
        alignSelf: 'center',
        justifySelf: 'center',
        gap: '20px', // Adjust the gap between icons as needed
        marginTop: '20px', // Add margin to separate icons from text
        width:'100%'
    };

    return (
        <div style={cardContainerStyle}>
            <div style={cardStyle}>
                <div style={cardSideStyle}>
                    <div>
                        <h5 className="main-content-label"
                            style={{color: '#343a40', fontWeight: '500', marginBottom: '10px', fontSize: '18px'}}>Use an Existing Repository.</h5>
                        <p className="card-subtitle" style={{color: '#6c757d', fontSize: '16px'}}>Use this to set up a
                            new application with an existing codebase. </p>
                    </div>
                    <div style={iconContainerStyle}>
                        <form onSubmit={handleSubmit} style={{width: '100%'}}>
                            <div className="form-group" style={{marginBottom: '20px'}}>
                                <RepoSelect
                                    options={(props.repos || []).map(repo => ({ value: repo.id, label: repo.name }))}
                                    onChange={handleRepoSelect}
                                    placeholder={isFetching ? 'Loading repositories...' : 'Select a repository'}
                                />
                                <div className="input-group" style={{display: 'flex', alignItems: 'center', marginTop:'20px'}}>
                                    <input
                                        type="text"
                                        className="form-control mg-r-10"
                                        id="subdomain"
                                        value={subdomain}
                                        onChange={handleInputChange}
                                        placeholder="Enter subdomain"
                                        style={{
                                            flex: '1',
                                            padding: '10px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '0.25rem 0 0 0.25rem'
                                        }}
                                        aria-label="Subdomain"
                                        aria-describedby="subdomain-addon"
                                    />
                                    <div className="input-group-append" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#e9ecef',
                                        borderRadius: '0 0.25rem 0.25rem 0',
                                        padding: '10px'
                                    }}>
                                            <span className="input-group-text"
                                                  id="subdomain-addon">.pearsonct.design</span>
                                    </div>
                                </div>
                            </div>
                            <SpinnerButton loading={loading} buttonText={'Create App'}/>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ session, repos }) => ({
    session,
    repos // Assuming repos is stored in the state with a key 'repos'
});

const mapDispatchToProps = dispatch => ({
    signin: user => dispatch(signin(user)),
    toastMessage: (data) => dispatch(toastMessage(data)),
    allGitLabRepos: (data) => dispatch(allGitLabRepos(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardComponent);
