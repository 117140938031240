import { useState, useEffect } from "react";
import { getAccessTokenFromSessionStorage } from "../util/helpers/getSsoAccessToken";
import AUTH_BASE_URL from "../util/AUTH_BASE_URL";

const useFetchImage = (url) => {
    const [token, setToken] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        console.log("Fetching token"); // Debug log
        const fetchToken = async () => {
            try {
                const fetchedToken = await getAccessTokenFromSessionStorage();
                console.log("Token fetched:", fetchedToken); // Debug log
                setToken(fetchedToken);
            } catch (error) {
                console.error("Error fetching token:", error); // Debug log
            }
        };
        fetchToken();
    }, []);

    useEffect(() => {
        console.log("In image fetching useEffect"); // Debug log
        let isMounted = true; // Flag to track component's mounted state

        const fetchImageUrl = async () => {
            if (token && url && isMounted) { // Check isMounted before attempting to fetch
                console.log("Attempting to fetch image"); // Debug log
                try {
                    const encodedUrl = encodeURIComponent(url);
                    const encodedToken = encodeURIComponent(token);

                    const imageUrlResponse = await fetch(`${AUTH_BASE_URL}news/fetchImage/${encodedUrl}/${encodedToken}`);
                    if (imageUrlResponse.ok) {
                        const imageUrlData = await imageUrlResponse.json();
                        console.log("Image fetched successfully", imageUrlData.url); // Debug log
                        if (isMounted) { // Only update state if component is still mounted
                            setImage(imageUrlData.url);
                        }
                    } else {
                        console.log("Image fetch returned non-ok status:", imageUrlResponse.status); // Debug log
                    }
                } catch (error) {
                    console.error("Error fetching image:", error); // Debug log
                }
            }
        };
        fetchImageUrl();

        return () => {
            isMounted = false; // Set flag to false when component unmounts
        };
    }, [token, url]); // Re-run effect if token or URL changes

    return image;
};

export default useFetchImage;
