export async function acquireTokenWithAccount(instance, scopes, account) {
  const response = await instance.acquireTokenSilent({
    scopes,
    account: account,
  });
  if (response) {
    // console.log("In acquireTokenWithAccount: Response:", response);
    return response;
  }
  return null;
}

export async function acquireTokenWithOutAccount(
  instance,
  scopes,
  forceRefresh,
  newAccount
) {
  // console.log("TokenWithOutAccount")
  const response = await instance.acquireTokenSilent({
    scopes,
    forceRefresh: forceRefresh,
    account: newAccount,
  });
  // console.log("In acquireTokenWithOutAccount: Response:", response);

  if (response) {
    // console.log("In acquireToken: Response:", response);
    return response;
  }
  return null;
}
