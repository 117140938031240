import AUTH_BASE_URL from "../AUTH_BASE_URL";

const updateSsoRole = async (
  email,
  department,
  jobTitle,
  role,
  ssoDirectReports,
  ssoIsManager,
  ssoManager,
  ssoDepartmentMembers,
  loginType
) => {
  const url = "auth/user/ssoRole/";
  // console.log("updateSsoRole: Department:", department)
  const params = {
    email,
    team: department,
    ssoDepartmentMembers,
    role: jobTitle,
    ssoRole: role,
    // ssoDirectReports: ["juju.moore@pearson.com", "greg.davis@pearson.com", "vidya.angeri@pearson.com"],
    ssoDirectReports,
    // ssoIsManager: true,
    ssoIsManager,
    ssoManager,
    loginType,
  };

  const response = await fetch(AUTH_BASE_URL + url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: params }),
  });

  // transform results from object to json
  const data = await response.json();

  // call search function
  return data;
};

export default updateSsoRole;
