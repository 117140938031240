import checkForWebHooks from "./checkForWebHooks";
import addWebHook from "./addWebHook";
import addRepo from "./addRepo";

export default function postCreateRepo(data) {
    const token = data.token;
    const group = data.group;
    return postToGitlab(data, token)
        .then(gitlabData => {
            if (gitlabData.message) {
                return { error: gitlabData.message };
            } else {
                return transferProjectToGroup(gitlabData.id, group, token)
                    .then(response => {
                        addRepo(gitlabData, token);
                        return response;
                    });
            }
        })
        .catch(error => {
            console.error('Error creating project:', error);
            return { error: error.message };
        });
}

function postToGitlab(data, token) {
    const payload = data.importUrl
        ? { name: data.inputValue, user_id: data.gitlab[0].id, import_url: data.importUrl }
        : { name: data.inputValue, user_id: data.gitlab.id };

    console.log('Payload:', payload);
    console.log('Token:', token);

    return fetch('https://gitlab.com/api/v4/projects', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'PRIVATE-TOKEN': `${token}`,
            'Content-Type': 'application/json',
        })
    }).then(response => response.json())
        .then(data => {
            if (data.message) {
                throw new Error(JSON.stringify(data.message));
            }
            return data;
        });
}

function transferProjectToGroup(id, group, token) {
    const payload = {
        namespace: 10566838,
        id: id,
    };

    console.log('Transfer Payload:', payload);

    return fetch(`https://gitlab.com/api/v4/projects/${id}/transfer`, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    }).then(response => {
        return response.json();
    }).then(data =>{
        return data
    });
}
