import React, {useEffect, useState} from "react";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import Avatar from "../../global/Avatar";

function Open (props) {
	const [data, setData] = useState();
	useEffect(()=>{
		if (props.data) {
			fetch(`${AUTH_BASE_URL}usertesting/results/${props.data.name}/answers?type=${props.resultType}`, {
				method: 'GET',
				headers: {
					"Content-Type": "application/json",
				},
			}).then(response => response.json()).then(data => {

				let arr = []
				data.forEach(item => {
					if (item.type === 'Open Answer') {
						arr.push(item)
					}
				})
				setData(arr)
			})
		}
	},[props.data])

	if (data) {
		return data.map((item, key) => {

			return (
				<div key={key} className="col-sm-12 col-md-6 col-xl-4">
					<div className="card custom-card pd-0" style={{paddingBottom: '24px', marginBottom: '0'}}>
						<div className="card-header border-bottom-0 pb-0 d-flex align-content-between justify-content-between">
							<label className="main-content-label mb-2 pt-1">{item.question}</label>
						</div>
						<div className="card-body sales-product-info ot-0 pt-0 pb-0" style={{position: 'relative'}}>
							<div id="recentorders" >
								{
									item.answers.map((answerItem,key) => {
										return (
											<div key={key} className="main-chat-list tab-pane">
												<div className="media new" style={{paddingLeft: '0'}}>
													<div className="main-img-user online">
														<Avatar user={answerItem.username} test={'yes'} size='sm'/>
													</div>
													<div className="media-body">
														<div className="media-contact-name">
															<span>{answerItem.username}</span>
															<span>2 hours</span>
														</div>
														<p>{answerItem.answer}</p>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
				</div>
			)
		})

	} else {
		return (
			<div></div>
		)
	}

}

export default Open
