import React, {useEffect, useRef, useState} from "react";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import YesNoLineChart from "./YesNoLineChart";

function YesNo (props) {
	const [data, setData] = useState();
	const nodeElement = useRef()
	useEffect(()=>{
		if (props.data) {

			fetch(`${AUTH_BASE_URL}usertesting/results/${props.data.name}/answers?type=${props.resultType}`, {
				method: 'GET',
				headers: {
					"Content-Type": "application/json",
				},
			}).then(response => response.json()).then(data => {

				setData(data)
			})
		}
	},[props.data])

	useEffect(()=> {

		// let nodeEl = nodeElement.current.classList.contains('delete')
		// if (nodeElement.current) {
		// 	nodeElement.current.parentNode.style.display = 'none'
		// }
	}, [nodeElement])

	if (data && data.filter(val => val.type === 'Yes / No').length > 0) {
		return (
			<div  className="col-sm-12 col-md-6 col-xl-4">
			<div className="card custom-card pd-0">
				<div className="card-header border-bottom-0 pb-0 d-flex align-content-between justify-content-between">
					<label className="main-content-label mb-2 pt-1">Yes / No Questions</label>
				</div>
				<div className="card-body sales-product-info ot-0 pt-0 pb-0" style={{position: 'relative'}}>
					<div id="recentorders" >
						<YesNoLineChart data={data} type={"Yes / No"}/>
					</div>
				</div>
			</div>
			</div>
		)
	}  else {
		return <></>
	}
}

export default YesNo
