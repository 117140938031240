import AUTH_BASE_URL from "../AUTH_BASE_URL";

// export function createStatus(ownerId, message, statusType) {
//     let data = {ownerId, message, statusType};
    // return new Promise((resolve, reject) => {
    //     try {
    //         fetch(AUTH_BASE_URL + 'status', {
    //             method: "POST",
    //             body: JSON.stringify({data}),
    //             headers: new Headers({
    //                 'Credentials': 'include',
    //                 'Content-Type': 'application/json'
    //             }),
    //         }).then(response => {
    //             return response.json()
    //         }).then(data => {
    //             resolve(data)
    //         })
    //     }
    //     catch(err) {
    //         reject(err)
    //     }
    // })
// }

// export const createToDo = async (data) => {
export function createStatus(ownerId, message, statusType) {
    let data = {ownerId, message, statusType};
    return fetch(AUTH_BASE_URL + 'status/save',  {
        method: "POST",
        body: JSON.stringify({data:data}),
        headers: {
            // 'Credentials': 'include',
            "Content-Type": "application/json"
        }
    })
}