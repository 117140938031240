import {connect} from "react-redux";
import React from "react";

/*****************************
 * Returns Pagination Table Heading
 *****************************/
function Head({ tableHeaders, session }) {
  // console.log("TABLEHEADERS:", tableHeaders);

  const renderHeaders = tableHeaders.map((header, index) => {
    if (header === "USER") {
      return (
        <React.Fragment key={`header-${index}`}>
          <th className="wd-lg-8p">
            <span>{header}</span>
          </th>
          <th className="wd-lg-20p">
            <span />
          </th>
        </React.Fragment>
      );
    } else if (header !== "ACTION") {
      return (
        <th className="wd-lg-20p" key={`header-${index}`}>
          <span>{header}</span>
        </th>
      );
    } else if (session.team === "Creative Technology") {
      return <th className="wd-lg-20p" key={`header-${index}`}>Action</th>;
    }
    // else {
    //   return <></>
    // }
  });
  return (
    <thead>
      <tr>{renderHeaders}</tr>
    </thead>
  );
}
const mapStateToProps = ({ session }) => ({
  session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Head);
