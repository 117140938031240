import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useParams} from "react-router-dom"
// import getAllPrototypes from "../../util/prototypes/getAllPrototypes";
import {allPrototypes} from '../../actions/allPrototypes'
import {updateProject} from "../../util/projects/updateProject";
import Select from "../global/inputs/CustomSelect"
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";


const ManagePrototypes = props => {

    const params = useParams();

    const [projectPrototypes, setProjectPrototypes] = useState([]);
    const [allAvailablePrototypes, setAllAvailablePrototypes] = useState([])

    useEffect(()=> {
        //get the apps that are already in this project from ProjectDetail
        if(props.projectDetails.prototypes && props.projectDetails.prototypes.length !== 0){
            props.projectDetails.prototypes.forEach((prototype)=>{
                // console.log("ManagePrototoype: Prototype:data:", prototype.data)
                setProjectPrototypes((prevState)=>{
					// console.log('need', prototype)
                    prototype.label = prototype.data.name
                    prototype.value = prototype.data.id
                    return [...prevState, prototype]
                })
            })
        }

    /**
     * Step1: Load prototypes from Redux
     */
        if(!props.prototypes){
			// console.log('ManagePrototypes:no prototypes', props.prototypes)
			fetch(`${AUTH_BASE_URL}dev-ops/gitlab/repos/archive`, {
				method: 'GET',
				headers: new Headers({
					'Content-Type': 'application/json'
				})
			}).then(response => response.json()).then(data => {
				// Set all Prototypes:
                props.allPrototypes(data.repos)
			});

        }
    }, [])

    //once users are available in redux save them to state
    useEffect(()=>{
        // console.log("ManagePrototypes:Prototypes:", props.prototypes);
        if(props.prototypes && props.prototypes.length > 0){
 /**
   * Step2: setAllAvailablePrototypes,
   * once props.prototypes have been loaded
   */
            props.prototypes.forEach((prototype)=>{
                setAllAvailablePrototypes((prevState)=>{
                    prototype.label = prototype.data?.name
					prototype.name = prototype.data?.name
                    prototype.value = prototype._id
                    return [...prevState, prototype]
                })
            })
        }
    },[props.prototypes])

    function handleSave () {
    // console.log("ManagePrototypes:TaskSave:Data:", projectPrototypes);
        let project_data = {
            prototypes: projectPrototypes
        }
        updateProject(params.id, project_data).then(()=>{
            props.getProjectDetails()
            props.handleCloseDetailModal()
        })
    }
	// console.log('need these', projectPrototypes, allAvailablePrototypes)

    return(
        <div className="modal d-block">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow">
                    <div className="modal-header">
                        <span>{props.projectDetails.name}</span>
                        <h6 className="modal-title">Manage Apps</h6>
                    </div>

                    <div className='detail-modal-body'>
                        <div className="form-group">
                            <label>Add or remove apps</label>
                            <Select
                                value={projectPrototypes}
                                handler={setProjectPrototypes}
                                data={allAvailablePrototypes}
                                type={'prototypes'}
                                card
                            />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="button" onClick={handleSave}>Save</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseDetailModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({session, prototypes}) => ({
    session, prototypes
});

const mapDispatchToProps = dispatch => ({
    allPrototypes: (data) => dispatch(allPrototypes(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManagePrototypes);
