
// Fetch user's GitLab activity
import AUTH_BASE_URL from "../AUTH_BASE_URL";

async function fetchGitLabActivity(username, token) {
  const url = `${AUTH_BASE_URL}dev-ops/gitlab/user-activity/${username}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_GL_TOKEN }`
    }
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch GitLab activity: ${response.statusText}`);
  }

  return await response.json();
}

async function transformGitLabActivity(employees, token) {
  let updatedEmployees = await Promise.all(employees.map(async val => {
    if (val.user.gitLab && val.user.gitLab[0] && val.user.gitLab[0].username) {
      let gitlabData = await fetchGitLabActivity(val.user.gitLab[0].id, token);
      if (gitlabData) {
        return {
          ...val,
          gitlabData: true
        };
      } else {
        return {
          ...val,
          gitlabData: false
        };
      }
    } else {
      return val;
    }
  }));

  return updatedEmployees;
}




export function findManager(targetEmail, employees) {
  const manager = employees.find(
    (employee) => employee.user.email === targetEmail
  );
  return manager;
}

export function getIndexByEmail(targetEmail, employees) {
  const managerIndex = employees.findIndex(
      (employee) => employee.user.email === targetEmail
  );
  return managerIndex;
}

export function getIndexById(id, employees) {
  // console.log("orgChartUtil: Employees:", employees);
  const managerIndex = employees.findIndex((employee) => employee.id === id);
  return managerIndex;
}
/**
 * Format employee data for OrgChart and placed
 * and adds mangerId as parent Id.
 * @param {*} targetEmail
 * @param {*} employees
 * @returns
 */

export async function parseEmployeesByManagerIndex(employees, managerIndex, token) {
  // Transform the employee data by fetching GitLab activities

  let transformedEmployees = await transformGitLabActivity(employees, token);

  if (managerIndex > -1) {
    const managerId = transformedEmployees[managerIndex]?.user._id;
    transformedEmployees.forEach((employee, index) => {
      employee.name = `${employee.user.firstName} ${employee.user.lastName}`;
      employee.positionName = employee.user.role;
      employee.team = "";
      employee.imageUrl = employee.user.avatar;
      employee.numOfSubordinates = employee.user.ssoDirectReports
          ? employee.user.ssoDirectReports.length
          : 0;

      if (index === managerIndex) {
        employee.parentId = "";
        employee.id = managerId;
      } else {
        employee.parentId = managerId;
        employee.id = employee.user._id;
      }
    });
  }
  return transformedEmployees;
}

/**
 * Format employee data for OrgChart and placed
 * and adds mangerId as parent Id.
 * @param {*} targetEmail
 * @param {*} employees
 * @returns
 */
export function parseEmployeesByManagerId(employees, managerId) {
    employees.forEach((employee, index) => {
      employee.name = `${employee.user.firstName} ${employee.user.lastName}`;
      employee.positionName = employee.user.role;
      employee.team = "";
      employee.imageUrl = employee.user.avatar;
      employee.numOfSubordinates = employee.user.ssoDirectReports
        ? employee.user.ssoDirectReports.length
        : 0;
        employee.parentId = managerId;
        employee.id = employee.user._id;
    });
  return employees;
}

export const addChildren = (targetEmail, employees, chartData) => {
  const children = [];
  return new Promise((resolve) => {
    // console.log(
    //   "orgChartUtil: EMPLOYEES007:",
    //   employees,
    //   "targetEmail:",
    //   targetEmail
    // );
    employees.forEach(async (employee) => {
      if (employee.user.email !== targetEmail) {
        children.push({
          id: employee.user._id,
          name: `${employee.user.firstName} ${employee.user.lastName}`,
          email: employee.user.email,
          user: employee.user,
          status: employee.status,
        });
      }
    });
    /**
     * Chart Data must be filled before returning
     * the data.  We must return a promise to the caller.
     */
    Promise.all(children).then((children) => {
      chartData.children = children;
      resolve();
    }); // Promise All
  }); // Promise
};

export const formatData = async (targetEmail, employees) => {
  const chartData = {};
  // console.log(
  //   "orgChartUtil: targetEmail:",
  //   targetEmail,
  //   "employees:",
  //   employees
  // );
  const manager = findManager(targetEmail, employees);
  if (manager) {
    chartData.id = manager.user._id;
    chartData.name = `${manager.user.firstName} ${manager.user.lastName}`;
    chartData.email = manager.user.email;
    chartData.user = manager.user;
    chartData.status = manager.status;
    chartData.children = [];
    // chartData can not return until all children have been added
    await addChildren(targetEmail, employees, chartData);
  }

  return chartData;
};

export function getNumberOfDays(start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

/**
 * displaySection: Determine if a
 * specific type of status should display
 * @param {*} type - Section type ("push", "manual", "todos")
 * @returns shouldHeaderDisplay
 */
export function displaySection(user, statuses, maxStatus) {
  // console.log("orgChartUtil: CALLED DISPLAYSECTION!");
  const types = ["push", "todos", "manual"];
  let shouldHeaderDisplay = false;
  if (statuses) {
    types.forEach((type) => {
      statuses[type]
        ?.slice(-maxStatus)
        .sort((a, b) => b.updated - a.updated)
        .forEach((status, index) => {
          // console.log(
          //   "orgChartUtil: UserName:",
          //   user.firstName,
          //   "TYPE:",
          //   status.type,
          //   ";DisplayItems:",
          //   Date.now() - parseInt(status.updated)
          // );
          // If any of the status.updates are true the display the header
          if (getNumberOfDays(parseInt(status.updated), Date.now()) <= 7) {
            // console.log(`orgChartUtil: ${type}* Header should be displayed`);
            shouldHeaderDisplay = true;
            // console.log(`orgChartUtil: DISPLAYSECTION: ${user.firstName}, ":", ${type}`);
            // setHasDisplaySection(true);
            return shouldHeaderDisplay;
          }
        });
    }); //forEach
  }
  return shouldHeaderDisplay;
}
