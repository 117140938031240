import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const retrieveTasks = async (projectId) => {
  // console.log('retreiveTasks', projectId)
  const response = await fetch(AUTH_BASE_URL + `tasks/${projectId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
};
