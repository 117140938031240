import React, {useEffect, useRef, useState} from "react";
import { connect } from "react-redux";
import { updateSession } from "../../actions/session/updateSession";
import { allUserData } from "../../actions/allUsers";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ExecutiveNews from "./widgets/ExecutiveNews";
import MyTasksInitializer from "./myTasksAndPinnedProjects/MyTasksInitializer";
import PinnedProjectInitializer from "./myTasksAndPinnedProjects/PinnedProjectInitializer";
import PinnedEmployeeSelectorController from "./pinnedEmployeeTasks/SelectorController"
import MyTeam from "./widgets/MyTeam";
import DisplayMyActivity from "./widgets/MyActivity";
import RecentNews from "./widgets/RecentNews";

const DashboardWidgetContainer = (props) => {
    const [draggable, setDraggable] = useState(props.draggable);
    const [dragDirection,setDragDirection] = useState('');
    const [dragging, setDragging] = useState(false);

    const sectionRef = useRef();

    useEffect(() => {
        setDraggable(props.draggable)
    },[props.draggable])

    useEffect(() => {
        setDragDirection('')
        setDragging(false)
    },[props.item])

    const dragOver = (event) => {
        event.preventDefault()

        if (!dragging) {
            const currentItem = event.currentTarget,
            currentBounds = currentItem.getBoundingClientRect() ,
            halfwayPoint = currentBounds.top + window.scrollY + (0.5* currentBounds.height);

            // console.log(currentBounds, event.pageY)
            //between currentBounds top and 1/2 height
            if (event.pageY < halfwayPoint) {
            console.log("place above")
            // if (textId) textId.innerText = "above"
            setDragDirection("above")
            props.dragOver(event,"above")
            // console.log("")
            } else {
            console.log("place below")
            setDragDirection("below")
            props.dragOver(event,"below")
            // if (textId) textId.innerText = "below"
            }
        }
        
       
    }
    
    const isDragging = () => {
        // const currentItem = sectionRef.current;
        // event.dataTransfer.setData("drag-type", "section");
        setDragDirection('')
        setDragging(true)
        props.dragStart()
    }
    const stopDragging = (event) => {
        setDragDirection('')
        setDragging(false)
        
        // console.log(event.currentTarget)
    }

    const dragLeave = (event) => {
        setDragDirection('');
        props.dragStop(event)
    }

    return (
        <div className={`card custom-card p-0 card-draggable ${draggable && dragging ? "is-dragging": ""} ${draggable && dragDirection ? "place-"+dragDirection : ""}`} 
                draggable={draggable} 
                ref={sectionRef}
                onDragOver={(event) => dragOver(event)}
                onDragLeave={(event) => dragLeave(event)}
                onDragStart={() => isDragging()}
                onDragEnd={(event) => stopDragging(event)}
                onDrop={(event) => {props.drop(event);setDragDirection('')}}
                >
            <div className="card-header text-start border-bottom-0">
                {draggable && <span className="drag-icon"><FontAwesomeIcon icon={`fa-solid fa-grip-vertical`} /></span> }
                <h2 className="card-title tx-20">{props.title}</h2>
            </div>
            <div className="card-body">
                {props.title.toLowerCase() === "executive news" ? 
                    <ExecutiveNews/>
                : props.title.toLowerCase() === "my work" ? 
                <MyTasksInitializer
                endeavorUsers={props.users}
              />
                : props.title.toLowerCase() === "pinned work" ? 
                <PinnedProjectInitializer
                session={props.session}
                endeavorUsers={props.users}
                />
            : props.title.toLowerCase() === "my team" ? 
            <PinnedEmployeeSelectorController
            endeavorUsers={props.users}
            />
            : props.title.toLowerCase() === "my activity" ? 
            <DisplayMyActivity/>

            : props.title.toLowerCase() === "recent news" ? 
            <RecentNews/>

                : <p>Add something here</p>    
                }
                
            </div>
        </div>
    )

}
const mapStateToProps = ({ session, users }) => ({
    session,
    users,
  });
const mapDispatchToProps = (dispatch) => ({
    allUserData: (data) => dispatch(allUserData(data)),
    updateSession: (user) => dispatch(updateSession(user)),
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(DashboardWidgetContainer)