import React, { useState, useEffect } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import KanbanCard from './cards/KanBanCardSmall';

const TeamStatus = ({
                        fetchData,
                        selectedAssignee,
                        data,
                        columns,
                        setData,
                        effortColumnId,
                        dueDateColumnId,
                        complexityColumnId,
                        capacityColumnId, // Assuming you pass capacityColumnId as a prop
                        cloudSave,
                        setCloudSave,
                        onTaskFiltered,
                        sprintEndDate // Pass sprintEndDate as a prop
                    }) => {
    const [editingTaskId, setEditingTaskId] = useState(null);
    const [fadeClass, setFadeClass] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (selectedAssignee) {
            setIsVisible(true);
            setFadeClass('fade-in');
        } else if (isVisible) {
            setFadeClass('fade-out');
            setTimeout(() => {
                setIsVisible(false);
            }, 500);
        }
    }, [selectedAssignee]);

    useEffect(() => {
        if (onTaskFiltered) {
            onTaskFiltered(inProgressTasks); // Pass inProgressTasks back to `Planning`
        }
    }, [onTaskFiltered]);

    if (!data || !data.rows || !columns || !isVisible) {
        return null;
    }

    const stateColumnId = Object.keys(columns).find(columnId => columns[columnId].title === "State");
    const assigneeColumnId = Object.keys(columns).find(columnId => columns[columnId].title === "Assignee");

    // Split tasks into current sprint and other tasks
    const inProgressTasks = data.rows.filter(row => {
        const stateCell = row.cells.find(cell => cell.columnId === parseInt(stateColumnId));
        const assigneeCell = row.cells.find(cell => cell.columnId === parseInt(assigneeColumnId));
        const dueDateCell = row.cells.find(cell => cell.columnId === dueDateColumnId);

        const isInProgress = stateCell?.value.toLowerCase() === "in progress";
        const dueDate = dueDateCell?.value ? new Date(dueDateCell.value) : null;

        // Include tasks assigned to the selected assignee or within this sprint's due date range
        return isInProgress && (selectedAssignee === 'This Sprint' || assigneeCell?.value === selectedAssignee);
    });

    // Sort tasks by due date and capacity
    inProgressTasks.sort((a, b) => {
        const dueDateA = a.cells.find(cell => cell.columnId === dueDateColumnId)?.value;
        const dueDateB = b.cells.find(cell => cell.columnId === dueDateColumnId)?.value;

        const dateA = dueDateA ? new Date(dueDateA) : new Date(8640000000000000);
        const dateB = dueDateB ? new Date(dueDateB) : new Date(8640000000000000);

        if (dateA - dateB !== 0) return dateA - dateB;

        const capacityA = a.cells.find(cell => cell.columnId === capacityColumnId)?.value || 0;
        const capacityB = b.cells.find(cell => cell.columnId === capacityColumnId)?.value || 0;
        return capacityB - capacityA;
    });

    // Separate tasks into current sprint and other groups
    const currentSprintTasks = inProgressTasks.filter(task => {
        const dueDate = task.cells.find(cell => cell.columnId === dueDateColumnId)?.value;
        return dueDate && new Date(dueDate) <= sprintEndDate;
    });

    const otherTasks = inProgressTasks.filter(task => {
        const dueDate = task.cells.find(cell => cell.columnId === dueDateColumnId)?.value;
        return !dueDate || new Date(dueDate) > sprintEndDate;
    });

    const handleDeleteTask = (taskId) => {
        setData(prevData => {
            const updatedRows = prevData.rows.filter(task => task.id !== taskId);
            return {
                ...prevData,
                rows: updatedRows
            };
        });
    };

    return (
        <div className={`team-status ${fadeClass}`} style={{ width: '100%' }}>
            <Droppable droppableId="team-status-tasks" direction="horizontal">
                {(provided) => (
                    <div
                        className="kanban-tasks"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                            display: 'flex',
                            gap: '20px',
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            padding: '10px 0',
                        }}
                    >
                        {/* Render current sprint header above tasks */}
                        {currentSprintTasks.length > 0 && (
                            <div style={{ display: 'inline-block' }}>
                                <h5 style={{ textAlign: 'left', marginBottom: '5px', paddingLeft:'5px' }}>Current Sprint</h5>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    {currentSprintTasks.map((task, index) => (
                                        <Draggable
                                            key={task.id}
                                            draggableId={String(task.id)}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    <KanbanCard
                                                        key={task.id}
                                                        task={task}
                                                        columns={columns}
                                                        isEditing={editingTaskId === task.id}
                                                        setEditingTaskId={setEditingTaskId}
                                                        fetchData={fetchData}
                                                        handleDeleteTask={handleDeleteTask}
                                                        effortColumnId={effortColumnId}
                                                        dueDateColumnId={dueDateColumnId}
                                                        complexityColumnId={complexityColumnId}
                                                        setCloudSave={setCloudSave}
                                                        cloudSave={cloudSave}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* Render other tasks header and tasks only if a teammate is selected */}
                        {selectedAssignee !== 'This Sprint' && otherTasks.length > 0 && (
                            <div style={{ display: 'inline-block', marginLeft: '20px' }}>
                                <h5 style={{ textAlign: 'left', marginBottom: '5px', paddingLeft:'5px' }}>Future Sprints</h5>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    {otherTasks.map((task, index) => (
                                        <Draggable
                                            key={task.id}
                                            draggableId={String(task.id)}
                                            index={currentSprintTasks.length + index}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    <KanbanCard
                                                        key={task.id}
                                                        task={task}
                                                        columns={columns}
                                                        isEditing={editingTaskId === task.id}
                                                        setEditingTaskId={setEditingTaskId}
                                                        fetchData={fetchData}
                                                        handleDeleteTask={handleDeleteTask}
                                                        effortColumnId={effortColumnId}
                                                        dueDateColumnId={dueDateColumnId}
                                                        complexityColumnId={complexityColumnId}
                                                        setCloudSave={setCloudSave}
                                                        cloudSave={cloudSave}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </div>
                            </div>
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default TeamStatus;
