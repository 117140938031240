import AUTH_BASE_URL from "../AUTH_BASE_URL";

export default async function (data,token) {
	let newData = {}
	newData.data = data
		newData.token = token
	fetch(`${AUTH_BASE_URL}dev-ops/repos/add`, {
		method:'POST',
		body: JSON.stringify(newData),
		headers: new Headers({
			'Content-Type': 'application/json'
		})
	})
}
