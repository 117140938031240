import commitFile from './commitProxyFile'

export default async function (data, token) {

    return fetch(`https://gitlab.com/api/v4/projects/34880575/repository/files/${encodeURIComponent('proxies.js')}?ref=main`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    }).then(response => response.json()).then(file => {
		if (file.message === '404 Commit Not Found') {
			console.log('no proxy file')
		} else {
			console.log('my file', file)
			let base64ToString = atob(file.content)
			let arr = eval(base64ToString.replace('module.exports =  ', ''))
			const length = arr.length
			const obj = {
				path: `/apps/${data.name}`,
				target: `http://localhost:${createPort(length)}`
			}
			arr.push(obj)
			let newContent = `module.exports =  ${JSON.stringify(arr)}`
			let commitData = {}
			commitData.content = newContent
			commitData.commit_message = 'updated proxy file'
			commitData.author_name = 'system commit'
			commitData.token = token
			commitData.branch = data.default_branch
			return commitFile(commitData).then(final => {
				return createPort(length)
			})
		}


    })
}

function createPort (num) {
    if (num < 10) {
        return `900${num}`
    } else if (num >= 10 && num < 100) {
        return `90${num}`
    } else {
        return `9${num}`
    }
}
