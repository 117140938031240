import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import ProjectsLink from "../links/ProjectsLink";
const GuestSidebar = () => {
    const [location, setLocation] = useState(useLocation());
    return (
        <>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'projects' ? 'active' : ''}`}>
                <ProjectsLink/>
            </li>
        </>

    )
};

export default GuestSidebar;
