export default async function () {
   return fetch(`https://api.digitalocean.com/v2/apps/` + process.env.REACT_APP_DO_ID, {
       method: 'GET',
       headers: new Headers({
           'Authorization': `Bearer ${process.env.REACT_APP_DO_TOKEN}`,
           'Content-Type': 'application/json'
       })
   }).then(response => {
       return response.json()
   }).then(data =>{
       return data.app.spec
   }).then(spec => spec)
}