import AUTH_BASE_URL from "../AUTH_BASE_URL";
import createSubgroup from '../../util/dev-ops/createSubgroup'
async function copyRepoData(repoData) {
	return fetch(`${AUTH_BASE_URL}dev-ops/gitlab/copyRepo`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(repoData),
	})
		.then(response => response.json())
		.then(data => {
			return data
		})
		.catch((error) => {
			console.error('Error:', error);
		});
}
export default async function (data, token) {
	let subgroup = await createSubgroup(`${data.name}-classtest`, 70690967, token);

	// update doc in db to flag that a test is running
	const frontRepoData = {
		newRepoName: `${data.name}-front-end`,
		sourceRepo: "https://gitlab.com/class-testing/system-files/ut-class-front-end.git",
		branch: "starting-point",
		token: token,
		classTest: data,
		subGroup: subgroup
	};

	const backRepoData = {
		newRepoName: `${data.name}-back-end`,
		sourceRepo: "https://gitlab.com/class-testing/system-files/ut-class-back-end.git",
		branch: "starting-point",
		token: token,
		classTest: data,
		subGroup: subgroup
	};

	const appRepoData = {
		newRepoName: `${data.name}-app`,
		sourceRepo: "https://gitlab.com/class-testing/system-files/ut-class-app.git",
		branch: "main",
		token: token,
		classTest: data,
		subGroup: subgroup
	};
	let front = await copyRepoData(frontRepoData)
	let back = await copyRepoData(backRepoData)
	let app = await copyRepoData(appRepoData)
	console.log('the front end', front)
	console.log('the back end', back)
	console.log('the back end', app)
	const newRepoData = {
		front: front,
		back: back,
		app: app,
		name: data.name
	};

	return fetch(`${AUTH_BASE_URL}usertesting/classtest/launch`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(newRepoData),
	})
		.then(response => {
			console.log('create new do application', response)
			return response
		});

	// create new digital ocean app
	// edit the returned app spec
		// domains - name of test
		// envs - db name
		// cors - dev.pearsonct.design
	// put the new app spec
	// add cors to platform

	// TODO IN NEW SYSTEM
	// setup usernames and passwords in new database
	// push the test data also to the new database
}
