import AUTH_BASE_URL from '../../util/AUTH_BASE_URL'

export default async function (data) {
     return fetch(`${AUTH_BASE_URL}dev-ops/gitlab/commit`, {
         method: 'POST',
         body: JSON.stringify(data),
         headers: new Headers({
             'Content-Type': 'application/json'
         })
     }).then(response => {
         return response.json()
     }).then(final => {
         return final
     })
}
