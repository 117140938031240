import React from "react";
import moment from "moment";
import { Link } from "react-router-dom"; // Import the Link component
import truncateDescription from "../../../util/tasks/truncateDescription";
import { v4 as uuidv4 } from "uuid"; // Import the v4 function from the uuid library

const titleCharLimit = 17;

const DisplayWidget = ({ session, items, type, header }) => {
  // console.log("Items:", items,";type:", type,";header:", header);
  const charLimit = 55;

  const getPinnedItemHeader = () => {
    const itemHeader =
      header + " " + type?.charAt(0).toUpperCase() + type?.slice(1);
    return itemHeader;
  };

  const displayList = () => {
    // console.log("Logs:", items, "Type:", typeof items);
    if (items?.length > 0) {
      return items?.map((item, index) => {
        console.log("Task Item:", item)
        let startDate;
        let endDate;
        let statusColor = "";
        let daysDifference = 0;
        startDate =
          type !== "projects" ? moment(item?.startDate) : moment(item?.created);
        endDate = type !== "projects" ? moment(item?.endDate) : moment();
        const currentDate = moment();
        daysDifference =
          type !== "projects"
            ? endDate.diff(currentDate, "days")
            : currentDate.diff(moment(item?.created), "days");
        if (daysDifference < 0) {
          statusColor = "text-margin text-danger";
        } else {
          statusColor = "text-margin text-success";
        }
        return (
          <>
          { 
          (
            item.status 
            &&
            (type !== "projects" || item.status !== "closed") 
            &&
            (item.status !== "closed" 
            || statusColor !== "text-margin text-danger"
             )
             ) ? 
          (<Link
            key={uuidv4()}
            to={type && item ? `/${type}/${item?._id}` : "/#"} // Use the "to" prop for navigation
            className="list-group-item list-group-item-action flex-column border-right-0 border-left-0 align-items-start p-3 border-top-0"
          >
            <div className="d-flex w-100 justify-content-between align-baseline">
              <h6 className="mb-1 font-weight-semibold tx-16">
                {item && item.name
                  ? truncateDescription(item.name, titleCharLimit)
                  : ""}
              </h6>
              <small className={statusColor}>
                <i className="fa fa-caret-down mr-1" />
                {/* {console.log("Days Difference:", daysDifference )} */}
                {daysDifference ? Math.abs(daysDifference) + " days" : ""}
              </small>
            </div>
            <p className="left-header mb-0 text-muted mb-0 tx-12">
              {"Started: "}
              {/* {console.log("StartDate:", startDate)} */}
              {startDate._i ? startDate?.format("MM-DD-YY") : "N/A"}
            </p>
            <div className="left-header">
              <small className="text-muted">
                {item?.description
                  ? truncateDescription(item?.description, charLimit)
                  : "N/A"}
              </small>
            </div>
          </Link>
          ) 
          : (<></>)
          }
          </>
        );
      });
    }
  };
  return (
    
        <div className="left-header">
          {/* <label className="main-content-label mb-2">
            {getPinnedItemHeader()}
          </label>{" "}*/}
          <span className="d-block tx-12 mb-2 text-muted">Current status</span> 
        <div className="list-group border projects-list">{displayList()}</div>
    </div>
  );
};

export default DisplayWidget;
