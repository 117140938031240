import React from "react";
import { connect } from "react-redux";
import digitalAvatar from "../../../../assets/images/digitalocean.png";
import DropDown from '../../gitlab-repos/DropDown';
import { toastMessage } from "../../../../actions/toast";

function TableRow(props) {
    const { database } = props;

    function avatarSwitcher() {
        return <img style={{ width: '30px' }} alt="avatar" src={digitalAvatar} />;
    }

    return (
        <tr>
            <td>{avatarSwitcher()}</td>
            <td>{database?.name || 'N/A'}</td>
            <td>{database?.engine || 'N/A'}</td>
            <td>{database?.version || 'N/A'}</td>
            <td>{database?.status || 'N/A'}</td>
            <td>{database?.region || 'N/A'}</td>
            <td>{database?.size?.price_monthly ? `$${database.size.price_monthly}/mo` : 'N/A'}</td>
            <td>{database?.created_at ? new Date(database.created_at).toLocaleDateString() : 'N/A'}</td>
        </tr>
    );
}

const mapStateToProps = ({ session }) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(TableRow);
