import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Wrapper from "../../../global/Wrapper";
import DigitalOceanSettings from "../../../settings/DigitalOcean";
import { useNavigate } from "react-router-dom";
import Table from "./Table";
import { toastMessage } from "../../../../actions/toast";

function TableContainer(props) {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [noToken, setNoToken] = useState(null);
	const [showDigitalOcean, setShowDigitalOcean] = useState(false);
	const [droplets, setDroplets] = useState([]);

	useEffect(() => {
		setLoading(true);
	}, []);

	useEffect(() => {
		if (!props.session.digitalAccessToken && !noToken) {
			setNoToken(true);
			setShowDigitalOcean(true);
			props.toastMessage('Your Digital Ocean token has expired. Please re-authenticate.');
		} else if (props.session.digitalAccessToken) {
			fetchDigitalOceanDroplets(props.session.digitalAccessToken);
		}
	}, [props.session.digitalAccessToken, noToken, props]);

	const fetchDigitalOceanDroplets = async (token) => {
		try {
			console.log('Fetching droplets with token:', token);
			let allDroplets = [];
			let page = 1;
			let hasMore = true;

			while (hasMore) {
				const response = await fetch(`https://api.digitalocean.com/v2/droplets?page=${page}&per_page=20`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Content-Type': 'application/json'
					}
				});

				if (!response.ok) {
					throw new Error('Network response was not ok');
				}

				const data = await response.json();
				allDroplets = allDroplets.concat(data.droplets || []);

				hasMore = data.links && data.links.pages && data.links.pages.next;
				page += 1;
			}

			// Sort droplets by price_monthly in descending order
			allDroplets.sort((a, b) => b.size.price_monthly - a.size.price_monthly);

			console.log('Fetched all droplets data:', allDroplets);
			setDroplets(allDroplets);
			setLoading(false);
		} catch (error) {
			console.error('Error fetching DigitalOcean Droplets:', error);
			setLoading(false);
		}
	};

	console.log('showDigitalOcean', showDigitalOcean);
	console.log('Droplets:', droplets);

	return (
		<Wrapper>
			<div className="main-content pt-0 digitalocean-repos">
				<div className="container-fluid">
					<div className="inner-body users">
						{/* Page Header */}
						<div className="page-header">
							<div>
								<h2 className="main-content-title tx-24 mg-b-5">Digital Ocean Hosting - Droplets</h2>
							</div>
						</div>
						<div className="row row-sm">
							{
								showDigitalOcean ?
									<div className={"col-sm-12 col-md-12 col-lg-12 col-xl-4 grid-margin"}>
										<div className="card custom-card pd-0">
											<div className={'figma-auth'}><DigitalOceanSettings /></div>
										</div>
									</div> :
									<div className={"col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin"}>
										<div className="card custom-card pd-0">
											<Table loading={loading} apps={droplets} />
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}

const mapStateToProps = ({ session }) => ({
	session
});

const mapDispatchToProps = dispatch => ({
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TableContainer);
