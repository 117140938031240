import React, { useRef, useState, useEffect } from 'react';
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import { connect } from "react-redux";
import { getSidebarData } from "../../actions/sidebar";
import IconSelect from "../global/IconSelect";

const NewItem = props => {
	const { item } = props; // Extract the item prop
	const isEdit = !item.nativeEvent; // Determine if it's an edit or a new item

	const [icon, setIcon] = useState(item?.icon || null);

	// Use the current value for editing or a default value for a new item
	const titleRef = useRef(null);
	const textRef = useRef(null);
	const leftButtonRef = useRef(null);
	const leftButtonLinkRef = useRef(null);
	const rightButtonRef = useRef(null);
	const rightButtonLinkRef = useRef(null);

	useEffect(() => {
		if (item) {
			titleRef.current.value = item.title || '';
			textRef.current.value = item.text || '';
			leftButtonRef.current.value = item.leftButtonText || '';
			leftButtonLinkRef.current.value = item.leftButtonLink || '';
			rightButtonRef.current.value = item.rightButtonText || '';
			rightButtonLinkRef.current.value = item.rightButtonLink || '';
		}
	}, [item]);

	const handleSave = (item) => {
		console.log(props.item)
		let page_data = {
			title: titleRef.current.value,
			text: textRef.current.value,
			leftButtonText: leftButtonRef.current.value,
			leftButtonLink: leftButtonLinkRef.current.value,
			rightButtonText: rightButtonRef.current.value,
			rightButtonLink: rightButtonLinkRef.current.value,
			path: window.location.pathname,
			svg: icon?.iconName
		};

		const url = isEdit ? `${AUTH_BASE_URL}resources/${props.item._id}` : `${AUTH_BASE_URL}resources`;
		const method = isEdit ? 'PUT' : 'POST';

		fetch(url, {
			method: method,
			body: JSON.stringify(page_data),
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		})
			.then(response => {
				if (!response.ok) {
					return response.json().then(errorData => {
						throw new Error(errorData.message);
					});
				}
				return response.json();
			})
			.then(data => {
				props.setData(data);
				props.handleCloseNewModal();
			})
			.catch(error => {
				console.error('An error occurred:', error.message);
			});
	};

	return (
		<div className="modal d-block">
			<div className="modal-dialog" role="dialog">
				<div className="modal-content modal-content-demo shadow pd-20">
					<div className="modal-header border-bottom-0">
						<h6 className="modal-title">{isEdit ? 'Edit Item' : 'New Item'}</h6>
					</div>
					<form>
						<div className="form-group">
							<label>Title</label>
							<input ref={titleRef} className="form-control" placeholder="New Hire" type="text" required />
						</div>
						<div className="form-group">
							<label>Text</label>
							<input ref={textRef} className="form-control" placeholder="Decks & Presentations" type="text" required />
						</div>
						<div className="form-group">
							<label>Left Button Text</label>
							<input ref={leftButtonRef} className="form-control" placeholder="Global" type="text" />
						</div>
						<div className="form-group">
							<label>Left Button Link</label>
							<input ref={leftButtonLinkRef} className="form-control" placeholder="http://yourlink.com" type="text" />
						</div>
						<div className="form-group">
							<label>Right Button Text - optional</label>
							<input ref={rightButtonRef} className="form-control" placeholder="US" type="text" />
						</div>
						<div className="form-group">
							<label>Right Button Link - optional</label>
							<input ref={rightButtonLinkRef} className="form-control" placeholder="http://yourlink.com" type="text" />
						</div>
						<div className="form-group">
							<label>Font Awesome Selector</label>
							<IconSelect setIcon={setIcon} />
						</div>
					</form>
					<div className="modal-footer">
						<button className="btn ripple btn-primary" type="button" onClick={handleSave}>Save</button>
						<button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseNewModal}>Cancel</button>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = ({ session, users, prototypes, sidebarData }) => ({
	session, users, prototypes, sidebarData
});

const mapDispatchToProps = dispatch => ({
	getData: (data) => dispatch(getSidebarData(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NewItem);
