import getAppSpec from "./getAppSpec";

export default async function (name) {
	return getAppSpec().then(async spec => {
		let filtered = spec.static_sites.filter(site => site.name === name)
		if (filtered && filtered.length === 0) {
			return false
		} else {
			return true
		}
	})
}
