import AUTH_BASE_URL from "../AUTH_BASE_URL";

const getProducts = async () => {
    return fetch(`${AUTH_BASE_URL}products`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export default getProducts;