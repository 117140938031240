import React, { useEffect, useState } from "react";
import {
  parseEmployeesByManagerIndex,
  getIndexByEmail,
} from "../../../util/helpers/orgChartUtil";
import OrganizationalChart from "../../global/d3-org-chart/orgChart";
import {getSidebarData} from "../../../actions/sidebar";
import {connect} from "react-redux";

const ChartController = ({ rootUsers, ssoManager, session}) => {
  const [chartData, setChartData] = useState([]);
  const [displayTree, setDisplayTree] = useState(false);
  // console.log("ChartController:", "ROOTUSERS:", rootUsers);
  
  /**
   * If at least one ssoUser display tree
   */
  useEffect(() => {
    const fetchData = async () => {
      let data = {};
      data.token = session.token;
      data.email = session.email;

      const managerIndex = await getIndexByEmail(ssoManager, rootUsers, data);
      const parsedEmployees = await parseEmployeesByManagerIndex(rootUsers, managerIndex, data.token);

      const employeesLength = parsedEmployees.length;
      if (employeesLength > 0) {
        setDisplayTree(true);
        const newData = [...parsedEmployees];
        setChartData(newData);
      } else {
        setDisplayTree(false);
      }
    };

    fetchData();
  }, [rootUsers, ssoManager]);

  useEffect(() => {
    // console.log("Display Tree:", displayTree);
  }, [displayTree]);

  return (
    <div className="org-container">
      <div className="">
        {displayTree ? (
          <OrganizationalChart 
          data={chartData} 
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({session}) => ({
  session
});

const mapDispatchToProps = dispatch => ({
  getData: (data) => dispatch(getSidebarData(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(ChartController));

