import React, { useState, useEffect } from "react";

/**
 *
 * @param {Optiona} noHeader - true to indicate an inline item which will supply its on header.
 * false indicates a block item, which will pass the header as a prop.
 * @returns
 */
const Number = (props) => {
  const [value, setValue] = useState(props.value);

  const handleChange = (event) => {
    // console.log("HandleChange:", event);
    setValue(event.target.value);
  };

  useEffect(() => {
    // console.log("Description:", value);
  }, [value]);

  return (
    <>
      {props.noHeader ? (
        <div className="form-group mg-b-0 flex-grow-1">
          <input
            {...props.register(props.name)}
            className={`form-control ${props.card ? "card-input" : null}`}
            placeholder={props.placeholder}
            type="number"
            onChange={handleChange}
            required={props.required}
            // defaultValue={props.defaultValue}
            value={value}
            step={props.step}
          />
        </div>
      ) : (
        <div className="form-group">
          <label>{props.label}</label>
          <input
            {...props.register(props.name)}
            className={`form-control ${props.card ? "card-input" : null}`}
            placeholder={props.placeholder}
            // defaultValue={props.defaultValue}
            onChange={handleChange}
            value={value}
            type="number"
            required={props.required}
            step={props.step}
          />
        </div>
      )}
    </>
  );
};

export default Number;
