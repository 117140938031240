import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

/**
 * Description: Multi Value selector
 * @param {*} name: element name
 * @param {*} label: element label
 * @param {*} isMulti: does input take multiple inputs
 * @param {*} options: dropdown options
 * @param {Optional} handler: method to execute OnChange
 * @param {Optional} value: default selected value
 *
 * @returns
 */
const StaticSelect = (props) => {
  // console.log("StaticSelect:", props);
  return (
    <div className="form-group">
      {props.label && props.label !== undefined ? (
        <label>{props.label}</label>
      ) : (
        <> </>
      )}
      <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => (
          <Select
            {...field}
            isClearable // enable isClearable to demonstrate extra error handling
            isSearchable={true}
            isMulti={props.isMulti}
            name={props.name}
            options={props.options}
            className={`basic-multi-select ${props.card ? "card-select" : null}`}
            classNamePrefix="select"
            required={props.required}
            // onChange={props.retrieveData ? (data) => props.retrieveData(data) : null}
            styles={{
              container: (provided) => ({
                ...provided,
              }),
            }}
          />
        )}
      />
    </div>
  );
};

export default StaticSelect;
