import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const updateSubtask = async (subtaskId, data) => {
  // console.log("UpdateSubtask: Data:", data, "; SubtaskId:", subtaskId);
  const response = await fetch(AUTH_BASE_URL + "subtasks/update/" + subtaskId, {
    method: "PUT",
    body: JSON.stringify({ data }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};
