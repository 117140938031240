import React, { useEffect, useRef, useState, useContext } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Wrapper from "../../global/Wrapper";
import UpdateSubtask from "./modals/Update";
import EditAsset from "../../assets/EditAsset";
import ManageAssets from "../../assets/ManageAssets";
import DeleteAsset from "../../assets/DeleteAsset";
import { getSubtaskDetails as getSubtask } from "../../../util/subtask/getSubtaskDetails";
import {
  getTaskDetail as getTask,
  getTaskDetail,
} from "../../../util/tasks/getTaskDetails";
import { getProjectDetail } from "../../../util/projects/getProjectDetail";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { updateSubtask } from "../../../util/subtask/updateSubtask";
import ConfirmationModal from "../../global/ConfirmationModal";
// import { TasksContext } from "../../global/Providers/TaskProvider";
import Avatar from "../../global/Avatar";
import { signin } from "../../../actions/session/signIn";
import { allGitLabRepos } from "../../../actions/allGitlabRepos";
import { filteredGitLabRepos } from "../../../actions/filteredData";
import { toastMessage } from "../../../actions/toast";
import { getGitlabGroupData } from "../../../actions/gitlabGroups";
import { getGitlabGroupIdData } from "../../../actions/gitlabGroupId";
import { getGitlabSubGroupData } from "../../../actions/gitlabSubGroups";
import { getGitlabSubGroupIdData } from "../../../actions/gitlabSubGroupId";
import { setFilter } from "../../../actions/filterBy";
import { allCreators } from "../../../actions/creators";
import { setRepoLoading } from "../../../actions/repoLoading";
import Descriptions from "../side-bar/components/Descriptions";
import Assets from "../side-bar/components/Assets";
import { deleteSubtask } from "../../../util/subtask/deleteSubtask";
import { retrieveTasks } from "../../../util/tasks/retrieveTasksByProject";
import { formatDataByName } from "../../../util/tasks/formatData";
import truncateDescription from "../../../util/tasks/truncateDescription";
import AppWidget from "../../dev-ops/gitlab/AppWidget";
import { useGetAndSetProjects } from "../../../util/projects/useGetAndSetProjects";

const titleCharLimit = 20;

const SubtaskDetail = (props) => {
  const regex = /0{0,1}/;

  const navigate = useNavigate();
  const params = useParams();
  const actionsRef = useRef(null);
  // let subtaskId;
  const [subtaskId] = useState(params.id);
  // console.log("SubtaskId:", subtaskId);
  const [showEditAsset, setShowEditAsset] = useState(null);
  const [showManageAssets, setShowManageAssets] = useState(false);
  const [showDeleteAssetModal, setShowDeleteAssetModal] = useState(null);
  const [showNewSubtask, setShowNewSubtask] = useState(false);
  const [subtask, setSubtask] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [apps, setApps] = useState([]);
  // console.log("render");
  /********NEWTASK START*******/
  // const { isSubtaskUpdated, setIsSubtaskUpdated } = useContext(TasksContext);

  const [toggleTaskRefresh, setToggleTaskRefresh] = useState(false);
  const [showUpdateTask, setShowUpdateTask] = useState(false);
  const [showDeleteSubtask, setShowDeleteSubtask] = useState(false);
  const [responsibleDefaultValues, setResponsibleDefaultValues] =
    useState(null);
  const [assetToDelete, setAssetToDelete] = useState(null);
  const [taskGitlabId, setTaskGitlabId] = useState([]);
  const [subtaskGitlabId, setSubtaskGitlabId] = useState([]);

  /**********Assets***************/
  const [parentDetails, setParentDetails] = useState([]); //null
  const [parentDesignAssets, setParentDesignAssets] = useState([]);
  const [parentNonDesignAssets, setParentNonDesignAssets] = useState([]);

  const [taskDesignAssets, setTaskDesignAssets] = useState([]);
  const [taskNonDesignAssets, setTaskNonDesignAssets] = useState([]);
  const [taskDefaultValues, setTaskDefaultValues] = useState({});
  const [project, setProject] = useState({});

  const {getAndSetProjects} = useGetAndSetProjects()

  /*************************
   * Fetch Project Data
   **************************/
  const fetchProject = async (id) => {
    // console.log("SubtaskDetails:0Project:1 ", id);
    if (id) {
      const project = await getProjectDetail(id);
      // console.log("SubtaskDetails:1 Project:1 ", project);
      if (project.ok) {
        const currentProject = (await project.json()).project;
        const projKeys = Object.keys(currentProject);
        // console.log("SubtaskDetails: ProjectData:", currentProject);
        if (
          projKeys !== null &&
          projKeys.hasOwnProperty("length") &&
          projKeys?.length > 0
        ) {
          setProject(currentProject);
        }
      }
    }
    return null;
  };
  // console.log("TaskDetails:Props:", props);
  
     /******************************
      * Fetch Task Details
      ******************************/
      const getParentDetails = (taskId) => {
        getTaskDetail(taskId)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            // console.log("TaskDetails:", data);
            setApps(data.task.project.apps)
            setParentDetails(data.task);
          });
      };

  //Retrieve Subtask data
  const retrieveSubtasks = async (subtaskId) => {
    if (subtaskId) {
      const response = await getSubtask(subtaskId);
      // console.log("SubtaskDetails:3", response);
      if (response.status === 200) {
        const data = await response.json();
        const newData = [...data];
        // console.log("SubtaskDetails:4", newData);

        if (newData && newData.length > 0) {
          // console.log("getTaskData:", newData[0]?.task);

          // Pull Task Data
          getParentDetails(newData[0]?.task._id);

          // Pull Project Data
          fetchProject(newData[0]?.task?.project);

          // Set Subtask
          setSubtask(newData);
        }
      }
    }
  };

  /********************************************
   *  Required:
   * Get gitlab ids to fetch project/subtask/task prototypes
   *********************************************/
  useEffect(() => {
    // console.log("256 the sub of task", subtask);
    const fetchData = async () => {
      // Get tasks Gitlab Id Prototypes
      if (subtask && subtask[0] && subtask[0].task) {
        // Extract the prototypes array from the task inside the subtask
        const prototypesArray = subtask[0].task.prototypes;

        // Map over the prototypes array and get each id
        let gitlabIds = await Promise.all(
          prototypesArray.map(async (val) => {
            // If there's an actual async operation you need to run here, replace the following line.
            return val.id;
          })
        );

        // Set the extracted gitlab IDs
        setTaskGitlabId(gitlabIds);
      }

      // Get subtask gitlabIds for subtask prototypes
      if (subtask && subtask[0] && subtask[0].prototypes) {
        // Assuming that getting each gitlabId is an async operation
        let gitlabIds = await Promise.all(
          subtask[0].prototypes.map(async (val) => {
            // Your async operation here

            return val.id; // replace this with the actual async operation if needed
          })
        );
        // console.log("267 the sub of task", gitlabIds);
        setSubtaskGitlabId(gitlabIds);
      }
      // set projectDesignAssets
      if (subtask?.length > 0 && subtask[0]?.task?._id) {
      }
    };

    fetchData();
  }, [subtask, project]);

  /**
   * Get Subtask on load and on update
   */
  // useEffect(() => {
  //   retrieveSubtasks(params.id);
  // }, [isSubtaskUpdated]);
  useEffect(() => {
    retrieveSubtasks(params.id);
  }, []);

  /** Get Task Assets and Task Options */
  useEffect(() => {
    /********** Get Assets **********/
      // console.log("SUBTASKDETAIL: Change subtask:", subtask);
      setTaskDesignAssets([]);
      setTaskNonDesignAssets([]);
    if (subtask && subtask[0]?.assets?.length > 0) {
      subtask[0].assets.forEach((link) => {
        // console.log("Subtask.link:", link);
        if (link.figma === true) {
          setTaskDesignAssets((prevState) => {
            return [...prevState, link];
          });
        } else {
          setTaskNonDesignAssets((prevState) => {
            return [...prevState, link];
          });
        }
      });
    }
    /*******End Get Assets ******/
    /**
     * Retrieve all tasks associated with Subtask
     */
    if (subtask && subtask.length > 0) {
      // console.log("RETRIEVE TASKS", subtask[0]);
      // Get task on current subtask:
      setTaskDefaultValues(getTaskValues(subtask[0]));

      // Retrieve all Task associated with Project
      retrieveTasks(subtask[0].task.project).then((response) => {
        const allTasks = [
          ...response.current,
          ...response.upcoming,
          ...response.past,
        ];

        const allFormattedTasks = formatDataByName(allTasks, "name", "_id");
        setTaskOptions(allFormattedTasks);
      });

      // Set DefaultValues
      setResponsibleDefaultValues(getResponsibleValues());
      // console.log("SubtaskDetails: In subtask:", subtask);
    }
  }, [subtask]);

  /**
   * Get Task/Subtask Prototypes
   */
  useEffect(() => {
    // Assets
    if (
      parentDetails &&
      parentDetails?.assets &&
      parentDetails.assets?.length > 0
    ) {
      setParentDesignAssets([]);
      setParentNonDesignAssets([]);
      parentDetails.assets.forEach((link) => {
        if (link.figma === true) {
          setParentDesignAssets((prevState) => {
            return [...prevState, link];
          });
        } else {
          setParentNonDesignAssets((prevState) => {
            return [...prevState, link];
          });
        }
      });
    }
  }, [parentDetails]);

  /*******************************
   * Set Controlled Component Defaults
   *******************************/
  useEffect(() => {
    getResponsibleValues();
    // Set Form responsible field on Form
    // if (responsibleDefaultValues?.label) {
    setValue("responsible", responsibleDefaultValues);
    // }
    // console.log("TaskDefaultValues:", taskDefaultValues);
    if (taskDefaultValues?.label) {
      setValue("task", taskDefaultValues);
      // console.log("TaskDefaultValues1:", taskDefaultValues);
    }
  }, [responsibleDefaultValues, taskDefaultValues]);

  // Get the Task on the Subtask
  const getTaskValues = (subtask) => {
    let defaultValues = {
      label: "",
      value: "",
    };
    if (subtask && subtask.task) {
      defaultValues.label = subtask.task?.name;
      defaultValues.value = subtask.task?._id;
    }
    return defaultValues;
  };

  const getResponsibleValues = () => {
    let defaultValues = [];
    // Filter responsible users that meet the conditions
    const filteredUsers = subtask[0]?.responsible?.filter(
      (user) =>
        user._id !== null &&
        user.firstName !== null &&
        user.lastName !== null &&
        user.email !== null
    );
    // console.log("FilteredUsers:", filteredUsers);
    // Add filtered users to defaultValues array
    filteredUsers?.forEach((user) => {
      defaultValues.push({
        value: user._id,
        label: user.email,
      });
    });
    // console.log("DefaultValues:", defaultValues);

    return defaultValues;
  };

  // Default Values:
  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
    setValue,
  } = useForm();

  // Delete Subtask:
  const handleDeleteSubtask = async () => {
    const subtaskId = subtask[0]._id;
    // console.log("subtaskId:", subtaskId);
    // console.log("subtask[0].task._id", subtask[0].task._id);
    if (subtaskId) {


      await deleteSubtask(subtaskId)
        .then(()=>{
            handleCloseDeleteModal();
            getAndSetProjects(props.session)
            navigate(`/tasks/${subtask[0].task._id}`);
        });

      // const deleteTaskRes = await deleteSubtask(subtaskId);
      // /**
      //  * Notify Project
      //  */
      // setIsSubtaskUpdated(!isSubtaskUpdated);

      // handleCloseDeleteModal();
      // navigate(`/tasks/${subtask[0].task._id}`);
    }
  };

  // Handle Details
  const handleSubtaskDelete = () => {
    handleOpenDetailModal("deleteSubtask");
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteSubtask(false);
  };

  // handle Subtask Update form submission
  const handleSubtaskUpdate = async (data) => {
    // console.log("Subtask Update: Data:", data);
    const endDate = data?.endDate && moment(data.endDate).toISOString();
    const startDate = data?.startDate && moment(data.startDate).toISOString();
    // console.log("SubtaskDetail: Creator:", props.session?.userId,);

    // Converting to an array of values
    const responsibleArray =
      data?.responsible && data.responsible.length > 0
        ? data.responsible.map((res) => res.value)
        : [];

    const subtaskData = {
      name: data?.name !== "" ? data.name : null,
      // description: descriptionString,
      description: data?.description,
      creator: props.session?.userId,
      responsible: responsibleArray,
      subtask: data?.subtask?.value ? data.subtask.value : null,
      task: data?.task?.value,
      version: data?.version,
      startDate,
      endDate,
      projectId: project?._id,
      // status: data?.status ? data.status : subtask[0].status,
      status: data?.status ? data.status : subtask.status,
      // status: data?.status ? data.status : taskDetails.status,
      note:
        data?.notes !== ""
          ? {
              message: data.notes,
              updateBy: props.session?.userId,
              lastUpdated: moment().toISOString(),
            }
          : null,
    };

    // Update Subtask Settings
    await updateSubtask(subtaskId, subtaskData)
      .then(()=>{   
        setToggleTaskRefresh(!toggleTaskRefresh);
        handleCloseDetailModal();
        //reset selectedValues
        reset();
        getAndSetProjects(props.session)
        retrieveSubtasks(params.id);
      });
    // const response = await updateSubtask(subtaskId, subtaskData);

    // if (response.status === 201) {
    //   setToggleTaskRefresh(!toggleTaskRefresh);
    //   /**
    //    * Notify Task
    //    */
    //   setIsSubtaskUpdated(!isSubtaskUpdated);
    // }

    // handleCloseDetailModal();
    // // reset selectedValues
    // reset();

    // //Update Task Details when subtask is saved
    // setIsSubtaskUpdated(!isSubtaskUpdated);
  }; //handleSubtaskUpdated

  const handleActionsMenu = () => {
    if (actionsRef.current.classList.contains("show")) {
      actionsRef.current.classList.remove("show");
    } else {
      actionsRef.current.classList.add("show");
    }
  };

  const handleOpenDetailModal = (type) => {
    switch (type) {
      case "subtask":
        setShowUpdateTask(true);
        break;
      case "assets":
        setShowManageAssets(true);
        break;
      // case "subtask":
      //   setShowNewSubtask(true);
      //   break;
      case "deleteSubtask":
        setShowDeleteSubtask(true);
        break;
      default:
    }
    handleActionsMenu();
  };

  const handleCloseDetailModal = () => {
    setShowUpdateTask(false);
    setShowManageAssets(false);
    setShowNewSubtask(false);
  };

  const handleOpenEditAssetModal = (asset) => {
    setShowEditAsset(asset);
  };

  const handleCloseEditAssetModal = () => {
    setShowEditAsset(null);
  };

  const handleOpenDeleteAssetModal = (asset) => {
    // console.log("Delete Button clicked");
    setShowDeleteAssetModal(true);
    setAssetToDelete(asset);
  };

  const handleCloseDeleteAssetModal = () => {
    setShowDeleteAssetModal(false);
  };

  const getSubtaskDetails = () => {
    getSubtask(params.id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("SubtaskDetails:", data);
        setSubtask(data.subtask);
        // setIsSubtaskUpdated(!isSubtaskUpdated);
      });
  };

  function iconColor() {
    if (props.session.theme === "dark") {
      return "white";
    }
    if (props.session.theme === "default" || props.session.theme === "light") {
      return "black";
    }
  }
// console.log('THE PARENT DETAILS', apps)


  const refreshPage = () => {
    getAndSetProjects(props.session)
    setToggleTaskRefresh(!toggleTaskRefresh);
    handleCloseDetailModal();
    reset();
    retrieveSubtasks(params.id);
  }

  return (
    <Wrapper>
      <div className="main-content pt-0">
        <div className="container-fluid">
          <div className="inner-body task-detail">
            {/*modals*/}
            {showUpdateTask === true ? (
              <UpdateSubtask
                subtask={subtask.length > 0 ? subtask[0] : null}
                header="Subtask Settings"
                handleClose={handleCloseDetailModal}
                submitForm={handleSubtaskUpdate}
                handleSubmit={handleSubmit}
                control={control}
                register={register}
                setValue={setValue}
                taskOptions={taskOptions}
              />
            ) : null}
            {showEditAsset !== null ? (
              <EditAsset
                handleClose={handleCloseEditAssetModal}
                asset={showEditAsset}
                details={subtask}
                getDetails={refreshPage}
              />
            ) : null}
            {showManageAssets === true && subtask != null ? (
              <ManageAssets
                dataLevel={"subtask"}
                details={subtask.length > 0 ? subtask[0] : null}
                projectId={project._id}
                taskId={taskDefaultValues.value}
                handleCloseDetailModal={handleCloseDetailModal}
                // getDetails={getSubtaskDetails}
                getDetails={refreshPage}
              />
            ) : null}
            {assetToDelete !== null && showDeleteAssetModal === true ? (
              <DeleteAsset
                message="This action will permanently delete the asset from all projects and tasks. Try Manage Assets to remove it from this subtask only. Are you sure you want to delete this asset?"
                assetToDelete={assetToDelete}
                getDetails={refreshPage}
                close={handleCloseDeleteAssetModal}
              />
            ) : null}

            {showDeleteSubtask === true ? (
              <ConfirmationModal
                message="Are you sure you want to delete this subtask?"
                save={handleDeleteSubtask}
                close={handleCloseDeleteModal}
              />
            ) : null}
            {/* Page Header */}
            {subtask?.length > 0 ? (
              <>
                <div className="page-header">
                  <div>
                    <Link to={`/projects/${project?._id}`}>
                      Back to {project?.name}
                    </Link>
                    <span> &gt; </span>
                    <Link to={`/tasks/${subtask[0]?.task._id}`}>
                      Back to {subtask[0]?.task?.name}
                    </Link>
                    <h2 className="main-content-title tx-24 mg-b-8 mg-t-8">
                      <FontAwesomeIcon
                        className="transform-45"
                        icon="fa-solid fa-clipboard-list"
                      />
                      Subtask:{" "}
                      {subtask &&
                      subtask.length > 0 &&
                      subtask[0]?.name &&
                      subtask[0].version
                        ? <span title={subtask[0]?.name}>{truncateDescription(
                            subtask[0]?.name,
                            titleCharLimit
                          ) +
                          "_v" +
                          subtask[0]?.version
                        }</span>
                        : <span title={subtask[0]?.name}>{truncateDescription(subtask[0]?.name, titleCharLimit)}</span>
                        }
                      <span
                        className="badge"
                        style={{
                          background:
                            subtask[0].status === "active"
                              ? "#0CA789"
                              : subtask[0].status === "new"
                              ? "#ecb529"
                              : "#fd6074",
                          color: "white",
                        }}
                      >
                        {subtask[0].status}
                      </span>
                    </h2>
                    {/* {console.log("Responsible:", subtask[0])} */}
                    {subtask[0].responsible ? (
                      <p>
                        Responsible{" "}
                        {/* <b>
                          {subtask[0].responsible.firstName}{" "}
                          {subtask[0].responsible.lastName}
                        </b> */}
                        <div>
                          {subtask[0].responsible.map((user, index) => (
                            <b key={user._id}>
                              {index > 0 && ", "}
                              {user.firstName} {user.lastName}
                            </b>
                          ))}
                        </div>
                      </p>
                    ) : null}
                    {subtask[0].startDate ? (
                      <p>
                        Started{" "}
                        <b>
                          {moment(subtask[0].startDate)
                            .format("MM-DD-YY")
                            .replace(regex, "")}
                        </b>
                      </p>
                    ) : null}
                    {subtask[0].endDate ? (
                      <p>
                        Target Date{" "}
                        <b>
                          {moment(subtask[0].endDate)
                            .format("MM-DD-YY")
                            .replace(regex, "")}
                        </b>
                      </p>
                    ) : null}
                  </div>
                  <div className="d-flex">
                    {props.session.systemAccess >= 2 ? (
                      <div className="justify-content-center table-controls">
                        <button
                          type="button"
                          className="btn btn-primary my-2 btn-icon-text"
                          onClick={handleActionsMenu}
                        >
                          Manage
                          <FontAwesomeIcon
                            className="mg-l-4"
                            icon="fa-solid fa-chevron-down"
                          />
                        </button>
                        <div
                          ref={actionsRef}
                          className="dropdown-menu animated-menu actions-menu"
                        >
                          <ul>
                            <li>
                              <button
                                onClick={() => {
                                  handleOpenDetailModal("subtask");
                                }}
                              >
                                Subtask Settings
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => {
                                  handleOpenDetailModal("assets");
                                }}
                              >
                                Assets
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => {
                                  handleSubtaskDelete();
                                }}
                              >
                                Delete Subtask
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* End Page Header */}
                {/* Start Container 2*/}
                <div className="col-container d-flex justify-content-between">
                  {/* Col 1 Start - Subtasks/Notes */}
                  <div className="col col-sm-12 col-md-8 col-lg-8 col-xl-8 grid-margin">
                    {/* Row 1 - Project Description Start */}
                    <Descriptions
                      taskDetails={subtask[0]}
                      type="subtask"
                      parentDetails={parentDetails}
                    />
                    {/* Row 1 - Project Description End*/}

                    {/* Row 2-1:2 Start CREATOR */}
                    <div className="row">
                      <div className="card pd-8 flex-grow-1">
                        <div className="card-body pd-0">
                          <h4 className="mg-b-0 pd-8">Creator</h4>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                            className="user-row mg-b-20 pd-x-20"
                          >
                            <div
                              style={{ display: "flex", marginRight: "16px" }}
                              className="align-items-center"
                            >
                              <Avatar user={subtask[0].creator} size="sm" />
                            </div>
                            <div className="col">
                              <div className="node-header--color">
                                {subtask[0].creator?.firstName +
                                  " " +
                                  subtask[0].creator?.lastName}
                              </div>
                              <div>{subtask[0].creator?.role}</div>
                              <div>{subtask[0].creator?.email}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Row 2-1:2 END CREATOR*/}
                    
                     {/* Row 2-1:2 Start NOTES */}
                    <div className="row">
                      <div className="card pd-8 flex-grow-1">
                        <div className="card-body pd-0">
                          <h4 className="mg-b-0 pd-8">Notes</h4>
                          <p className="pd-8">
                            {subtask[0].note
                              ? subtask[0].note.message
                              : "Add notes for this task in Task Settings."}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*  Row 2-1:2 End NOTES */}

                  </div>{" "}
                  {/* Col 1 End - ToDos/Notes */}
                  {/* Col 2 Start - Side Bar */}
                  <div
                    className="col col-sm-12 col-md-4 col-lg-4 col-xl-4 grid-margin"
                    // style={{ border: "1px solid pink" }}
                  >
                    {/* Row 1 - Project Description Start */}
                    {/* <Descriptions
                      taskDetails={subtask[0]}
                      type="subtask"
                      parentDetails={parentDetails}
                    /> */}
                    {/* Row 1 - Project Description End*/}
                    {/* Row 2 - Project Prototypes Start */}
                    {}
                    {subtask?.length > 0 ? (
                      <>
                        <div className="row">
                          <AppWidget
                              label={"Project Apps"}
                              gitlabProjectIds={apps.map(val => val.value)}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* Row 2 - Project Prototypes End*/}
                    {/* Row3 - Assets Start */}
                    <Assets
                      parentName="Task"
                      parentDesignAssets={parentDesignAssets}
                      parenttNonDesignAssets={parentNonDesignAssets}
                      taskDesignAssets={taskDesignAssets}
                      taskNonDesignAssets={taskNonDesignAssets}
                      handleOpenEditAssetModal={handleOpenEditAssetModal}
                      handleOpenDeleteAssetModal={handleOpenDeleteAssetModal}
                      type="subtask"
                    />
                    {/* Row3 - Assets End */}
                  </div>
                  {/* Col 2 End - Side Bar*/}
                </div>{" "}
                {/* End Container 2*/}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps =
  ({ session, users }) =>
  ({
    session,
    users,
    repos,
    hasCiCd,
    gitlabGroupId,
    hasAuthentication,
    filteredData,
    gitlabGroups,
    gitlabSubGroups,
    filterBy,
    creators,
    gitlabSubGroupId,
  }) => ({
    session,
    users,
    repos,
    hasCiCd,
    gitlabGroupId,
    hasAuthentication,
    filteredData,
    gitlabGroups,
    gitlabSubGroups,
    filterBy,
    creators,
    gitlabSubGroupId,
  });

const mapDispatchToProps = (dispatch) => ({
  signin: (user) => dispatch(signin(user)),
  allGitLabRepos: (repos) => dispatch(allGitLabRepos(repos)),
  filteredGitLabRepos: (repos) => dispatch(filteredGitLabRepos(repos)),
  toastMessage: (data) => dispatch(toastMessage(data)),
  getGitlabGroupData: (groups) => dispatch(getGitlabGroupData(groups)),
  getGitlabGroupId: (id) => dispatch(getGitlabGroupIdData(id)),
  getGitlabSubGroupData: (groups) => dispatch(getGitlabSubGroupData(groups)),
  getGitlabSubGroupId: (id) => dispatch(getGitlabSubGroupIdData(id)),
  setFilter: (id) => dispatch(setFilter(id)),
  allCreators: (arr) => dispatch(allCreators(arr)),
  setRepoLoading: (bool) => dispatch(setRepoLoading(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubtaskDetail);
