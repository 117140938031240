import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef, useState} from 'react';
import postCreateRepo from '../../util/dev-ops/postCreateRepo'
import validateToken from '../../util/dev-ops/validateToken'
import Toast from '../global/Toast'
import doesProjectExist from '../../util/dev-ops/doesProjectExist'
import {connect} from "react-redux";
import {signin} from "../../actions/session/signIn";
import {useNavigate} from "react-router-dom";
import getAllRepos from "../../util/dev-ops/getAllRepos";
import GroupControl from "./GroupControl"
import appendRepoData from "../../util/dev-ops/appendRepoData";
import {allGitLabRepos} from "../../actions/allGitlabRepos";
import {filteredGitLabRepos} from "../../actions/filteredData";
import {setRepoLoading} from "../../actions/repoLoading";
import {toastMessage} from "../../actions/toast";
import fetchAndSetUserData from "../../util/dev-ops/fetchAndSetUserData";

function CreateBlankRepo (props) {
    let navigate = useNavigate();
    const inputRef = useRef(null)

    const [importUrl, setImportUrl] = useState(null)

	function returnGroup () {
		if (props.gitlabSubGroupId) {
			if (props.gitlabSubGroupId === '0') {
				if (props.gitlabGroupId) {
					return props.gitlabGroupId
				} else {
					return props.session.gitlabGroup
				}
			} else {
				return props.gitlabSubGroupId
			}
		} else {
			if (props.gitlabGroupId) {
				return props.gitlabGroupId
			} else {
				return props.session.gitlabGroup
			}
		}

	}

   async function handleCreate() {
		props.setRepoLoading(true)
		props.toastMessage(`${inputRef.current.value.split(' ').join('-')} is being added to GitLab. You will be redirected back to the repos list.`)
        if (inputRef.current.value.length !== 0) {
            validateToken(props.session).then(data => {
                props.signin(data.user)
                doesProjectExist(inputRef.current.value, data.token).then(result => {

                    if (result.length === 0) {
                        function captureData () {
                            let postData = {}
                            postData.inputValue = inputRef.current.value.split(' ').join('-')
                            postData.gitlab = props.session.gitLab
                            postData.token = data.token
                            postData.importUrl = importUrl
							// console.log(props.gitlabGroupId, props.gitlabSubGroupId, props.session)
							if (props.gitlabGroupId || props.gitlabSubGroupId) {
								if (!props.gitlabSubGroupId || props.gitlabSubGroupId === '0') {
									postData.groupId = props.gitlabGroupId
								} else {
									postData.groupId = props.gitlabSubGroupId
								}
							} else {
								postData.groupId = props.session.gitlabSubGroup !== '0' ? props.session.gitlabSubGroup : props.session.gitlabGroup
							}

                            return postData
                        }
                        postCreateRepo(captureData())
                            .then(response => response)
                            .then(async (final) => {
                                if (typeof final === 'string') {
                                    props.toastMessage(`There was an error creating your repo.  Please try again.  ERROR: ${final}`);
                                    props.setRepoLoading(false)
                                } else {
                                    navigate("/dev-ops/gitlab-repos");

                                    // Make sure to define what 'data' is if it's not in scope.
                                    let res = await fetchAndSetUserData(data, 'delete');
                                    let userData = await res.json();

                                    const userRepos = userData.repos.filter((repo) => repo.creator_id === parseInt(props.session.gitLab[0].id));

                                    await allGitLabRepos(userData.repos);
                                    await filteredGitLabRepos(userRepos);
                                }
                            });

                    } else {
						props.toastMessage('Repo Name Already Exists.  Please Try Again.')
                        inputRef.current.focus()
						props.setRepoLoading(false)
                    }
                })
            })
        } else {
			props.toastMessage('You Need To Enter A Name For Your Project.')
			props.setRepoLoading(false)

        }


    }

    return (
        <div className="card custom-card pd-0">
            <div className="card-body">
                <h5 className="mg-b-8">Create a blank / boilerplate repo in Gitlab</h5>
                <p className="card-text">Create and start either a blank project or one with with a specific code base in GitLab.</p>
                <label htmlFor="projectName" className={"tx-12 mg-t-8"}>Repo Name</label>
                <input ref={inputRef} id="projectName" className="form-control mg-b-20 card-input" type="text" placeholder="blank-repo"/>
                <div className={'group-controls'}>
                    <label htmlFor="subgroup"
 style={{position: 'absolute', fontSize: '12px', top: '-13px'}}>Select a codebase:</label>
                    <select onChange={(event)=> {setImportUrl(event.target.value)}}style={{display: 'inline-flex', height: '40px'}} name="codebase" className="form-control select mg-r-8 card-input" required="" >
                        <option key={'0'} value={'0'} >Blank - Empty Project</option>
                        <option key={'1'} value={'https://gitlab.com/pearson-ct/react-template.git'} >React JS</option>
                        <option key={'2'} value={'https://gitlab.com/pearson-ct/html5-template.git'}>HTML 5 Boilerplate</option>
						<option key={'3'} value={'https://gitlab.com/pearson-ct/vue-template.git'}>Vue JS</option>
                        <option key={'5'} value={'https://gitlab.com/pearson-ct/express-template.git'}>Express Server</option>
						<option key={'6'} value={'https://gitlab.com/pearson-ct/astro-template.git'}>Astro Starter</option>
						<option key={'7'} value={'https://gitlab.com/pearson-ct/eleventy-template.git'}>NEW - Eleventy Starter</option>
						<option key={'8'} value={'https://gitlab.com/pearson-ct/storybook-react-template.git'}>NEW - Storybook / React</option>
                    </select>
                </div>
				{
					props.repoLoading ?      <button disabled onClick={handleCreate} className="btn btn-secondary ripple ">
						<FontAwesomeIcon icon={`fa-brands fa-solid fa-code-commit`}/> Loading...
					</button> :      <button onClick={handleCreate} className="btn btn-secondary ripple ">
						<FontAwesomeIcon icon={`fa-brands fa-solid fa-code-commit`}/> Create
					</button>
				}
            </div>
        </div>
    )
}
const mapStateToProps = ({session, repos, gitlabGroupId,  gitlabSubGroupId, gitlabGroups, gitlabSubGroups, gitlabRenderRepos, repoLoading}) => ({
    session, repos, gitlabGroupId,  gitlabSubGroupId, gitlabGroups, gitlabSubGroups, gitlabRenderRepos, repoLoading
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user)),
	allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
	filteredGitLabRepos: repos => dispatch(filteredGitLabRepos(repos)),
	setRepoLoading: bool => dispatch(setRepoLoading(bool)),
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateBlankRepo);
