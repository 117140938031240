import React from 'react';
import './capacityIcon.css';  // CSS for the capacity icon

const CapacityIcon = ({ capacity, startColor, endColor }) => {
    console.log('capacity:', capacity);
    const gradientId = `capacityGradient-${startColor}-${endColor}`;  // Unique id for each gradient

    return (
        <div className="capacity-icon">
            <svg viewBox="0 0 320 24" className="capacity-svg">
                <defs>
                    <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor={startColor} />
                        <stop offset="100%" stopColor={endColor} />
                    </linearGradient>
                </defs>
                <rect
                    className="capacity-background"
                    width="320"
                    height="24"
                    fill="#ccc"  // Gray background for empty part
                />
                <rect
                    className="capacity-fill"
                    width={`${capacity}%`}  // Dynamic fill width based on capacity
                    height="24"  // Constant height
                    fill={`url(#${gradientId})`}  // Reference the gradient using its unique ID
                />
            </svg>
        </div>
    );
};

export default CapacityIcon;
