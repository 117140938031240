import React, {useEffect, useState, useRef} from 'react'
import { createTemplate } from "../../util/mailgun/createTemplate";
import { defaultTemplate } from "../../assets/data/mailgun-default"

//for template preview 
let typingTimer;

function CreateTemplate (props) {

	const [name, setName] = useState('')
	const [description, setDescription] = useState('')
	const [template, setTemplate] = useState(defaultTemplate)
	const [comment, setComment] = useState('')

	const [showTemplateEditor, setShowTemplateEditor] = useState(false)

	const htmlSideRef = useRef(null)
	const viewSideRef = useRef(null)

	useEffect(()=>{
		document.querySelector('body').style.overflowY = 'hidden'
		return ()=>{
			document.querySelector('body').style.overflowY = 'scroll'
		}
	},[])

	useEffect(()=>{
		if(showTemplateEditor === true){
			renderPreview()
		}
	},[showTemplateEditor])

	const renderPreview = () => {		
		let html = htmlSideRef.current.textContent;
		viewSideRef.current.srcdoc = html
	}

	const saveTemplate = (e) => {
		e.preventDefault()
		setTemplate(htmlSideRef.current.textContent)
		setShowTemplateEditor(false)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		let mailData = {
			name: name,
			description: description,
			template: template,
			comment: comment ? comment : null
		}
		createTemplate(mailData).
			then((response)=>{
				props.getAllTemplates()
			})
		props.handleCloseModal()
	}

    return (
		<div className="main-content pt-0">
		<div className="container-fluid">
			<div className={`modal d-block create-mailgun-template`}>
				<div className="modal-dialog" style={{width:"80vw", height: "80vh"}} role="dialog">
				<div className="modal-content modal-content-demo shadow" style={{ height:"100%", overflow:'hidden'}}>
					<div className="modal-header">
					<h6 className="modal-title">Create new template</h6>
					</div>
					
					
					{
						showTemplateEditor ? <>
							<div
								className="detail-modal-body"
							>
								<div className='main-editor'>
									<div  
										ref={htmlSideRef}
										className="htmlSide"
										contentEditable
										onKeyDown={(e)=>{
											e.stopPropagation();
											clearTimeout(typingTimer);
										}}
										onKeyUp={(e)=>{
											e.stopPropagation();
											clearTimeout(typingTimer);
											typingTimer = setTimeout(renderPreview, 1000);
										}}
										>
										{template}
									</div>
									<iframe ref={viewSideRef}className="viewSide" >
									</iframe>
								</div>
							</div>
							<div className="modal-footer">
								<button 
									type="button"
									className='btn btn-primary'
									onClick={saveTemplate}
									>
									Save
								</button>
							</div>
						</> : <>
						
						<div
							className="detail-modal-body"
							style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "100px" }}
							>
							<form id='emailform' onSubmit={handleSubmit}>
								<div>
									<label style={{marginTop:'16px'}}>Template name</label>
									<input
										onChange={(e)=>{setName(e.target.value)}}
										type="text"
										value={name}
										required
										className={`form-control card-input`}
									/>
								</div>

								<div>
									<label style={{marginTop:'16px'}}>Description</label>
									<input
										onChange={(e)=>{setDescription(e.target.value)}}
										type="text"
										value={description}
										required
										className={`form-control card-input`}
									/>
								</div>

								<div>
									<label style={{marginTop:'16px'}}>Template</label>
									<textarea
									 	style={{minHeight:'200px'}}
										onChange={(e)=>{setTemplate(e.target.value)}}
										value={template}
										required
										className={`form-control card-input`}
									/>
									<button 
										className='btn btn-secondary'
										style={{marginTop:'8px'}}
										onClick={()=>{setShowTemplateEditor(true)}}
										>
											Edit & Preview
										</button>
								</div>

								<div>
									<label style={{marginTop:'16px'}}>Comment (optional)</label>
									<input
										onChange={(e)=>{setComment(e.target.value)}}
										type="text"
										value={comment}
										className={`form-control card-input`}
									/>
								</div>
							</form>

							</div>

							<div className="modal-footer">
							<button className="btn ripple btn-primary" type="submit" form="emailform">
								Save
							</button>
							<button
								className="btn ripple btn-secondary muted"
								type="button"
								onClick={props.handleCloseModal}
							>
								Cancel
							</button>
							</div>
							
						</>
					}
					

				</div>
				</div>
			</div>
		</div>
		</div>
    )
}

export default CreateTemplate