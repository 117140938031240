import React, { useEffect, useState } from "react";
import {DashAuthorPicture, DashNewsPicture} from "../../global/gitlab/SkeletonText";
import FetchAuthorImage from "../../news/FetchAuthorImage";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import useFetchImage from "../../../util/useFetchImage";

function ExecutiveNews() {
    const [newsData, setNewsData] = useState([]);
    const [image, setImage] = useState(null);
    const [imageURLToFetch, setImageURLToFetch] = useState(null);
    const [loading, setLoading] = useState(true);
    const fetchedImage = useFetchImage(imageURLToFetch);

    useEffect(() => {
        // console.log("newsData:", newsData)
        setImageURLToFetch(newsData?.openGraphSummary?.image?.mediaUrl);
    }, [newsData]);

    useEffect(() => {
        setImage(fetchedImage);
    }, [fetchedImage, imageURLToFetch]);

    useEffect(() => {
        let isMounted = true; // flag to track component's mount status

        const fetchData = async () => {
            try {
                const response = await fetch(`${AUTH_BASE_URL}news/executive`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error(`An error has occurred: ${response.status}`);
                }

                const data = await response.json();

                if (isMounted) {
                    setNewsData(data.documents[0].item);
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    console.error("There was a problem with the fetch operation:", error);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // cleanup function to indicate component has unmounted
        };
    }, []);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    };

    return (
        <div>

            {
                newsData && newsData.length !== 0 ? (
                    <div className="mg-b-20">
                        <div className="card card-aside custom-card pd-0 shadow-2">
                            {image ? <DashNewsPicture loading={loading} data={newsData} image={image} /> : null}

                            <div className="">
                                <a href={newsData.url} target="_blank">
                                    <span className="main-content-label tx-16">{newsData.openGraphSummary.title}</span>
                                </a>

                                <div className="mt-3">{newsData.openGraphSummary.description}</div>

                                <div className="d-flex align-items-center pt-3 mt-auto">
                                    <div className="main-img-user avatar-sm me-3">
                                        <a href={`https://hub.pearson.com/userprofile/${newsData.properties.author.id}/${newsData.properties.author.urlName}`} target="_blank">
                                            <FetchAuthorImage url={`https://hub.pearson.com/api/users/userphotobyid?userId=${newsData.properties.author.id}`} />
                                        </a>
                                    </div>

                                    <div>
                                        <a href={`https://hub.pearson.com/userprofile/${newsData.properties.author.id}/${newsData.properties.author.urlName}`} target="_blank">
                                            {newsData.properties.author.displayName}
                                        </a>
                                        <small className="d-block">{formatDate(newsData.properties.articleDate)}</small>
                                    </div>

                                    <div className="ms-auto text-muted">
                                        <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3">
                                            <i className="far fa-heart me-1" />
                                        </a>
                                        <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3">
                                            <i className="far fa-thumbs-up" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}
export default ExecutiveNews;