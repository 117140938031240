import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DashboardLink = () => {
    return (
        <Link className='nav-link' to={'/dashboard'}>
            <span className="shape1"/>
            <span className="shape2"/>
            <FontAwesomeIcon className='sidemenu-icon' icon='house'/>
            <span className="sidemenu-label">Dashboard</span>
        </Link>
    );
};

export default DashboardLink;
