import postCreateRepo from './postCreateRepo';
import validateToken from './validateToken';
import doesProjectExist from './doesProjectExist';
import { createDigitalOceanApp } from './createDigitalOceanApp';
import {detectFramework} from "../detectFramework";
const gitlabToken = process.env.REACT_APP_GL_TOKEN; // Ensure you have the GitLab token in your environment variables

export async function handleCreateRepo(inputValue, user, signin, toastMessage, digitalOceanToken, navigate, importUrl) {
    if (inputValue.length !== 0) {
        try {
            const data = await validateToken(user);
            signin(data.user);

            const result = await doesProjectExist(inputValue, data.token);

            if (result.length === 0) {
                const postData = {
                    inputValue,
                    group: user.gitlabGroup,
                    gitlab: user.gitLab,
                    token: data.token,
                    importUrl: importUrl
                };

                const response = await postCreateRepo(postData);

                if (response.error) {
                    toastMessage(response.error);
                } else {
                    toastMessage('Repository created successfully.');
                    setTimeout(() => {
                        toastMessage('Sending files to digital ocean, we will redirect you shortly.');
                    },4000)
                    // Create DigitalOcean App
                    try {
                        let framework = await detectFramework(response, gitlabToken);
                        const digitalOceanResponse = await createDigitalOceanApp(response, digitalOceanToken, inputValue, framework);
                        toastMessage(`App created successfully on DigitalOcean: ${inputValue}`);
                        navigate('/hosting/apps');
                    } catch (doError) {
                        toastMessage(`Failed to create app on DigitalOcean: ${doError.message}`);
                    }
                }
            } else {
                toastMessage('Repo Name Already Exists. Please Try Again.');
            }
        } catch (error) {
            console.error(error);
            toastMessage(error.message);
        }
    } else {
        toastMessage('You Need To Enter A Name For Your Project.');
    }
}
