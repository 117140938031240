import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { useAuthFetch } from "../../hooks/sso/useAuthFetch";
import Select from "react-select";
import { useDebounce } from "../../hooks/useDebounce";
import {
  filterAndFormatOptions,
  styles,
  themes,
  filter,
  lowerCaseEmail,
} from "./helpers";

/**
 * Description: Allows users to search and select pearson
 * employees and send an inviation to the Platform, via email
 * @param {*}  name - react-select name
 * @param {*}  control - react-hook-form property
 * @param {*}  placehoderText - input placeholder text
 * @param {*}  msGraphName - MSGraph graphConfig prop name in util/sso/authConfig
 *  current Object names "graphMe" and "graphMessages."
 * @param {{Optional} selectFields - Fields to be returned in MSGraph response
 * @param {Boolean} pullEndeavorUsers - Determine if endeavor or nonEndeavor
 * users should be pulled from MSGraph
 * @param {Boolean} isMulti = Determing if the field allows mult or single search.
 * @returns - React-Select component
 */
const GraphSearchSelect = ({
  name,
  control,
  placeholderText,
  msGraphName,
  selectFields,
  userEmails,
  onChange,
  pullEndeavorUsers,
  isMulti,
  // defaultValues,
  // setValue, 
  // reset
}) => {
  const [inputValue, setInputValue] = useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 500);

  const peopleRequest = {
    msGraphName,
    searchTerm: debouncedInputValue,
    fields: selectFields,
  };
  // Make MS Graph Request for data
  const [userContacts] = useAuthFetch([peopleRequest]);

  // console.log("MSGRAPH: USERCONTACTS:", userContacts);
  const [formattedContacts, setFormattedContacts] = useState();
  const [options, setOptions] = useState({
    isLoading: false,
    data: [],
  });

  // Set the Option properties on formattedContacts
  const setOptionsIsLoading = (name, value) => {
    setFormattedContacts({ ...formattedContacts, [name]: value });
  };

  const setOptionsDataArray = (newObj) => {
    setOptions((prevState) => {
      return { ...prevState, data: [...newObj] };
    });
  };

  // Set inputValue with entered text
  function testSetInput(data) {
    if (data) {
      // console.log("GraphicSearchSelect: New Data:", "*" + data + "*");
      setInputValue(data);
    }
    // Clear the previous options, when user clicks outside input element
    else {
      // console.log("GraphicSearchSelect: New Data:", "*" + data + "*");
      setOptionsDataArray([]);
    }
  }

  useEffect(() => {
    // console.log("GraphicSearchSelect: inputValue:", inputValue);
    // console.log("GraphicSearchSelect: New  debouncedInputValue:", debouncedInputValue);

    // When debounceInputValue has been identified, set isLoading to true
    setOptionsIsLoading("isLoading", true);
  }, [inputValue, debouncedInputValue]);

  useEffect(() => {
    // console.log("GraphSearchSelect: dInputValue", debouncedInputValue, "; userContacts:", userContacts);

    if (debouncedInputValue && userContacts?.length > 0) {
      // console.log("GSSelect: UserContacts:", userContacts, "7PullEndeavorUsers:", pullEndeavorUsers);
      setFormattedContacts(
        filterAndFormatOptions(
          lowerCaseEmail(userContacts),
          userEmails,
          pullEndeavorUsers
        )
      );
      // console.log("GSSelect: FormattedContacts1:", formattedContacts);
    }
  }, [debouncedInputValue, userContacts]);

  useEffect(() => {
    if (formattedContacts?.length > 0) {
      setOptionsDataArray([...formattedContacts]);
      // set isLoading to false when options received
      setOptionsIsLoading("isLoading", false);
    }
  }, [formattedContacts]);

  useEffect(() => {
    // console.log("Options Data:", options);
  }, [options.data]);

  // useEffect(() => {
    // console.log("NAME:", name, "DEFAULTValues:", defaultValues);
    // setValue(name, defaultValues)
    // reset();
  // }, []);

  return (
    <div className="react-select">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            isClearable
            isMulti={isMulti !== false}
            placeholder={placeholderText}
            // onInputChange={testSetInput}
            onInputChange={(inputValue) => {
              // Define a regular expression pattern to allow only specific characters (excluding periods)
              const allowedCharactersRegex = /^[A-Za-z0-9\s]*$/;
          
              // Remove periods from the input value
              const filteredValue = inputValue.replace(/\./g, '');
          
              // Filter the input value to allow only allowed characters
              const filteredInput = filteredValue.replace(
                new RegExp(`[^${allowedCharactersRegex.source}]`, "g"),
                ""
              );
          
              // Update the input value with the filtered value
              setInputValue(filteredInput);
            }}          
            onChange={onChange? onChange: null}
            styles={styles}
            theme={themes}
            options={options.data}
            // defaultValue={defaultValues}
            // setValue={setValue}
          />
        )}
      />
    </div>
  );
};

export default GraphSearchSelect;
