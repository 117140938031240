import React, { useEffect, useState } from 'react';
import Wrapper from "../global/Wrapper";
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import FetchImage from "./FetchImage";
import {getAccessTokenFromSessionStorage} from "../../util/helpers/getSsoAccessToken";
import {DashAuthorPicture} from "../global/gitlab/SkeletonText";
import FetchAuthorImage from "./FetchAuthorImage";


function News() {
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);
    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getAccessTokenFromSessionStorage();
            setToken(fetchedToken);
        };

        fetchToken();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${AUTH_BASE_URL}news`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (token){
                    console.log('DUDE THERES A TOKEN', token)
                    const responseTwo = await fetch(`${AUTH_BASE_URL}resources/tools`, {
                        method: 'POST',
                        body: JSON.stringify({token:token}),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!responseTwo.ok) {
                        throw new Error(`An error has occurred: ${response.status}`);
                    }
                    const dataTwo = await responseTwo.json();

                    console.log("DUDE THIS IS THE DATA", dataTwo)
                }


                if (!response.ok) {
                    throw new Error(`An error has occurred: ${response.status}`);
                }

                const data = await response.json();

                setNewsData(data.documents);
                setLoading(false);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        fetchData();
    }, [token]);

    const getGradientClass = (category) => {
        console.log('category', category)
        const mapping = {
            'Video': 'gradient-common gradient-video',
            'Event': 'gradient-common gradient-event',
            'News': 'gradient-common gradient-news',
        };

        return mapping[category] || 'gradient-common default-gradient';
    };


    // Helper function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    };


    console.log('NEWS DATA', newsData)
    return (
        <Wrapper>
            <div className="main-content pt-0">
                <div className="container-fluid">
                    <div className="inner-body users">
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">News & Events</h2>
                            </div>
                        </div>
                        {/* End Page Header */}
                        {/* Row */}
                        <div className="row row-sm">
                            {loading ? '' :
                                newsData?.map((item, index) => {
                                        console.log('lets go', item.item.openGraphSummary.category)
                                    if (item.item.properties && item.item.properties.postImage && item.item.properties.postImage.id) {
                                        const formattedDate = formatDate(item.item.properties.articleDate); // Formatting date here
                                        return (
                                            <div key={index} className="col-xl-3 col-md-4 mg-b-20 ">
                                                <div className="card custom-card pd-0 ht-100p">
                                                    <div>
                                                        <FetchImage url={`https://hub.pearson.com/media/getimagebycropalias/${item.item.properties.postImage.id}/large%20square`} />
                                                        <span className={getGradientClass(item.item.openGraphSummary.category)}>{item.item.openGraphSummary.category}</span>
                                                    </div>

                                                    <div className="card-body bd shadow-2">
                                                        <h4 className="main-content-label mb-3 tx-18" style={{lineHeight: '1.2'}}><a href={item.item.url} target={"_blank"} className='card-title'>{item.item.openGraphSummary.title}</a></h4>

                                                        <FetchAuthorImage url={`https://hub.pearson.com/api/users/userphotobyid?userId=${item.item.properties.author.id}`} />

                                                        <strong><a className="" href={`https://hub.pearson.com/userprofile/${item.item.properties.author.id}/${item.item.properties.author.urlName}`} target={"_blank"}>{item.item.properties.author.displayName}</a> - <span className={"text-muted"}>{formattedDate}</span></strong>{/* Render the formatted date here */}
                                                        <p className="card-text">{item.item.openGraphSummary.description}</p>
                                                        {
                                                            item.item.nodeTypeAlias === 'News' ?
                                                                <a className="btn ripple btn-outline-primary bd d-flex align-items-center justify-content-center" target="_blank" href={item.item.url}>
                                                                    Read More
                                                                    <i className="fe fe-arrow-right ml-1" />
                                                                </a> : item.item.nodeTypeAlias === 'Event' ?
                                                                    <a className="btn ripple btn-outline-primary bd d-flex align-items-center justify-content-center" target="_blank" href={item.item.url}>

                                                                        Sign up for the Event
                                                                        <i className="fe fe-arrow-right ml-1" />
                                                                    </a> :        <a className="btn ripple btn-outline-secondary bd d-flex align-items-center justify-content-center" target="_blank" href={item.item.url}>

                                                                        Watch Video
                                                                        <i className="fe fe-arrow-right ml-1" />
                                                                    </a>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return null
                                    }

                                })
                            }
                        </div>
                        {/* End Row */}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default News;
