import React, {useEffect} from 'react'
import {connect} from "react-redux";
import {signin} from "../../actions/session/signIn";
import {allUserData} from "../../actions/allUsers";
import allUsers from "../../util/users/allusers";
import {setOriginalUser} from "../../actions/ghostUser";
import {useNavigate} from "react-router-dom";

function GhostMode (props) {
	let navigate = useNavigate();
	useEffect(() => {
		if (!props.ghostUser) {
			props.setOriginalUser(props.session)
			async function fetchData() {
				await allUsers().then(async (data) => {
					props.allUserData(await data);
				});
			}
			fetchData();
		}
	}, [props.ghostUser]);



	function changeUser(event) {
		let user = props.users[event.target.value];
		delete user.password
		user.ghost = true
		// signInGhost(user).then(data => {
		// 	console.log(data)
		// })
		props.signin(props.users[event.target.value]).then(data => {
			window.location.href = '/dashboard'

		})
	}
    return (
        <div className="card custom-card">
            <div className="card-body">
                <div className="card-item">
                    <div className="card-item-title  mb-2">
                        <label className="main-content-label tx-14 font-weight-bold mb-1">Ghost mode</label>
                        <span className="d-block tx-13 mb-0">Choose a user in the system you want to be</span>
                    </div>
                    <div className="card-item-body">
                        <div className="card-item-stat d-flex justify-items-center align-items-center">
							<select onChange={changeUser} style={{display: 'inline-flex', height: '40px'}} name="group" className="form-control select mg-r-8" required=""  >
								{
									props.users ? props.users.map((item,index) => {
										if (item) {
											return <option key={index} value={index}> {item.firstName} {item.lastName}</option>
										}

									}) : <></>
								}

							</select>
							<button
								type="button"
								className="btn btn-secondary my-2 btn-icon-text"

							>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
const mapStateToProps = ({session, users, ghostUser}) => ({
    session, users, ghostUser
});

const mapDispatchToProps = (dispatch) => ({
	allUserData: (data) => dispatch(allUserData(data)),
	setOriginalUser: (data) => dispatch(setOriginalUser(data)),
	signin: (user) => dispatch(signin(user)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GhostMode);
