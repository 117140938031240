import React from 'react';

/**
 * Higher Order Component used 
 * to display JSX Elements only
 * @param {*} param0 
 * @returns 
 */
const EmployeeTaskRenderer = ({ children }) => {
    // You can add any additional logic or styling here
    return <>{children}</>;
  };

export default EmployeeTaskRenderer;