// KanbanCardFront.jsx

import React, { useEffect, useState } from 'react';
import Avatar from '../../../global/Avatar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const KanbanCardFront = ({
                             editableFields,
                             columns,
                             user,
                             handleEdit,
                             capacity,
                             cloudSave,
                             setCloudSave,
                             task,
                             handleFieldChange,
                             handleDateChange,
                         }) => {
    const [fadeIn, setFadeIn] = useState(false);

    // Get column IDs for project, description, and dates
    const projectColumnId = Object.keys(columns).find(
        columnId => columns[columnId].title.toLowerCase() === "project"
    );
    const descriptionColumnId = Object.keys(columns).find(
        columnId => columns[columnId].title.toLowerCase() === "description"
    );
    const dueDateColumnId = Object.keys(columns).find(columnId =>
        columns[columnId].title.toLowerCase().includes("end")
    );
    const startDateColumnId = Object.keys(columns).find(columnId =>
        columns[columnId].title.toLowerCase().includes("start")
    );

    const project = editableFields[projectColumnId] || "No title provided";
    const description = editableFields[descriptionColumnId] || "No description available";
    const endDate = dueDateColumnId ? editableFields[dueDateColumnId] : null;
    const startDate = startDateColumnId ? editableFields[startDateColumnId] : null;

    useEffect(() => {
        setFadeIn(true);
    }, []);

    const handleDateChangeLocal = (date, dateType) => {
        if (dateType && (dateType === 'start' || dateType === 'end')) {
            const columnId = dateType === 'start' ? startDateColumnId : dueDateColumnId;
            const dateValue = date ? date.toISOString().split('T')[0] : null;

            // Update the editableFields state
            handleFieldChange(columnId, dateValue);

            // Create an updatedFields object with the new date
            const updatedFields = {
                ...editableFields,
                [columnId]: dateValue,
            };

            // Ensure both start and end dates are included
            if (startDateColumnId && updatedFields[startDateColumnId] === undefined) {
                updatedFields[startDateColumnId] = startDate || '';
            }
            if (dueDateColumnId && updatedFields[dueDateColumnId] === undefined) {
                updatedFields[dueDateColumnId] = endDate || '';
            }

            // Convert updatedFields to an array for the API call
            const fieldsArray = Object.keys(updatedFields)
                .filter(
                    colId =>
                        updatedFields[colId] !== undefined &&
                        updatedFields[colId] !== null
                )
                .map(colId => ({
                    columnId: parseInt(colId),
                    value: updatedFields[colId],
                }));

            // Trigger the save to the backend
            handleDateChange(fieldsArray);
        }
    };

    // Helper function to determine the color based on capacity
    const getCapacityColor = capacity => {
        if (capacity > 75) return '#4caf50'; // Green
        if (capacity > 50) return '#ffeb3b'; // Yellow
        if (capacity > 25) return '#ff9800'; // Orange
        return '#f44336'; // Red
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div
                className="kanban-card-front"
                style={{
                    opacity: fadeIn ? 1 : 0,
                    transition: 'opacity 0.5s ease-in',
                }}
            >
                <div className="d-flex align-items-center justify-content-center mg-b-10">
                    <h3 className="kanban-card-title mg-b-0">{project}</h3>
                    <div>
                        <button onClick={handleEdit} style={{ margin: '0' }}>
                            {/* Edit icon */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#333"
                            >
                                <path
                                    d="M280-280h80v-400h-80v400Zm320-80h80v-320h-80v320ZM440-480h80v-200h-80v200ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
                                />
                            </svg>
                        </button>
                    </div>

                    {cloudSave === task?.id && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="#00b7fe"
                            style={{
                                opacity: cloudSave ? 1 : 0,
                                transition: 'opacity 0.5s ease-in-out',
                                marginLeft: '8px',
                            }}
                        >
                            <path
                                d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm0-80h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41Zm220-240Z"
                            />
                        </svg>
                    )}
                </div>

                <p className="kanban-card-description">{description}</p>

                {user && (
                    <span className="kanban-card-assignee">
                        <Avatar user={user} />
                    </span>
                )}

                <div className="capacity-display mg-b-15">
                    <span>Weight:</span>
                    <div className="capacity-bar">
                        <div
                            className="capacity-fill"
                            style={{
                                width: `${capacity}%`,
                                backgroundColor: getCapacityColor(capacity),
                            }}
                        ></div>
                    </div>
                    <span>{capacity}%</span>
                </div>

                <DatePicker
                    label="Target Due Date"
                    value={endDate ? new Date(endDate) : null}
                    onChange={date => handleDateChangeLocal(date, 'end')}
                    renderInput={params => <TextField {...params} />}
                />
            </div>
        </LocalizationProvider>
    );
};

export default KanbanCardFront;
