import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const updateFirstTime = async (user) => {
        return fetch(`${AUTH_BASE_URL}settings/update/firstTime`, {
            method: "POST",
            body: JSON.stringify({data:user}),
            credentials: 'include',
            headers: {
              "Content-Type": "application/json"
            }
        })

}
