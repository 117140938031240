import Wrapper from "../../global/Wrapper";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NewTest from "./NewTest";
import Toast from "../../global/Toast";
import {useNavigate} from "react-router-dom";
import Table from "./Table";
import CollectUserData from "./CollectUserData";
import Introduction from "./IntroductionVideo";
import IntroductionText from "./IntroductionText";
import PreQuestionData from "./PreQuestionData";
import PostQuestionData from "./PostQuestionData";
import allTests from "../../../util/usertesting/allTests";
import {allTestData} from "../../../actions/allUserTests";
import {connect} from "react-redux";
import EditTest from "./EditTest";
import ImportUsersModal from "../ManageTestUsers/ImportUsersModal";

function Online (props) {
	const [data, setData] = useState(null)
	const [preData, setPreData] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [editData, setEditData] = useState(false)
	const [showCollectModal, setShowCollectModal] = useState(false)
	const [showPreQuestionModal, setPreQuestionModal] = useState(false)
	const [showPostQuestionModal, setPostQuestionModal] = useState(false)
	const [showIntroductionModal, setShowIntroductionModal] = useState(false)
	const [showIntroductionTextModal, setShowIntroductionTextModal] = useState(false)

	const [testId, setTestId] = useState(false)
	const [showImportUsersModal, setShowImportUsersModal] = useState(false)

	const navigate = useNavigate();

	const handleOpenNewModal = (path) => {
		navigate('/usertesting/online/new')
		setShowModal(true)
	}
	const handleEditModal = (data) => {
		setEditData(data)
		navigate('/usertesting/online/edit')
		setShowEditModal(true)
	}
	const handleIntroductionModal = (data) => {
		setData(data)
		navigate('/usertesting/online/introduction/video')
		setShowIntroductionModal(true)
	}
	const handleIntroductionTextModal = (data) => {
		console.log('intro text fired', data)
		setData(data)
		navigate('/usertesting/online/introduction/text')
		setShowIntroductionTextModal(true)
	}
	const handleOpenCollectModal = (data) => {
		setData(data)
		navigate('/usertesting/online/collect')
		setShowCollectModal(true)
	}
	const handlePreQuestionModal = (data) => {
		setPreData(data)
		navigate('/usertesting/online/pre-data')
		setPreQuestionModal(true)
	}
	const handlePostQuestionModal = (data) => {
		setPreData(data)
		navigate('/usertesting/online/post-data')
		setPostQuestionModal(true)
	}
	const handleImportUsersModal = (id) => {
		navigate('/usertesting/online/import-test-users')
		setShowImportUsersModal(true)
		setTestId(id)
	}

	const handleCloseNewModal = () => {
		setShowModal(false)
		setShowEditModal(false)
		setShowCollectModal(false)
		setPreQuestionModal(false)
		setPostQuestionModal(false)
		setShowIntroductionModal(false)
		setShowIntroductionTextModal(false)
		setShowImportUsersModal(false)
		setTestId(null)
		navigate('/usertesting/online')

	}

	useEffect(()=> {
		if (window.location.pathname === '/usertesting/online/new') {
			setShowModal(true)
		} else if (window.location.pathname === '/usertesting/online/edit') {
			setShowEditModal(true)
		} else if (window.location.pathname === '/usertesting/online/collect'){
			setShowCollectModal(true)
		} else if (window.location.pathname === '/usertesting/online/pre-data'){
			setPreQuestionModal(true)
		} else if (window.location.pathname === '/usertesting/online/post-data'){
			setPostQuestionModal(true)
		} else if (window.location.pathname === '/usertesting/online/introduction/video'){
			setShowIntroductionModal(true)
		} else if (window.location.pathname === '/usertesting/online/introduction/text'){
			setShowIntroductionTextModal(true)
		} else {
			setShowModal(false)
		}
	},[])

	useEffect(()=> {
		if(!props.allTests || props.allTests.length === 0) {
			allTests().then(data => {
				props.allTestData(data)
			})
		}
	},[])
	return (
		<Wrapper>
			<div className="main-content pt-0">
				<div className="container-fluid">
					<div className="inner-body users">
						{/* Page Header */}
						{showModal === true ? <NewTest handleCloseNewModal={handleCloseNewModal} /> : null}
						{showEditModal === true ? <EditTest data={editData} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showCollectModal === true ? <CollectUserData data={data} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showPreQuestionModal === true ? <PreQuestionData data={preData} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showPostQuestionModal === true ? <PostQuestionData data={preData} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showIntroductionModal === true ? <Introduction data={data} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showIntroductionTextModal === true ? <IntroductionText data={data} handleCloseNewModal={handleCloseNewModal} /> : null}
						{showImportUsersModal === true ? <ImportUsersModal handleCloseNewModal={handleCloseNewModal} testId={testId}/> : null}
						<div className="page-header">
							<div>
								<h2 className="main-content-title tx-24 mg-b-5">User Testing - Self Lead</h2>
							</div>
							<div className="d-flex">
								<div className="justify-content-center table-controls">
									<button onClick={handleOpenNewModal} type="button" className="btn btn-primary my-2 btn-icon-text">
										<FontAwesomeIcon className='mg-r-4' icon={`fa-solid fa-plus`} /> New Test
									</button>
								</div>
							</div>
						</div>
						{/* End Page Header */}
						{/*Row*/}
						<div className="row row-sm">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
								<div className="table-responsive" style={{minHeight: '75vh'}}>
									<Table  handleIntroductionTextModal={handleIntroductionTextModal} handleIntroductionModal={handleIntroductionModal} handleEditModal={handleEditModal} handleOpenCollectModal={handleOpenCollectModal} handlePreQuestionModal={handlePreQuestionModal} handlePostQuestionModal={handlePostQuestionModal} handleImportUsersModal={handleImportUsersModal}/>
								</div>
							</div>{/* COL END */}
						</div>
						{/* row closed  */}
					</div>
				</div>
			</div>

		</Wrapper>
	)
}
const mapStateToProps = ({session, users, allTests}) => ({
	session, users, allTests
});

const mapDispatchToProps = dispatch => ({
	allTestData: (data) => dispatch(allTestData(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Online);

