import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";
import Wrapper from "../global/Wrapper";
import Pagination from "../global/Pagination/Pagination";
import allUsers from "../../util/users/allusers";
import {allUserData} from "../../actions/allUsers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableController from "../global/Pagination/table/Controller";
import {user as deleteRecord} from "../../util/delete/user";
import Search from "../global/inputs/Search";
import {useDataFilter} from "../global/hooks/useDataFilter";
import Modal from "../global/templates/Modal";
import GraphSearchSelect from "../global/inputs/graphSearchSelect/GraphSearchSelect";
import inviteUsers from "../../util/users/inviteUsers";

/**
 * Description: Used to display the list of Application users.
 * Page uses React-Hook-Form to manage email Selections
 * from GraphSearchSelect component (see submitForm function)
 * @param {*} props
 * @returns
 */
const Users = (props) => {

  // Set Pagination Results
  const recommendedPageLimit = 10;
  const dataLimit = 20;
  const tableHeaders = ["USER", "CREATED", "TEAM", "ROLE",  "EMAIL", "ACTION"];
  const tableFields = ["name", "createdAt", "team", "role", "email", "action"];

  const [term, setTerm] = useState(null);
  const [userLength, setUserLength] = useState();

  // Filter Existing Endeavor users
  const [filteredData] = useDataFilter(
    props.users,
    term,
    "firstName LastName",
    "combined"
  );

  /******************Start Invite User*********************/
  const searchSelectName = "mail";
  const selectFields = "displayName,userPrincipalName";
  const searchPlaceHolderText = "Search by User";
  const msGraphName = "graphPeople";
  const modalMessage = "Invite contacts to create an account.";
  const modalHeader = "Invite Users";
  const [showUserModal, setShowUserModal] = useState();

  // Tracks array of selected scraper
  const [selectedValues, setSelectedValues] = useState([]);

  // Uses setEmailTerm in Search-Select version to Update EmailTerm
  const [emailTerm, setEmailTerm] = useState(null);

  // react-hook-form userForm
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    // setValue,
  } = useForm({});

  // handle Email Contacts form submission
  const submitForm = (data) => {
    // console.log("User: SubmitForm Data:", data.mail);
    // console.log("User: Session:", props.session);

    // Sends all selected scraper to invite users route
    inviteUsers(data.mail, props.session);

    // Update Selected Values
    // setSelectedValues(data);

    // close modal
    setShowUserModal(false);
    // reset selectedValues
    reset();
  };

  const handleClose = (e) => {
    e.preventDefault();
    //close modal
    setShowUserModal(false);
    // reset selectedValues
    reset();
  };

  /*********************End Invite User********************/

  const deleteUser = async (email) => {
    const response = await deleteRecord(email);
    const userCount = parseInt(response.length);
    // deleteUser: sets new userCount
    setUserLength(userCount);
  };

  useEffect(() => {
    async function fetchData() {
      await allUsers().then(async (data) => {
        props.allUserData(await data);
      });
    }
    fetchData();
  }, [userLength]);

  return (
    <Wrapper>
      <div className="main-content pt-0">
        <div className="container-fluid">
          <div className="inner-body users">
            {/*Modal*/}
            {showUserModal === true ? (
              // {/* Display Modal */}
              <Modal
                header={modalHeader}
                handleCloseModal={handleClose}
                handleSubmit={handleSubmit}
                submitForm={submitForm}
                searchSelectName={searchSelectName}
                message={modalMessage}
                primaryText={'Send'}
              >
                <GraphSearchSelect
                  name={searchSelectName}
                  control={control}
                  placeholderText={searchPlaceHolderText}
                  msGraphName={msGraphName}
                  selectFields={selectFields}
                  userEmails={props.users.map(user => user.email)}
                />
              </Modal>
            ) : null}
            {/* Page Header */}
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Users</h2>
              </div>
              <div className="d-flex">
                <div className="justify-content-center">
                  <Search
                    placeholderText="Search By User"
                    setParentTerm={setTerm}
                  />
                  <button
                    type="button"
                    className="btn btn-primary my-2 btn-icon-text"
                    onClick={() => setShowUserModal(true)}
                  >
                    <FontAwesomeIcon
                      className="sidemenu-icon mg-r-4"
                      icon={`fa-solid fa-user-plus`}
                    />
                    Invite Users
                  </button>
                </div>
              </div>
            </div>
            {/* End Page Header */}
            {/*Row*/}
            <div className="row row-sm">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                <div className="card custom-card pd-0">
                  <div className="card-body pd-0">
                    {/*Pagination Table and Controls */}
                    <Pagination
                      type="person"
                      data={
                        filteredData && filteredData.length > 0 && term
                          ? filteredData
                          : props.users
                      }
                      dataLimit={dataLimit}
                      recommendedPageLimit={recommendedPageLimit}
                      RenderComponent={TableController}
                      deleteUser={deleteUser}
                      tableHeaders={tableHeaders}
                      tableFields={tableFields}
                    />
                  </div>
                </div>
              </div>
              {/* COL END */}
            </div>
            {/* row closed  */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
const mapStateToProps = ({ session, users }) => ({
  session,
  users,
});

const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
