import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Wrapper from '../global/Wrapper';
import { useNavigate } from 'react-router-dom';
import { toastMessage } from '../../actions/toast';
import AUTH_BASE_URL from '../../util/AUTH_BASE_URL';
import './report.css';

const ViewReport = (props) => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchReports = async () => {
        try {
            const currentUrl = window.location.href;
            const urlParts = currentUrl.split('/');
            const id = urlParts[urlParts.length - 1];
            const response = await fetch(`${AUTH_BASE_URL}reporting/view/${id}`);

            if (!response.ok) {
                throw new Error('Network response was not OK');
            }

            const data = await response.json();

            if (data) {
                setReports(Array.isArray(data) ? [data[0].aiResponse] : [data[0].aiResponse]);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching reports:', error);
            props.toastMessage('Failed to fetch reports');
        }
    };

    useEffect(() => {
        fetchReports();
    }, []);


    return (
        <Wrapper>
            <div className="main-content pt-0">
                <div className="page-header">
                    <div>
                        <h2 className="main-content-title tx-24 mg-b-5">
                            Reporting - {props.session.team}
                        </h2>
                    </div>
                </div>
            <div className="container-fluid report">

                <div className="row row-sm">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                        <div className="card custom-card pd-l-40 pd-r-40">
                            {reports?.length > 0 ? (
                                reports.map((report, reportIndex) => (
                                    <div key={reportIndex}>
                                        <h1>{report.title}</h1>
                                        <p><strong>Dates:</strong> {report.dates}</p>
                                        <p><strong>Overview:</strong> {report.overview}</p>
                                        <ul className={'mg-t-30 '}>
                                            {report.teammateDetails?.map((member, index) => (
                                                <li key={index}>
                                                    <h3>{member.name}</h3>
                                                    <p><strong>Repository:</strong> {member.repo}</p>
                                                    <p><strong>Summary:</strong> {member.summary}</p>
                                                </li>
                                            ))}
                                        </ul>

                                        <h2>Conclusion</h2>
                                        <p>{report.conclusion}</p>
                                    </div>
                                ))
                            ) : (
                                <div>No reports available</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </Wrapper>
    );
};

const mapStateToProps = ({ session, users, toast }) => ({
    session,
    users,
    toast,
});

const mapDispatchToProps = (dispatch) => ({
    toastMessage: (data) => dispatch(toastMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewReport);
