import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef} from "react";

function DropDown (props) {
    const actionsRef = useRef(null)
    const handleActionsMenu = async (event) => {
	console.log('open')
        if(actionsRef.current.classList.contains('show')){
            actionsRef.current.classList.remove('show')
        }  else{
            actionsRef.current.classList.add('show')
        }

    }
    return (
        <div className={'dropdown-container'} style={{position: 'relative'}}>
            <button type="button" className={props.label === 'Manage' ? 'btn btn-secondary my-2 btn-icon-text mg-0' : 'btn btn-secondary my-2 btn-icon-text mg-0'} onClick={handleActionsMenu} style={{margin: '0', minWidth:'100px'}}>
				{props.label}
                <FontAwesomeIcon className="mg-l-4" icon={`fa-solid ${props.icon}`} />
            </button>
            <div ref={actionsRef} className="dropdown-menu animated-menu actions-menu" style={{minWidth: '150px', right: 0, left: 'unset'}}>
                <ul style={{textAlign: 'left'}} onClick={()=>{
                    if(actionsRef.current.classList.contains('show')){
                        actionsRef.current.classList.remove('show')
                    }
                }}>
                    {props.children}
                </ul>
            </div>
        </div>
    )
}

export default DropDown
