import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import compareDates from "../../../util/dev-ops/compareDates";
import {connect} from "react-redux";
import getAllRepos from "../../../util/dev-ops/getAllRepos";
import deleteGitlabRepo from "../../../util/dev-ops/deleteGitlabRepo";
import {signin} from "../../../actions/session/signIn";
import {allGitLabRepos} from "../../../actions/allGitlabRepos";
import Toast from "../../global/Toast";
import validateToken from "../../../util/dev-ops/validateToken";
import DropDown from '../gitlab-repos/DropDown'
import checkForWebHooks from "../../../util/dev-ops/checkForWebHooks";
import setCiConfigPath from "../../../util/dev-ops/setCiConfigPath";
import commitCiCd from "../../../util/dev-ops/commitCiCd";
import addWebHook from "../../../util/dev-ops/addWebHook";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import addAuthentication from "../../../util/dev-ops/addAuthentication";
import removeAuthentication from "../../../util/dev-ops/removeAuthentication";
import {hasAuthentication} from "../../../actions/hasAuthentication";
import gitlabAvatar from "../../../assets/images/gitlab-avatar.png";
import digitalAvatar from "../../../assets/images/digitalocean.png";
import reactLogo from '../../../assets/images/framework/react.png'
import vueLogo from '../../../assets/images/framework/vue.png'
import htmlLogo from '../../../assets/images/framework/html.png'
import gulpLogo from '../../../assets/images/framework/gulp.png'
import docuLogo from '../../../assets/images/framework/docusaurus.svg'
import astroLogo from '../../../assets/images/framework/astro.svg'
import eleventyLogo from '../../../assets/images/framework/11ty.png'
import storybookLogo from '../../../assets/images/framework/storybook.png'
import Avatar from "../../global/Avatar";
import appendRepoData from "../../../util/dev-ops/appendRepoData";
import {filteredGitLabRepos} from "../../../actions/filteredData";
import deletePrototype from "../../../util/deletePrototype";
import {toastMessage} from "../../../actions/toast";
import fetchAndSetUserData from "../../../util/dev-ops/fetchAndSetUserData";


function TableRow (props) {
	function returnGroup () {
        if (props.session.gitlabSubGroup && props.session.gitlabSubGroup !== '0') {
            return props.session.gitlabSubGroup
        } else {
            return props.session.gitlabGroup
        }
	}

   const setActivityColor =  (lastActivityAt) => {
       return Number(compareDates(lastActivityAt))
    }
    const setNewBadge = (createdAt) => {
        return Number(compareDates(createdAt))
    }

    function handleCiCdRemoval (repo) {
        let user = props.session
        validateToken(user).then(data => {
            props.signin(data.user)
            props.toastMessage('While we remove your hosting, take a break for a minute or two, you`ll receive a notification when the process completes.')

            fetch(`https://gitlab.com/api/v4/projects/${repo.id}/repository/files/.gitlab-ci.yml?branch=${repo.default_branch}&commit_message="removed cicd"&access_token=${data.token}`, {
                method: 'DELETE'
            }).then(response => {
                setCiConfigPath(repo.id, null, data.token)
                fetch(`${AUTH_BASE_URL}webhooks/ftp?name=${encodeURIComponent(repo.name)}`, {
                    method: 'GET',
					headers: new Headers({
						'Content-Type': 'text/html'
					})
                })
            })
        });
    }

    function handleCiCdConnection (repo) {
        let user = props.session
        validateToken(user).then(data => {
            props.signin(data.user)
            props.toastMessage('Grab a cup of coffee or tea while ci/cd is configured. Well send you a notification or email when the project is ready.')

            checkForWebHooks(repo.id, data.token).then(hook => {
                if (hook.length !==0) {
                    setCiConfigPath(repo.id, '', data.token)
                    commitCiCd(repo.id, repo.name, repo.default_branch, data.token)
                } else {
                    addWebHook(repo.id, data.token).then(response => {
                        setCiConfigPath(repo.id, '', data.token)
                        commitCiCd(repo.id, repo.name, repo.default_branch, data.token)
                    })
                }
            })

        })

    }
    async function handleDelete(event, id, name) {
        try {
            let rowToDelete = event.target.closest('tr');
            if (rowToDelete) {
                // Attach an event listener to remove the element after animation
                rowToDelete.addEventListener('animationend', function() {
                    rowToDelete.classList.add('d-none');
                });

                // Trigger the animation - replace with the class for your chosen animation
                rowToDelete.classList.add('blur-rotate-and-drop-down');
                const data = await validateToken(props.session);
                let deleted = await deleteGitlabRepo(id, data.token);

                await fetch(`${AUTH_BASE_URL}dev-ops/repos/delete?id=${id}`, {
                    method: 'DELETE',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });

                let response = await fetchAndSetUserData(data);
                let responseData = await response.json()
                props.allGitLabRepos(responseData.repos);
                props.filteredGitLabRepos(responseData.repos.filter(repo => repo.creator_id === parseInt(props.session.gitLab[0].id)));
                props.toastMessage(`${name} was successfully deleted from Gitlab. Give the system a few seconds to refresh the repo list.`);


            } else {
                console.error("Could not find <tr> element");
            }
        } catch (error) {
            console.error("An error occurred:", error);
            props.toastMessage(`An error occurred while deleting ${name}.`);
        }
    }





    function handleCloneRepo (text) {
        navigator.clipboard.writeText(text).then(function() {
            props.toastMessage(`${text} has been saved to your clipboard`)

        }, function(err) {
            props.toastMessage('Could not copy text: ', err)

        });
    }

    function handleAuthentication () {
        validateToken(props.session).then(data => {
			props.signin(data.user)
            props.toastMessage(`Authentication is being setup and will be ready in 10-20 minutes.  In the meantime, take a walk, grab some food, get some work done, you will get a notification / email when its ready`)

            addAuthentication(props.data, data.token, props.session).then(deployData => {
                if (deployData && deployData.message) {
                    props.toastMessage(deployData.message)

                }
            })
        })
        // add commands to package.json
        // add empty package.lock file
        // get app spec
        // deploy to digital ocean
    }
	function handleRemoveAuthentication () {
		props.toastMessage('Ok, give the system a few minutes to remove authentication from this application.  You can connect again at any time.')

		props.getAuthentication(false)
		validateToken(props.session).then(data => {
			props.signin(data.user)
			removeAuthentication(props.data, data.token, props.session)
		})
		// remove proxy from server


	}

	function returnAdmin () {
		return 	props.data.creator_id === Number(props.session.gitLab[0].id) || props.session.systemAccess === 4
	}
	function buildWebUrl(type, path) {
		if (type === 'platform') {
			return path
			// return 'https://dev.pearsonct.design' + path
		} else {
			return path
		}
	}

	function avatarSwitcher(data) {
		if (data.appUrl && data.appUrl[0]) {
			let newData = data.appUrl[0]
			if (newData.type === 'platform') {
				return <img  style={{width: '30px'}} alt="avatar" src={digitalAvatar} />

			} else if (newData.type === 'static') {
				return <img  style={{width: '40px'}} alt="avatar" src={gitlabAvatar} />
			}
		}
	}
    return (
        <>

        <tr>
			<td>
				{
					props.data.user && props.data.user[0]? <Avatar user={props.data.user[0]} size='md'/> : <></>
				}

			</td>
            <td>
                {
                    compareDates(props.data.created_at) <= 1 ?
                        <span className="repos badge badge-gradient">New</span>
                        : <></>
                }

				<a href={props.data.http_url_to_repo} >{props.data.name}</a>
            </td>
			<td>
				{props.data.appUrl && props.data.appUrl[0] && props.data.appUrl[0].type === 'static'? <span className={props.data.appUrl[0].server === 200 ? 'd-flex text-success' : 'd-flex text-danger'}>  <FontAwesomeIcon icon={`fa-solid fa-server text-danger`} style={{fontSize: '22px', marginRight:'5px'}} /><span style={{fontWeight:'bold', fontSize: '12px', alignSelf: 'center'}}>{props.data.appUrl[0].server ? props.data.appUrl[0].server: 'N/A' }</span></span> : <></>}
			</td>
			<td>
				{
					props.data.framework === 'Create React App'  || props.data.framework === 'create-react-app'?
					 <img style={{width: '30px'}} alt="avatar" src={reactLogo} /> :
						props.data.framework === 'Vue.js' ?
							<img style={{width: '30px'}} alt="avatar" src={vueLogo} /> :
							props.data.framework === 'Html' ? "":
								props.data.framework === 'gulp.js' ?
									<img style={{width: '30px'}} alt="avatar" src={gulpLogo} /> :
									props.data.framework === 'Docusaurus 2' ?
										<img style={{width: '30px'}} alt="avatar" src={docuLogo} /> :
										props.data.framework === 'astro' || props.data.framework === 'Astro' ?
											<img style={{width: '30px'}} alt="avatar" src={astroLogo} /> :
											props.data.framework === 'Eleventy' ?
												<img style={{width: '30px'}} alt="avatar" src={eleventyLogo} /> :
												props.data.framework === 'Parcel' || props.data.framework === 'Grunt' || props.data.framework === 'grunt'  ?
													<img style={{width: '30px'}} alt="avatar" src={htmlLogo} /> :
												props.data.framework === 'react-storybook' || props.data.framework === 'Storybook React App' ?
													<img style={{width: '30px'}} alt="avatar" src={storybookLogo} /> :
							props.data.framework

				}
			</td>
			<td>
				{avatarSwitcher(props.data)}
			</td>
			<td>
				{
					setActivityColor(props.data.last_activity_at) <= 1 ?
						<span className="repos badge badge-primary pd-0 mg-r-8">Today</span> :
						setActivityColor(props.data.last_activity_at) === 2 ?
							<span className="repos badge badge-primary  pd-0 mg-r-8">Yesterday</span> :
							setActivityColor(props.data.last_activity_at) <= 7  ?
								<span className="repos badge badge-primary pd-0 mg-r-8">This Week </span> :
								setActivityColor(props.data.last_activity_at) <= 14  ?
									<span className="repos badge badge-primary pd-0 mg-r-8">Last Week </span> :
									setActivityColor(props.data.last_activity_at) <= 30  ?
										<span className="repos badge badge-primary pd-0 mg-r-8">This Month </span> :
										new Date(props.data.last_activity_at).toLocaleDateString()
				}
			</td>
            <td>
                {new Date(props.data.created_at).toLocaleDateString()}
            </td>

            <td>
                <span data-placement="top" data-toggle="tooltip" title="Currently Worked On" className={setActivityColor(props.data.last_activity_at) <= 30 ? "circle badge badge-success active" : setActivityColor(props.data.last_activity_at) <= 60 ? "circle badge badge-warning" : "circle badge badge-danger active" }></span>
            </td>
			{
				!(/.*-template$/.test(props.data.name))  && props.data.name !== 'production-back-end' && props.data.name !== 'production-front-end' && props.data.name !== 'front-end' && props.data.name !== 'back-end' && props.data.name !== 'ut-online-back-end' && props.data.name !== 'ut-online-front-end' && props.data.name !== 'ut-online-job-success' && props.data.name !== 'unify-book-service'?                <td>
                    <DropDown data={props.data} label={"Manage"} icon={"fa-chevron-down"}>
                        {
                            props.data.appUrl && props.data.appUrl[0] ?
                                <li>
                                    <button onClick={() => {
                                        window.open(props.data.framework === 'react-storybook' || props.data.framework === 'Storybook React App' ? props.data.appUrl[0].webLink + '/' : props.data.appUrl[0].webLink, '_blank')
                                    }}>View App
                                    </button>
                                </li> : <></>
                        }
                        <li>
                            <button onClick={() => {
                                window.open(props.data.web_url)
                            }}>View On GitLab
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => {
                                    window.open(`https://gitlab.com/-/ide/project/${props.data.path_with_namespace}/edit/`)
                                }}
                            >Open in IDE
                            </button>
                        </li>
                        <li>
                            <button onClick={() => {
                                handleCloneRepo(`git clone ${props.data.ssh_url_to_repo}`)
                            }}>Clone with SSH
                            </button>
                        </li>
                        <li>
                            <button onClick={() => {
                                handleCloneRepo(`git clone ${props.data.http_url_to_repo}`)
                            }}>Clone with HTTPS
                            </button>
                        </li>

                        {
                            returnAdmin() ? <li>
                                <button onClick={(event) => {
                                    handleDelete(event, props.data.id, props.data.name)
                                }} className={'text-danger'}>Delete
                                </button>
                            </li> : <li>
                                <button disabled onClick={() => {
                                    handleDelete(props.data.id, props.data.name)
                                }}>Delete
                                </button>
                            </li>
                        }

                    </DropDown>
                </td> : <td></td>
            }

        </tr>
        </>
    )
}
const mapStateToProps = ({session, repos, hasCiCd, gitlabGroupId, hasAuthentication, filteredData}) => ({
    session, repos, hasCiCd, gitlabGroupId, hasAuthentication, filteredData
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user)),
    allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
	getAuthentication: bool => dispatch(hasAuthentication(bool)),
	filteredGitLabRepos: repos => dispatch(filteredGitLabRepos(repos)),
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(TableRow);
