import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const getSubtaskDetails = async (subtaskId) => {
    return fetch(`${AUTH_BASE_URL}subtasks/subtask/${subtaskId}`, {
        method: "GET", 
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export const getSubtaskDetailsByTask = async (taskId) => {
    return fetch(`${AUTH_BASE_URL}subtasks/task/${taskId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
}