import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";


function TableRow (props) {

	const [disabled, setDisabled] = useState();
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [email, setEmail] = useState();
	const [answer, setAnswer] = useState();

	useEffect(()=>{
		if (props.data.firstName) {
			decrypt(props.data.firstName).then(firstName => {
				setFirstName(firstName)
			})
		}
		if (props.data.lastName) {
			decrypt(props.data.lastName).then(lastName => {
				setLastName(lastName)
			})
		}
		if (props.data.email) {
			decrypt(props.data.email).then(email => {
				setEmail(email)
			})
		}

		let question = props.data.questions.filter(val => val.question === props.question)[0] ?
			props.data.questions.filter(val => val.question === props.question)[0].answer :
			props.data.survey.filter(val => val.question === props.question)[0] ?
			props.data.survey.filter(val => val.question === props.question)[0].answer : []

		setAnswer(question)
	},[])


	function decrypt(item) {
		if (props.tableData.user[0].userId === props.session.userId) {
			return fetch(`${AUTH_BASE_URL}usertesting/decrypt`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					"Content-Type": "application/json",
				},
			}).then(response => response.json()).then(final => {
				return final.data
			})
		} else {
			return fetch(`${AUTH_BASE_URL}usertesting/decrypt`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					"Content-Type": "application/json",
				},
			}).then(response => response.json()).then(final => {
				return'Must be owner to view'
			})
		}

	}
	console.log('the props', props)
	if (props.type === 'video') {
		console.log('WATCH THIS', props.data)
		if (props.data.video) {
			return (
				<tr>
					<td className={'wd-lg-5p'}><span>{props.data.username}</span></td>
					{
						props.data.video.video ? <td className={'wd-lg-5p'}><span><a href={props.data.video.video}>Watch video</a></span></td> : <td className={'wd-lg-5p'}></td>
					}

				</tr>
			)
		} else {
			return null
		}

	} else {
		return (
			<tr>
				<td className={'wd-lg-5p'}><span>{props.data.username}</span></td>
				<td className={'wd-lg-5p'}><span>{firstName}</span></td>
				<td className={'wd-lg-5p'}><span>{email}</span></td>
				<td className={'wd-lg-5p'}><span>{props.type}</span></td>
				<td className={'wd-lg-5p'}><span>{answer}</span></td>
			</tr>
		)
	}
}

const mapStateToProps = ({session, allTests}) => ({
	session, allTests
});


export default connect(
	mapStateToProps
)(TableRow);
