import Wrapper from "../../global/Wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from 'react';
import { toastMessage } from "../../../actions/toast";
import { connect } from "react-redux";
import AUTH_BASE_URL  from "../../../util/AUTH_BASE_URL";
import {useNavigate} from "react-router-dom";
function AddWebComponent(props) {
    const [componentName, setComponentName] = useState('');
    const [installCommand, setInstallCommand] = useState('');
    const [importUrl, setImportUrl] = useState(null);
    const [repos, setRepos] = useState([]);
    const [npmPackages, setNpmPackages] = useState([]);
    const [npmPackage, setNpmPackage] = useState([]);
    const token = props.session.token;
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchRepos = async () => {
            try {
                const groupId = '92232574'; // The ID of your subgroup
                const response = await fetch(`https://gitlab.com/api/v4/groups/${groupId}/projects?include_subgroups=true`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setRepos(data);
            } catch (error) {
                console.error("Error fetching repositories:", error);
            }
        };

        const fetchNpmPackages = async () => {
            try {
                const response = await fetch('https://registry.npmjs.org/-/v1/search?text=scope:pearson-ux&size=100');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setNpmPackage(data.objects.map(pkg => pkg.package));
                setNpmPackages(data.objects.map(pkg => pkg.package.name));
            } catch (error) {
                console.error("Error fetching NPM packages:", error);
            }
        };

        fetchRepos();
        fetchNpmPackages();
    }, [token]);

    const handleAddClick = async () => {
        const result = {
            componentName,
            installCommand,
            importUrl,
            npmPackage
        };

        try {
            const response = await fetch(`${AUTH_BASE_URL}dev-ops/webcomponents/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(result)
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Web component added successfully:', data);
                props.toastMessage('Web component added successfully');
                navigate('/dev-ops/web-components'); // Navigate to the desired route
            } else {
                const error = await response.json();
                console.error('Error adding web component:', error);
                props.toastMessage('Error adding web component');
            }
        } catch (e) {
            console.error('Network error:', e);
            props.toastMessage('Network error');
        }
    };

    return (
        <Wrapper>
            <div className="main-content pt-0">
                <div className="container-fluid">
                    <div className="inner-body users">
                        {/* Page Header */}
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Add a Web Component</h2>
                            </div>
                            <div className="d-flex">
                                <div className="justify-content-center">
                                </div>
                            </div>
                        </div>
                        {/* End Page Header */}
                        {/* Row */}
                        <div className="row row-sm">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 grid-margin">
                                <div className="card custom-card pd-0">
                                    <div className="card-body">
                                        <h5>Add a web component to the system</h5>
                                        <div className="group-controls">
                                            <label htmlFor="componentName" className="tx-12 mg-t-8">Component Name</label>
                                            <input
                                                id="componentName"
                                                className="form-control mg-b-20 card-input"
                                                type="text"
                                                placeholder="name"
                                                value={componentName}
                                                onChange={(e) => setComponentName(e.target.value)}
                                            />
                                        </div>
                                        <div className="group-controls">
                                            <label htmlFor="subgroup" style={{ position: 'absolute', fontSize: '12px', top: '-13px' }}>
                                                Select the repo where the component is located:
                                            </label>
                                            <select
                                                onChange={(event) => setImportUrl(event.target.value)}
                                                style={{ display: 'inline-flex', height: '40px' }}
                                                name="codebase"
                                                className="form-control select mg-r-8 card-input"
                                                required
                                            >
                                                <option key="0" value="0">Blank - Empty Project</option>
                                                {repos.map(repo => (
                                                    <option key={repo.id} value={repo.web_url}>{repo.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="group-controls">
                                            <label htmlFor="installCommand" className="tx-12">NPM Package</label>
                                            <select
                                                id="installCommand"
                                                className="form-control mg-b-20 card-input"
                                                value={installCommand.replace('npm install ', '')}
                                                onChange={(e) => setInstallCommand(`npm install ${e.target.value}`)}
                                            >
                                                <option value="">Select an NPM package</option>
                                                {npmPackages.map(pkg => (
                                                    <option key={pkg} value={pkg}>{pkg}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <button className="btn btn-secondary ripple" onClick={handleAddClick}>
                                            <FontAwesomeIcon icon="fa-solid fa-code-commit" /> Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* COL END */}
                        </div>
                        {/* Row closed */}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const mapStateToProps = ({ session }) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddWebComponent);
