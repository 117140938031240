import React from 'react';
import {connect} from "react-redux";
import TextArea from "./CustomTextArea";
import {updateToDo} from "../../../util/todo/updateToDo";
import {useForm} from "react-hook-form";


const EditToDo = props => {

    const {
        register,
        control,
        handleSubmit,
    } = useForm();

    const handleSave = async (data) => {

        let todo_data = {
            toDo: data?.toDo,
        }

        let id = props.toDo._id

        updateToDo(id, todo_data).then(()=>{
            props.getDetails()
            props.close()
        })
    }

    return(
        <div className="modal d-block">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow">
                    <form onSubmit={handleSubmit(handleSave)}>
                    <div className="modal-header">
                        <span>{props.details.name}</span>
                        <h2 className="modal-title">Edit To Do</h2>
                    </div>

                    <div className='detail-modal-body'>
                            <div className="form-group">
                                <TextArea
                                    label="To Do"
                                    name="toDo"
                                    register={register}
                                    placeholder="Write a single to do"
                                    required={true}
                                    defaultValue={props.toDo.toDo}
                                    card
                                />
                            </div>
                    </div>

                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="submit">Save</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.close}>Cancel</button>
                    </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({}) => ({
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditToDo);
