import AUTH_BASE_URL from "../AUTH_BASE_URL";

export default function (data) {
	console.log('data id delete test', data._id)
	return fetch(`${AUTH_BASE_URL}usertesting/${data._id}`, {
		method: 'DELETE'
	}).then(response => {
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		return response.text();
	}).then(text => {
		try {
			// If response is not empty, parse it as JSON
			return text ? JSON.parse(text) : {};
		} catch (err) {
			console.error('Parsing response failed', err);
			throw err;
		}
	});
}


