
export const FIGMA_COMPONENT_DATA = 'FIGMA_COMPONENT_DATA';

export const getFigmaComponentData = (data) => {
	return {
		type: FIGMA_COMPONENT_DATA,
		figmaComponentData: data
	}
};


