import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const getTemplates = async () => {
    return fetch(`${AUTH_BASE_URL}mailgun/templates`, {
        method: "GET", 
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export const getTemplateByName = async (template) => {
    return fetch(`${AUTH_BASE_URL}mailgun/template/${template}`, {
        method: "GET", 
        headers: {
            "Content-Type": "application/json"
        }
    })
}