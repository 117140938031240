import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import Select from "react-select";
import {allClassTests} from "../../../actions/allClassTests";
import {allTestData} from "../../../actions/allUserTests";


const EditTest = props => {
	const [nameData, setnameData] = useState({})
	const [repoData, setRepoData] = useState()
	const [repoNameData, setRepoNameData] = useState()
	const [selected, setSelected] = useState()
	const [customSelected, setCustomSelected] = useState()
	const [validation, setValidation] = useState();
	const nameRef = useRef(null);

	const urlRef = useRef(null);
	const pwRef = useRef()
	const instructorEmailRef = useRef()



	useEffect(()=> {
		if (props.data) {
			setRepoNameData(props.data.repoName)
			setSelected(props.data.repo)
		}
		let group = props.session.gitlabGroup ? props.session.gitlabGroup : '10566838'
		fetch(`${AUTH_BASE_URL}dev-ops/gitlab/repos/recent?group=${group}&id=${props.session.gitLab[0].id}`, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json"
			}
		}).then(response => response.json()).then(data => {
			console.log('THE REPO DATA', data.repoData)
			setRepoData(data.repoData)
		})
	},[])

	useEffect(()=> {

		if(!props.allClassTestData || props.allClassTestData.length === 0) {
			fetch(`${AUTH_BASE_URL}usertesting/classtest`, {
				method: 'GET',
				headers: {
					"Content-Type": "application/json",
				},
			}).then((response => {
				return response.json()
			})).then((data => {
				props.allClassTests(data)
			}))
		}
	},[])

	let data = {}

	function handleName (event) {
		data.name = event.target.value
		setnameData(data)
	}

	function handleUpdate () {
		console.log('ID', customSelected)
		let dataObj = {}
		dataObj.id = props.data._id
		dataObj.name = nameRef.current.value ? nameRef.current.value.replace(/ /g, "-") : ''
		dataObj.url = urlRef.current.value
		dataObj.instructorEmail = instructorEmailRef.current.value
		dataObj.repoName = repoNameData
		dataObj.repo = selected
		dataObj.customBack = customSelected
		dataObj.password = pwRef.current.value
		dataObj.user = props.session

		fetch(`${AUTH_BASE_URL}usertesting/classtest`, {
			method: 'PUT',
			body: JSON.stringify(dataObj),
			headers: {
				"Content-Type": "application/json"
			}
		}).then(response => response.json()).then(data => {
			props.handleCloseNewModal()
			console.log('EditTest: 85, the data is', data)
			props.allClassTests(data)
		})
	}

	function handleUrlInputValue () {
		return 'classtesting'
	}
	console.log('the props data is', repoData)
	return(
		<div className="modal d-block">
			<div className="modal-dialog" role="dialog">
				<div className="modal-content modal-content-demo shadow">
					<div className="modal-header">
						<h6 className="modal-title">Edit</h6>
					</div>
					<form className={"pd-30"}>
						<div className={"mg-b-20"}>
							<label>Name of the test</label>
							<input ref={nameRef} defaultValue={props.data ? props.data.name : ''} onChange={handleName} className="form-control"/>
						</div>
						<div className={"mg-b-20"}>
							<label>URL - read only and generated from the name</label>
							<input ref={urlRef} readOnly className="form-control" value={`https://${nameData.name ? nameData.name.split(' ').join('-') : (props.data ? props.data.name.split(' ').join('-') : '')}.${handleUrlInputValue()}.pearsonct.design`} />
						</div>
						<div className={"mg-b-20"}>
							<label>Instructor Email</label>
							<input  defaultValue={props.data ? props.data.instructorEmail : ''} ref={instructorEmailRef} className="form-control"/>
						</div>
						<div className={"mg-b-20"}>
							<label>Choose application to test</label>
							<Select
								options={repoData ? repoData.map(val => val ? {label: val.name, value: val} : null).filter(Boolean) : []}
								className="basic-multi-select"
								classNamePrefix="select"
								onChange={(value)=>{setSelected(value.value.id); setRepoNameData(value.label)}}
								defaultInputValue={props.data ? props.data.repoName : ''}/>
						</div>
						<div className={"mg-b-20"}>
							<label>Choose Custom Backend (optional)</label>
							<Select
								options={repoData ? repoData.map(val => val ? {label: val.name, value: val} : null).filter(Boolean) : []}
								className="basic-multi-select"
								classNamePrefix="select"
								onChange={(value)=>{setCustomSelected(value.value)}}
								defaultInputValue={props.data.customBack ? props.data.customBack.name : ''}

							/>

						</div>


						<div className={"mg-b-20"}>
							<label>Set instructor password to access test</label>
							<input defaultValue={props.data ? props.data.password : ''} ref={pwRef} className="form-control" type={"text"}/>
						</div>
						{
							validation ? <p className={'text-danger'}>{validation}</p> : <></>
						}
					</form>
					<div className="modal-footer">
						<button type="button" onClick={props.handleCloseNewModal} className="btn btn-secondary">Close</button>
						<button type="button" onClick={handleUpdate} className="btn btn-primary">Save</button>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	session: state.session,
	testing: state.testing,
	allClassTestData: state.allClassTestData
})
const mapDispatchToProps = dispatch => ({
	allClassTests: (data) => dispatch(allClassTests(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditTest);
