/**
 * setSsoLocalStorage: Set and returns the set value
 * @param {*} name
 * @param {*} value
 * @returns value of the local storage set
 */
export const setSsoLocalStorage = async (name, value) => {
  return await asyncLocalStorage.setItem(name, value).then(() => {
    return asyncLocalStorage.getItem(name);
  });
};

/**
 * asyncLocalStorate: Contains utility functions
 *  to set and retrieve localStorage variables
 */
export const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      // Return boolean false or true
      let endeavorLocalStorage = localStorage.getItem(key);
      switch (true) {
        case typeof endeavorLocalStorage === "string":
          if (endeavorLocalStorage === "false") {
            return false;
          } else {
            return true;
          }
        default:
      }
    });
  },
};
