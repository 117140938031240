import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const NewsLinks = () => {
    return (
        <Link className='nav-link' to={'/team'}>
            <span className="shape1"/>
            <span className="shape2"/>
            <FontAwesomeIcon className='sidemenu-icon' icon='clipboard-list'/>
            <span className="sidemenu-label">Team Status</span>
        </Link>
    );
};

export default NewsLinks ;
