import AUTH_BASE_URL from "../AUTH_BASE_URL";

export default function (data) {
	console.log('usertest', data)
	// update doc in db to flag that a test is running
	return fetch(`${AUTH_BASE_URL}usertesting/launch?name=${data.name}`, {
		method: 'GET'
	}).then(response => {
		console.log('create new do application', response)
		return response
	})

	// create new digital ocean app
	// edit the returned app spec
		// domains - name of test
		// envs - db name
		// cors - dev.pearsonct.design
	// put the new app spec
	// add cors to platform

	// TODO IN NEW SYSTEM
	// setup usernames and passwords in new database
	// push the test data also to the new database
}
