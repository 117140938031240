import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Wrapper from "../global/Wrapper";
import { updateSession } from "../../actions/session/updateSession";
import { toastMessage } from "../../actions/toast";
import DashboardWidgetContainer from "./DashboardWidgetContainer";
import BannerCarousel from "../global/BannerCarousel"

const widgets = [
  { type: "My work", size: 2, column: 1 },
  { type: "Pinned work", size: 2, column: 1},
  { type: "My activity", size: 1, column: 3 },
  { type: "Executive news", size: 1, column: 3 },
  { type: "Recent news", size: 1, column: 3 },
];

const Dashboard = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirect, setRedirect] = useState('');
  const [dashWidgets, setDashWidgets] = useState(widgets);
  const [editMode, setEditMode] = useState(false);
  const [dragItem, setDragItem] = useState(null);
  const [dropLocation, setDropLocation] = useState(null);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  function deleteCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  useEffect(() => {
    let localStorageRedirect = localStorage.getItem("redirect");
    if (localStorageRedirect) {
      setRedirect(localStorageRedirect);
      localStorage.removeItem("redirect");
    } else {
      const cookieRedirect = getCookie("unauthorized-redirect");
      if (cookieRedirect) {
        const formattedRedirect = decodeURIComponent(cookieRedirect);
        setRedirect(formattedRedirect);
        deleteCookie("unauthorized-redirect");
      }
    }
  }, []);

  useEffect(() => {
    if (redirect && redirect !== location.pathname) {
      if (redirect.startsWith('http') || redirect.startsWith('https')) {
        window.location.href = redirect;
      } else {
        navigate(redirect);
      }
      setRedirect('');
    }
  }, [redirect, navigate, location.pathname]);

  useEffect(() => {
    setDragItem(null);
    setDropLocation(null);
  }, [dashWidgets]);

  const dragStart = (item) => {
    setDragItem(item);
  };

  const dragOver = (event, item, location) => {
    event.preventDefault();
    if (event.currentTarget.classList.contains("drop-zone")) {
      event.currentTarget.classList.add("drag-over");
    }
    setDropLocation({ item: item, location: location });
  };

  const dragLeave = (event) => {
    event.preventDefault();
    if (event.currentTarget.classList.contains("drop-zone")) {
      event.currentTarget.classList.remove("drag-over");
    }
    setDropLocation(null);
  };

  const dropItem = (event) => {
    event.preventDefault();
    if (dropLocation && dragItem) {
      let newWidgets = [...dashWidgets];
      const itemToMoveIndex = newWidgets.findIndex((f) => f.type === dragItem.type);
      const itemToMove = newWidgets.splice(itemToMoveIndex, 1)[0];
      itemToMove.size = dropLocation.item.size;
      itemToMove.column = dropLocation.item.column;
      const dropLocationIndex = newWidgets.findIndex((f) => f.type === dropLocation.item.type);
      let newArr;
      if (dropLocation.location === "above") {
        newArr = [...newWidgets.slice(0, dropLocationIndex), itemToMove, ...newWidgets.slice(dropLocationIndex)];
      } else {
        newArr = [...newWidgets.slice(0, dropLocationIndex + 1), itemToMove, ...newWidgets.slice(dropLocationIndex + 1)];
      }
      setDashWidgets(newArr);
    }
  };

  console.log('91', props.session);

  return (
      <Wrapper>
        {/* Always start the page with Wrapper, including sidebar and header */}
        <div className="main-content pt-0">
          <div className="container-fluid">
            <div className="inner-body dashboard pt-4">
              {props.session.isPearson && props.session.ssoRole === 'guest' ? (
                  <div className="row row-sm">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                      <div className="card custom-card pd-0">
                        <div className="card-body">
                          <h5>Your current role is guest, and you have minimum access.</h5>
                          <p> You can request to change your role through{" "}
                            <a
                                href={"https://pearson.service-now.com/help?id=sc_cat_item&table=sc_cat_item&sys_id=84cff2d8db8f1744af8b36fffe9619c1"}
                                target={"_blank"}
                            >
                              Pearson My Help.
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
              ) : (<></>)}
              <div className="sortable ui-sortable">
                <div className="row row-sm">
                  <div className="col-lg-12 col-sm-12">
                    <div className="card custom-card shadow-2" style={{overflow:'hidden', height: "100px", padding: 0,}}>
                      <BannerCarousel/>
                    </div>
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-lg-12 col-sm-12">
                    {dashWidgets.filter((f) => f.size === 3).length > 0 ? (
                        dashWidgets.filter((f) => f.size === 3).map((item, index) => {
                          return (
                              <DashboardWidgetContainer
                                  draggable={editMode}
                                  item={item}
                                  title={item.type}
                                  index={"size3" + index}
                                  dragStart={() => dragStart(item)}
                                  dragOver={(e, loc) => dragOver(e, item, loc)}
                                  dragStop={(e) => dragLeave(e)}
                                  drop={(e) => dropItem(e)}
                              />
                          );
                        })
                    ) : (
                        <div
                            className={`column-empty drop-zone ${editMode ? "active" : ""}`}
                            onDragEnter={(event) => dragOver(event)}
                            onDragLeave={(event) => dragLeave(event)}
                            onDragOver={(event) => dragOver(event, { column: 1, size: 3 }, "below")}
                            onDrop={(e) => dropItem(e)}
                        />
                    )}
                  </div>
                </div>
                <div className="row row-sm">
                  <div className="col-lg-8 col-sm-12">
                    <div className="row row-sm">
                      <div className="col-lg-12 col-sm-12">
                        {dashWidgets.filter((f) => f.size === 2).length > 0 ? (
                            dashWidgets
                                .filter((f) => f.size === 2)
                                .map((item, index) => {
                                  return (
                                      <DashboardWidgetContainer
                                          draggable={editMode}
                                          item={item}
                                          title={item.type}
                                          type={item.type}
                                          key={"size2" + index}
                                          dragStart={() => dragStart(item)}
                                          dragOver={(e, loc) => dragOver(e, item, loc)}
                                          dragStop={(e) => dragLeave(e)}
                                          drop={(e) => dropItem(e)}
                                      />
                                  );
                                })
                        ) : (
                            <div
                                className={`column-empty drop-zone ${editMode ? "active" : ""}`}
                                onDragEnter={(event) => dragOver(event)}
                                onDragLeave={(event) => dragLeave(event)}
                                onDragOver={(event) => dragOver(event, { column: 1, size: 2 }, "below")}
                                onDrop={(e) => dropItem(e)}
                            />
                        )}
                      </div>
                    </div>
                    <div className="row row-sm">
                      <div className="col-lg-6 col-sm-12">
                        {dashWidgets.filter((f) => f.size === 1 && f.column === 1).length > 0 ? (
                            dashWidgets
                                .filter((f) => f.size === 1 && f.column === 1)
                                .map((item, index) => {
                                  return (
                                      <DashboardWidgetContainer
                                          draggable={editMode}
                                          item={item}
                                          title={item.type}
                                          key={"size1Col1" + index}
                                          dragStart={() => dragStart(item)}
                                          dragOver={(e, loc) => dragOver(e, item, loc)}
                                          dragStop={(e) => dragLeave(e)}
                                          drop={(e) => dropItem(e)}
                                      />
                                  );
                                })
                        ) : (
                            <div
                                className={`column-empty drop-zone ${editMode ? "active" : ""}`}
                                onDragEnter={(event) => dragOver(event)}
                                onDragLeave={(event) => dragLeave(event)}
                                onDragOver={(event) => dragOver(event, { column: 1, size: 1 }, "below")}
                                onDrop={(e) => dropItem(e)}
                                key={"size1Col1Drop"}
                            />
                        )}
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        {dashWidgets.filter((f) => f.column === 2).length > 0 && dashWidgets.filter((f) => f.size === 1).length > 0 ? (
                            dashWidgets
                                .filter((f) => f.column === 2)
                                .map((item, index) => {
                                  return (
                                      <DashboardWidgetContainer
                                          draggable={editMode}
                                          item={item}
                                          title={item.type}
                                          key={"size1Col2" + index}
                                          dragStart={() => dragStart(item)}
                                          dragOver={(e, loc) => dragOver(e, item, loc)}
                                          dragStop={(e) => dragLeave(e)}
                                          drop={(e) => dropItem(e)}
                                      />
                                  );
                                })
                        ) : (
                            <div
                                className={`column-empty drop-zone ${editMode ? "active" : ""}`}
                                onDragEnter={(event) => dragOver(event)}
                                onDragLeave={(event) => dragLeave(event)}
                                onDragOver={(event) => dragOver(event, { column: 2, size: 1 }, "below")}
                                onDrop={(e) => dropItem(e)}
                                key={"size1Col2Drop"}
                            />
                        )}
                      </div>
                    </div>
                    <div className="row row-sm">
                      <div className="col-lg-12 col-sm-12">
                        {editMode ? (
                            <button
                                type="button"
                                class="btn btn-primary my-2 btn-icon-text"
                                onClick={() => setEditMode(false)}>
                              Done
                            </button>
                        ) : (
                            <button
                                type="button"
                                class="btn btn-primary my-2 btn-icon-text"
                                onClick={() => setEditMode(true)}>
                              Edit
                            </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-12">
                    {dashWidgets.filter((f) => f.column === 3).length > 0 && dashWidgets.filter((f) => f.size === 1).length > 0 ? (
                        dashWidgets
                            .filter((f) => f.column === 3)
                            .map((item, index) => {
                              return (
                                  <DashboardWidgetContainer
                                      draggable={editMode}
                                      item={item}
                                      title={item.type}
                                      key={"size1Col3" + index}
                                      dragStart={() => dragStart(item)}
                                      dragOver={(e, loc) => dragOver(e, item, loc)}
                                      dragStop={(e) => dragLeave(e)}
                                      drop={(e) => dropItem(e)}
                                  />
                              );
                            })
                    ) : (
                        <div
                            className={`column-empty drop-zone ${editMode ? "active" : ""}`}
                            onDragEnter={(event) => dragOver(event)}
                            onDragLeave={(event) => dragLeave(event)}
                            onDragOver={(event) => dragOver(event, { column: 3, size: 1 }, "below")}
                            onDrop={(e) => dropItem(e)}
                            key={"size1Col3Drop"}
                        />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
  );
};

const mapStateToProps = ({ session, wsClient, score }) => ({ session, wsClient, score });

const mapDispatchToProps = (dispatch) => ({
  updateSession: (user) => dispatch(updateSession(user)),
  toastMessage: (data) => dispatch(toastMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);