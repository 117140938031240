import React, {useEffect, useRef, useState} from 'react'
import {updateUser} from "../../util/settings/updateUser";
import {connect} from "react-redux";
import {signin} from "../../actions/session/signIn";


function ProfileItem (props) {
    const [edit, setEdit] = useState(false);
    const [teamSelected, setTeamSelected] = useState(null)
    const inputRef = useRef()
    const selectRef = useRef()

    const handleSelectTeam = (team) => {
        setTeamSelected(team)
    }



    function handleEdit () {
        setEdit(!edit)
    }
    function handleSave () {
        handleEdit()
        if (props.type === 'input') {
            let data = {}
            data[props.model] = inputRef.current.value
            data.email = props.session.email
            updateUser(data).then(response => {
                return response.json()
            }).then(data =>{
                props.signin(data.user)
            })
        } else if (props.type === 'select') {
            let data = {}

            data[props.model] = selectRef.current.value
            data.email = props.session.email
            updateUser(data).then(response => {
                return response.json()
            }).then(data =>{
                props.signin(data.user)
            })
        }

    }

    useEffect(()=> {
        if (props.type === 'input' && edit === true) {
            inputRef.current.focus();
        }
    }, [edit])

    if (!edit) {
        return (
            <div className={'d-flex justify-content-between flex-row align-items-center profile'} >
                <div>
                    <label>{props.label}</label>
                    <span className="tx-medium">{props.value}</span>
                </div>
                <button onClick={handleEdit} className="btn ripple btn-secondary">Edit</button>
            </div>
        )
    } else {
        return (
            <div className={'d-flex justify-content-between flex-row align-items-center profile'} >
                <div>
                    {
                        props.type === 'input' ? <input ref={inputRef} className={'form-control card-input mg-b-4'} defaultValue={props.value} /> : props.type === 'select' ?
                            <select ref={selectRef} name="team" className="form-control card-select select" required defaultValue={props.value} onChange={(event)=>{handleSelectTeam(event.target.value)}}>
                                <option disabled value=''> -- select an option -- </option>
                                {
                                    props.data.map((team, i)=>{
                                        return <option key={i} data-index={i} value={team.name ? team.name : team}>{team.name ? team.name : team}</option>
                                    })
                                }
                            </select> : <></>
                    }

                </div>
                <button onClick={handleSave} className="btn ripple btn-secondary">Save</button>
            </div>
            )
    }

}
const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileItem);
