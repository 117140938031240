import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const updateTemplate = async (data) => {
    return fetch(`${AUTH_BASE_URL}mailgun/update`, {
        method: "POST", 
        body: JSON.stringify({data:data}),
        headers: {
            "Content-Type": "application/json"
        }
    })
}