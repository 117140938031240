import React, {useEffect} from 'react';
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import {SET_COMPONENTS_TEAM_ID, SET_PROJECTS_TEAM_ID, SET_TEAM_ID} from "../../../actions/figmaTeamId";
import {connect} from 'react-redux';
import {getFigmaTeamData} from "../../../actions/figmaTeam";
import {getFigmaTeamId} from "../../../actions/figmaTeamId";
import {updateUser} from "../../../util/settings/updateUser";
import {signin} from "../../../actions/session/signIn";

function TeamSelect (props) {
	console.log('team select props', props)
	useEffect(()=> {
		if (!props.figmaTeamData) {
			fetch(`${AUTH_BASE_URL}auth/figma/teams`, {
				method: 'GET',
			}).then(res => res.json()).then(teams => {
				props.getFigmaTeamData(teams)
			})
		}
	},[])

	function handleOnChange (e) {
		if (props.type === 'default') {
			props.getFigmaTeamId({ type: SET_TEAM_ID, figmaTeamId: e.target.value, theCase: props.type });
			updateUser({email: props.session.email, figmaTeamId: e.target.value}).then(data => {
				props.signin(data.user)
			})
		} else if (props.type === 'projects') {
			props.start(true)
			props.getFigmaTeamId({ type: SET_PROJECTS_TEAM_ID, figmaProjectsTeamId: e.target.value, theCase: props.type  });
		} else if (props.type === 'component') {
			props.start(true)
			props.getFigmaTeamId({ type: SET_COMPONENTS_TEAM_ID, figmaComponentsTeamId: e.target.value,  theCase: props.type  });
		}
	}

	function returnDefaultValue () {
		if (props.type === 'default') {
			return props.session.figmaTeamId;
		} else if (props.type === 'projects') {
			if (props.figmaProjectsTeamId) {
				return props.figmaProjectsTeamId;
			} else {
				return props.session.figmaTeamId
			}
		} else if (props.type === 'component') {
			if (props.figmaComponentsTeamId) {
				return props.figmaComponentsTeamId;
			} else {
				return props.session.figmaTeamId
			}
		}
	}

	console.log('default value please', returnDefaultValue())
	if (props.figmaTeamData) {
		return (
			<select onChange={handleOnChange} style={{display: 'inline-flex', height: '40px'}}  name="group" className="form-control select mg-r-8" required="" defaultValue={returnDefaultValue()}>
				{
					props.figmaTeamData.map((team,index) => {
						return (
							<option key={index} data-index={index} value={team.id} >{team.name}</option>
						)
					})
				}
			</select>
		)
	} else {
		return <></>
	}
}

const mapStateToProps = ({session, figmaTeamData, figmaTeamId, figmaProjectsTeamId, figmaComponentsTeamId}) => ({
	session, figmaTeamData, figmaTeamId,figmaProjectsTeamId, figmaComponentsTeamId
});

const mapDispatchToProps = dispatch => ({
	getFigmaTeamData: (token) => dispatch(getFigmaTeamData(token)),
	signin: user =>dispatch(signin(user)),
	getFigmaTeamId: (token) => dispatch(getFigmaTeamId(token))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TeamSelect);
