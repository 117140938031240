export const SET_TEAM_ID = 'SET_TEAM_ID';
export const SET_PROJECTS_TEAM_ID = 'SET_PROJECTS_TEAM_ID';
export const SET_COMPONENTS_TEAM_ID = 'SET_COMPONENTS_TEAM_ID';

export const getFigmaTeamId = (type) => {
	let actionType;
	let payload = {};
	console.log('this is the type type', type)
	switch (type.theCase) {
		case 'projects':
			actionType = SET_PROJECTS_TEAM_ID;
			payload = { figmaProjectsTeamId: type.figmaProjectsTeamId  };
			break;
		case 'component':
			actionType = SET_COMPONENTS_TEAM_ID;
			payload = { figmaComponentsTeamId: type.figmaComponentsTeamId  };
			break;
		case 'default':
			actionType = SET_TEAM_ID;
			payload = { figmaTeamId: type.figmaTeamId };
			break;
		default:
			actionType = null;
			payload = { figmaTeamId: null };
			break;
	}

	return {
		type: actionType,
		...payload,
	};
};
