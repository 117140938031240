import React, {useEffect, useState} from "react";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import TableRow from "./TableRow"
// import {allProjects} from "../../../actions/allProjects";
import {connect} from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProjectsTable = ({renderComponentOptions, data, originalData, project_filters, projects}) => {
	const [projectData, setProjectData] = useState(null);

	// If Data changes get new data slice
	useEffect(()=>{
	// if (data && data[0].name === "TestProject"){
	// }
	setProjectData(data);
	},  [data]);

	const handleChangeSort = (property) => {
		let setSortedBy = renderComponentOptions.setSortedBy
		let sortedBy = renderComponentOptions.sortedBy
		if(sortedBy.property === property){
			let newDirection;
			if(sortedBy.direction === 'ascending'){
				newDirection = 'descending'
			}else{
				newDirection = 'ascending'
			}
			setSortedBy({
					property: sortedBy.property,
					direction: newDirection
			})
		}else{
			setSortedBy({
				property: property,
				direction: 'ascending'
			})
		}
	}

	//check if there is projectData being passed through Pagination and also that the projects exist in redux
	if (projectData && projects) {
		return (
			<div className="table-responsive">
				<table className="table card-table table-striped table-vcenter mb-0">
					<thead>
					<tr>
						<th className={'wd-lg-25p'}>
							<button 
								onClick={()=>{handleChangeSort('name')}}
								className="pd-0"
								>
								<FontAwesomeIcon
									style={{ paddingRight: "4px" }}
									// className="mg-l-4 pd-x-4"
									icon="fa-solid fa-sort"
								/>
							</button>
							<span>Project Name</span>
						</th>
						<th className={'wd-lg-15p'}>
							<button 
								onClick={()=>{handleChangeSort('product')}}
								className="pd-0"
								>
								<FontAwesomeIcon
									style={{ paddingRight: "4px" }}
									// className="mg-l-4 pd-x-4"
									icon="fa-solid fa-sort"
								/>
							</button>
							<span>Product</span>
						</th>
						<th className={'wd-lg-15p'}>
							<button 
								onClick={()=>{handleChangeSort('status')}}
								className="pd-0"
								>
								<FontAwesomeIcon
									style={{ paddingRight: "4px" }}
									// className="mg-l-4 pd-x-4"
									icon="fa-solid fa-sort"
								/>
							</button>
							<span>Status</span>
						</th>
						<th className={'wd-lg-15p'}>
							<button 
								onClick={()=>{handleChangeSort('updated')}}
								className="pd-0"
								>
								<FontAwesomeIcon
									style={{ paddingRight: "4px" }}
									// className="mg-l-4 pd-x-4"
									icon="fa-solid fa-sort"
								/>
							</button>
							<span>Updated</span>
						</th>
						<th className={'wd-lg-15p'}>
							<button 
								onClick={()=>{handleChangeSort('created')}}
								className="pd-0"
								>
								<FontAwesomeIcon
									style={{ paddingRight: "4px" }}
									// className="mg-l-4 pd-x-4"
									icon="fa-solid fa-sort"
								/>
							</button>
							<span>Created</span>
						</th>
						<th className={'wd-lg-15p'}>
							<button 
								onClick={()=>{handleChangeSort('creator')}}
								className="pd-0"
								>
								<FontAwesomeIcon
									style={{ paddingRight: "4px" }}
									// className="mg-l-4 pd-x-4"
									icon="fa-solid fa-sort"
								/>
							</button>
							<span>Creator</span>
						</th>
						<th className={'wd-lg-15p'}><span>Team</span></th>
						{/* <th className={'wd-lg-15p'}><span>Issues</span></th> */}
					</tr>
					</thead>
					<tbody>
					{ projectData && projectData.length > 0 ?
						projectData.map((project, index) => {
							return (
								<TableRow
									key={index}
									project={project}
									renderComponentOptions={renderComponentOptions}
								/>
							)
						}) : null
					}
					</tbody>
				</table>
			</div>
		);
	} else {
		return (
			<div className="table-responsive">
				<table className="table card-table table-striped table-vcenter mb-0">
					<thead>
					<tr>
						<th className={'wd-lg-25p'}><span>Project Name</span></th>
						<th className={'wd-lg-15p'}><span>Created</span></th>
						<th className={'wd-lg-15p'}><span>Creator</span></th>
						<th className={'wd-lg-15p'}><span>Team</span></th>
						{/* <th className={'wd-lg-15p'}><span>Issues</span></th> */}
					</tr>
					</thead>
					<tbody>
						{
							//if projectData is undefined but the projects already exist in redux
							!projectData && projects ? 
							<tr><td colSpan={5}>No projects match these filters</td></tr>			
							:
							//else the projects don't exist in redux yet
							<tr><td colSpan={5}> Loading Projects...</td></tr>			
						}
							
					</tbody>
				</table>
			</div>
		)
	}
};


const mapStateToProps = ({session, projects, project_filters}) => ({
	session, projects, project_filters
});

const mapDispatchToProps = dispatch => ({
	// allProjects: (data) => dispatch(allProjects(data))
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(ProjectsTable);
