const fetchGitLabRepoFiles = async (projectId, branch, gitLabToken) => {
    const url = `https://gitlab.com/api/v4/projects/${projectId}/repository/tree?ref=${branch}`;
    const headers = {
        'PRIVATE-TOKEN': gitLabToken,
    };

    const response = await fetch(url, { headers });
    if (!response.ok) {
        throw new Error(`Failed to fetch repository files: ${response.statusText}`);
    }
    return response.json();
};

const fetchFileContent = async (projectId, filePath, branch, gitLabToken) => {
    const url = `https://gitlab.com/api/v4/projects/${projectId}/repository/files/${encodeURIComponent(filePath)}/raw?ref=${branch}`;
    const headers = {
        'PRIVATE-TOKEN': gitLabToken,
    };

    const response = await fetch(url, { headers });
    if (!response.ok) {
        throw new Error(`Failed to fetch file content: ${response.statusText}`);
    }
    return response.text(); // Fetch as text because package.json is a JSON file in string format
};

export const detectFramework = async (repo, gitLabToken) => {
    let framework = 'unknown';
    let buildCommands = [];
    let outputDir = '';
    let packageManager = 'npm'; // Default to npm

    try {
        const repoFiles = await fetchGitLabRepoFiles(repo.id, repo.default_branch, gitLabToken);
        const fileNames = repoFiles.map(file => file.name);

        // Check for yarn.lock or package-lock.json
        if (fileNames.includes('yarn.lock')) {
            packageManager = 'yarn';
        } else if (fileNames.includes('package-lock.json')) {
            packageManager = 'npm';
        }

        // Fetch package.json if it exists
        let packageJson = null;
        if (fileNames.includes('package.json')) {
            const packageJsonContent = await fetchFileContent(repo.id, 'package.json', repo.default_branch, gitLabToken);
            packageJson = JSON.parse(packageJsonContent); // Parse the JSON content
        }

        // Check for Node.js/React, Angular, Vue.js, and Storybook
        if (packageJson) {
            const buildCommandPrefix = packageManager === 'yarn' ? 'yarn' : 'npm run';
            if (packageJson.dependencies.react && !packageJson.dependencies['@storybook/react']) {
                framework = 'react';
                buildCommands = [`PUBLIC_URL=/ ${buildCommandPrefix} build`];
                outputDir = 'build';
            } else if (packageJson.dependencies['@storybook/react']) {
                framework = 'storybook';
                buildCommands = [`PUBLIC_URL=/ ${buildCommandPrefix} build-storybook`];
                outputDir = 'storybook-static';
            } else if (packageJson.dependencies['@angular/core']) {
                framework = 'angular';
                buildCommands = [`PUBLIC_URL=/ ${buildCommandPrefix} build`];
                const packageName = packageJson.name || 'package';
                outputDir = `dist/${packageName}/browser`;
            } else if (packageJson.dependencies.vue) {
                framework = 'vue';
                buildCommands = [`PUBLIC_URL=/ ${buildCommandPrefix} build`];
                outputDir = 'dist';
            }
        }

        // Check for Ruby on Rails
        if (fileNames.includes('Gemfile')) {
            framework = 'rails';
            buildCommands = ['bundle install && rails assets:precompile'];
            outputDir = 'public/assets';
        }

        // Check for Django
        if (fileNames.includes('requirements.txt') || fileNames.includes('Pipfile')) {
            framework = 'django';
            buildCommands = ['pip install -r requirements.txt && python manage.py collectstatic --noinput'];
            outputDir = 'staticfiles';
        }

        // If no framework detected, use the build command from package.json if available
        if (framework === 'unknown' && packageJson && packageJson.scripts && packageJson.scripts.build) {
            buildCommands = [`${packageManager === 'yarn' ? 'yarn' : 'npm run'} build`];
            outputDir = 'dist'; // Default output directory, can be adjusted if necessary
        }
    } catch (error) {
        console.error('Error detecting framework:', error);
    }

    return { framework, buildCommands, outputDir, packageManager };
};
