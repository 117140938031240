export const getGradientColors = (selectedAssignee) => {
    const lowerAssignee = selectedAssignee?.toLowerCase();
    if (lowerAssignee?.includes('juju')) {
        return ['#a600ff', '#17e078'];
    } else if (lowerAssignee?.includes('hannah')) {
        return ['#07d707', '#ffc952'];
    } else if (lowerAssignee?.includes('rich')) {
        return ['#eded17', '#ffa202'];
    } else if (lowerAssignee?.includes('jackie')) {
        return ['#2ad8dd', '#3660bd'];
    } else if (lowerAssignee?.includes('tony')) {
        return ['rgb(10,235,110)', '#00e2f8'];
    } else if (lowerAssignee?.includes('vidya')) {
        return ['#fbd79d', '#db2d2d'];
    } else if (lowerAssignee?.includes('dave')) {
        return ['#ff2b8c', '#9e00ff'];
    } else if (lowerAssignee?.includes('greg')) {
        return ['#5bc6ff', '#f300ff'];  // Default gradient
    } else {
        return ['#000000', '#3a3a3a'];  // Default gradient
    }
}
