import Wrapper from "../../global/Wrapper";
import React, {useEffect, useRef, useState} from "react";
import {allTestData} from "../../../actions/allUserTests";
import {connect} from "react-redux";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import Total from "./Total";
import YesNo from "./YesNo";
import TrueFalse from "./TrueFalse";
import Scale from "./Scale";
import Open from "./Open";
import {Link, useNavigate} from "react-router-dom";

function Results (props) {
	const [result, setResult] = useState()
	const [resultData, setResultData] = useState();
	const yesRef = useRef()
	const navigate = useNavigate();
	useEffect(()=> {
		const parts = window.location.pathname.split("/"),
			result = parts.pop();
		setResult(result)
		fetch(`${AUTH_BASE_URL}usertesting/results/${result}`, {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		}).then(response => response.json()).then(data => {
			setResultData(data)
		})
	},[])

	return (
		<Wrapper>
			<div className="main-content pt-0">
				<div className="container-fluid">
					<div className="inner-body project-detail">
						{/* Page Header */}
						<div className="page-header">
							<div className={"project-detail"}>
								<Link to="/usertesting/online">Back to User Testing</Link>
								<div>
									<h2 className="main-content-title tx-24 mg-b-5 mg-t-10">Test Results for {result}</h2>
								</div>
								<p>
									This usertest was created by <strong>{resultData ? resultData.user[0].firstName + ' ' + resultData.user[0].lastName : ""}</strong>
								</p>
							</div>
							<div className="d-flex">
								<div className="justify-content-center table-controls">
									{
										resultData ? <button onClick={()=>{navigate(`/usertesting/online/results/${resultData.name}/all`)} } type="button" className="btn btn-secondary btn-icon-text mg-r-10">View Detailed Results
										</button> : <></>
									}

									<button onClick={function(){window.location.href = 'https://app.fullstory.com/ui/171DYF/home'}} type="button" className="btn btn-primary my-2 btn-icon-text">
									Full Story Analytics
									</button>
								</div>
							</div>
						</div>
						<div className="row row-sm">
							<div className="col-sm-12 col-md-6 col-xl-3">
								<Total data={resultData}/>
							</div>
							<div className="col-sm-12 col-md-6 col-xl-9">
								<div className="row row-sm">
										<YesNo  data={resultData} resultType={'questions'} />
										<TrueFalse data={resultData} resultType={'questions'}  />
									<Scale data={resultData} resultType={'questions'}  />
									<Open data={resultData} resultType={'questions'} />
									<YesNo node={yesRef} data={resultData}  resultType={'survey'} />
									<TrueFalse data={resultData} resultType={'survey'}/>
									<Scale data={resultData} resultType={'survey'}/>
									<Open data={resultData} resultType={'survey'} />
								</div>
							</div>

							{/*<div className="col-sm-12 col-md-6 col-xl-3">*/}
							{/*	<TotalSlider data={resultData} />*/}
							{/*</div>*/}
							{/*<div className="col-sm-12 col-md-6 col-xl-3">*/}
							{/*	<TotalSlider data={resultData} />*/}
							{/*</div>*/}


						</div>

					</div>
				</div>
			</div>

		</Wrapper>
	)
}
const mapStateToProps = ({session, users, allTests}) => ({
	session, users, allTests
});

const mapDispatchToProps = dispatch => ({
	allTestData: (data) => dispatch(allTestData(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Results);

