import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import ProjectsLink from "../links/ProjectsLink";
import ReportingLink from "../links/ReportingLinks";
import CodeArchiveLinks from "../links/CodeArchiveLinks";
import NewsLinks from "../links/NewsLink";
import ProjectSubmenu from "../submenus/ProjectSubMenu";
const ExecutiveSidebar = () => {
    const [location, setLocation] = useState(useLocation());
    return (
        <>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'projects' ? 'active' : ''}`}>
                <ProjectSubmenu/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'reporting' ? 'active' : ''}`}>
                <ReportingLink/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'archive' ? 'active' : ''}`}>
                <CodeArchiveLinks/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'news' ? 'active' : ''}`}>
                <NewsLinks />
            </li>
        </>

    )
};

export default ExecutiveSidebar;
