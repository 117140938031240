import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const nativeSignUp = async (user) => {
  // console.log("signUp: /auth/user:", user)
  let response = await fetch(AUTH_BASE_URL + "auth/user", {
    method: "POST",
    body: JSON.stringify({ data: user }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  // console.log("Util/session/signUp.js:", response.json())
  return response;
};
