import React, { useState, useEffect } from 'react';
import Select from 'react-select';

function TemplateSelect({ templates, onChange, defaultValue, required, card, placeholder }) {
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        // Set default value if provided
        if (defaultValue) {
            setSelectedTemplate(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        // Reset selected template if templates prop changes
        setSelectedTemplate(null);
    }, [templates]);

    const handleChange = (selectedOption) => {
        setSelectedTemplate(selectedOption);
        if (onChange) {
            onChange(selectedOption.value); // Send the selected template ID
        }
    };

    return (
        <Select
            options={templates}
            isMulti={false}
            required={required}
            onChange={handleChange}
            value={selectedTemplate}
            className={`${card ? 'card-select' : null}`}
            classNamePrefix="select"
            placeholder={placeholder || 'Select a template'}
        />
    );
}

export default TemplateSelect;

