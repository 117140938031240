import AUTH_BASE_URL from "../AUTH_BASE_URL";

export default async function (data) {
	return fetch(`${AUTH_BASE_URL}usertesting/collect`, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
		},
	}).then(response => response.json).then(data => data)
}
