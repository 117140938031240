const ConfirmationModal = props => {

    return(
        <div className="modal d-block confirmation-modal">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow">
                    <div className="modal-header">
                        <h2 className="modal-title">Are you sure?</h2>
                    </div>

                    <p>{props.message}</p>

                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="button" onClick={props.save}>Yes</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.close}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal