import {signout as signOutUtil} from '../../util/session/signOut';

export const SIGNOUT_CURRENT_USER = 'SIGNOUT_CURRENT_USER';

const signoutCurrentUser = () => ({
  type: SIGNOUT_CURRENT_USER
});

export const signout = () => async dispatch => {
  // console.log('signing out')
  const response = await signOutUtil();
  await response;
  if (response.ok) {
    return dispatch(signoutCurrentUser());
  } else {
    // console.log('error')
  }
};
