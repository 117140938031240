import React from "react";
import FileTypeFilter from "../../../assets/FilterFiles/FileTypeFilter";
import ContentToggle from "../../../global/ContentToggle";

const Assets = ({
  parentName,
  parentDesignAssets,
  parentNonDesignAssets,
  taskDesignAssets,
  taskNonDesignAssets,
  handleOpenEditAssetModal,
  handleOpenDeleteAssetModal,
  type
}) => {
  const sectionName = "Assets";
  const header = parentName? parentName : "Project";

  return (
    <div className="row">
      <div className="card pd-8 flex-grow-1">
        <div className="card-body">
          {/* Project Assets Start*/}
          <ContentToggle
            showContent={(parentDesignAssets && parentDesignAssets?.length > 0) || (parentNonDesignAssets && parentNonDesignAssets?.length > 0)}
            sectionName={sectionName}
            header={header}
          >
            <div className="mg-b-40 bd-b pd-b-20">
              <p className="mg-b-0 pd-8">Designs</p>
              <ul className="pd-8">
                {parentDesignAssets && parentDesignAssets.length > 0 ? (
                  <FileTypeFilter
                    linkData={parentDesignAssets}
                    handleOpenEditAssetModal={handleOpenEditAssetModal}
                    handleOpenDeleteAssetModal={handleOpenDeleteAssetModal}
                  />
                ) : (
                  <p className="empty">There are no designs in this project.</p>
                )}
              </ul>
              <p className="mg-b-0 pd-8">Other assets</p>
              <ul className="pd-8">
                {parentNonDesignAssets && parentNonDesignAssets.length > 0 ? (
                  <FileTypeFilter
                    linkData={parentNonDesignAssets}
                    handleOpenEditAssetModal={handleOpenEditAssetModal}
                    handleOpenDeleteAssetModal={handleOpenDeleteAssetModal}
                  />
                ) : (
                  <p className="empty">
                    There are no other assets in this project.
                  </p>
                )}
              </ul>
            </div>
          </ContentToggle>{" "}
          {/* Project Assets End */}
          {/* Task Assets Start */}
          <h4 className="mg-b-0 pd-8">{type && type === "subtask" ? "Subtask Assets": "Task Assets"}</h4>
          <p className="mg-b-0 pd-8">Designs</p>
          <ul className="pd-8">
            {taskDesignAssets && taskDesignAssets.length > 0 ? (
              <FileTypeFilter
                linkData={taskDesignAssets}
                handleOpenEditAssetModal={handleOpenEditAssetModal}
                handleOpenDeleteAssetModal={handleOpenDeleteAssetModal}
              />
            ) : (
              <p className="empty">There are no designs in this project.</p>
            )}
          </ul>
          <p className="mg-b-0 pd-8">Other assets</p>
          <ul className="pd-8">
            {taskNonDesignAssets && taskNonDesignAssets.length > 0 ? (
              <FileTypeFilter
                linkData={taskNonDesignAssets}
                handleOpenEditAssetModal={handleOpenEditAssetModal}
                handleOpenDeleteAssetModal={handleOpenDeleteAssetModal}
              />
            ) : (
              <p className="empty">
                There are no other assets in this project.
              </p>
            )}
          </ul>
        </div>

        {/* Task Assets End */}
      </div>
    </div>
    // </div>
  );
};

export default Assets;
