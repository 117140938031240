import React from "react";
import TotalChart from "./TotalChart";

function Total (props) {

	return (
		<div className="card custom-card pd-0 ">
			<div className="card-header border-bottom-0 pb-0">
				<label className="main-content-label mb-2 pt-1">Overview</label>
			</div>
			<div className="card-body sales-product-info ot-0 pt-0 pb-0 d-flex flex-column justify-content-baseline ali" style={{position: 'relative'}}>
				<div id="recentorders" >
					<TotalChart data={props.data}/>
				</div>
				<div className="row sales-product-infomation pb-0 mb-0 mx-auto wd-100p">

					<div className="col-md-6 col text-center float-right">
						<p className="mb-0 d-flex justify-content-center ">
						Not Taken
						</p>
						<h3 className="mb-1 font-weight-bold">{props.data ? props.data.users - props.data.answers.length : 0}</h3>
						<div className="d-flex justify-content-center ">
							<p className="text-muted">Last 5 Days</p>
						</div>
					</div>
					<div className="col-md-6 col justify-content-center text-center">
						<p className="mb-0 d-flex justify-content-center" >
							Results
						</p>
						<h3 className="mb-1 font-weight-bold">{props.data ? props.data.answers.length : 0}</h3>
						<div className="d-flex justify-content-center ">
							<p className="text-muted ">Last 5 Days</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Total
