export function systemRole(arr) {
	if (arr) {
		let admin = arr.find(val => val === 'admin'),
			developer = arr.find(val => val === 'developer'),
			maintainer = arr.find(val => val === 'maintainer'),
			executive = arr.find(val => val === 'executive'),
			guest = arr.find(val => val === 'guest');
		if (admin) {
			return admin
		} else if (developer) {
			return developer
		} else if (maintainer) {
			return maintainer
		} else if (executive) {
			return executive
		} else {
			return guest
		}
	} else {
		return 'guest'
	}
}
