export default function (name, token) {
    return fetch(`https://gitlab.com/api/v4/groups/${process.env.REACT_APP_GL_GROUP_ID}/search?scope=projects&search=${name}`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    }).then(response => {
        return response.json()
    }).then(result => {
        return result
    })
}