import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}`;
};


const ContributionChart = ({ gitLabData }) => {
    const ref = useRef();

    useEffect(() => {
        const data = processData(gitLabData);
        drawChart(ref.current, data);
    }, [gitLabData]);

    const processData = (data) => {
        let dateMap = new Map();
        const uniqueDates = new Set(data.map(entry => new Date(entry.created_at).toDateString()));
        const minDate = new Date(Math.min(...Array.from(uniqueDates).map(date => new Date(date))));
        const maxDate = new Date(Math.max(...Array.from(uniqueDates).map(date => new Date(date))));
        const completeDateRange = generateDateRange(minDate, maxDate);

        completeDateRange.forEach(date => {
            dateMap.set(date.toDateString(), 0);
        });

        data.forEach(entry => {
            let createdAt = new Date(entry.created_at).toDateString();
            let count = entry.push_data?.commit_count ?? 0; // Use nullish coalescing to default to 0
            if (typeof count !== 'number' || isNaN(count)) {
                count = 1; // Fallback to 0 if count is not a number
            }
            dateMap.set(createdAt, (dateMap.get(createdAt) || 0) + count); // Use || 0 to handle undefined map entries
        });

        return Array.from(dateMap).map(([date, count]) => {
            const entry = data.find(item => new Date(item.created_at).toDateString() === date);
            const id = entry ? entry.id : null;
            return { date, count: count ?? 0, id }; // Ensure count is a number
        });
    };


    function generateDateRange(startDate, endDate) {
        let dateRange = [];
        let currentDate = new Date(startDate);

        // Adjust the start date to the previous Sunday
        while (currentDate.getDay() !== 0) {
            currentDate.setDate(currentDate.getDate() - 1);
        }

        while (currentDate <= endDate) {
            dateRange.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateRange;
    }

    const drawChart = (element, data) => {
        const padding = { top: 20, right: 20, bottom: 40, left: 45 };
        const gridSize = 40;
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const weeksCount = Math.ceil(data.length / 7);
        const width = padding.left + 7 * gridSize + padding.right;  // Calculate the width based on the number of columns

        const svg = d3.select(element)
            .attr("width", width)  // Set the width dynamically
            .attr("height", height);  // Set the height dynamically

        const xScale = d3.scaleBand()
            .domain(d3.range(7))
            .range([padding.left, padding.left + 7 * gridSize]);

        const yScale = d3.scaleBand()
            .domain(d3.range(weeksCount))
            .range([padding.top, padding.top + weeksCount * gridSize]);

        const colorDomain = [0, d3.max(data, d => d.count)];
        const colors = ["#D0E1FF", "#A2BDFE", "#8398f2", "#717feb"];

        const colorScale = d3.scaleQuantize()
            .domain(colorDomain)
            .range(colors);
        const groups = svg.selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('id', d => d.id)
            .attr('class', 'rect-hover')
            .style('cursor', 'pointer')
            .on('click', function(d) {
                const targetId = `#commit-${d.target.parentNode.id}`;
                const listItem = document.querySelector(targetId);
                if (listItem) {
                    listItem.scrollIntoView({ behavior: 'smooth' });
                }
            });


        groups.append('rect')
            .attr("x", d => xScale(new Date(d.date).getDay()))
            .attr("y", (d, i) => yScale(Math.floor(i / 7)))
            .attr("width", gridSize)
            .attr("height", gridSize)
            .attr("fill", d => (d.count === 0) ? "#e6e6e6" : colorScale(d.count))
            .attr("stroke", "#fff");

        const getLuminance = (color) => {
            const rgb = d3.rgb(color);
            return 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b;
        }

        groups.append('text')
            .attr('x', d => xScale(new Date(d.date).getDay()) + gridSize / 2)
            .attr('y', (d, i) => yScale(Math.floor(i / 7)) + gridSize / 2)
            .attr('dy', '0.35em')
            .attr('text-anchor', 'middle')
            .attr('fill', d => {
                const color = colorScale(d.count);
                if (color === colors[0] || color === colors[1]) {
                    return 'black';
                } else {
                    return 'white';
                }
            })
            .style('font-weight', 'bold')  // Make the numbers bold
            .text(d => d.count === 0 ? "" : d.count);




        const startingDay = new Date(data[0].date).getDay();
        const adjustedDaysOfWeek = [...daysOfWeek.slice(startingDay), ...daysOfWeek.slice(0, startingDay)];

        svg.selectAll(".dayLabel")
            .data(adjustedDaysOfWeek)
            .enter().append("text")
            .text(d => d)
            .attr("x", (d, i) => xScale(i) + gridSize / 2)
            .attr("y", padding.top + weeksCount * gridSize + 20)
            .style("text-anchor", "middle")
            .attr("class", "dayLabel")
            .style("font-size", "12px");


        svg.selectAll(".weekLabel")
            .data(data.filter((_, i) => i % 7 === 0))
            .enter().append("text")
            .text(d => formatDate(d.date))
            .attr("x", padding.left - 10)
            .attr("y", (d, i) => yScale(i) + gridSize / 2)
            .style("text-anchor", "end")
            .attr("dy", ".35em")
            .attr("class", "weekLabel")
            .style("font-size", "12px");
    };

    const padding = { top: 20, right: 20, bottom: 40, left: 45 };
    const gridSize = 40;
    const weeksCount = Math.ceil(processData(gitLabData).length / 7);
    const width = padding.left + 7 * gridSize + padding.right;
    const height = padding.top + weeksCount * gridSize + padding.bottom;
    console.log('hello')
    return (
        <div style={{ display: 'flex', paddingTop: 15, paddingBottom: 0, flexDirection: "column" }}>
            <h6>Commits for the last 30 days:</h6>
            <svg ref={ref} width="400" height="260"></svg>
        </div>
    )
};

export default ContributionChart;
