import React, {useEffect, useState, Suspense} from "react";
import {useNavigate} from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "../global/Avatar";

function Table(props) {
    const navigate = useNavigate();
    function handleViewReport(id) {
        navigate('/reporting/'+id)
    }

    if (props.loading) {
        return (
            <div className="table-responsive userlist-table">
                <table className="table card-table table-striped table-vcenter mb-0">
                    <thead>
                    <tr>
                        <th className={'wd-lg-15p'}><span>Creator</span></th>
                        <th className={'wd-lg-15p'}><span>Report Name</span></th>
                        <th className={'wd-lg-25p'}><span>Timerange</span></th>
                        <th className={'wd-lg-15p'}><span>Teammates</span></th>
                        <th className={'wd-lg-15p'}><span>Created</span></th>
                        <th className={'wd-lg-25p'}><span>Actions</span></th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan={1}><div className={`skeleton-text user-avatar`} style={{ width: '32px', height: '32px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '92px', height: '38px'}}></div></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    } else {

        if (props.data && props.data.length > 0) {

            return (
                <div className="table-responsive userlist-table">
                    <table className="table card-table table-striped table-vcenter mb-0">
                        <thead>
                        <tr>
                            <th className={'wd-lg-15p'}><span>Creator</span></th>
                            <th className={'wd-lg-15p'}><span>Report Name</span></th>
                            <th className={'wd-lg-25p'}><span>Timerange</span></th>
                            <th className={'wd-lg-15p'}><span>Teammates</span></th>
                            <th className={'wd-lg-15p'}><span>Created</span></th>
                            <th className={'wd-lg-25p'}><span>Actions</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            props.newLoading ?
                                <tr>
                                    <td colSpan={1}><div className={`skeleton-text user-avatar`} style={{ width: '40px', height: '40px'}}></div></td>
                                    <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                                    <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                                    <td colSpan={1}><div className={`skeleton-text user-avatar`} style={{ width: '40px', height: '40px'}}></div></td>
                                    <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                                    <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100px', height: '38px'}}></div></td>
                                </tr>  : null
                        }{
                            props.data.map(report => {
                                return (
                                    <tr>
                                        <td colSpan={1}>{<Avatar user={report.creatorDetails} size='md'/>}</td>

                        <td colSpan={1}>{report.name}</td>

                                        <td colSpan={1}>{report.dateRange}</td>
                                        <td>
                                            <ul className="user-row d-flex align-items-center mg-b-0">
                                                {report.teammatesDetails.map((user, index) => {
                                                    if (index <= 3) {
                                                        return (
                                                            <li key={index}>
                                                                <Avatar user={user} size="md" />
                                                            </li>
                                                        );
                                                    }
                                                })}
                                            </ul>
                                        </td>
                                        <td colSpan={1}>{report.createdAt}</td>
                                        <td colSpan={1}><button onClick={()=>{handleViewReport(report._id)}} type="button" className="btn btn-secondary my-2 btn-icon-text mg-0" style={{margin: '0'}}>View Report</button></td>
                                    </tr>
                                )
                            })
                        }

                        </tbody>
                    </table>
                </div>
            )
        } else {
            return (
                <div className="table-responsive userlist-table">
                    <table className="table card-table table-striped table-vcenter mb-0">
                        <thead>
                        <tr>
                            <th className={'wd-lg-15p'}><span>Creator</span></th>
                            <th className={'wd-lg-15p'}><span>Report Name</span></th>
                            <th className={'wd-lg-15p'}><span>Teammates</span></th>
                            <th className={'wd-lg-15p'}><span>Created</span></th>
                            <th className={'wd-lg-35p'}><span>Actions</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan={6}>There are no reports generated.</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            )
        }
        return null
    }


}

const mapStateToProps = ({session,}) => ({
    session,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Table);
