import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";

import fetchAndSetUserData from "../../util/dev-ops/fetchAndSetUserData";
import {allGitLabRepos} from "../../actions/allGitlabRepos";
import Select from "react-select";

function RepoSelect({repos, session, allGitLabRepos, onChange, value, defaultValue, required, card}) {
    const [filtered, setFiltered] = useState([]);
    const [repoValue, setRepoValue] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const data = {
                user: session,
                token: process.env.REACT_APP_GL_TOKEN,
            };
            let response = await fetchAndSetUserData(data);
            let responseData = await response.json();
            allGitLabRepos(responseData.repos);
        }
        catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        if (defaultValue) {
            console.log('DEFAULT', defaultValue)
            // Flatten the array of arrays into a single array of objects
            if (Array.isArray(defaultValue)) {
                const flattenedDefaultValue = [].concat(...defaultValue);
                setRepoValue(flattenedDefaultValue);
            } else {
                setRepoValue(defaultValue)
            }
        }
        if (repos) {
            const newFiltered = repos.map(repo => ({
                value: repo.id,
                label: repo.name,
            }));
            setFiltered(newFiltered);
        } else {
            fetchData();
        }
    }, [repos]);


    const handleChange = (selectedOption) => {
        setRepoValue(selectedOption);
        if(onChange) {
            onChange(selectedOption);
        }
    };

    return (
        <Select
            options={filtered ? filtered : []}
            isMulti={true}
            required={required}
            onChange={handleChange}
            value={repoValue}
            className={`${card ? "card-select" : null}`}
            classNamePrefix="select"
            placeholder={'Select a repository'}
        />
    );
}

const mapStateToProps = ({session, repos}) => ({
    session, repos
});

const mapDispatchToProps = dispatch => ({
    allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RepoSelect);
