import React, {useEffect, useState} from 'react'
import Wrapper from '../global/Wrapper'
import DropDown from './DropDown';
import EmailModal from './EmailModal';
import CreateTemplate from './CreateTemplate';
import EditTemplate from './EditTemplate'
import { getTemplates } from "../../util/mailgun/getTemplates";
import { deleteTemplate } from "../../util/mailgun/deleteTemplate";

function Mailgun (props) {

    const [templates, setTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState('')
	const [showEmailModal, setShowEmailModal] = useState(false)
	const [showCreateTemplate, setShowCreateTemplate] = useState(false)
	const [showEditTemplate, setShowEditTemplate] = useState(false)

	useEffect(()=> {
		if(templates.length === 0){
			getAllTemplates()
		}
    },[])

	const getAllTemplates = () =>{
		getTemplates()
		.then(response => response.json())
		.then(data=>{
			// console.log(data.items)
			setTemplates(data.items)
		})
	}

    return (
		<>
			{
				showEmailModal ? 
					<EmailModal 
						selectedTemplate={selectedTemplate}
						handleCloseModal={()=>{
							setShowEmailModal(false)
							setSelectedTemplate('')
						}
						}
					/>
					: null
			}
			{
				showCreateTemplate ? 
					<CreateTemplate
						getAllTemplates={getAllTemplates}
						handleCloseModal={()=>{setShowCreateTemplate(false)}}
					/>
					: null
			}
			{
				showEditTemplate ? 
					<EditTemplate
						getAllTemplates={getAllTemplates}
						selectedTemplate={selectedTemplate}
						handleCloseModal={()=>{
							setShowEditTemplate(false)
							setSelectedTemplate('')
						}}
					/>
					: null
			}
			<Wrapper>
			<div className="main-content pt-0 mailgun-page">
				<div className="container-fluid">
					<div className="inner-body users">
						{/* Page Header */}
						<div className="page-header">
							<div>
								<h2 className="main-content-title tx-24 mg-b-5">Mailgun - Email System</h2>
							</div>
							<button className='btn btn-primary'
								onClick={()=>{setShowCreateTemplate(true)}}
							>
								Create new template
							</button>
						</div>
						{/* End Page Header */}
						{/*Row*/}
						<div className="row row-sm">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
								<div className="card custom-card pd-0">
									<div className="card-body pd-0">
										<div className="table-responsive userlist-table">
											<table className="table card-table table-striped table-vcenter mb-0">
												<thead>
												<tr>
													<th><span>Template</span></th>
													<th><span>Description</span></th>
													<th><span>Type</span></th>
													<th><span>Created</span></th>
													<th>Action</th>
												</tr>
												</thead>
												<tbody>
													{
														templates && templates.length > 0 ? <>
														{
															templates.map((template, index)=>{
																return (
																	<tr key={index}>
																		<td>
																			<button 
																				className='link'
																				onClick={()=>{
																					setSelectedTemplate(template.name)
																					setShowEditTemplate(true)
																				}}
																			>
																				{template.name}
																			</button>
																		</td>
																		<td>{template.description}</td>
																		<td>{template.createdBy}</td>
																		<td>{new Date(template.createdAt).toLocaleDateString()}</td>
																		<td>
																				<DropDown label={"Manage"} icon={"fa-chevron-down"}>
																					<li>
																						<button 
																							onClick={()=>{
																							setSelectedTemplate(template.name)
																							setShowEmailModal(true)
																						}}
																					>
																						Send test email
																						</button>
																					</li>
																					<li>
																						<button  
																							className='text-danger'
																							onClick={()=> {
																								let mailData = {
																									name: template.name
																								}
																								deleteTemplate(mailData).then((response)=>{
																									getAllTemplates()
																								})
																							}}
																						>
																						Delete Template
																						</button>
																					</li>
																				</DropDown>
																		</td>
																	</tr>
																)
															})
														}
														</> : null
													}
													
												</tbody>
											</table>
											{/*<Pagination />*/}
										</div>
									</div>
								</div>
							</div>{/* COL END */}
						</div>
						{/* row closed  */}
					</div>
				</div>
			</div>


		</Wrapper>
	</>
    )
}

export default Mailgun
