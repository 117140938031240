import React, { useState } from 'react';
import { connect } from 'react-redux';
import AUTH_BASE_URL  from '../../../util/AUTH_BASE_URL';
import {toastMessage} from "../../../actions/toast";
import updatePersonal from "../../../util/usertesting/updatePersonal";
import allTests from "../../../util/usertesting/allTests";
import {allTestData} from "../../../actions/allUserTests";
import SpinnerButton from "./SpinnerButton";
import SpinnerDeleteButton from "./SpinnerDeleteButton";
const IntroductionVideo = (props) => {
	const [fileName, setFileName] = useState('');
	const [file, setFile] = useState(null);
	const [loadingState, setLoadingState] = useState(false);
	const [deleteLoadingState, setDeleteLoadingState] = useState(false);
	function handleFileChange(event) {
		const file = event.target.files[0];
		if (file) {
			setFileName(file.name);
		} else {
			setFileName('');
		}
		setFile(file);
	}
	async function handleDelete(event) {
		event.preventDefault();
		setDeleteLoadingState(true)
		console.log(props)
		let data = {
			name: props.data.name,
			id: props.data._id,
			fileName: props.data.introductionData.fileName, // Replace with the actual file name
		};

		try {
			const response = await fetch(`${AUTH_BASE_URL}usertesting/intro/delete`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});

			if (!response.ok) {
				props.toastMessage(`HTTP error ${response.status}`)
				throw new Error(`HTTP error ${response.status}`);
			}

			const result = await response.json();
			if (result) {
				await allTests().then(data => {
					props.allTestData(data)
				})
				props.handleCloseNewModal()
				props.toastMessage(`File deleted successfully and UserTest updated`)
			}
		}

		catch (error) {
			props.toastMessage(`Error deleting file or updating UserTest`)

			console.error('Error deleting file or updating UserTest:', error.message);
		}
	}

	async function handleSave() {

		setLoadingState(true)
		let data = {}
		data.name = props.data.name
		if (!file) {
			props.toastMessage('Please select a file to upload')
			return;
		}

		const formData = new FormData();
		formData.append('file', file);
		formData.append('name', props.data.name);
		try {
			const response = await fetch(`${AUTH_BASE_URL}usertesting/intro/upload`, {
				method: 'PUT',
				body: formData,
			});

			if (!response.ok) {
				props.toastMessage('Theres a problem with the server, please try again later')
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			props.toastMessage('File uploaded successfully')
			await allTests().then(data => {
				props.allTestData(data)
			})
			props.handleCloseNewModal()
		} catch (error) {
			props.toastMessage(`Error uploading file: ${error.message}`)
		}
	}

	console.log(props, 'WTF', props.data.introductionData.video)
	return (
		<div className="modal d-block">
			<div className="modal-dialog" role="dialog">
				<div className="modal-content modal-content-demo shadow">
					<div className="modal-header">
						<h6 className="modal-title">Add Introduction Video</h6>
					</div>
					<form className={'pd-30'}>
						<div className="row row-sm card-body">
							<div className="col-sm-12 col-md-12 col-lg-12">
								{
									props.data.introductionData.video.length === 0 ?
										<><p>Introduction Video</p>
										<div className="input-group file-browser d-flex" >
											<input
												type="text"
												className="form-control border-right-0 browse-file"
												placeholder="Select a video"
												readOnly
												value={fileName}
											/>
											<label className="input-group-btn">
												<span className="btn btn-primary" style={{display: 'flex', alignItems: 'center', marginLeft: '-5px'}}>
													Browse <input type="file" accept="video/*" onChange={handleFileChange} style={{ display: "none" }} multiple="" />
												</span>
											</label>
										</div></> : <>{props.data.introductionData ? props.data.introductionData.video : ''}
										<SpinnerDeleteButton onClick={handleDelete} loading={deleteLoadingState}/>
										</>
								}

							</div>
						</div>

					</form>
					<div className="modal-footer">
						<SpinnerButton onClick={handleSave} deleteLoading={deleteLoadingState} loading={loadingState} buttonText="Save & Upload" />
						<button
							className="btn ripple btn-secondary muted"
							type="button"
							onClick={props.handleCloseNewModal}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
	toastMessage: (data) => dispatch(toastMessage(data)),
	allTestData: (data) => dispatch(allTestData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionVideo);
