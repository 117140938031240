/**
 * Description: Filter out all non-pearon scraper
 * @param {*} contacts - Array of user object.
 * @param {Boolean} pullEndeavorUsers - Determine if only
 * Endeavor or nonEndeavor users should be retreived
 *  Syntax: [{userPrincipalName: string, displayName: string, id: number}]
 * @returns  Filtered Object array:  [{id, label, value}]
 */
export const filterAndFormatOptions = (
  contacts,
  userEmails,
  pullEndeavorUsers
) => {
  const regex = /(@pearson.com)/i;
  /* If we are pulling non Endeavor user, then filter out Endeavor users, 
  else If we are pulling only Endeavor user, then filter out non-endeavor users */
  const formattedContacts = contacts
  .filter((contact) => {
    const emailExists = userEmails.includes(
      contact?.userPrincipalName?.toLowerCase()
    );

    if ((pullEndeavorUsers && emailExists) || (pullEndeavorUsers !== true && !emailExists)) {
      return regex.test(contact.userPrincipalName);
    }

    return false;
  })
    .map((contact) => {
      return {
        label: contact.userPrincipalName,
        value: contact.displayName,
        id: contact.id,
      };
    });
  return formattedContacts;
};

export const styles = {
  multiValue: (styles) => {
    return {
      ...styles,
      // backgroundColor: "#ABD9D0",
    };
  },
  menuList: (base) => {
    return {
      ...base,
      padding: 0,
      backgroundColor: "white",
    };
  },
};

export const themes = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    // primary25: '#AFDED588',
    // primary: '#448fa3',
  },
});

export const lowerCaseEmail = (arrayOfObjects) => {
  const updatedArray = arrayOfObjects.map((obj) => ({
    ...obj,
    userPrincipalName: obj?.userPrincipalName?.toLowerCase(),
  }));
  return updatedArray;
};
