const retrieveDisplayGroup = (status, responsible) => {
  //   console.log("RETRIEVEDG: Status", status, " responsible:", responsible);
  switch (true) {
    case status === "new":
      return "upcoming";
    case status === "active":
      return "current";
    case status === "inactive":
      return "current";
    case status === "closed":
      return "past";
    default:
      return null;
  }
};

export default retrieveDisplayGroup;
