import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const ProtectedRoute = (path, Component, loggedIn) => {
    const location = useLocation();

    useEffect(() => {
        // Only run this effect if the path requires protection and the user is not logged in
        if (!loggedIn && path.split(':')[0] === location.pathname.split(/[0-9]/)[0]) {
            console.log('PROTECTED', location.pathname);
            localStorage.setItem("redirect", location.pathname);
        }
    }, [location, loggedIn, path]); // Only re-run the effect if these values change

    return (
        <Route
            exact
            path={path}
            element={loggedIn ? <Component /> : <Navigate to="/sign-in" />}
        />
    );
};

