import React, {useEffect, useRef} from 'react';

const SpinnerDeleteButton = props => {
	const buttonRef = useRef(null)
	const deleteIconRef = useRef(null)
	const loaderRef = useRef(null)

	useEffect(() => {
		if(props.loading === true){
			handleLoading()
		}else{
			handleReset()
		}
	}, [props.loading])

	const handleLoading = () => {
		if (buttonRef.current && deleteIconRef.current && loaderRef.current) {
			buttonRef.current.setAttribute('disabled', true)
			deleteIconRef.current.style.display = 'none'
			loaderRef.current.style.display = 'inline-block'
		}
	}

	const handleReset = () => {
		if (buttonRef.current && deleteIconRef.current && loaderRef.current) {
			buttonRef.current.removeAttribute('disabled')
			deleteIconRef.current.style.display = 'inline-block'
			loaderRef.current.style.display = 'none'
		}
	}

	return(
		<button type={'button'} ref={buttonRef} disabled={props.disabled ? props.disabled : false} onClick={props.onClick} className="btn ripple btn-danger d-inline-flex align-items-center mg-l-8">
			<svg ref={deleteIconRef}
				 aria-hidden="true"
				 focusable="false"
				 data-prefix="fas"
				 data-icon="trash"
				 className="svg-inline--fa fa-trash text-white"
				 role="img"
				 xmlns="http://www.w3.org/2000/svg"
				 viewBox="0 0 448 512"
			>
				<path
					fill="currentColor"
					d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"
				/>
			</svg>
			<span ref={loaderRef} aria-hidden="true" className="spinner-border spinner-border-sm" role="presentation" style={{display: 'none'}}></span>
		</button>
	)
}

export default SpinnerDeleteButton
