import { useMemo } from "react";
import {allProjects} from "./getAllProjects";
import { allProjectData } from "../../actions/allProjects";
import { useDispatch } from "react-redux"

export const useGetAndSetProjects = () => {
    
    const dispatch = useDispatch();

    const actions = useMemo(() => ({
    
        getAndSetProjects (session) {
            allProjects(session.userId,  Number(session.systemAccess)).then(async responseData => {
                dispatch(allProjectData(await responseData))
            })
        },
    
      }), [dispatch])

      return actions
    
};
