import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Wrapper from "../../../global/Wrapper";
import DigitalOceanSettings from "../../../settings/DigitalOcean";
import { useNavigate } from "react-router-dom";
import Table from "./Table";
import { toastMessage } from "../../../../actions/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LiveBuilds from './LiveBuilds'; // Import the LiveBuilds component
import AUTH_BASE_URL from "../../../../util/AUTH_BASE_URL"; // Make sure this path is correct

function TableContainer(props) {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [intervalLoading, setIntervalLoading] = useState(false);
	const [noToken, setNoToken] = useState(null);
	const [showDigitalOcean, setShowDigitalOcean] = useState(false);
	const [apps, setApps] = useState([]);
	const [dataFetched, setDataFetched] = useState(false);

	useEffect(() => {
		setLoading(true);
	}, []);

	useEffect(() => {
		if (!props.session.digitalAccessToken && !noToken) {
			setNoToken(true);
			setShowDigitalOcean(true);
			props.toastMessage('Your Digital Ocean token has expired. Please re-authenticate.');
		} else if (props.session.digitalAccessToken && !dataFetched) {
			fetchData(props.session.digitalAccessToken, false);
		}
	}, [props.session.digitalAccessToken, noToken, props, dataFetched]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (props.session.digitalAccessToken) {
				fetchData(props.session.digitalAccessToken, true);
			}
		}, 10000); // 30 seconds interval

		// Cleanup interval on component unmount
		return () => clearInterval(interval);
	}, [props.session.digitalAccessToken]);

	const sortApps = (apps) => {
		return apps.sort((a, b) => {
			// Move offline apps to the bottom
			if (a.active_deployment && a.active_deployment.phase === 'OFFLINE') return 1;
			if (b.active_deployment && b.active_deployment.phase === 'OFFLINE') return -1;

			// Sort by last_deployment_active_at in descending order
			const dateA = new Date(a.last_deployment_active_at || '1970-01-01'); // Use a default far-past date if undefined
			const dateB = new Date(b.last_deployment_active_at || '1970-01-01');

			return dateB - dateA;
		});
	};



	const fetchData = async (token, isInterval) => {
		try {
			if (isInterval) {
				setIntervalLoading(true);
			} else {
				setLoading(true);
			}
			const digitalOceanApps = await fetchDigitalOceanApps(token);
			const dbApps = await fetchDbApps();
			const combinedApps = combineApps(digitalOceanApps, dbApps);
			setApps(combinedApps);
			setDataFetched(true);
			if (isInterval) {
				setIntervalLoading(false);
			} else {
				setLoading(false);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
			if (isInterval) {
				setIntervalLoading(false);
			} else {
				setLoading(false);
			}
		}
	};

	const fetchDigitalOceanApps = async (token) => {
		let allApps = [];
		let page = 1;
		let hasMore = true;

		while (hasMore) {
			const response = await fetch(`https://api.digitalocean.com/v2/apps?page=${page}&per_page=120`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				}
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();
			allApps = allApps.concat(data.apps || []);

			hasMore = data.links && data.links.pages && data.links.pages.next;
			page += 1;
		}

		// Calculate monthly price for each app
		allApps.forEach(app => {
			app.monthly_price = calculateMonthlyPrice(app);
		});

		return allApps;
	};

	const fetchDbApps = async () => {
		const response = await fetch(`${AUTH_BASE_URL}apps`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		});

		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const data = await response.json();
		return data;
	};

	const combineApps = (digitalOceanApps, dbApps) => {
		// Create a set of DigitalOcean app IDs
		const digitalOceanAppIds = new Set(digitalOceanApps.map(app => app.id));

		// Filter out dbApps that are already present in the DigitalOcean apps
		const filteredDbApps = dbApps.filter(app => !digitalOceanAppIds.has(app.id)).map(app => {
			// Remove href links from the database app
			const { live_url, ...rest } = app;
			return { ...rest };
		});

		// Combine the DigitalOcean apps with the filtered database apps
		const combinedApps = [...digitalOceanApps, ...filteredDbApps];

		// Sort the combined apps
		return sortApps(combinedApps);
	};


	const calculateMonthlyPrice = (app) => {
		let total = 0;
		if (app.spec && app.spec.services) {
			app.spec.services.forEach(service => {
				total += service.instance_size_slug === 'basic-xxs' ? 5 : 0; // Example pricing logic
			});
		}
		return total;
	};



	return (
		<Wrapper>
			<div className="main-content pt-0 digitalocean-repos">
				<div className="container-fluid">
					<div className="inner-body users">
						{/* Page Header */}
						<div className="page-header">
							<div>
								<h2 className="main-content-title tx-24 mg-b-5">Digital Ocean Hosting - App Platform</h2>
							</div>
							<div className='page-group-controls'>
								<button onClick={() => {
									navigate('/hosting/apps/templates')
								}} type="button" className="btn btn-secondary btn-icon-text">
									Manage Templates
								</button>
								<button onClick={() => {
									navigate('/hosting/apps/add')
								}} type="button" className="btn btn-primary btn-icon-text">
									<FontAwesomeIcon className='sidemenu-icon mg-r-4' icon={`fa-solid fa-plus`}/> Add
									App
								</button>

							</div>
						</div>
						<div className="row row-sm">
							{
								showDigitalOcean ?
									<div className={"col-sm-12 col-md-12 col-lg-12 col-xl-4 grid-margin"}>
										<div className="card custom-card pd-0">
											<div className={'figma-auth'}><DigitalOceanSettings /></div>
										</div>
									</div> :
									<div className={"col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin"}>
										<div className="card custom-card pd-0">
											<Table fetchData={fetchData} loading={loading && !intervalLoading} apps={apps} />
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}

const mapStateToProps = ({ session }) => ({
	session
});

const mapDispatchToProps = dispatch => ({
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TableContainer);
