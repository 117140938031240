import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserLinks from "../links/UserLink";
import {connect} from "react-redux";

const DesignOpsSubmenu = (props) => {
    const [location, setLocation] = useState(useLocation());
    const navigate = useNavigate();
    return (
        <>
            <Link className='nav-link' to={'/design-ops/figma/projects'}>
                <span className="shape1"/>
                <span className="shape2"/>
                {
                    props.session.figmaAccessToken ?
                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1"
                             className={'svg-inline--fa sidemenu-icon'} viewBox="0 25 240 240"
                             style={{fill: '#0acf83', fillRule: 'evenodd'}}>
                            <title>Figma.logo</title>
                            <desc>Created using Figma</desc>
                            <path id="path0_fill"
                                  d="M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z"
                                  style={{fill: '#0acf83'}}/>
                            <path id="path1_fill" d="M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z"
                                  style={{fill: '#a259ff'}}/>
                            <path id="path1_fill_1_" d="M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z"
                                  style={{fill: '#f24e1e'}}/>
                            <path id="path2_fill" d="M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z"
                                  style={{fill: '#ff7262'}}/>
                            <path id="path3_fill"
                                  d="M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z"
                                  style={{fill: '#1abcfe'}}/>
                        </svg>  : <FontAwesomeIcon className='sidemenu-icon' icon='fa-solid fa-brands fa-figma'/>
                }

                <span className="sidemenu-label">Figma</span>
            </Link>
            <ul className="nav-sub"
                style={location.pathname.split('/')[1] === 'design-ops' ? {overflow: 'visible'} : {overflow: 'hidden'}}>
                <li className="nav-sub-item">
                    <button onClick={() => {
                        navigate('/design-ops/figma/projects')
                    }} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Team Projects</span>
                    </button>
                </li>
                <li className="nav-sub-item">
                    <button onClick={() => {
                       navigate('/design-ops/figma/components')
                    }} className={'nav-sub-link text-white'}>
                        <span className="sidemenu-label pd-l-20">Components</span>
                    </button>
                </li>
            </ul>
        </>
    )

};

const mapStateToProps = ({session}) => ({
    session
});

export default connect(mapStateToProps)(DesignOpsSubmenu);


