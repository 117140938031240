import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const getUserWithPins = async (userId) => {
    // console.log("getUserWithPins: userId:", userId)
    return fetch(`${AUTH_BASE_URL}auth/user/pins/${userId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export const fetchMyTasks = async (id) => {
    // console.log("GetMyTasks: id:", id)
    return fetch(`${AUTH_BASE_URL}tasks/myTasks/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    })
}

/**
 * Retrieves a users pinnedEmployees tasks/subtasks
 * @param {*} emails 
 * @param {*} userId 
 * @returns 
 */
export const getUserTasks = async (emails, userId) => {
    // console.log("GetUserTasks: email:", scraper, "; userId:", userId)
    return fetch(`${AUTH_BASE_URL}tasks/userTasks/${userId}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ data: {emails }}),
    })
}

/**
 * Retrieves the pinnedEmployees from the user's record
 * and formats them for display in the GraphSelector's
 * default values.
 * @param {*} userId 
 * @returns 
 */
export const getPinnedDefaultUserTasks = async (userId) => {
    // console.log("GetUserTasks: email:", email)
    return fetch(`${AUTH_BASE_URL}tasks/defaultUsersTasks/${userId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    })
}