import React, {useState} from 'react';
import {useLocation} from "react-router-dom";
import ProjectsLink from "../links/ProjectsLink";
import ProjectSubmenu from "../submenus/ProjectSubMenu";
import ReportingLink from "../links/ReportingLinks";
import CodeArchiveLinks from "../links/CodeArchiveLinks";
import NewsLinks from "../links/NewsLink";
import TeamLink from "../links/TeamLink";
import UserLinks from "../links/UserLink";
import ClassTestLinks from "../links/ClassTestLink";
import ResourcesSubMenu from "../submenus/ResourcesSubMenu";
import DevOpsSubmenu from "../submenus/DevOpsSubMenu";
import DesignOpsSubmenu from "../submenus/DesignOpsSubMenu";
import HostingSubmenu from "../submenus/HostingSubMenu";
const AdminSidebar = () => {
    const [location, setLocation] = useState(useLocation());
    return (
        <>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'team' ? 'active' : ''}`}>
                <TeamLink/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'projects' ? 'active' : ''}`}>
                <ProjectSubmenu/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'reporting' ? 'active' : ''}`}>
                <ReportingLink/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'archive' ? 'active' : ''}`}>
                <CodeArchiveLinks/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'usertesting' ? 'active' : ''}`}>
                <ClassTestLinks/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'users' ? 'active' : ''}`}>
                <UserLinks/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'dev-ops' ? 'active' : ''}`}>
                <DevOpsSubmenu/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'hosting' ? 'active' : ''}`}>
                <HostingSubmenu/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'design-ops' ? 'active' : ''}`}>
                <DesignOpsSubmenu/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'news' ? 'active' : ''}`}>
                <NewsLinks/>
            </li>
            <li className={`nav-item ${location.pathname.split('/')[1] === 'resources' ? 'active' : ''}`}>
                <ResourcesSubMenu/>
            </li>
        </>

    )
};

export default AdminSidebar;
