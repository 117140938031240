export default async function (myData, token) {
    // console.log('myData', myData)
	if(myData && myData.id) {
		return fetch(`https://gitlab.com/api/v4/projects/${myData.id}/repository/files/.gitlab-ci.yml?ref=${myData.default_branch}&access_token=${token}`).then(async response => {
			return response.json();
		}).then(data => {
			return data
		})

	}

}
