import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearToast } from '../../actions/toast'; // Import the clearToast action

const Toast = () => {
    const toast = useSelector(state => state.toast);
    const dispatch = useDispatch();
    const toastRef = useRef(null);
    let timeoutId = useRef();

    useEffect(() => {
        if (toast && toast.length > 0) {
            clearTimeout(timeoutId.current);
            timeoutId.current = setTimeout(() => {
                handleClose();
            }, 5000);
        }
        return () => clearTimeout(timeoutId.current);
    }, [toast]);

    const handleClose = () => {
        if (toastRef.current) {
            toastRef.current.classList.add('fadeOut');
            setTimeout(() => {
                dispatch(clearToast()); // Dispatch action to clear toast
            }, 300);
        }
    };

    return (
        <>
            {toast ? (
                <div className="toast fadein" ref={toastRef}>
                    <div>
                        <p>{toast}</p>
                    </div>
                    <button className="close" onClick={handleClose}>
                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                    </button>
                </div>
            ) : null}
        </>
    );
};

export default Toast;
