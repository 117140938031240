import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Wrapper from "../../../../global/Wrapper";
import { toastMessage } from "../../../../../actions/toast";
import getDigitalOceanAppInfo from "../../../../../util/dev-ops/getDigitalOceanAppInfo";
import DomainCard from "./DomainCard";

const Details = (props) => {
    const { id } = useParams(); // Extract the app ID from the URL
    const [appInfo, setAppInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAppInfo = async () => {
            if (props.session.digitalAccessToken) {
                const data = await getDigitalOceanAppInfo(id, props.session.digitalAccessToken);
                console.log("Fetched App Info: ", data); // Log the fetched data to the console
                setAppInfo(data);
                setLoading(false);
            }
        };

        fetchAppInfo();
    }, [id, props.session.digitalAccessToken]);

    return (
        <Wrapper>
            <div className="main-content pt-0 digitalocean-repos">
                <div className="container-fluid">
                    <div className="inner-body users">
                        {/* Page Header */}
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">
                                    {loading ? "Loading..." : `App Details`}
                                </h2>
                            </div>
                        </div>
                        <div className="row row-sm">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 grid-margin">
                                <div className="card custom-card pd-0">
                                    {loading ? (
                                        <div>Loading...</div>
                                    ) : (
                                        appInfo?.app?.domains?.map((domain, index) => (
                                            <DomainCard domain={domain} key={index} />
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const mapStateToProps = ({ session }) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Details);
