import React from "react";
import CreatableSelect  from "react-select/creatable";
// import Select from "react-select";

const AddOptionSelect = (props) => {
  console.log("CREATABLE SELECT", props);
  // console.log("CustomSelect: Props.isMulti:", props.isMulti, "!props.isMulti", !props.isMulti, "required:", props.required);
  const displaySelect =
    props?.isMulti === undefined && props?.required === undefined ? (
      <CreatableSelect
        isMulti
        name={props.type}
        options={props.data}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={props.handler}
        value={props.value}
      />
    ) : !props.isMulti ? (
      <CreatableSelect
        name={props.type}
        options={props.data}
        className={`basic-multi-select ${props.card ? "card-select" : null}`}
        classNamePrefix="select"
        onChange={props.handler}
        // value={props.value}
        defaultValue={
          props.value?.length === 1 ? props.value : props.defaultValue
        }
        required={props.required ? props.required : false}
      />
    ) : props.required ? (
      <CreatableSelect
        isMulti
        name={props.type}
        required
        options={props.data}
        className={`basic-multi-select ${props.card ? "card-select" : null}`}
        classNamePrefix="select"
        onChange={props.handler}
        value={props.defaultValue}
      />
    ) : (
      <CreatableSelect
        isMulti
        name={props.type}
        options={props.data}
        className={`basic-multi-select ${props.card ? "card-select" : null}`}
        classNamePrefix="select"
        onChange={props.handler}
        value={props.value}
        defaultValue={
          props.value.length === 1 ? props.value : props.defaultValue
        }
      />
    );

  return <>{displaySelect}</>;
};

export default AddOptionSelect;
