import AUTH_BASE_URL from "../AUTH_BASE_URL";

export async function duplicateClassTest (app) {
    console.log('set this up on digital ocean', app)
    fetch(`${AUTH_BASE_URL}usertesting/classtest/duplicate/launch`, {
        method: 'POST',
        body: JSON.stringify(app),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => response.json()).then(async data => {

    })
}