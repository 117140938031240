import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "./d3-styles.css";

const CustomExpandButton = (node) => {
  const [childrenToLoad, setChildrenToLoad] = useState(
    (!node?.children) && node?.data?.numOfSubordinates !== undefined
  );
  // console.log("LOC: ChildrenLoad:", childrenToLoad);
  return (
    <>
      {node && (
        <div className="expand-btn">
          <span>
            {childrenToLoad
              ? node.data?.numOfSubordinates ?? node.data?._directSubordinates
              : node.data?._directSubordinates}
          </span>
          <span>
            {childrenToLoad? (
              <FaAngleDown />
            ) : node.children ? (
              <FaAngleUp />
            ) : (
              <FaAngleDown />
            )}
          </span>
        </div>
      )}
    </>
  );
};

export default CustomExpandButton;
