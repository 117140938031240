export const defaultTemplate = `<!DOCTYPE html>
<html  style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; box-sizing: border-box; font-size: 14px; margin: 0; height:100%">
<head>
<meta name="viewport" content="width=device-width" />
<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
<title>Pearson platform</title>
<style type="text/css">

    *{
      box-sizing: border-box;
    }

    body {
      -webkit-font-smoothing: antialiased;
      -webkit-text-size-adjust: none;
      width: 100% !important;
      height: 100%;
      line-height: 1.6em;
      font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
      font-size:14px;
      margin: 0;
      background-color: #02182b;
    }

    .body-wrap{
      width: 100%;
      background-color: #02182b;
      margin: 0;
      height:100%;
    }

    .container{
      display: block !important;
      max-width: 600px !important;
      clear: both !important;
      margin: 0 auto;
    }

    .content{
      max-width: 600px;
      display: block;
      margin: 0 auto;
      padding: 20px;
    }

    .main{
      border-radius: 3px;
      background-color: #fff;
      margin: 0;
    }

    .header{
       font-size: 16px;
       border-radius: 3px 3px 0 0;
       background-color: white;
       margin: 0;
       padding: 20px 20px 0 20px;
       display:flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
    }

    .logo{
      margin-bottom:20px;
      margin-top: 4px;
      width:30px;
      height:30px;
    }

    h1{
      font-size: 28px;
      text-align: center;
      line-height: 120%;
      margin: 0;
      color:#02182b;
    }

    .email a{
      text-decoration: none;
      color:#02182b;
    }

    .url a{
      color: #118fcc;
    }

    p{
      font-size:16px;
      text-align: center;
      color: #02182b;
    }


    ul{
      font-size:16px;
    }

    ul li{
      margin-top:8px
    }

    .btn-primary{
      box-sizing: border-box;
      font-size: 14px;
      color: #FFF;
      text-decoration: none;
      line-height: 2em;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
      display: block;
      border-radius: 100px;
      text-transform: capitalize;
      background-image: linear-gradient(190deg,#10bfd6 10%, #0E8190 90%);
      background-image: url('https://pearsonux.sfo2.cdn.digitaloceanspaces.com/email-gradient.jpg');
      background-repeat:no-repeat;
      background-size:cover;
      background-position:center;
      margin: 20px auto;
      padding:8px 16px;
      width: fit-content;
      min-width:100px;
    }

    @media only screen and (max-width: 640px) {
      body {
        padding: 0 !important;
      }
      .container {
        width: 100% !important;
      }

    .content {
        padding: 0 !important;
      }
    }

    .dark-img{
      display:none;
    }


    table[data-ogsb] .dark-img {
      display:block !important;
    }

    table[data-ogsb] .light-img {
      display:none !important;
    }
</style>
</head>

<body itemscope itemtype="http://schema.org/EmailMessage">

<table class="body-wrap">
    <tr style="margin: 0;">
    <td class="container">
      <div class="content">
        <table class="main" width="100%" cellpadding="0" cellspacing="0">
            <tr style="margin: 0;">
                <td class="header">
                  <img class="logo dark-img" src="https://pearsonux.sfo2.cdn.digitaloceanspaces.com/PearsonIconWhite.png"  alt='pearson logo' />
                  <img class="logo light-img" src="https://pearsonux.sfo2.cdn.digitaloceanspaces.com/PearsonIconDark.png"  alt='pearson logo' />
                  <h1>{{name}}, this is a test email</h1>
                </td>
            </tr>
            <tr>
                <td style="margin: 20px; display: block;">
                    <p>Here is something that you need to know!</p>
                  	<span class="btn-primary">
                      <a href={{url}} style="color:white; text-decoration:none;">Call to action</a>
                  	</span>             
                </td>
            </tr>
        </table>
        </div>
    </td>
  </tr>
</table>

</body>
</html>

`