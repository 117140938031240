import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import allUsers from "../../util/users/allusers";
import { allUserData } from "../../actions/allUsers";
// import getAllPrototypes from "../../util/prototypes/getAllPrototypes";
import getProducts from "../../util/projects/getProducts";
import { allPrototypes } from "../../actions/allPrototypes";
import { createProject } from "../../util/projects/createProject";
// import Select from "../global/inputs/CustomSelect";
import { addData } from "../../util/data/addData";
import { formatSelectOptions } from "../../util/tasks/formatData";
import sanitizeHtml from "sanitize-html-react";
// import { updateProject } from "../../util/projects/updateProject";
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import { useGetAndSetProjects } from "../../util/projects/useGetAndSetProjects";
import RepoSelect from "../dev-ops/RepoSelect";
import AddOptionSelect from "../global/inputs/AddOptionSelect";
import Select from "../global/inputs/CustomSelect";

const NewProject = (props) => {
  const nameRef = useRef(null);
  const codeRef = useRef(null);
  const taskRef = useRef(null);
  const descriptionRef = useRef(null);
  const [projectUsers, setProjectUsers] = useState([]);
  const [allAvailableUsers, setAllAvailableUsers] = useState([]);
  const [projectPrototypes, setProjectPrototypes] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [product, setProduct] = useState([]);
  const [dataAccess, setDataAccess] = useState(null);
  const [allAvailablePrototypes, setAllAvailablePrototypes] = useState([]);
  const [allAvailableProducts, setAllAvailableProducts] = useState([]);
  const {getAndSetProjects} = useGetAndSetProjects()

  const [projectApps, setProjectApps] = useState([]);
  useEffect(() => {
    /**
     * Step1: Load prototypes from Redux
     */
    if (!props.prototypes) {
      // console.log('New:no prototypes', props.prototypes)
      // getAllPrototypes().then(async (data) => {
      //   props.allPrototypes(await data);
      // });
      fetch(`${AUTH_BASE_URL}dev-ops/gitlab/repos/archive`, {
				method: 'GET',
				headers: new Headers({
					'Content-Type': 'application/json'
				})
			}).then(response => response.json()).then(data => {
				// Set all Prototypes
        // console.log("NewProjed:data.repos", data.repos)
        props.allPrototypes(data.repos)
			});
    }

     // Load users if users are not available from redux
    // get them from the database and store them
    if (!props.users) {
      allUsers().then(async (data) => {
        props.allUserData(await data);
      });
    }


    //Set Product options
    const fetchProducts = async () => {
      const products = await getProducts();
      if (products.ok) {
        const response = await products.json();
        // console.log("Response1:", response);
        setProductOptions(response);
      }
    };

    // Set Products
    fetchProducts();
  }, []);

  useEffect(() => {
    if (Array.isArray(productOptions)) {
      const formattedProducts = formatSelectOptions(productOptions);
      setAllAvailableProducts(formattedProducts);
    }
  }, [productOptions]);

  useEffect(() => {
    // console.log("AllAvailableProducts:", allAvailableProducts);
  }, [allAvailableProducts]);

  //once users are available in redux save them to state
  useEffect(() => {
    if (props.users && props.users.length > 0) {
      props.users.forEach((user) => {
        //fe won't allow you to add yourself to the project
        if (user.email !== props.session.email) {
          setAllAvailableUsers((prevState) => {
            user.label = user.email;
            user.value = user._id;
            return [...prevState, user];
          });
        }
      });
    }
  }, [props.users]);

  /**
   * Step2: setAllAvailablePrototypes,
   * once props.prototypes have been loaded
   */
  useEffect(() => {
    // console.log("NewProject:PrototypesGot from Repos:", props.prototypes);

    if (props.prototypes && props.prototypes.length > 0) {
      props.prototypes.forEach((prototype) => {
        setAllAvailablePrototypes((prevState) => {
          prototype.label = prototype.data?.name;
          prototype.value = prototype._id;
          return [...prevState, prototype];
        });
      });
    }
  }, [props.prototypes]);

  useEffect(()=>{
  //  console.log("allAvailablePrototypes:", allAvailablePrototypes);
  }, [allAvailablePrototypes]);

  // useEffect(()=> {
  //   console.log("Product:", product);
  // }, [product]);

  const handleSave = async (e) => {
    const descriptionString = sanitizeHtml(descriptionRef.current.innerHTML);

    e.preventDefault();
    console.log("NewProject:TaskSave:Data:", projectApps);
    let project_data = {
      email: props.session.email,
      name: nameRef.current?.value,
      description: descriptionString,
      status: "new",
      lead: null,
      users: projectUsers,
      product: product?.value,
      isProductNew: product?.__isNew__,
      prototypes: projectPrototypes,
      apps: projectApps,
      tasks: [],
      assets: [],
      projectCode: codeRef.current?.value,
      projectTask: taskRef.current?.value,
      dataAccess: dataAccess?.value ? dataAccess.value : "public",
    };
    // console.log("NewProject:", project_data);
    createProject(project_data, props.session)
      .then((response) => response.json())
      .then(async (data) => {
        // console.log("NewProject:Data:", data);
        let dataDoc = props.session.userId;
        let newData = {
          project: data.projectId,
        };

        const newProject = await addData(dataDoc, newData);

        // if (newProject.ok) {
        //   // Update Prototype Data on TableContainer:
        //   const prototype_data = {
        //     prototypes: projectPrototypes,
        //   };
        //   // console.log("NewProject:PrototypeData:", prototype_data.prototypes);
        //   // Update newProject
        //   const receivedPrototypeData =
        //     data.projectId && prototype_data?.prototypes.length > 0;
        //     // console.log("NewProject:ReceivedPrototypeData:", receivedPrototypeData);
        //   if (receivedPrototypeData) {
        //     updateProject(data.projectId, prototype_data).then(() => {

              props.handleCloseNewModal();
      }).then(()=>getAndSetProjects(props.session));
  };

  function onChange(event) {
    console.log('the event please', event)
    setProjectApps(event)
  }

  console.log('event project apps please', projectApps)
  return (
    <div className="modal d-block">
      <div className="modal-dialog" role="dialog">
        <div className="modal-content modal-content-demo shadow">
          <div className="modal-header">
            <h6 className="modal-title">New project</h6>
          </div>
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSave(e);
            }}
            id="new-project-form"
          >
            <div className="form-group">
              <label>Project name</label>
              <input
                ref={nameRef}
                className="form-control card-input"
                placeholder="Project name"
                type="text"
                required
              />
            </div>
            <div className="form-group">
              <label>Data Access</label>
              {/* {console.log("DataAccess:", dataAccess)} */}
              <Select
                value={dataAccess ? dataAccess : "public"}
                handler={setDataAccess}
                data={formatSelectOptions(["private", "public"])}
                type="dataAccess"
                card
                required={true}
                defaultValue={formatSelectOptions(["public"])}
                isMulti={false}
              />
            </div>
            <div className="form-group">
              <label>Product name (optional)</label>
              <AddOptionSelect
                value={product}
                handler={setProduct}
                data={allAvailableProducts}
                type="products"
                card
                required={false}
              />
            </div>
            <div className="form-group">
              <label>Add users (optional)</label>
              <Select
                value={projectUsers}
                handler={setProjectUsers}
                data={allAvailableUsers}
                type={"users"}
                card
                isMulti={true}
              />
            </div>
            <div className="form-group">
              <label>Connect apps</label>
             <RepoSelect onChange={onChange} required={false} card/>

            </div>
            <div className="form-group">
              <label>Description</label>
              {/* <textarea
                ref={descriptionRef}
                className="form-control card-input"
                required
              /> */}
              <div
                style={{ maxWidth: "821.7px", height: "auto" }}
                className="form-control card-input"
                ref={descriptionRef}
                contentEditable={true}
              />
            </div>
            <div className="form-group">
              <label>Oracle project code (optional)</label>
              <input
                ref={codeRef}
                className="form-control card-input"
                placeholder="ex: 82068044"
                type="number"
              />
            </div>
            <div className="form-group">
              <label>Oracle project task for development (optional)</label>
              <input
                ref={taskRef}
                className="form-control card-input"
                placeholder="ex: 3.01"
                type="number"
                step="0.01"
              />
            </div>
          </form>

          <div className="modal-footer">
            <button
              className="btn ripple btn-primary"
              form="new-project-form"
              type="submit"
            >
              Save
            </button>
            <button
              className="btn ripple btn-secondary muted"
              type="button"
              onClick={props.handleCloseNewModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ session, users, prototypes }) => ({
  session,
  users,
  prototypes,
});

const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
  allPrototypes: (data) => dispatch(allPrototypes(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProject);
