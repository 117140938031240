import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {useParams} from "react-router-dom"
import {v4 as uuidv4} from 'uuid';
import {uploadFile} from "../../util/projects/uploadFile"
import getAllAssets from "../../util/projects/getAllAssets";
import {allAssets} from '../../actions/allAssets'
import {createAsset} from "../../util/projects/createAsset"
import {updateProject} from "../../util/projects/updateProject"
// import {updateTask} from "../../util/tasks/updateTask"
//new update task route
import { updateSettings as updateTask } from "../../util/tasks/updateSettings";
import {updateSubtask} from "../../util/subtask/updateSubtask"
import Select from "../global/inputs/CustomSelect"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const ManageAssets = props => {
    const [currentAssets, setCurrentAssets] = useState([]);
    const [allAvailableAssets, setAllAvailableAssets] = useState([])
    const [uploadType, setUploadType] = useState('link')
    const [file, setFile] = useState(null)
    const [projectId, setProjectId] = useState(null)

    const params = useParams();

    const nameRef = useRef();
    const figmaRef = useRef();
    //for links
    const urlRef = useRef();
    //for files
    const fileRef = useRef(null)

    useEffect(()=>{
        if(props.projectId){
            setProjectId(props.projectId)
        }
    },[props.projectId])


    useEffect(()=> {
        //get the apps that are already in this project from ProjectDetail
        if(props.details.assets){
            props.details.assets.forEach((asset)=>{
                setCurrentAssets((prevState)=>{
                    asset.label = asset.name
                    asset.value = asset._id
                    return [...prevState, asset]
                })
            })
        }

        //if assets are not available from redux
        // get them from the database and store them
        if(!props.assets){
            getAllAssets().then(async data => {
                props.allAssets(await data)
            })
        }
    }, [])

    //once assets are available in redux save them to state
    useEffect(()=>{
        if(props.assets && props.assets.length > 0){
            props.assets.forEach((asset)=>{
                setAllAvailableAssets((prevState)=>{
                    asset.label = asset.name
                    asset.value = asset._id
                    return [...prevState, asset]
                })
            })
        }
    },[props.assets])


    const handleRadio = (event) => {
        let value = event.target.value
        setUploadType(value)
    }

    const handleUpload = () => {
        const hiddenUpload = fileRef.current
        hiddenUpload.click();
    }

    function handleSave () {

        if((urlRef.current && urlRef.current.value) || file){

            let allCurrentAssets = [...currentAssets]
            let newLink = {}
            newLink.name = nameRef.current.value
            newLink.figma = figmaRef.current.checked
            newLink.key = uuidv4()

            if((urlRef.current && urlRef.current.value) && uploadType === 'link'){
                    newLink.link = urlRef.current.value
                    newLink.type = "link"
                    createAsset(newLink)
                        .then(response=>{return response.json()})
                        .then(data=>{
                            newLink._id = data.assetId
                            allCurrentAssets.push(newLink)
                            updateLinks(allCurrentAssets)
                        })
            }else if(file && uploadType === 'file'){
                    let formData = new FormData();
                    formData.append('file', file) 
                    uploadFile(formData).then(response => {
                        return response.json()
                    }).then(data => {
                        if(!data.isError){
                            newLink.link = data.url
                            newLink.type = data.type
                            createAsset(newLink)
                                .then(response=>{return response.json()})
                                .then(data=>{
                                    newLink._id = data.assetId
                                    allCurrentAssets.push(newLink)
                                    updateLinks(allCurrentAssets)
                                })
                        }
                    })
            }
        }else{
            updateLinks(currentAssets)
        }
    }

    const updateLinks = (assets) => {

        let data = {
            assets: assets,
            projectId:projectId
        }

        if(props.dataLevel === 'project'){
            updateProject(params.id, data).then(()=>{
                props.getDetails()
                props.handleCloseDetailModal()
            })
        }else if(props.dataLevel === 'task'){
            updateTask(params.id, data).then(()=>{
                props.getDetails()
                props.handleCloseDetailModal()
            })
        }else if(props.dataLevel === 'subtask'){
            updateSubtask(params.id, data).then(()=>{
                props.getDetails()
                props.handleCloseDetailModal()
            })
        }

    }

    return(
        <div className="modal d-block">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow">
                    <div className="modal-header">
                        <span>{props.details.name}</span>
                        <h6 className="modal-title">Assets</h6>
                    </div>

                    <div className='detail-modal-body'>

                        <div className="form-group">
                            <label>Add or remove existing assets</label>
                            <Select
                                value={currentAssets}
                                handler={setCurrentAssets}
                                data={allAvailableAssets}
                                type={'assets'}
                                card
                            />
                        </div>

                        <label>or Add a new asset</label>
                        <label htmlFor="upload-link" className='rdiobox'>
                            <input type="radio" id="upload-link" name="upload" value="link" checked={uploadType === 'link'} onChange={handleRadio}/>
                            <span>Add Link</span>
                        </label>
                        <label htmlFor="upload-file" className='rdiobox'>
                            <input type="radio" id="upload-file" name="upload" value="file" checked={uploadType=== 'file'} onChange={handleRadio}/>
                            <span>Upload a File</span>
                        </label>
                        <form>
                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    ref={nameRef}
                                    className="form-control card-input"
                                    placeholder="Name"
                                    type="text"
                                    required
                                />
                            </div>
                            {
                                uploadType === 'link' ? <div className="form-group">
                                    <label>URL</label>
                                    <input
                                        ref={urlRef}
                                        className="form-control card-input"
                                        placeholder="https://example.com"
                                        type="text"
                                        required
                                    />
                                </div> :
                                <div className="form-group">
                                    <p>{file ? file.name : null}</p>
                                    <button  onClick={handleUpload} className="btn ripple btn-primary upload-button" type="button">
                                        Upload
                                        <FontAwesomeIcon icon="fa-solid fa-upload" />
                                    </button>
                                    <input
                                        ref={fileRef}
                                        style={{display: 'none'}}
                                        type="file"
                                        onChange={(e) => {
                                            if (e.target.files) {
                                                setFile(e.target.files[0]);
                                            }
                                        }}
                                    />
                                </div>
                            }
                            <label className="custom-switch mg-t-8">
                                <input ref={figmaRef} type="checkbox" name="custom-switch-checkbox" className="custom-switch-input"/>
                                <span className="custom-switch-indicator"></span>
                                <span className="custom-switch-description">Is this a design file?</span>
                            </label>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="button" onClick={handleSave}>Save</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseDetailModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({session, assets}) => ({
    session, assets
});

const mapDispatchToProps = dispatch => ({
    allAssets: (data) => dispatch(allAssets(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageAssets);
