import React, {useState} from 'react';
import {ReactComponent as Logo} from './logo.svg';
import Sidebar from './Sidebar';
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DashboardLink from "./links/DashboardLink";

const SidebarContainer = () => {
	const [location, setLocation] = useState(useLocation());
	return (
		<>
			<div className="main-sidebar main-sidebar-sticky side-menu ps ps--active-y">
				<div className="sidemenu-logo">
					<a className="main-logo" href="/front-end/public">
						<Logo />
					</a>
				</div>
				<div className="main-sidebar-body">
					<ul className="nav">
						<li className="nav-header"><span className="nav-label"></span></li>
							<li className={`nav-item ${location.pathname.split('/')[1] === 'dashboard' ? 'active' : ''}`}>
								<DashboardLink />
							</li>
						<Sidebar/>
					</ul>
				</div>
				<div className="ps__rail-x" style={{left: '0px', top: '0px'}}>
					<div className="ps__thumb-x" tabIndex={0} style={{left: '0px', width: '0px'}}/>
				</div>
				<div className="ps__rail-y" style={{top: '0px', height: '849px', right: '0px'}}>
					<div className="ps__thumb-y" tabIndex={0} style={{top: '0px', height: '657px'}}/>
				</div>
			</div>
		</>
	)
}


export default SidebarContainer





