import DropDown from "../../dev-ops/gitlab-repos/DropDown";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import launch from '../../../util/usertesting/launchTest'
import stop from '../../../util/usertesting/stopTest'
import allTests from "../../../util/usertesting/allTests";
import {allTestData} from "../../../actions/allUserTests";
import deleteThisTest from "../../../util/usertesting/deleteTest"
import Avatar from "../../global/Avatar";
import {useNavigate} from "react-router-dom";
import validateToken from "../../../util/dev-ops/validateToken";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import {signin} from "../../../actions/session/signIn";
import updatePublicHtml from "../../../util/dev-ops/updatePublicHtml";
import {toastMessage} from "../../../actions/toast";
function TableRow (props) {
	const [disabled, setDisabled] = useState();
	const navigate = useNavigate();

	useEffect(()=>{
		setDisabled(isDisabled())

	},[props.allTests])

	function launchTest(testData) {
		let repoData = {}
		repoData.id = testData.repo
		repoData.default_branch = testData.branch
		validateToken(props.session).then(data => {
			props.signin(data.user)

			fetch(`${AUTH_BASE_URL}dev-ops/gitlab/${props.data.repo}/package/update/${data.token}`).then(response => response.json()).then(file => {
				let framework = !file.file.package.message ? file.file.framework[0] : null;
				updatePublicHtml(repoData, data.token, framework).then(response => {
					props.toastMessage(`${props.data.name} is being setup for a usertest.  Give the system 20 minutes or so, you'll get a notification when the test is live.`)
					launch(testData).then(response => {
						allTests().then(tests => {
							console.log('test data', tests)
							props.allTestData(tests)
						})
					})
				})
			})

		})
	}

	function returnAdmin () {
		return props.session.systemAccess === 4 ||  props.data.user[0].userId === props.session.userId
	}

	function deleteTest(data) {
		props.toastMessage(`${props.data.name} is being deleted.  You'll recieve a notification in a few minutes.`)
		deleteThisTest(data).then(response => {
			allTests().then(data => {
				props.allTestData(data)

				setDisabled(isDisabled())
			})
		})
	}

	function viewResults(data) {
		navigate(`/usertesting/online/results/${props.data.name}`, {state:{id: props.data._id}})
	}

	function viewUsers(data) {
		navigate(`/usertesting/online/users/${props.data._id}`, {state:{id: props.data._id}})
	}

	function stopTest (data) {
		console.log('stop data', data);
		props.toastMessage(`The usertest ${props.data.name} is being stopped.  The database and app will be removed.  You can launch the test again at anytime.`)
		stop(data);
		setDisabled(isDisabled())
	}
	function isDisabled () {
		if (props.data.introduction && props.data.introductionData && props.data.introductionData.video && props.data.introductionData.video.length === 0) {
			return 'true'
		} else if (props.data.collectPersonal && props.data.personalData && props.data.personalData.length === 0) {
			return 'true'
		} else if (props.data.preTest && props.data.preTestData && props.data.preTestData.length === 0) {
			return 'true'
		} else if (props.data.postTest && props.data.postTestData && props.data.postTestData.length === 0) {
			return 'true'
		}else {
			return 'false'
		}
	}

	console.log('the data', props.data);
	if (props.allTests && props.allTests.length === 0) {
		return (
			<tr>
				<td colSpan={8}>
					There are no tests setup
				</td>
			</tr>
		)
	} else {
		return (
			<tr>
				<td>
					{
						props.data.user && props.data.user[0]? <Avatar user={props.data.user[0]} size='sm'/> : <></>
					}
				</td>
				<td>
					{
						!props.data.launching ? props.data.url : <a href={props.data.url}>{props.data.url}</a>
					}

				</td>
				<td>
					{props.data.repoName}
				</td>
				<td>
					{props.data.branch}
				</td>
				<td>
					{props.data.users}
				</td>
				<td>
					{props.data.answers.length}
				</td>
				<td>
					{new Date(props.data.createdAt).toLocaleDateString()}
				</td>
				<td>
					<span data-placement="top" data-toggle="tooltip" title="Currently Worked On" className={ props.data.launching && !props.data.live ? "circle badge badge-warning active" : props.data.live ? "circle badge badge-success active" : "circle badge badge-danger active"}></span>

				</td>
				<td>
					<DropDown data={props.data} label={"Manage"} icon={"fa-chevron-down"}>
						{
							props.data.testUsers && props.data.testUsers.length > 0 ?
							<li><button  onClick={viewUsers} >View Users</button></li> 
							:	<li><button  onClick={()=>{props.handleImportUsersModal(props.data._id)}} >Import Users</button></li> 
						}
						
						{
							props.data.answers && props.data.answers.length > 0 ?<li><button  onClick={viewResults}>View Results</button></li>  : <></>
						}
						{
							 !props.data.launching && returnAdmin() ?<li><button  onClick={()=>{props.handleEditModal(props.data)}} >Edit Test</button></li>  : <></>
						}
						{
							props.data.introduction && !props.data.launching ? props.data.introductionData && props.data.introductionData.video && props.data.introductionData.video.length  !== 0 ? <li><button onClick={()=>{props.handleIntroductionModal(props.data)}}>Intro Video</button></li> : <li><FontAwesomeIcon className='mg-r-4 text-danger' icon={`fa-solid fa-exclamation-circle`} /><button onClick={()=>{props.handleIntroductionModal(props.data)}}>Intro Video</button></li> : ''
						}
						{
							props.data.introductionText && !props.data.launching ? props.data.introductionData && props.data.introductionData.text && props.data.introductionData.text.length  !== 0 ? <li><button onClick={()=>{props.handleIntroductionTextModal(props.data)}}>Intro Text</button></li> : <li><FontAwesomeIcon className='mg-r-4 text-danger' icon={`fa-solid fa-exclamation-circle`} /><button onClick={()=>{props.handleIntroductionTextModal(props.data)}}>Intro Text</button></li> : ''
						}
						{
							props.data.collectPersonal && !props.data.launching && returnAdmin() ?  <li>{props.data.personalData.length === 0 ? <FontAwesomeIcon className='mg-r-4 text-danger' icon={`fa-solid fa-exclamation-circle`} /> : <></>}<button disabled={props.data.launch} className={props.data.launch? 'disabled': ''}   onClick={()=>{props.handleOpenCollectModal(props.data)}}> Collect User Data</button></li> : <></>
						}

						{
							props.data.preTest && !props.data.launching  && returnAdmin()? 	<li>{props.data.preTestData.length === 0 ? <FontAwesomeIcon className='mg-r-4 text-danger' icon={`fa-solid fa-exclamation-circle`} /> : <></>}<button  disabled={props.data.launch} className={props.data.launch? 'disabled': ''}  onClick={()=>{props.handlePreQuestionModal(props.data)}}>Pre / Start Questions</button></li> : <></>
						}

						{
							props.data.postTest && !props.data.launching && returnAdmin() ? <li>{props.data.postTestData.length === 0 ? <FontAwesomeIcon className='mg-r-4 text-danger' icon={`fa-solid fa-exclamation-circle`} /> : <></>}<button disabled={props.data.launch} className={props.data.launch || props.data.launching? 'disabled': ''}   onClick={()=> {props.handlePostQuestionModal(props.data)}}>Post / End Questions</button></li> : <></>
						}

						{
							! props.data.launching && returnAdmin() ? <li><button  disabled={disabled === 'true'} className={disabled === 'true' ? 'disabled': 'text-success'} onClick={()=>{launchTest(props.data)}}>Launch Test</button></li> : returnAdmin() ? <li><button onClick={()=>{stopTest(props.data)}}>Stop Test</button></li> : <></>
						}
						{
							!props.data.launching && returnAdmin() ? <li><button className={'text-danger'} onClick={()=>{deleteTest(props.data)}}>Delete Test</button></li> : <></>
						}

						{/*<li><button  onClick={()=> {window.open(props.data.appUrl[0].webLink, '_blank')}}>Schedule Test</button></li>*/}

						{/*<li><button  className='text-danger' onClick={()=> {window.open(props.data.appUrl[0].webLink, '_blank')}}>Delete Test</button></li>*/}
					</DropDown>
				</td>
			</tr>
		)
	}

}
const mapStateToProps = ({session, allTests}) => ({
	session, allTests
});

const mapDispatchToProps = dispatch => ({
	allTestData: (data) => dispatch(allTestData(data)),
	signin: user =>dispatch(signin(user)),
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TableRow);
