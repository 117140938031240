import React from "react";
import {TextareaAutosize} from '@mui/base/TextareaAutosize';

const TextArea= (props) => {
  return (
    <div className="form-group">
      <label>{props.label}</label>
      <TextareaAutosize
       {...props.register(props.name)}
       className={`form-control text-aria ${props.card ? 'card-input' : null}`}
        type="text"
        minRows={2}
        required={props.required}
        defaultValue={props.defaultValue}
      />
    </div>
  );
};

export default TextArea;
