import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const post = async (data) => {
   const response = await fetch(AUTH_BASE_URL + "subtasks/save", {
        method: "POST",
        body: JSON.stringify({data}),
        headers: {
            "Content-Type": "application/json"
        }
    });
    return response;
};