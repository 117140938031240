import AUTH_BASE_URL from "../AUTH_BASE_URL";
/**
 * Create User: Send Post request
 * to  sso/user/ route to create
 * the user record after sign-up
 *  button clicked
 * @param {*} data
 * @returns - {message, status}
 */
const ssoSignUp = async (data) => {
  // console.log("In ssoSignUp: data:", data);
  let response = await fetch(AUTH_BASE_URL + "auth/sso/user", {
    method: "POST",
    body: JSON.stringify({ data }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};

export default ssoSignUp;
