import { TOAST, CLEAR_TOAST } from "../actions/toast";

export default (state = null, action) => {
    Object.freeze(state);
    switch (action.type) {
        case TOAST:
            return action.toast;
        case CLEAR_TOAST:
            return null; // Reset the toast state
        default:
            return state;
    }
};
