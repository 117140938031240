import {PROJECT_FILTERS} from "../actions/projectFilters";

const preloadedFilters = [
    {filter:"active", group:"status"},
    {filter:"new", group:"status"},
]

export default (state = preloadedFilters, {type, project_filters}) => {
    Object.freeze(state);
    switch (type) {
        case PROJECT_FILTERS:
            return project_filters;
        default:
            return state;
    }
};
