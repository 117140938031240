import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ClassTestLinks = () => {
    return (
        <Link className='nav-link' to={'/usertesting/classtest'}>
            <span className="shape1"/>
            <span className="shape2"/>
            <FontAwesomeIcon className='sidemenu-icon' icon='code-compare'/>
            <span className="sidemenu-label">Class Testing</span>
        </Link>
    );
};

export default ClassTestLinks ;
