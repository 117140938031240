import React, { useState } from "react";
import { FaBuilding } from "react-icons/fa";
import "./d3-styles.css";
import ToggleStatus from "../../team/ToggleStatus";
import { displaySection } from "../../../util/helpers/orgChartUtil";

const maxStatus = 7;

/**
 * Displays the employe nodes in org tree
 * Note: UseEffect does not work in this
 * component, so we must add any attributes
 * directly to the props.data (props.data.user.showStatus)
 * @param {*} props
 * @returns
 */
const CustomNodeContent = (props) => {
    console.log('the props', props)
  const [hasDisplaySection] = useState(
    props.data.gitlabData
  );
  /**
   * Add showStatus to the data.user
   * Upon creation
   */
  if (props.data?.status) {
    props.data.user.showStatus = hasDisplaySection;
  }

  return (
    <>
      <div className="node-container">
        <div className="node-details">
            <div className="node-content justify-content-start">
              {props.data.imageUrl ? (
                <img
                  className="node-img avatar-xl"
                  src={props.data.imageUrl}
                  alt="Profile"
                />
              ) : (
                <div className="default-img">
                  <div className={`avatar avatar-xl`}>
                    {props.data.user.firstName &&
                      props.data.user.firstName.charAt(0).toUpperCase()}
                    {props.data.user.lastName &&
                      props.data.user.lastName.charAt(0).toUpperCase()}
                  </div>
                </div>
              )}
              <div className="node-info mg-l-10">
                <div className="node-name tx-18">{props.data.name}</div>
                <div className="node-role tx-14 mg-b-0 pd-b-0">{props.data.positionName}</div>
                  {props.data.user.trackActivity && props.data.user.gitLab && props.data.user.gitLab[0] ? (
                      // {Icons should not toggle}
                      <ToggleStatus
                          showStatus={false}
                          setShowStatus={(value) => {
                              // console.log("Do nothing", value)
                          }}
                      />
                  ) : (
                      <></>
                  )}
                {props.data.department && (
                  <div className="node-department">
                    <FaBuilding />
                    <div>{props.data.department}</div>
                  </div>
                )}
              </div>
              {/* {console.log("HDSection:", hasDisplaySection)} */}

            </div>
        </div>
      </div>
    </>
  );
};

export default CustomNodeContent;
