import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
// import { OrgChart } from "d3-org-chart";
import { LazyOrgChart } from "./LazyOrgChart";
import CustomNodeContent from "./customNodeContent";
import CustomExpandButton from "./customExpandButton";
import CustomEmployeeDetails from "./CustomEmployeeDetails";

const OrganizationalChart = (props) => {
  const d3Container = useRef(null);
  const modalRef = useRef(null);

  const [cardShow, setCardShow] = useState(false);
  // set employee Id
  const [employeeId, setEmployeeId] = useState("");
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  //  console.log("OrgChart Data:", data);
  let chart = new LazyOrgChart(props);

  const handleShow = () => setCardShow(true);

  const handleClose = (e) => {
    e.preventDefault();
    setModalOpen(false);
    setCardShow(false);
    //Reset EmployId after Modal close
    setEmployeeId("");
  };
  const handleModalClose = () => {
    setCardShow(false);
    //Reset EmployId after Modal close
    setEmployeeId("");
  };
  /**
   * When node clicked set employeId.
   * Once employeeId is set, the selected
   * node is identified, and we can show
   * its details.
   * @param {*} nodeId
   */
  const toggleDetailsCard = (nodeId) => {
    if (nodeId) {
      handleShow();
      setEmployeeId(nodeId);
    }
  };

  /* Make sure data is updated before 
  sending to CustomEmployeeDetails */
  useEffect(() => {
    setData(props.data);

    /**
     * When a click occurs set the employeeId
     * and show the employee details
     */
    chart
      // .compact(false)
      .setActiveNodeCentered(false)
      .container(d3Container.current)
      .data(props.data)
      .nodeWidth((d) => 300)
      .nodeHeight((d) => 140)
      .compactMarginBetween((d) => 80)
      .onNodeClick((d) => {
        toggleDetailsCard(d);
      })
      .buttonContent((node, state) => {
        return ReactDOMServer.renderToStaticMarkup(
          <CustomExpandButton {...node.node} />
        );
      })
      .nodeContent((d) => {
        /**
         * set data.user.showStatus = true, if a node had
         * a status within the past 7 days
         */
        return ReactDOMServer.renderToStaticMarkup(
          <CustomNodeContent {...d} />
        );
      })
      .render();
  }, [props.data]);

  return (
    <>
      {/* {console.log("OrgChartDATA:", data, "EmployeeID", employeeId)} */}
      <div className="org-chart" ref={d3Container}>
        {cardShow && employeeId !== "" && (
          <CustomEmployeeDetails
            employee={data.find((employee) => employee.id === employeeId)}
            handleClose={handleClose}
            handleModalClose={handleModalClose}
            showModal={cardShow}
            modalRef={modalRef}
            setModalOpen={setModalOpen}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(OrganizationalChart);
