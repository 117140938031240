import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import Select from "react-select";
import {allClassTests} from "../../../actions/allClassTests";
import {duplicateClassTest} from "../../../util/usertesting/duplicateClassTest";
import {toastMessage} from "../../../actions/toast";

const DuplicateTest = props => {
	const [nameData, setnameData] = useState({})
	const [repoData, setRepoData] = useState()
	const [repoNameData, setRepoNameData] = useState()
	const [selected, setSelected] = useState()
	const [customSelected, setCustomSelected] = useState()
	const [validation, setValidation] = useState();
	const urlRef = useRef(null);
	const pwRef = useRef()
	const instructorEmailRef = useRef()

	useEffect(()=> {
		if (props.data) {
			setRepoNameData(props.data.repoName)
			setSelected(props.data.repo)
		}
		let group = props.session.gitlabGroup ? props.session.gitlabGroup : '10566838'
		fetch(`${AUTH_BASE_URL}dev-ops/gitlab/repos/recent?group=${group}`, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json"
			}
		}).then(response => response.json()).then(data => {
			setRepoData(data.repoData)
		})
	},[])


	let data = {}

	function handleName (event) {
		data.name = event.target.value

		setnameData(data)
	}

	function handleSave () {
		let dataObj = {};
		console.log('PROPS', props.data.app)
		dataObj.name = nameData.name ? nameData.name.replace(/ /g, "-") : ''
		dataObj.url = urlRef.current.value
		dataObj.instructorEmail = instructorEmailRef.current.value
		dataObj.repoName = "beck-helmus-class-test-sandbox"
		dataObj.repo = 47340333
		dataObj.password = pwRef.current.value
		dataObj.user = props.session
		dataObj.duplicate = true
		dataObj.database = props.data.database ? props.data.database : props.data.name
		dataObj.app = props.data.app
		console.log('DATA OBJ', dataObj)
		fetch(`${AUTH_BASE_URL}usertesting/classtest/duplicate`, {
			method: 'POST',
			body: JSON.stringify(dataObj),
			headers: {
				"Content-Type": "application/json"
			}
		}).then(response => response.json()).then(async data => {
			if (!data.message) {
				props.allClassTests(data)
				props.handleCloseNewModal()
				props.toastMessage("Your classtest is being setup.  You'll receive an email when it's ready.")
				await duplicateClassTest(dataObj)
			} else {
				setValidation(data.message)
			}
		})
	}

	function handleUrlInputValue () {
		return 'classtesting'
	}
console.log('RPO DATA', props.data)
    return(
        <div className="modal d-block">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow">
                    <div className="modal-header">
                        <h6 className="modal-title">Duplicate Class Test</h6>
                    </div>
					<form className={"pd-30"}>
						<div className={"mg-b-20"}>
							<label>Name of the test</label>
							<input onChange={handleName} defaultValue={props.data.name} className="form-control"/>
						</div>
						<div className={"mg-b-20"}>
							<label>URL - read only and generated from the name</label>
							<input ref={urlRef} readOnly className="form-control" defaultValue={props.data.url} value={`https://${nameData.name ? nameData.name.split(' ').join('-') : ''}.${handleUrlInputValue()}.pearsonct.design`}/>
						</div>
						<div className={"mg-b-20"}>
							<label>Instructor Email</label>
							<input ref={instructorEmailRef} defaultValue={props.data.instructorEmail} className="form-control"/>
						</div>
						<div className={"mg-b-20"}>
							<label>Set instructor password to access test</label>
							<input ref={pwRef} className="form-control" type={"text"} defaultValue={props.data.password}/>
						</div>
						{
							validation ? <p className={'text-danger'}>{validation}</p> : <></>
						}
					</form>

                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="button" onClick={handleSave}>Save</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseNewModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({session, allClassTestData}) => ({
	session, allClassTestData
});

const mapDispatchToProps = dispatch => ({
	allClassTests: (data) => dispatch(allClassTests(data)),
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DuplicateTest);
