import {
	SET_TEAM_ID,
	SET_PROJECTS_TEAM_ID,
	SET_COMPONENTS_TEAM_ID,
} from "../actions/figmaTeamId";

const initialState = {
	figmaTeamId: null,
	figmaProjectsTeamId: null,
	figmaComponentsTeamId: null,
};

export function figmaTeamIdReducer(state = initialState.figmaTeamId, action) {
	switch (action.type) {
		case SET_TEAM_ID:
			return action.figmaTeamId;
		default:
			return state;
	}
}

export function figmaProjectsTeamIdReducer(state = initialState.figmaProjectsTeamId, action) {
	switch (action.type) {
		case SET_PROJECTS_TEAM_ID:
			return action.figmaProjectsTeamId;
		default:
			return state;
	}
}

export function figmaComponentsTeamIdReducer(state = initialState.figmaComponentsTeamId, action) {
	switch (action.type) {
		case SET_COMPONENTS_TEAM_ID:
			return action.figmaComponentsTeamId;
		default:
			return state;
	}
}
