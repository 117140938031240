import React from 'react';
import compareDates from "../../../util/dev-ops/compareDates";
const setActivityColor =  (lastActivityAt) => {
	return Number(compareDates(lastActivityAt))
}
const SkeletonText = ({ width, height, className }) => {
	return (
		<>
			<div className={`skeleton-text ${className}`} style={{ width, height }}></div>
			<div className={`skeleton-text ${className}`} style={{ width, height }}></div>
		</>
	);
};

const SkeletonTextSingle = ({ width, height, className }) => {
	return (
		<>
			<div className={`skeleton-text ${className}`} style={{ width, height }}></div>
		</>
	);
};

export const UserHandle = ({ data }) => {
	const isLoading = !data.users;

	return (
		<div className="mg-l-8">
			{isLoading ? (
				<SkeletonText width="170px" height="16px" className="mb-1" />
			) : (
				<>
					<strong>{data.users.handle}</strong>
					<small className="d-block text-muted">{'user'}</small>
				</>
			)}
		</div>
	);
};

export const DateHandle = ({ data }) => {
	const isLoading = !data.users;

	return (
		<div>
			{isLoading ? (
				<SkeletonTextSingle width="227px" height="16px" className="mb-1" />
			) : (
				<div className={'d-flex align-items-center'}>
					<span style={{width:'15px', height:'15px', marginRight:'8px'}} data-placement="top" data-toggle="tooltip" title="Currently Worked On" className={setActivityColor(data.last_modified) <= 30 ? "circle badge badge-success active" : setActivityColor(data.last_modified) <= 60 ? "circle badge badge-warning" : "circle badge badge-danger active" }></span>
					<small>{new Date(data.last_modified).toLocaleDateString()}</small>
				</div>
			)}
		</div>
	);
};


