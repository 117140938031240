import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const getNativeTeamUsers = async function (name, statusType) {
// console.log( "getTeamUsers: Name:", name, "status:", statusType)
	return fetch(`${AUTH_BASE_URL}team/users?name=${encodeURIComponent(name)}&status=${statusType}`, {
		method: 'GET',
		headers: {
			"Content-Type": "application/json"
		}
	}).then(response => response.json()).then(data => {
		return data
	})
}

export const getSsoTeamUsers = async (ssoDepartmentMembers, statusType) => {
	// console.log( "getTeamUsers: ssoDepartmentMembers:", ssoDepartmentMembers);
	const teamMembersData = await fetch(`${AUTH_BASE_URL}team/users/sso?ssoDepartmentMembers=${ssoDepartmentMembers}&status=${statusType}`);
	// check status
	if(teamMembersData.ok) {
		const response = await teamMembersData.json();
		// console.log("TeamMembers Response:", response);
		return response;
	}
}
