import {connect} from "react-redux";
import AUTH_BASE_URL from '../../util/AUTH_BASE_URL'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import compareDates from "../../util/dev-ops/compareDates";
import getAllPrototypes from "../../util/prototypes/getAllPrototypes";
import {allPrototypes} from "../../actions/allPrototypes";
import DropDown from "../global/DropDown";

function TableRow (props) {
    const setActivityColor =  (lastActivityAt) => {
        return Number(compareDates(lastActivityAt))
    }
    function handleDelete(id, name) {
        fetch(`${AUTH_BASE_URL}prototype`, {
            method: 'DELETE',
            body: JSON.stringify({data:id}),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(response => response.json()).then(prototypes => {
            getAllPrototypes().then(async data => {
                props.allPrototypes(data)
            })
        })

        fetch(`${AUTH_BASE_URL}prototype?id=${id}`, {
            method: 'DELETE'
        }).then(data => {
           getAllPrototypes().then(async data => {
                props.allPrototypes(data)
            })
        })
    }
    return (
        <tr>

            <td>
                {
                    compareDates(props.prototype.created) <= 1 ?
                        <span className="repos badge badge-primary pd-0 mg-r-8">New</span>
                        : <></>
                }
                {props.prototype.name}</td>
            <td>
                {
                    props.prototype.type === 'platform' ? <a href={`/${props.prototype.webLink}`} target={'_blank'}>{`/${props.prototype.webLink}`}</a> :
                        <a href={props.prototype.webLink} target={'_blank'}>{props.prototype.webLink}</a>
                }
            </td>
            <td>
                {new Date(props.prototype.created).toLocaleDateString()}
            </td>
            <td>
                {
                    props.prototype.type === 'platform' ?    <FontAwesomeIcon icon={`fa-solid fa-lock`} className={'text-success'}  /> :    <FontAwesomeIcon icon={`fa-solid fa-lock-open`} className={'text-danger'} />
                }
            </td>
            <td>





                        <DropDown>
                            <li> <button     onClick={()=> {window.open(props.prototype.webLink)}}>View App</button></li>

                            {
                                props.session.email === props.prototype.email ?
                            <li> <button     onClick={()=> {handleDelete(props.prototype._id, props.prototype.name)}} className={'text-danger'}>Delete</button></li> : <></>
                            }
                         </DropDown>: <></>


            </td>


        </tr>

    )
}

const mapStateToProps = ({session, prototypes}) => ({
    session, prototypes
});

const mapDispatchToProps = dispatch => ({
    allPrototypes: (data) => dispatch(allPrototypes(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableRow);
