import React, { useEffect, useRef, useState, useContext } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Wrapper from "../global/Wrapper";
import UpdateTask from "./modals/TaskUpdate";
import EditAsset from "../assets/EditAsset";
import ManageAssets from "../assets/ManageAssets";
import DeleteAsset from "../assets/DeleteAsset";
import NewToDo from "./modals/NewToDo";
import EditToDo from "./modals/EditToDo";
import ToDo from "./ToDo";
import { getTaskDetail } from "../../util/tasks/getTaskDetails";
import { getSubtaskDetailsByTask } from "../../util/subtask/getSubtaskDetails";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { updateSettings } from "../../util/tasks/updateSettings";
import ConfirmationModal from "../global/ConfirmationModal";
import { deleteTask } from "../../util/tasks/deleteTask";
// import { TasksContext } from "../global/Providers/TaskProvider";
import Avatar from "../global/Avatar";
// import { getProjectData } from "../../util/helpers/getProjectData";
import { signin } from "../../actions/session/signIn";
import { allGitLabRepos } from "../../actions/allGitlabRepos";
import { filteredGitLabRepos } from "../../actions/filteredData";
import { toastMessage } from "../../actions/toast";
import { getGitlabGroupData } from "../../actions/gitlabGroups";
import { getGitlabGroupIdData } from "../../actions/gitlabGroupId";
import { getGitlabSubGroupData } from "../../actions/gitlabSubGroups";
import { getGitlabSubGroupIdData } from "../../actions/gitlabSubGroupId";
import { setFilter } from "../../actions/filterBy";
import { allCreators } from "../../actions/creators";
import { setRepoLoading } from "../../actions/repoLoading";
import { getProjectDetail } from "../../util/projects/getProjectDetail";
import Descriptions from "./side-bar/components/Descriptions";
import Assets from "./side-bar/components/Assets";
import { getPrototypeByName } from "../../util/projects/getPrototypeByName";
import NewSubtask from "./modals/CreateSubtask";
import { post as postSubtask } from "../../util/subtask/post";
import SubtaskTable from "./subtasks/SubtaskTable";
import { formatDataByName } from "../../util/tasks/formatData";
import { retrieveTasks } from "../../util/tasks/retrieveTasksByProject";
import truncateDescription from "../../util/tasks/truncateDescription";
import AppWidget from "../dev-ops/gitlab/AppWidget";
import { useGetAndSetProjects } from "../../util/projects/useGetAndSetProjects";

const titleCharLimit = 20;

const TaskDetail = (props) => {
  const regex = /0{0,1}/;

  const navigate = useNavigate();
  const params = useParams();
  const [taskId] = useState(params.id);
  const actionsRef = useRef(null);
  const [gitlabId, setGitlabId] = useState([]);
  const [taskGitlabId, setTaskGitlabId] = useState([]);
  const [taskDetails, setTaskDetails] = useState(null);
  const [showEditAsset, setShowEditAsset] = useState(null);
  const [showManageAssets, setShowManageAssets] = useState(false);
  const [showDeleteAssetModal, setShowDeleteAssetModal] = useState(null);
  const [assetToDelete, setAssetToDelete] = useState(null);
  const [showNewToDo, setShowNewToDo] = useState(false);
  const [showNewSubtask, setShowNewSubtask] = useState(false);
  const [showEditToDo, setShowEditToDo] = useState(null);
  const [subtasks, setSubtasks] = useState([]);

  /********NEWTASK START*******/
  // const {
  //   isTaskUpdated,
  //   setIsTaskUpdated,
  //   isSubtaskUpdated,
  //   setIsSubtaskUpdated,
  // } = useContext(TasksContext);

  const [toggleTaskRefresh, setToggleTaskRefresh] = useState(false);
  const [showUpdateTask, setShowUpdateTask] = useState(false);
  const [showDeleteTask, setShowDeleteTask] = useState(false);
  const [responsibleDefaultValues, setResponsibleDefaultValues] =
    useState(null);
  /********ProjectDetails***********/
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectData, setProjectData] = useState(null);
  /**********Assets***************/
  const [parentDesignAssets, setParentDesignAssets] = useState([]);
  const [parentNonDesignAssets, setParentNonDesignAssets] = useState([]);
  const [taskDesignAssets, setTaskDesignAssets] = useState([]);
  const [taskNonDesignAssets, setTaskNonDesignAssets] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [taskDefaultValues, setTaskDefaultValues] = useState({});

  const {getAndSetProjects} = useGetAndSetProjects()


  // console.log("RenderTasks");
  // Get the Task on the Subtask
  const getTaskValues = (task) => {
    let defaultValues = {
      label: "",
      value: "",
    };
    if (task) {
      defaultValues.label = task?.name;
      defaultValues.value = task?._id;
    }
    return defaultValues;
  };

  // console.log("TaskDetails:Props:", props);
  const foundUser = taskDetails?.project?.users?.includes(
    props.session?.userId
  );
  const isTaskMember =
    projectDetails?.creator === props.session?.userId || foundUser;

  const fetchProject = async (id) => {
    const project = await getProjectDetail(id);
    // console.log("TaskDetail: Project:1 ", project);
    if (project.ok) {
      const projectData = (await project.json()).project;
      // console.log("TempProjectData:", projectData);
      const projKeys = Object.keys(projectData);
      if (projKeys.length > 0) {
        setProjectData(projectData);
      }
    }
  };

  const fetchPrototype = async (name, index) => {
    const prototype = await getPrototypeByName(name);

    if (!prototype.ok) {
      console.error(`Failed to fetch prototype: ${prototype.status}`);
      return;
    }

    // Read the text response first for debugging
    const textResponse = await prototype.text();

    // Check if the text is empty or not a valid JSON string
    if (!textResponse) {
      console.error("Received an empty response");
      return;
    }

    try {
      const jsonResponse = JSON.parse(textResponse);

      setTaskDetails((prevState) => {
        const prototypeToUpdate = prevState.prototypes[index];
        const updatedData = { ...prototypeToUpdate.data, appUrl: jsonResponse };
        const updatedPrototype = { ...prototypeToUpdate, data: updatedData };
        const updatedPrototypes = [...prevState.prototypes];
        updatedPrototypes[index] = updatedPrototype;

        return { ...prevState, prototypes: updatedPrototypes };
      });
    } catch (e) {
      console.error(`Error parsing JSON: ${e.message}`);
      console.error(`Received response: ${textResponse}`);
    }
  };

  /*******************************
   * Fetch Subtasks
   *******************************/
  const retrieveSubtasks = async (taskId) => {
    // console.log("TaskDetail: taskId:", taskId)
    if (taskId) {
      const response = await getSubtaskDetailsByTask(taskId);

      if (response.status === 200) {
        const data = await response.json();
        const newData = [...data];
        setSubtasks(newData);
      }
    }
  };
  /****************************** */
  useEffect(() => {
    getTaskDetails();
  }, [toggleTaskRefresh]);

  useEffect(() => {
    if (taskDetails) {
      // console.log("taskDetails:", taskDetails);
      // RetrieveSubtasks
      // retrieveSubtasks(taskDetails._id);
      setTaskDesignAssets([]);
      setTaskNonDesignAssets([]);
      if(taskDetails.assets.length > 0){
        taskDetails.assets.forEach((link) => {
          if (link.figma === true) {
            setTaskDesignAssets((prevState) => {
              return [...prevState, link];
            });
          } else {
            setTaskNonDesignAssets((prevState) => {
              return [...prevState, link];
            });
          }
        })
      }
    }
    if (taskDetails && taskDetails.project?._id) {
      // Get task
      setTaskDefaultValues(getTaskValues(taskDetails));

      // Retrieve all Task associated with Project
      retrieveTasks(taskDetails.project._id).then((response) => {
        const allTasks = [
          ...response.current,
          ...response.upcoming,
          ...response.past,
        ];

        const allFormattedTasks = formatDataByName(allTasks, "name", "_id");
        setTaskOptions(allFormattedTasks);
      });
    }
  }, [taskDetails]);

  useEffect(() => {
    const defaultResponsibleValues = [...getResponsibleValues()];
    // console.log("DefaultReponsibleValues:", defaultResponsibleValues);
    setResponsibleDefaultValues(defaultResponsibleValues);
    // Required: RetrieveSubtasks
    retrieveSubtasks(taskDetails?._id);
    //Project Details
    // console.log("TaskDetails2:", taskDetails);
    setProjectDetails(taskDetails?.project);
  }, [taskDetails]);

  /*******************
   * Retrieve prototypes && Assets
   ********************/
  useEffect(() => {
    // console.log("ProjectDetails:", projectDetails);
    if (
      !projectData &&
      projectDetails &&
      projectDetails !== undefined &&
      projectDetails._id
    ) {
      // fetch Projects
      fetchProject(projectDetails._id);
    }
    if (taskDetails?.prototypes.length > 0) {
      if (taskDetails?.prototypes.length > 0) {
        taskDetails.prototypes.forEach((prototype, index) => {
          fetchPrototype(prototype.data.name, index);
        });
      }
    }
  }, [projectDetails]);
  /*************/

  /**
   * Get Project Asserts
   */
  useEffect(() => {
    // Set Project Data Assets
    if (projectData && projectData.assets?.length > 0) {
      setParentDesignAssets([]);
      setParentNonDesignAssets([]);
      projectData.assets.forEach((link) => {
        // console.log("Link:", link);
        if (link.figma === true) {
          setParentDesignAssets((prevState) => {
            const newAssets = [...prevState, link];
            // console.log("NewAssets True:", newAssets);
            return newAssets;
          });
        } else {
          setParentNonDesignAssets((prevState) => {
            const newAssets = [...prevState, link];
            // console.log("NewAssets False:", newAssets);
            return newAssets;
          });
        }
      });
    }
  }, [projectData]);

  /*******************************
   * Required: Set Project GitlabIds TaskGitlab Ids
   *******************************/
  useEffect(() => {
    const fetchData = async () => {
      // console.log('40, Prototypes', taskDetails);
      if (projectData && projectData.prototypes) {
        // Assuming that getting each gitlabId is an async operation
        let gitlabIds = await Promise.all(
          projectData.prototypes.map(async (val) => {
            // Your async operation here
            return val.id; // replace this with the actual async operation if needed
          })
        );
        setGitlabId(gitlabIds);
      }
      if (taskDetails && taskDetails.prototypes) {
        // Assuming that getting each gitlabId is an async operation
        let gitlabIds = await Promise.all(
          taskDetails.prototypes.map(async (val) => {
            // Your async operation here
            return val.id; // replace this with the actual async operation if needed
          })
        );
        setTaskGitlabId(gitlabIds);
      }
    };

    fetchData();
  }, [projectData, taskDetails]);

  /***********************/
  useEffect(() => {
    //10/19/2022
    getResponsibleValues();
    // console.log("ResponsibleDefaultValues:3", responsibleDefaultValues);
    // Set Form responsible field on Form
      // console.log("ResponsibleDefaultValues:3", responsibleDefaultValues);
      setValue("responsible", responsibleDefaultValues);
    if (taskDefaultValues?.label) {
      setValue("task", taskDefaultValues);
      // console.log("TaskDefaultValues1:", taskDefaultValues);
    }
    setValue("status", {
      value: "new",
      label: "New",
    });
  }, [responsibleDefaultValues, taskDefaultValues]);

  const getResponsibleValues = () => {
    // console.log("TaskDetails0:", taskDetails);
    let defaultValues = [];
    
    taskDetails?.responsible?.forEach((user) => {
      defaultValues.push({
        value: user._id,
        label: user.email,
      });
    });
    // console.log("DefaultValues:", defaultValues);

    return defaultValues;
  };

  // Default Values:
  const {
    register,
    control,
    handleSubmit,
    // formState: { errors },
    reset,
    setValue,
  } = useForm();

  // Delete Task:
  const handleDeleteTask = async () => {
    const taskId = taskDetails._id;
    if (taskId) {
      await deleteTask(taskId)
      .then(()=>{
        getAndSetProjects(props.session)
        // Close modal
        handleCloseDeleteModal();
        navigate(`/projects/${taskDetails.project?._id}`);
     });
 
      /**
       * Notify Project
       */
      // setIsTaskUpdated(!isTaskUpdated);

      // handleCloseDeleteModal();
      // navigate(`/projects/${taskDetails.project?._id}`);
    }
  };

  // Handle Details
  const handleTaskDelete = () => {
    handleOpenDetailModal("deleteTask");
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteTask(false);
  };

  // handle Add Task form submission
  const handleTaskSave = async (data) => {
    // console.log("TaskDetails:", data);
    const endDate = data?.endDate && moment(data.endDate).toISOString();
    const startDate = data?.startDate && moment(data.startDate).toISOString();

    // Converting to an array of values
    const responsibleArray =
      data?.responsible && data.responsible.length > 0
        ? data.responsible.map((res) => res.value)
        : [];
    // console.log("ResponsibleArray:", responsibleArray);
    const taskData = {
      name: data?.name !== "" ? data.name : null,
      // description: descriptionString,
      description: data?.description,
      responsible: responsibleArray,
      version: data?.version,
      startDate,
      endDate,
      status: data?.status ? data.status : taskDetails.status,
      projectId:projectData?._id,
      note:
        data?.notes !== ""
          ? {
              message: data.notes,
              updateBy: props.session?.userId,
              lastUpdated: moment().toISOString(),
            }
          : null,
    };

    // Update Task Settings
    await updateSettings(params.id, taskData)
      .then(()=>{
          // Rerender Screen
          setToggleTaskRefresh(!toggleTaskRefresh);
          // Close modal
          handleCloseDetailModal()
          // reset selectedValues
          reset();

          getAndSetProjects(props.session)
      });

      // console.log("TaskDetails: Response:", response);
      //   if (response.status === 201) {
      //     const newTask = await response.json();
      //     // console.log("TaskDetails: newTask:", newTask);
          
      //     setToggleTaskRefresh(!toggleTaskRefresh);
      //     /**
      //      * Notify Task
      //      */
      //     setIsTaskUpdated(!isTaskUpdated);
      //   }

      //   handleCloseDetailModal();
      //   // reset selectedValues
      //   reset();

    };


  // handle Add Task form submission
  const handleSubtaskSave = async (data) => {
    // console.log("Save TaskDetails: Data:", data);
    const endDate = data?.endDate && moment(data.endDate).toISOString();
    const startDate = data?.startDate && moment(data.startDate).toISOString();

    const subtaskData = {
      name: data?.name !== "" ? data.name : null,
      // description: descriptionString,
      description: data?.description,
      creator: props.session?.userId,
      responsible: data?.responsible?.length > 0 ? data.responsible : null,
      task: data?.task?.value ? data.task.value : null,
      version: data?.version,
      startDate,
      endDate,
      projectId: projectDetails?._id,
      status: data?.status ? data.status : null,
      prototypes:
        data?.prototypes !== undefined
          ? data?.prototypes?.map((prototype) => {
              return { id: prototype._id };
            })
          : null,
      assets: data?.assets?.map((asset) => {
        return { id: asset.value };
      }),
      note:
        data?.notes !== ""
          ? {
              message: data.additionalNotes,
              updateBy: props.session?.userId,
              lastUpdated: moment().toISOString(),
            }
          : null,
    };

    // Update Task Settings
    await postSubtask(subtaskData)
      .then(()=>{
          // Rerender Screen
          setToggleTaskRefresh(!toggleTaskRefresh);
          // Close modal
          handleCloseDetailModal()
          // reset selectedValues
          reset();

          getAndSetProjects(props.session)
      });

    // const response = await postSubtask(subtaskData);

    // if (response.status === 201) {
    //   setToggleTaskRefresh(!toggleTaskRefresh);
    //   /**
    //    * Notify Task
    //    */
    //   setIsTaskUpdated(!isTaskUpdated);
    // }

    // handleCloseDetailModal();
    // // reset selectedValues
    // reset();

    // //Update Task Details when subtask is saved
    // setIsSubtaskUpdated(!isSubtaskUpdated);
  };


  const handleActionsMenu = () => {
    if (actionsRef.current.classList.contains("show")) {
      actionsRef.current.classList.remove("show");
    } else {
      actionsRef.current.classList.add("show");
    }
  };

  const handleOpenDetailModal = (type) => {
    switch (type) {
      case "task":
        setShowUpdateTask(true);
        break;
      case "assets":
        setShowManageAssets(true);
        break;
      case "todo":
        setShowNewToDo(true);
        break;
      case "subtask":
        setShowNewSubtask(true);
        break;
      case "deleteTask":
        setShowDeleteTask(true);
        break;
      default:
    }
    handleActionsMenu();
  };

  const handleCloseDetailModal = () => {
    setShowUpdateTask(false);
    setShowManageAssets(false);
    setShowNewToDo(false);
    setShowNewSubtask(false);
    reset()
  };

  const handleOpenEditAssetModal = (asset) => {
    setShowEditAsset(asset);
  };

  const handleCloseEditAssetModal = () => {
    setShowEditAsset(null);
  };

  const handleOpenDeleteAssetModal = (asset) => {
    setShowDeleteAssetModal(true);
    setAssetToDelete(asset);
  };

  const handleCloseDeleteAssetModal = () => {
    setShowDeleteAssetModal(false);
    setAssetToDelete(null);
  };

  const getTaskDetails = () => {
    getTaskDetail(params.id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("TaskDetails0:", data);
        setTaskDetails(data.task);
      });
  };

  const handleCloseEditToDo = () => {
    setShowEditToDo(null);
  };

  return (
    <Wrapper>
      <div className="main-content pt-0">
        <div className="container-fluid">
          <div className="inner-body task-detail">
            {/*modals*/}
            {showUpdateTask === true ? (
              <UpdateTask
                task={taskDetails}
                header="Task Settings"
                handleClose={handleCloseDetailModal}
                submitForm={handleTaskSave}
                handleSubmit={handleSubmit}
                control={control}
                register={register}
                setValue={setValue}
              />
            ) : null}
            {showEditAsset !== null ? (
              <EditAsset
                handleClose={handleCloseEditAssetModal}
                asset={showEditAsset}
                details={taskDetails}
                getDetails={getTaskDetails}
              />
            ) : null}
            {showManageAssets === true && taskDetails != null ? (
              <ManageAssets
                dataLevel={"task"}
                details={taskDetails}
                taskId={taskDetails._id}
                projectId={projectDetails._id}
                handleCloseDetailModal={handleCloseDetailModal}
                getDetails={getTaskDetails}
              />
            ) : null}
            {assetToDelete !== null && showDeleteAssetModal === true ? (
              <DeleteAsset
                message="This action will permanently delete the asset from all projects and tasks. Try Manage Assets to remove it from this task only. Are you sure you want to delete this asset?"
                assetToDelete={assetToDelete}
                getDetails={getTaskDetails}
                close={handleCloseDeleteAssetModal}
              />
            ) : null}
            {showNewToDo === true ? (
              <NewToDo
                details={taskDetails}
                getDetails={getTaskDetails}
                close={handleCloseDetailModal}
              />
            ) : null}
            {showNewSubtask === true ? (
              <NewSubtask
                task={taskDetails}
                header="New Subtask"
                handleClose={handleCloseDetailModal}
                submitForm={handleSubtaskSave}
                handleSubmit={handleSubmit}
                control={control}
                register={register}
                setValue={setValue}
                project={projectDetails}
                taskOptions={taskOptions}
              />
            ) : null}
            {showEditToDo !== null ? (
              <EditToDo
                details={taskDetails}
                getDetails={getTaskDetails}
                toDo={showEditToDo}
                close={handleCloseEditToDo}
              />
            ) : null}
            {showDeleteTask === true ? (
              <ConfirmationModal
                message="Are you sure you want to delete this task?"
                save={handleDeleteTask}
                close={handleCloseDeleteModal}
              />
            ) : null}
            {/* Page Header */}
            {taskDetails != null ? (
              <>
                <div className="page-header">
                  <div>
                    <Link to="/projects">Back to Projects</Link>
                    <span> &gt; </span>
                    <Link to={`/projects/${taskDetails.project?._id}`}>
                      Back to {taskDetails.project?.name}
                    </Link>
                    <h2 className="main-content-title tx-24 mg-b-8 mg-t-8">
                      <FontAwesomeIcon
                        className="transform-45"
                        icon="fa-solid fa-clipboard-list"
                      />
                      Task:{" "}
                      {taskDetails.name && taskDetails.version
                        ?  <span title={taskDetails.name}>{truncateDescription(
                            taskDetails.name,
                            titleCharLimit
                          ) +
                          "_v" +
                          taskDetails.version
                         }</span>
                        : <span title={taskDetails.name}>{truncateDescription(taskDetails.name, titleCharLimit)}</span>
                      }
                      <span
                        className="badge"
                        style={{
                          background:
                            taskDetails.status === "active"
                              ? "#0CA789"
                              : taskDetails.status === "new"
                              ? "#ecb529"
                              : "#fd6074",
                          color: "white",
                        }}
                      >
                        {taskDetails.status}
                      </span>
                    </h2>
                    {taskDetails.responsible ? (
                      <p>
                        Responsible{" "}
                        <div>
                          {taskDetails?.responsible.map((user, index) => (
                            <b key={user._id}>
                              {index > 0 && ", "}
                              {user.firstName} {user.lastName}
                            </b>
                          ))}
                        </div>
                      </p>
                    ) : null}
                    {taskDetails.startDate ? (
                      <p>
                        Started{" "}
                        <b>
                          {moment(taskDetails.startDate)
                            .format("MM-DD-YY")
                            .replace(regex, "")}
                        </b>
                      </p>
                    ) : null}
                    {taskDetails.endDate ? (
                      <p>
                        Target Date{" "}
                        <b>
                          {moment(taskDetails.endDate)
                            .format("MM-DD-YY")
                            .replace(regex, "")}
                        </b>
                      </p>
                    ) : null}
                  </div>
                  <div className="d-flex">
                    {(props.session.systemAccess >= 2 && isTaskMember) ||
                    props.session.systemAccess === 4 ? (
                      <div className="justify-content-center table-controls">
                        <button
                          type="button"
                          className="btn btn-primary my-2 btn-icon-text"
                          onClick={handleActionsMenu}
                        >
                          Manage
                          <FontAwesomeIcon
                            className="mg-l-4"
                            icon="fa-solid fa-chevron-down"
                          />
                        </button>
                        <div
                          ref={actionsRef}
                          className="dropdown-menu animated-menu actions-menu"
                        >
                          <ul>
                            <li>
                              <button
                                onClick={() => {
                                  handleOpenDetailModal("task");
                                }}
                              >
                                Task Settings
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => {
                                  handleOpenDetailModal("assets");
                                }}
                              >
                                Assets
                              </button>
                            </li>
                            {/* <li>
                              <button
                                onClick={() => {
                                  handleOpenDetailModal("todo");
                                }}
                              >
                                Add To Do
                              </button>
                            </li> */}
                            <li>
                              <button
                                onClick={() => {
                                  handleOpenDetailModal("subtask");
                                }}
                              >
                                Add Subtask
                              </button>
                            </li>
                            <li>
                              <button
                                onClick={() => {
                                  handleTaskDelete("todo");
                                }}
                              >
                                Delete Task
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* End Page Header */}
                
                {/* Start Container 2*/}
                <div className="col-container d-flex justify-content-between">
                  {/* Col 1 Start - Subtasks/ToDos/Notes */}
                  <div className="col col-sm-12 col-md-8 col-lg-8 col-xl-8 grid-margin">

                    {/* Row 1 - Project Description Start */}
                    <Descriptions
                          taskDetails={taskDetails}
                          type="task"
                          parentDetails={projectDetails}
                        />
                    {/* Row 1 - Project Description End*/}

                    {/* Row 2-1:2 Start CREATOR */}
                    <div className="row">
                      <div className="card pd-8 flex-grow-1">
                        <div className="card-body pd-0">
                          <h4 className="mg-b-0 pd-8">Creator</h4>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                            className="user-row mg-b-20 pd-x-20"
                          >
                            <div
                              style={{ display: "flex", marginRight: "16px" }}
                              className="align-items-center"
                            >
                              <Avatar user={taskDetails?.creator} size="sm" />
                            </div>
                            <div className="col">
                              <div className="node-header--color">
                                {taskDetails?.creator?.firstName +
                                  " " +
                                  taskDetails?.creator?.lastName}
                              </div>
                              <div>{taskDetails?.creator?.role}</div>
                              <div>{taskDetails?.creator?.email}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Row 2-1:2 END CREATOR*/}

                    {/* Row 0 Start Subtasks */}
                    {subtasks?.length > 0 ? (
                      <div className="row mb-3">
                        <SubtaskTable
                          taskId={taskDetails._id}
                          subtasks={subtasks}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* Row 0 End Subtasks */}
                    {/* Row 1 Start */}
                    {/* Row 1-1:1 Start TODOS */}

                    {/* Row 2-1:2 Start NOTES */}
                    <div className="row">
                      <div className="card pd-8 flex-grow-1">
                        <div className="card-body pd-0">
                          <h4 className="mg-b-0 pd-8">Notes</h4>
                          <p className="pd-8">
                            {taskDetails.note
                              ? taskDetails.note.message
                              : "Add notes for this task in Task Settings."}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*  Row 2-1:2 End NOTES */}
                    {/* Row 2 End */}
                  </div>{" "}
                  {/* Col 1 End - ToDos/Notes */}
                  {/* Col 2 Start - Side Bar */}
                  <div
                    className="col col-sm-12 col-md-4 col-lg-4 col-xl-4 grid-margin"
                    // style={{ border: "1px solid pink" }}
                  >
                    {/* Row 1 - Project Description Start */}
                    {/* <Descriptions
                      taskDetails={taskDetails}
                      type="task"
                      parentDetails={projectDetails}
                    /> */}
                    {/* Row 1 - Project Description End*/}
                    {/* Row 2 - Project Prototypes Start */}
                    <div className="row">
                      <AppWidget label={"Project Apps"} gitlabProjectIds={projectDetails?.apps.map(val => val.value)}/>
                    </div>

                    {/* Row 2 - Project Prototypes End*/}
                    {/* Row3 - Assets Start */}
                    <Assets
                      parentName="Project"
                      parentDesignAssets={parentDesignAssets}
                      parentNonDesignAssets={parentNonDesignAssets}
                      taskDesignAssets={taskDesignAssets}
                      taskNonDesignAssets={taskNonDesignAssets}
                      handleOpenEditAssetModal={handleOpenEditAssetModal}
                      handleOpenDeleteAssetModal={handleOpenDeleteAssetModal}
                    />
                    {/* Row3 - Assets End */}
                  </div>
                  {/* Col 2 End - Side Bar*/}
                </div>{" "}
                {/* End Container 2*/}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps =
  ({ session, users }) =>
  ({
    session,
    users,
    repos,
    hasCiCd,
    gitlabGroupId,
    hasAuthentication,
    filteredData,
    gitlabGroups,
    gitlabSubGroups,
    filterBy,
    creators,
    gitlabSubGroupId,
  }) => ({
    session,
    users,
    repos,
    hasCiCd,
    gitlabGroupId,
    hasAuthentication,
    filteredData,
    gitlabGroups,
    gitlabSubGroups,
    filterBy,
    creators,
    gitlabSubGroupId,
  });

const mapDispatchToProps = (dispatch) => ({
  signin: (user) => dispatch(signin(user)),
  allGitLabRepos: (repos) => dispatch(allGitLabRepos(repos)),
  filteredGitLabRepos: (repos) => dispatch(filteredGitLabRepos(repos)),
  toastMessage: (data) => dispatch(toastMessage(data)),
  getGitlabGroupData: (groups) => dispatch(getGitlabGroupData(groups)),
  getGitlabGroupId: (id) => dispatch(getGitlabGroupIdData(id)),
  getGitlabSubGroupData: (groups) => dispatch(getGitlabSubGroupData(groups)),
  getGitlabSubGroupId: (id) => dispatch(getGitlabSubGroupIdData(id)),
  setFilter: (id) => dispatch(setFilter(id)),
  allCreators: (arr) => dispatch(allCreators(arr)),
  setRepoLoading: (bool) => dispatch(setRepoLoading(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetail);
