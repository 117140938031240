import React, { useEffect, useState } from 'react';
import Select from "react-select";

function RepoSelect({ options, onChange, defaultValue, required, card, placeholder }) {
    const [repoValue, setRepoValue] = useState([]);

    useEffect(() => {
        if (defaultValue) {
            if (Array.isArray(defaultValue)) {
                setRepoValue(defaultValue);
            } else {
                setRepoValue([defaultValue]);
            }
        }
    }, [defaultValue]);

    const handleChange = (selectedOption) => {
        setRepoValue(selectedOption);
        if (onChange) {
            onChange(selectedOption);
        }
    };

    return (
        <Select
            options={options}
            isMulti={false}
            required={required}
            onChange={handleChange}
            value={repoValue}
            className={`${card ? "card-select" : null}`}
            classNamePrefix="select"
            placeholder={placeholder}
        />
    );
}

export default RepoSelect;
