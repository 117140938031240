import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import digitalAvatar from "../../../../assets/images/digitalocean.png";
import DropDown from '../../gitlab-repos/DropDown';
import { toastMessage } from "../../../../actions/toast";

function TableRow(props) {
    const { droplet } = props;

    const setActivityColor = (lastActivityAt) => {
        return Number(new Date(lastActivityAt));
    }

    function avatarSwitcher() {
        return <img style={{ width: '30px' }} alt="avatar" src={digitalAvatar} />;
    }

    return (
        <tr>
            <td>{avatarSwitcher()}</td>
            <td>{droplet?.name || 'N/A'}</td>
            <td>{droplet?.memory ? `${droplet.memory} MB` : 'N/A'}</td>
            <td>{droplet?.vcpus || 'N/A'}</td>
            <td>{droplet?.disk ? `${droplet.disk} GB` : 'N/A'}</td>
            <td>{droplet?.status || 'N/A'}</td>
            <td>{droplet?.region?.name || 'N/A'}</td>
            <td>{droplet?.size?.price_monthly ? `$${droplet.size.price_monthly}/mo` : 'N/A'}</td>
            <td>{droplet?.created_at ? new Date(droplet.created_at).toLocaleDateString() : 'N/A'}</td>
        </tr>
    );
}

const mapStateToProps = ({ session }) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(TableRow);
