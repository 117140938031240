import React, {useEffect, useState} from "react";
import {getAccessTokenFromSessionStorage} from "../../util/helpers/getSsoAccessToken";
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import {NewsPicture} from "../global/gitlab/SkeletonText";

function FetchImage({ url }) {
    const [token, setToken] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getAccessTokenFromSessionStorage();
            setToken(fetchedToken);
        };

        fetchToken();
    }, []);

    useEffect(() => {
        const fetchImageUrl = async () => {
            if (token) {
                const encodedUrl = encodeURIComponent(url);
                const encodedToken = encodeURIComponent(token);

                const imageUrlResponse = await fetch(`${AUTH_BASE_URL}news/fetchImage/${encodedUrl}/${encodedToken}`);
                console.log('rus', imageUrlResponse)
                if (imageUrlResponse.ok) {
                    const imageUrlData = await imageUrlResponse.json(); // Assuming the URL is returned as JSON
                    const actualImageUrl = imageUrlData.url; // Replace 'url' with the actual key name in your response
                    console.log('rus', imageUrlData)
                    setImage(actualImageUrl);
                } else {
                    console.log("Failed to fetch image URL");
                }
            }
        };

        fetchImageUrl();
    }, [token]);

   return (
       <NewsPicture loading={false} image={image} />
   )
}

export default FetchImage;