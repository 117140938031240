import React, {useEffect, useRef, useState} from 'react'
import {updateUser} from "../../util/settings/updateUser";
import {connect} from "react-redux";
import {updateSession} from "../../actions/session/updateSession";
import validateToken from "../../util/dev-ops/validateToken";
import getAllRepos from "../../util/dev-ops/getAllRepos";
import {allGitLabRepos} from "../../actions/allGitlabRepos";
import getGitlabGroups from "../../util/dev-ops/getGitlabGroups";
import getGitlabSubGroups from "../../util/dev-ops/getGitlabSubGroups";
import appendRepoData from "../../util/dev-ops/appendRepoData";
import {getGitlabGroupData} from "../../actions/gitlabGroups";
import {getGitlabSubGroupData} from "../../actions/gitlabSubGroups";
import {setFilter} from "../../actions/filterBy";
import {filteredGitLabRepos} from "../../actions/filteredData";
import {allCreators} from "../../actions/creators";
import checkForWebHooks from "../../util/dev-ops/checkForWebHooks";
import addWebHook from "../../util/dev-ops/addWebHook";

function ActivityPrefs (props) {

    const input = useRef()

    const [group, setGroup] = useState()
    const [subGroup, setSubGroup] = useState()

    useEffect(()=> {
        setGroup(props.session.gitlabGroup)
        setSubGroup(props.session.gitlabSubGroup)
		if (props.session.gitLab && props.session.gitLab[0]) {
			props.setFilter(props.session.gitLab[0].id)
		}

    },[])

    function prefsToggle (event) {
        let node = input.current;
        let checked = node.checked;
        updateUser({
            trackActivity:checked,
            email: props.session.email
        }).then(response => {
            return response.json()
        }).then(data =>{
            let newSession = {...props.session};
            if(checked === true){

                validateToken(props.session).then(tokenData => {
                    let newSession = {...props.session};
                    newSession.token = tokenData.token
                    newSession.refresh = tokenData.refresh

                    if (!props.repos) {
                        getGitlabGroups(tokenData)
                        .then(groups => {
                            props.getGitlabGroupData(groups)
                        }).then(() => {
                            getGitlabSubGroups(tokenData, props.session.gitlabGroup)
                            .then(subGroup => {
                                props.getGitlabSubGroupData(subGroup)
                            }).then(() => {
                                getAllRepos(tokenData.token, subGroup ? subGroup : props.session.gitlabGroup).then(reposResponse => {
                                    if (reposResponse) {
                                        props.allGitLabRepos(reposResponse)
                                        props.filteredGitLabRepos(reposResponse.filter(repo => repo.creator_id === parseInt(props.session.gitLab[0].id)))
                                        appendRepoData(reposResponse).then(appendedData => {
                                            props.allGitLabRepos(appendedData)
                                            props.filteredGitLabRepos(appendedData.filter(repo => repo.creator_id === parseInt(props.session.gitLab[0].id)))
                                        })
                                    }
                                })
                            })
                        })
                    } else {
                        getAllRepos(tokenData.token, props.session.gitlabGroup).then(reposResponse => {
                            props.allGitLabRepos(reposResponse)
                            props.filteredGitLabRepos(reposResponse.filter(repo => repo.creator_id === parseInt(props.session.gitLab[0].id)))
                            appendRepoData(reposResponse).then(appendedData => {
                                props.allGitLabRepos(appendedData)
                                props.filteredGitLabRepos(appendedData.filter(repo => repo.creator_id === parseInt(props.session.gitLab[0].id)))

                                let reposToCheck = appendedData.filter(repo => repo.creator_id === parseInt(props.session.gitLab[0].id))

                                reposToCheck.forEach(repo => {
                                    checkForWebHooks(repo.id, props.session.token).then(hook => {
                                        if (hook.length !== 0) {
                                            // console.log(hook)
                                        } else {
                                            // console.log('webhook NOT present')
                                            addWebHook(repo.id, props.session.token)
                                            // .then(response => {
                                            //         console.log(response)
                                            //  })
                                        }
                                    })
                                })

                            })
                        })
                    }
                })
            }
            if(JSON.stringify(props.session.trackActivity) !== JSON.stringify(data.user.trackActivity) ){
                newSession.trackActivity = data.user.trackActivity
            }
            props.updateSession(newSession)
        })
    }


    return (
        <div className="card custom-card">
            <div className="card-body">
                <div className="card-item">
                    <div className="card-item-title  mb-2">
                        <label className="main-content-label tx-14 font-weight-bold mb-1">Sharing Preferences</label>
                        <span className="d-block tx-13 mb-0">Do you want us to share your gitlab activity?</span>
                    </div>
                    <div className="card-item-body wd-100p">
                        <div className="card-item-stat">
                            <label className="custom-switch">
                                <input ref={input} type="checkbox" onChange={prefsToggle} name="custom-switch-checkbox" className="custom-switch-input" defaultChecked={props.session.trackActivity} />
                                <span className="custom-switch-indicator"></span>
                                <span className="custom-switch-description">Share activity</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
const mapStateToProps = ({ session, repos, filteredData}) => ({
    session, repos, filteredData
});

const mapDispatchToProps = dispatch => ({
    updateSession: (user) => dispatch(updateSession(user)),
    // signin: user =>dispatch(signin(user)),
    allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
    getGitlabGroupData: groups => dispatch(getGitlabGroupData(groups)),
    getGitlabSubGroupData: groups => dispatch(getGitlabSubGroupData(groups)),
    setFilter: id => dispatch(setFilter(id)),
    filteredGitLabRepos: repos => dispatch(filteredGitLabRepos(repos)),
    allCreators: arr => dispatch(allCreators(arr)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityPrefs);
