import React from "react";
import TableRow from "./TableRow";

function Table({ loading, apps }) {
    console.log('Table loading:', loading);
    console.log('Table apps:', apps);

    if (loading) {
        return (
            <div className="table-responsive userlist-table">
                <table className="table card-table table-striped table-vcenter mb-0">
                    <thead>
                    <tr>
                        <th className={'wd-lg-10p'}><span>Avatar</span></th>
                        <th className={'wd-lg-15p'}><span>Name</span></th>
                        <th className={'wd-lg-10p'}><span>Memory</span></th>
                        <th className={'wd-lg-10p'}><span>vCPUs</span></th>
                        <th className={'wd-lg-10p'}><span>Disk</span></th>
                        <th className={'wd-lg-10p'}><span>Status</span></th>
                        <th className={'wd-lg-15p'}><span>Region</span></th>
                        <th className={'wd-lg-10p'}><span>Monthly Price</span></th>
                        <th className={'wd-lg-15p'}><span>Created At</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan={9}><div className={`skeleton-text`} style={{ width: '100%', height: '10px' }}></div></td>
                    </tr>
                    <tr>
                        <td colSpan={9}><div className={`skeleton-text`} style={{ width: '100%', height: '10px' }}></div></td>
                    </tr>
                    <tr>
                        <td colSpan={9}><div className={`skeleton-text`} style={{ width: '100%', height: '10px' }}></div></td>
                    </tr>
                    <tr>
                        <td colSpan={9}><div className={`skeleton-text`} style={{ width: '100%', height: '10px' }}></div></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <div className="table-responsive userlist-table">
                <table className="table card-table table-striped table-vcenter mb-0">
                    <thead>
                    <tr>
                        <th className={'wd-lg-10p'}><span>Avatar</span></th>
                        <th className={'wd-lg-15p'}><span>Name</span></th>
                        <th className={'wd-lg-10p'}><span>Memory</span></th>
                        <th className={'wd-lg-10p'}><span>vCPUs</span></th>
                        <th className={'wd-lg-10p'}><span>Disk</span></th>
                        <th className={'wd-lg-10p'}><span>Status</span></th>
                        <th className={'wd-lg-15p'}><span>Region</span></th>
                        <th className={'wd-lg-10p'}><span>Monthly Price</span></th>
                        <th className={'wd-lg-15p'}><span>Created At</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {apps.length === 0 ? (
                        <tr>
                            <td colSpan={9}>No droplets found.</td>
                        </tr>
                    ) : (
                        apps.map((droplet, index) => (
                            <TableRow key={index} droplet={droplet} />
                        ))
                    )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Table;
