async function createSubgroup(name, parent_id, token) {
	console.log('create subgroup', name, parent_id, token);
	try {
		const response = await fetch('https://gitlab.com/api/v4/groups', {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				name: name,
				path: name.toLowerCase().replace(/ /g, '-'),  // Format the path based on the name
				parent_id: parent_id,
				visibility: 'private',
				allow_descendants_override_disabled_shared_runners: true // Change this to true
			}),
		});
		console.log('response', response)
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const data = await response.json();
		console.log('create subgroup', data);
		return data.id;
	} catch (error) {
		console.error(error);
	}
}
export default createSubgroup;
