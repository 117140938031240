import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { signin } from "../../../actions/session/signIn";
import Avatar from "../../global/Avatar";
import ProjectStatus from "../../global/ProjectStatus";
import truncateDescription from "../../../util/tasks/truncateDescription";
import { addPinnedItems } from "../../../util/users/addPinnedItems";
import { removePinnedItems } from "../../../util/users/removePinnedItems";
import getUserById from "../../../util/users/getUserById";

const nameCharLimit = 30;
const color = {
  TEAL: "rgb(78,163,146)",
  BLACK: "rgb(119,119,119)",
};

function TableRow(props) {
  const { project, renderComponentOptions } = props;
  const [projectData, setProjectData] = useState(project);
  const [isItemPinned, setIsItemPinned] = useState(false); // State to track checkbox
  const thumbtackColor = isItemPinned ? color.TEAL : color.BLACK; // Color based on checkbox state
  const [user, setUser] = useState(null);

  /**
   * getUser: Responsible for tracking if item is Pinned
   * @param {*} userId
   * @returns
   */
  const getUser = async (id) => {
    const user = await getUserById(id);
    // console.log("User:", user);

    if (user) {
      setUser(user);
      setIsItemPinned(user.pinnedProjects.includes(projectData._id));
    }
  };

  const toggleChecked = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    // Remove pinnedProject, that is checked:
    if (isItemPinned) {
      const data = {
        userId: props.session.userId,
        itemId: projectData._id,
        type: "project",
      };
      const removedPinResponse = await removePinnedItems(data);
      // console.log("RemovePinned: response:", removedPinResponse);
    } else {
      const data = {
        userId: props.session.userId,
        itemId: projectData._id,
        type: "project",
      };
      // AddedPinnedItem
      const addedPinnedResponse = await addPinnedItems(data);
      // console.log("AddPinned: response:", addedPinnedResponse.ok);
      if (addedPinnedResponse.ok) {
        const user = await getUser(props.session.userId);
        // console.log("Add Pinned User:", user);
      }
    }
    // If project is currently pinned, unpin it
    setIsItemPinned(!isItemPinned);
  };

  // Set Initial Project Checked InitialState
  useEffect(() => {
    getUser(props.session.userId);
  }, []);

  useEffect(() => {
    if (user && user.pinnedProjects > 0) {
      // console.log("UseEffect: user:", user);
    }
  }, [user]);

  // Use useEffect to watch for changes to the project prop or its nested properties
  useEffect(() => {
    setProjectData(project);
  }, [project]);

  return (
    <tr>
      <td className="font-weight-semibold">
        <span
          // className="mg-l-4"
          style={{
            color: thumbtackColor,
            cursor: "pointer",
            marginLeft: "-8px"
          }}
          onClick={(e) => toggleChecked(e)}
        >
          <FontAwesomeIcon
            className="pinned-icon"
            icon="fa-solid fa-bookmark"
          />
        </span>
        <Link
          to={{
            pathname: `/projects/${projectData._id}`,
            search: `userId=${props.session.userId}`,
          }}
        >
          {truncateDescription(projectData?.name, nameCharLimit)}
        </Link>
      </td>
      <td>{projectData?.product}</td>
      <td>
        <ProjectStatus
          project={projectData}
          renderCount={renderComponentOptions.renderCount}
          isTaskUpdated={renderComponentOptions.isTaskUpdated}
        />
      </td>
      <td>
        {
          props.project.updated ? 
          <>
            <p style={{margin: 0}}>{new Date(props.project.updated).toLocaleDateString()}</p>
            <p style={{margin: 0}}>{new Date(props.project.updated).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})}</p>
          </>
          : <p>no updated date</p>
        }
      </td>
      {/* <td>{new Date(props.project.created).toLocaleDateString()}</td> */}
      <td>{new Date(projectData.created).toLocaleDateString()}</td>
      <td>
        <ul className="user-row">
          <li>
            <Avatar user={projectData.creator} size="sm" />
          </li>
        </ul>
      </td>
      <td>
        <ul className="user-row">
          {projectData.users.map((user, index) => {
            if (index <= 3) {
              return (
                <li key={index}>
                  <Avatar user={user} size="sm" />
                </li>
              );
            }
          })}
        </ul>
      </td>
    </tr>
  );
}

const mapStateToProps = ({ session }) => ({
  session,
});

const mapDispatchToProps = (dispatch) => ({
  signin: (user) => dispatch(signin(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableRow);
