import React, {useEffect, useRef, useState} from 'react'
import {connect} from "react-redux";
import Wrapper from "../global/Wrapper";
import TableRow from './TableRow'
import {user as deleteRecord} from "../../util/delete/user";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import getAllPrototypes from "../../util/prototypes/getAllPrototypes";
import {allPrototypes} from '../../actions/allPrototypes'

function Prototypes (props) {

    const [userLength, setUserLength] = useState();

    const filterRef = useRef(null)

    useEffect(()=> {
        async function fetchData() {
            await getAllPrototypes().then(async data => {
               props.allPrototypes(data)
            })
        }
        fetchData();

    }, []);

    const deleteUser = async (email) => {
        const response = await deleteRecord(email);
        const userCount = parseInt(response.length);
        // deleteUser: Returns length
        setUserLength(userCount);
    }

    const handleFilterMenu = () => {
        if(filterRef.current.classList.contains('show')){
            filterRef.current.classList.remove('show')
        }  else{
            filterRef.current.classList.add('show')
        }
    }

    return (
        <Wrapper>
            <div className="main-content pt-0">
                <div className="container-fluid">
                    <div className="inner-body users">
                        {/* Page Header */}
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Applications</h2>
                            </div>
                            <div className="d-flex">
                                <div className="justify-content-center table-controls">
                                    <button type="button" className="btn btn-primary my-2 btn-icon-text">
                                        <FontAwesomeIcon className='sidemenu-icon mg-r-4' icon={`fa-solid fa-plus`} /> Add Apps
                                    </button>
								</div>
                            </div>
                        </div>
                         {/* End Page Header */}
                        {/*Row*/}
                        <div className="row row-sm">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                                <div className="card custom-card pd-0">
                                    <div className="card-body pd-0">
                                        <div className="table-responsive userlist-table">
                                            <table className="table card-table table-striped table-vcenter mb-0">
                                                <thead>
                                                <tr>
                                                    <th className={'wd-lg-25p'}><span>Prototype Name</span></th>
                                                    <th className={'wd-lg-30p'}><span />URL</th>
                                                    <th className={'wd-lg-15p'}><span>Created</span></th>
                                                    <th className={'wd-lg-5p'}><span>Type</span></th>
                                                    <th className={'wd-lg-25p'}>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                 props.prototypes  ?  props.prototypes.map((data,index) => {
													 console.log('BLAH', data)
													 return (
														 <TableRow
															 key={index}
															 prototype={data}
															 deleteUser={deleteUser}
														 />
													 )
                                                    }): <tr><td colSpan={5}>Loading Apps...</td></tr>
                                                }

                                                </tbody>
                                            </table>
                                            {/*<Pagination />*/}
                                        </div>
                                    </div>
                                </div>
                            </div>{/* COL END */}
                        </div>
                        {/* row closed  */}
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}
const mapStateToProps = ({session, prototypes, notifications}) => ({
    session, prototypes, notifications
});

const mapDispatchToProps = dispatch => ({
    allPrototypes: (data) => dispatch(allPrototypes(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Prototypes);
