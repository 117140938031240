import React, {useState, useEffect} from "react";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";

function SkillScraping() {
    const [url, setUrl] = useState(""); // State to hold the URL input
    const [selectedSource, setSelectedSource] = useState("Udemy"); // State to hold the selected source

    const runScraper = () => {
        // Construct the request data
        const requestData = {
            source: selectedSource, // Set the source based on the selected option
            scrapingUrl: url,
        };


        // Make a POST request to your backend
        fetch(`${AUTH_BASE_URL}dev-ops/util/scrape`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from the server
                // You can display a success message or handle any errors here
                console.log(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <div className="col-sm-6 col-md-6 col-xl-3" style={{ marginBottom: 20 }}>
            <div className="card custom-card" style={{ padding: 20}}>
                <div className="card-body user-card text-center pd-0">
                    <div
                        className="icon-service bg-primary rounded-circle text-primary align-content-center justify-content-center"
                        style={{
                            backgroundColor: "rgba(235, 154, 29, 0.1)",
                        }}
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="paperclip"
                            className="svg-inline--fa fa-paperclip sidemenu-icon d-flex align-content-center justify-content-center"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            style={{fontSize: 28}}
                        >
                            <path
                                fill="currentColor"
                                d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"
                            />
                        </svg>
                    </div>
                    <div className="mt-2">
                        <h4 className="mb-1">Skill Scraping</h4>
                        <span>
                            <i className="far fa-check-circle text-success mr-1"/>
                            <p>Scrape skills from different sources to populate the database.</p>
                            <p><strong>This can take several hours, you will receive an email when this completes.</strong></p>
                        </span>
                    </div>
                    <div>
                        <label htmlFor="group" style={{width: "100%", textAlign: "left"}}>
                            Select Source
                        </label>
                        <select
                            name="group"
                            className="form-control select mg-r-8"
                            style={{display: "inline-flex", height: 40}}
                            onChange={(e) => setSelectedSource(e.target.value)}
                        >
                            <option data-index={0} value="Udemy">
                                Udemy
                            </option>
                            {/* Add more options for other sources here */}
                        </select>
                    </div>
                    <div className="form-group">
                        <label style={{width: '100%', textAlign: 'left', marginTop: '25px'}}>Enter Scraping URL</label>
                        <input
                            className="form-control card-input"
                            type="text"
                            placeholder={"https://www.udemy.com/courses/marketing/public-relations/"}
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </div>

                    <button className="btn ripple btn-primary mt-3 d-inline-flex align-items-center"
                            onClick={runScraper}>
                        Run Skill Scraper
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SkillScraping;
