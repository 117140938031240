export default function deleteGitlabRepo (id, token) {
   return fetch(`https://gitlab.com/api/v4/projects/${id}`, {
       method: 'DELETE',
       headers: new Headers({
           'Authorization': `Bearer ${token}`,
           'Content-Type': 'application/json'
       })
   }).then(response => {
        return response.json()
    }).then(data => {
        return data
    })
}