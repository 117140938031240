import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const updateProject = async (id, data, session) => {
    return fetch(`${AUTH_BASE_URL}projects/update/${id}`, {
        method: "POST",
        body: JSON.stringify({data:data}),
        headers: {
            "Content-Type": "application/json"
        }
    })
}