import React, { useEffect, useState } from "react";
import {DashAuthorPicture, DashNewsPicture} from "../../global/gitlab/SkeletonText";
import FetchAuthorImage from "../../news/FetchAuthorImage";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import useFetchImage from "../../../util/useFetchImage";
import {getAccessTokenFromSessionStorage} from "../../../util/helpers/getSsoAccessToken";
import { Link } from "react-router-dom";
function RecentNews() {
    const [newsData, setNewsData] = useState([]);
    const [image, setImage] = useState(null);
    const [imageURLToFetch, setImageURLToFetch] = useState(null);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);
    const fetchedImage = useFetchImage(imageURLToFetch);

    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getAccessTokenFromSessionStorage();
            setToken(fetchedToken);
        };

        fetchToken();
    }, []);
    // useEffect(() => {
    //     setImageURLToFetch(newsData?.openGraphSummary?.image?.mediaUrl);
    // }, [newsData]);

    // useEffect(() => {
    //     setImage(fetchedImage);
    // }, [fetchedImage, imageURLToFetch]);
    useEffect(() => {
        let isMounted = true; // flag to track component mount status

        const fetchData = async () => {
            try {
                const response = await fetch(`${AUTH_BASE_URL}news`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error(`An error has occurred: ${response.status}`);
                }

                const data = await response.json();

                if (isMounted) { // only update state if component is still mounted
                    setNewsData(data.documents);
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) { // only log error or update state if component is still mounted
                    console.error("There was a problem with the fetch operation:", error);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // set flag to false when the component unmounts
        };
    }, []);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    };

    return (
        <div>

            {
                newsData && newsData.length !== 0 ? (
                    <div className="recent-news">
                    {newsData.slice(0,5).map((item, index) => {
                        return (
                            <div className="card card-aside custom-card pd-0 pd-b-10-f mg-b-10-f shadow-2 bd-b" key={"recentNews"+index}>
                            <DashNewsPicture loading={loading} data={item.item} image={item.item.properties.postImage?.url} />

                            <div className="">
                                <a href={item.item.url} target="_blank">
                                    <span className="main-content-label tx-16">{item.item.openGraphSummary.title}</span>
                                </a>

                                {/* <div className="mt-3">{item.openGraphSummary.description}</div> */}

                                <div className="d-flex align-items-center pt-1 mt-auto">
                                    {/* <div className="main-img-user avatar-sm me-3 flex-none">
                                        <a href={`https://hub.pearson.com/userprofile/${item.item.properties.author.id}/${item.item.properties.author.urlName}`} target="_blank">
                                            <FetchAuthorImage url={`https://hub.pearson.com/api/users/userphotobyid?userId=${item.item.properties.author.id}`} />
                                        </a>
                                    </div> */}

                                    <div className="d-flex align-items-center flex-wrap">
                                        <a className="tx-12 text-nowrap" href={`https://hub.pearson.com/userprofile/${item.item.properties.author.id}/${item.item.properties.author.urlName}`} target="_blank">
                                            {item.item.properties.author.displayName}
                                        </a>
                                        <span className="mx-2">&middot;</span>
                                        <span className="d-block tx-12 lh-1">{formatDate(item.item.properties.articleDate)}</span>
                                    </div>

                                    <div className="ms-auto text-muted">
                                        <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3">
                                            <i className="far fa-heart me-1" />
                                        </a>
                                        <a href="javascript:void(0)" className="icon d-none d-md-inline-block ms-3">
                                            <i className="far fa-thumbs-up" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        )
                    })}
                        <div className="card-footer tx-center pd-0">
                            <Link className="card-link" to="/news">View all</Link>
                        </div>                        
                    </div>
                ) : null
            }
        </div>
    )
}
export default RecentNews;