import React, { useEffect } from 'react';

const CareerPathsRedirect = () => {
    useEffect(() => {
        // Immediately redirect to the external site
        window.location.href = '/apps/career-paths';
    }, []);

    // Styles to ensure the div covers the entire viewport
    const fullPageStyle = {
        width: '100vw', // 100% of the viewport width
        height: '100vh', // 100% of the viewport height
        backgroundColor: '#1C222E', // Background color
        display: 'flex', // Use Flexbox for centering
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        color: 'white', // Text color
        fontSize: '20px', // Optional: Adjust text size
        flexDirection: 'column', // Stack items vertically
    };

    return (
        <div style={fullPageStyle}>

        </div>
    );
};

export default CareerPathsRedirect;
