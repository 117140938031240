const truncateDescription = (str, strSize) => {
  let newStr = "";
  // console.log("length:", str.length)
  if (str.length < strSize) {
    newStr = str;
    // console.log("Name1", str, newStr);
  } else if (str.length >= strSize) {
    newStr = str.slice(0, strSize) + "...";
    // console.log("Name2", str, newStr);
  } 
  return newStr;
};

export default truncateDescription;
