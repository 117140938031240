import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";

function YesNoLineChart (props) {
	const [series, setSeries] = useState();
	const [options, setOptions] = useState()
	useEffect(async () => {
		if (props.data) {
			let cleanData = [],
				returnData = [];

			await props.data.map(item => {
				if (item) {
					cleanData.push(item)
				}
			})

			await cleanData.map(item => {
				if (item.type === 'Yes / No') {
					returnData.push({name: item.question, data: [item.yes, item.no]})
				}
			})

			setSeries(await returnData)
		}

		setOptions({
			options: {
				chart: {
					id: "basic-bar"
				},
				xaxis: {
					categories: ['Yes', 'No']
				},
				bar: {
					horizontal: true,
					borderRadius: 0,
					borderRadiusApplication: 'around',
					borderRadiusWhenStacked: 'last',
					columnWidth: '70%',
					barHeight: '70%',
					distributed: false,
					rangeBarOverlap: true,
					rangeBarGroupRows: false,
					colors: {
						ranges: [{
							from: 0,
							to: 0,
							color: undefined
						}],
						backgroundBarColors: [],
						backgroundBarOpacity: 1,
						backgroundBarRadius: 0,
					},
					dataLabels: {
						position: 'top',
						maxItems: 100,
						hideOverflowingLabels: true,
						orientation: 'horizontal',
						total: {
							enabled: false,
							formatter: undefined,
							offsetX: 0,
							offsetY: 0,
							style: {
								color: '#373d3f',
								fontSize: '12px',
								fontFamily: undefined,
								fontWeight: 600
							}
						}
					}
				}
			}
			}
		)
	},[props.data])
	if (options && series ) {
		return (
			<div id="chart" style={{margin: '0 auto', display: 'flex', justifyContent:'center', paddingTop:'16px'}}>
				<Chart options={options.options} series={series} type="bar" width={250} />
			</div>
		)
	} else {

		return <></>
	}

}

export default YesNoLineChart

