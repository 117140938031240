const truncateStr = (str, strSize) => {
  let newStr = "";
  // console.log("length:", str.length)
  if (str.length < strSize) {
    newStr = str;
    // console.log("Name1", str, newStr);
  } else if (str.length >= strSize && !str.includes(" ")) {
    newStr = str.slice(0, strSize) + "...";
    // console.log("Name2", str, newStr);
  } else if (str.includes(" ")) {
    const strArray = str.split(" ");

      strArray.forEach((item, index) => {
        if (item.length >= strSize && strArray.length !== index) {
          item = item.slice(0, strSize) + "...";
          newStr = newStr + item + " ";
        } else if (strArray.length !== index) {
          newStr = newStr + item + " ";
        } else newStr = newStr + item;
        // console.log("Name3", str, newStr);
      });
  }
  return newStr;
};

export default truncateStr;
