import React from "react";
import { Link } from "react-router-dom"; // Assuming you are using react-router for navigation
import DropDown from '../../global/DropDown'; // Assuming you have a DropDown component
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
function TableRow({ data, onDelete }) {
    const handleViewDetails = () => {
        window.location.href = `/dev-ops/web-components/details/${data.componentName}`;
    };

    const handleDelete = async (event, data) => {
        event.preventDefault()
        onDelete(data);
    };

    return (
        <tr>
            <td>
                <Link to={`/dev-ops/web-components/details/${data.componentName}`}>
                    {data.componentName}
                </Link>
            </td>
            <td>
                <Link to={`https://unpkg.com/@pearson-ux/${data.componentName.toLowerCase()}`}>
                    https://unpkg.com/@pearson-ux/{data.componentName.toLowerCase()}
                </Link>
            </td>
            <td>{data.installCommand}</td>
            <td>
                <DropDown label={"Actions"} icon={"fa-chevron-down"}>
                    <li>
                        <button onClick={handleViewDetails}>
                            View Details
                        </button>
                    </li>
                    <li>
                        <button onClick={() => window.open(data.importUrl, '_blank')}>
                            View on GitLab
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={(event) => handleDelete(event, data)}
                            className="text-danger"
                        >
                            Delete
                        </button>
                    </li>
                </DropDown>
            </td>
        </tr>
    );
}

export default TableRow;
