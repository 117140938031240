import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const getUsersByEmail = async (email) => {
    try {
        // Check if the email domain is 'pearson.com'
        if (!email.endsWith('@pearson.com')) {
           return
        }

        const url = `${AUTH_BASE_URL}auth/user/email/${email}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok, status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching user by email:', error);
        return null;
    }
};
