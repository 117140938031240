import commitFile from "./commitProxyFile";

export default async function (data, token) {
	return fetch(`https://gitlab.com/api/v4/projects/34880575/repository/files/${encodeURIComponent('proxies.js')}?ref=main`, {
		method: 'GET',
		headers: new Headers({
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'application/json'
		})
	}).then(response => response.json()).then(file => {
		console.log('my file', file)
		let base64ToString = atob(file.content)
		let arr = eval(base64ToString.replace('module.exports =  ', ''))
		const length = arr.length
		let foundItem = arr.filter(item => item.path === `/apps/${data.name}`)
		let foundIndex = arr.indexOf(foundItem)
		arr.splice(foundIndex, 1)
		let newContent = `module.exports =  ${JSON.stringify(arr)}`
		let commitData = {}
		commitData.content = newContent
		commitData.commit_message = 'updated proxy file'
		commitData.author_name = 'system commit'
		commitData.token = token
		commitData.branch = data.default_branch
		commitFile(commitData)
		return commitData
	})

	}

