import AUTH_BASE_URL from "../AUTH_BASE_URL";

export default function (id, token) {
    // console.log(id)
	console.log('ADDING WEBHOOK')
    let url = `https://pearsonct.design/server/api/webhooks/gitlab`
    return fetch(`https://gitlab.com/api/v4/projects/${id}/hooks`, {
        method: 'POST',
        body: JSON.stringify({
            url: encodeURI(url),
            job_events: true,
            pipeline_events: true,
            push_events: true
        }),
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    }).then(response => {
        return response.json()
    })
}
