import React from 'react';
import {connect} from "react-redux";
import ConfirmationModal from "../global/ConfirmationModal";
import {deleteFile} from "../../util/projects/deleteFile";


const DeleteAsset = props => {

    const handleDeleteAsset = () => {

        //if the link is a file
        let fileName = null;
        if (props.assetToDelete.type !== "link") {
            fileName = props.assetToDelete.link.split("/").pop().replace(/%20/g, " ");
        }

        let data = {
            fileName: fileName,
            objectId: props.assetToDelete._id
        };

        deleteFile(data).then(response=>{
            props.getDetails()
            props.close()
        })
    }

    return(
        <ConfirmationModal
            message={props.message}
            save={handleDeleteAsset}
            close={props.close}
        />
    )
}

const mapStateToProps = ({}) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteAsset);
