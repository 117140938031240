import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Wrapper from "../../../../global/Wrapper";
import DigitalOceanSettings from "../../../../settings/DigitalOcean";
import { useNavigate } from "react-router-dom";
import Table from "./TableComponent";
import { toastMessage } from "../../../../../actions/toast";
import AUTH_BASE_URL from "../../../../../util/AUTH_BASE_URL"; // Make sure this path is correct

function TableContainer(props) {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [noToken, setNoToken] = useState(null);
    const [showDigitalOcean, setShowDigitalOcean] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        if (!props.session.digitalAccessToken && !noToken) {
            setNoToken(true);
            setShowDigitalOcean(true);
            props.toastMessage('Your Digital Ocean token has expired. Please re-authenticate.');
        } else if (props.session.digitalAccessToken && !dataFetched) {
            fetchData(props.session.digitalAccessToken, false);
        }
    }, [props.session.digitalAccessToken, noToken, props, dataFetched]);

    const fetchData = async (token) => {
        try {
            setLoading(true);
            const templatesData = await fetchTemplates(token);
            setTemplates(templatesData);
            setDataFetched(true);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchTemplates = async (token) => {
        const response = await fetch(`${AUTH_BASE_URL}templates`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    };

    return (
        <Wrapper>
            <div className="main-content pt-0 digitalocean-repos">
                <div className="container-fluid">
                    <div className="inner-body users">
                        {/* Page Header */}
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Manage Templates</h2>
                            </div>
                            <div className='page-group-controls'>
                                <button onClick={() => {
                                    navigate('/hosting/apps')
                                }} type="button" className="btn btn-secondary btn-icon-text">
                                    Back to Apps
                                </button>
                            </div>
                        </div>
                        <div className="row row-sm">
                            {
                                showDigitalOcean ?
                                    <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-4 grid-margin"}>
                                        <div className="card custom-card pd-0">
                                            <div className={'figma-auth'}><DigitalOceanSettings /></div>
                                        </div>
                                    </div> :
                                    <div className={"col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin"}>
                                        <div className="card custom-card pd-0">
                                            <Table fetchData={fetchData} loading={loading} templates={templates} />
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const mapStateToProps = ({ session }) => ({
    session
});

const mapDispatchToProps = dispatch => ({
    toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableContainer);
