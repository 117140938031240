import React, { useEffect, useState, useRef } from "react";
import StaticSelect from "../../global/inputs/StaticSelect";
import Text from "../../global/inputs/Text";
import Modal from "../../global/templates/Modal";
import Date from "../../global/inputs/DateSelector-A";
import TextArea from "../../global/inputs/TextArea";
import { getAllAssets } from "../../../util/tasks/getAssets";
import { allUserData } from "../../../actions/allUsers";
import { allPrototypes } from "../../../actions/allPrototypes";
import allUsers from "../../../util/users/allusers";
// import getAllPrototypes from "../../../util/prototypes/getAllPrototypes";
import { connect } from "react-redux";
import {
  formatDataByName,
  formatDataByEmail,
} from "../../../util/tasks/formatData";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import Number from "../../global/inputs/Number";

const modalMessage = "";

const statusOptions = [
  {
    value: "new",
    label: "New",
  },
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
  {
    value: "closed",
    label: "Closed",
  },
];

const CreateSubtask = (props) => {
  const {
    header,
    handleClose,
    submitForm,
    handleSubmit,
    control,
    register,
    searchSelectName,
    project,
    taskOptions
  } = props;

  //    console.log("PROJECTID:", projectId);
  const [responsibleOptions, setResponsibleOptions] = useState([]);
  const [allAvailablePrototypes, setAllAvailablePrototypes] = useState([]);
  const [assetOptions, setAssetOptions] = useState([]);

  // console.log("Create:Task:Props", props);
  useEffect(() => {
    if (!props.prototypes) {
      // console.log('Create:New:no prototypes', props.prototypes)
      // getAllPrototypes().then(async (data) => {
      //   props.allPrototypes(await data);
      // });
      fetch(`${AUTH_BASE_URL}dev-ops/gitlab/repos/archive`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Set all Prototypes
          props.allPrototypes(data.repos);
        });
    }

    //if users is not available from redux
    // get them from the database and store them
    if (!props.users) {
      allUsers().then(async (data) => {
        props.allUserData(await data);
      });
    }
  }, []);

  /**
   * Step2: setAllAvailablePrototypes,
   * once props.prototypes have been loaded
   */
  useEffect(() => {
    // console.log("Create:New:PrototypesGot from Repos:", props.prototypes);

    if (props.prototypes && props.prototypes.length > 0) {
      props.prototypes.forEach((prototype) => {
        setAllAvailablePrototypes((prevState) => {
          prototype.label = prototype.data?.name;
          prototype.value = prototype._id;
          return [...prevState, prototype];
        });
      });
    }
  }, [props.prototypes]);

  // Once users are available in redux save them to state
  useEffect(() => {
    if (props.users && props.users.length > 0) {
      setResponsibleOptions(formatDataByEmail(props.users, "email", "_id"));
    }
  }, [props.users]);

  // Initialize Data
  useEffect(() => {
    // console.log("Task:", teamName);
    /****************************
     * Deprecated:getAllPrototypes: if apps is not available from redux
     ****************************/
    // // get them from the database and store them
    // if (!props.prototypes) {
    //   getAllPrototypes().then(async (data) => {
    //     props.allPrototypes(await data);
    //   });
    // }
    /****************************/

    getAllAssets().then(async (response) => {
      if (response.length > 0) {
        setAssetOptions(formatDataByName(response, "name", "_id"));
      }
    });
  }, [responsibleOptions]);

  useEffect(() => {
    // console.log("Create:ResponsibleUsers:", responsibleOptions);
    // console.log("Create:AssetsUsers:", assetOptions);
  }, [responsibleOptions, assetOptions]);

  return (
    <>
      <Modal
        header={header}
        handleCloseModal={handleClose}
        handleSubmit={handleSubmit}
        submitForm={submitForm}
        searchSelectName={searchSelectName ? searchSelectName : ""}
        message={modalMessage}
        modalClass="create-task-modal"
      >
        <Text
          label="Name"
          name="name"
          register={register}
          placeholder="Subtask Name"
          required={true}
          card
        />
        <Number
          label={"Version"}
          name={"version"}
          register={register}
          className="form-control card-input"
          placeholder="ex:  1.0"
          step="0.1"
          required={false}
          card
        />
        <Text
          label="Description"
          name="description"
          register={register}
          placeholder="Subtask Description"
          required={true}
          card
        />

        <StaticSelect
          isMulti={true}
          name="responsible"
          label="Assign a person responsible"
          options={responsibleOptions}
          control={control}
          required={false}
          card
        />
        <StaticSelect
          isMulti={false}
          name="task"
          label="Assign subtask to a task"
          options={taskOptions}
          control={control}
          required={true}
          card
        />
        <StaticSelect
          isMulti={true}
          name="assets"
          label="Add Assets"
          options={assetOptions}
          control={control}
          card
        />
        <Date
          name="startDate"
          label="Start Date"
          control={control}
          register={register}
          required={false}
          card
          theme={props.session.theme}
        />
        <Date
          name="endDate"
          label="End Date"
          control={control}
          register={register}
          required={false}
          card
          theme={props.session.theme}
        />
        <StaticSelect
          isMulti={false}
          name="status"
          label="Status"
          options={statusOptions}
          control={control}
          required={true}
          card
        />
        <TextArea
          label="Notes"
          name="additionalNotes"
          register={register}
          placeholder="Add Notes"
          required={false}
          card
        />
      </Modal>
    </>
  );
};

const mapStateToProps = ({ session, users, prototypes }) => ({
  session,
  users,
  prototypes,
});

const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
  allPrototypes: (data) => dispatch(allPrototypes(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubtask);
