export const formatDataByName = (data, labelName = "name", valueName = "_id") => {
  let newData = [];

  if (data.length > 0) {
    switch (true) {
      case labelName === "combinedName" && valueName === "_id":
        newData = data.map((item) => {
          return {
            label: item.firstName + " " + item.lastName,
            value: item[valueName],
          };
        });
        break;
        case labelName === "name" && valueName === "_id":
            newData = data.map((item) => {
                return {
                  label: item[labelName],
                  value: item[valueName],
                };
              });
              break;
      default:
    }
  }
  return newData;
};

export const formatDataByEmail = (data, labelName = "email", valueName = "_id") => {
  let newData = [];

  if (data.length > 0) {
    switch (true) {
      case labelName === "email" && valueName === "_id":
        newData = data.map((item) => {
          return {
            label: item.email,
            value: item[valueName],
          };
        });
        break;
        case labelName === "name" && valueName === "_id":
            newData = data.map((item) => {
                return {
                  label: item[labelName],
                  value: item[valueName],
                };
              });
              break;
      default:
    }
  }
  return newData;
};

export const formatSelectOptions = (items) => {
  // console.log("FormatOptions:ITEMS:", items);
  const newData = items?.map((item, index) => {
    return {
      label: item,
      value: item,
    };
  });
  return newData;
}
