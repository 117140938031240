import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

function TableRow (props) {
	

		return (
			<tr>
				<td>
					{props.user.name}
				</td>
				<td>
					{props.user.email}
				</td>
				<td>
					{props.user.emailSent === true ? 'true' : 'false'}
				</td>
				<td>
					{props.user.username != null ? props.user.username : 'not assigned'}
				</td>
				<td>
					{props.user.finishedTest === true ? 'true' : 'false'}
				</td>
			</tr>
		)

}

const mapStateToProps = ({}) => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TableRow);
