import React, { useEffect, useState } from "react";
import Subtask from "./Subtask";

const SubtaskTable = ({ taskId, subtasks, isText }) => {
// console.log("TaskId:", taskId, "; Subtasks:", subtasks);
  const displayItems = () => {
    return subtasks?.map((subtask, index) => {
      return (
        <tr key={`subtask-${subtask._id}`}>
          <Subtask
            key={`subtask-${subtask._id}`}
            subtask={subtask}
            taskId={taskId}
            isText={isText}
          />
        </tr>
      );
    });
  };

  useEffect(() => {
    // console.log("SubtaskTable: Subtasks:", subtask);
  }, [subtasks]);

  return (
    <>
      <table className="table card-table table-striped table-vcenter mb-0">
        <thead>
          <tr>
            <th className={"wd-lg-25p"}>
              <span>Subtask Name</span>
            </th>
            <th className={"wd-lg-5p"}>
              <span>Responsible</span>
            </th>
            <th className={"wd-lg-25p"}>
              <span>Target Date</span>
            </th>
            <th className={"wd-lg-15p"}>
              <span>Status</span>
            </th>
          </tr>
        </thead>
        <tbody>{displayItems()}</tbody>
      </table>
    </>
  );
};

export default SubtaskTable;
