import React, { useEffect, useState } from "react";
import { retrieveTasks } from "../../../util/tasks/retrieveTasksByProject";
import Task from "./Task";

const Header = {
  upcoming: "Upcoming",
  current: "Current",
  past: "Past",
};

const TaskTable = ({ projectId, toggleTaskRefresh }) => {
  //   console.log("DisplayTasks projectId:", projectId);

  const [tasks, setTasks] = useState({});

  // Display Table Header:
  const displayHead = () => {
    return (<tr>
            <th 
            className={"wd-lg-25p"} 
            >
              <span>Task Name</span>
            </th>
            <th 
            className={"wd-lg-15p align-center"} 
            >
              <span>Responsible</span>
            </th>
            <th 
            className={"wd-lg-25p"} 
            >
              <span>Target Date</span>
            </th>
            <th 
            className={"wd-lg-15p"} 
          >
              <span>Status</span>
            </th>
          </tr>)
  }
  
  // Display Table Rows
  const displayItems = (group) =>
    tasks[group]?.map((task, index) => {
      // console.log("DisplayItems:", task)
      return (
        <tr key={`task-${index}`}>
          <Task id={`task-${index}`} task={task} />
        </tr>
      );
    });
  useEffect(() => {
    // Pull Task records where
    retrieveTasks(projectId).then((response) => {
      // console.log("DisplayTasks: Tasks:", response);
      // console.log("DisplayTasks: ProjId:", projectId);
      setTasks({ ...response });
    });
  }, [toggleTaskRefresh]);

  useEffect(() => {
    // console.log("DisplayTasks:", tasks);
  }, [tasks]);

  return (
    <>
      <h4 className="mg-b-0 pd-8 wd-100p mg-b-4 mg-t-8">Tasks</h4>
      <h5 style={{ paddingLeft: "8px", marginBottom: "20px" }}>
        Current Tasks
      </h5>
      <table className="table card-table table-striped table-vcenter mb-0">
        <thead>
          {displayHead()}
        </thead>
        <tbody>{displayItems("current")}</tbody>
      </table>
      <h5
        style={{ paddingLeft: "8px", marginBottom: "20px", marginTop: "40px" }}
      >
        Upcoming Tasks
      </h5>
      <table className="table card-table table-striped table-vcenter mb-0">
        <thead>
          {displayHead()}
        </thead>
        <tbody>{displayItems("upcoming")}</tbody>
      </table>
      <h5
        style={{ paddingLeft: "8px", marginBottom: "20px", marginTop: "40px" }}
      >
        Past Tasks
      </h5>
      <table className="table card-table table-striped table-vcenter mb-40">
        <thead>
          {displayHead()}
        </thead>
        <tbody>{displayItems("past")}</tbody>
      </table>
    </>
  );
};

export default TaskTable;
