import React, {useEffect, useState} from 'react';
import Wrapper from '../global/Wrapper'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/brand/p-logo-black.svg";
import {saveWSClient} from "../../actions/wsclient";
import {connect} from "react-redux";
import NewResource from "./NewResource";
import NewItem from "./NewItem";
import { useLocation } from 'react-router';
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import DropDown from "../global/DropDown";
import DropDownEllipsis	 from "../global/DropDownEllipsis";
import {getSidebarData} from "../../actions/sidebar";
function Resources(props) {
	console.log(props.session)
	const [selectedItem, setSelectedItem] = useState();
	const [showModal, setShowModal] = useState(false)
	const [showModalTwo, setShowModalTwo] = useState(false)
	const path = useLocation()
	const [data, setData] = useState();
	const [title, setTitle] = useState();

	useEffect(()=> {
		let result = /[^/]*$/.exec(window.location.pathname)[0];
		if (result === 'resources') {
			result = 'All'
		}
		setTitle(result.replace(/_/g," "));
		if (window.location.pathname === '/resources') {
			fetch(`${AUTH_BASE_URL}resources/all`, {
				method:'GET',
				headers: new Headers({
					'Content-Type': 'application/json'
				})
			}).then(response => response.json()).then(data => {
				setData(data)
			})
		} else {
			fetch(`${AUTH_BASE_URL}resources?path=${window.location.pathname}`, {
				method:'GET',
				headers: new Headers({
					'Content-Type': 'application/json'
				})
			}).then(response => response.json()).then(data => {
				setData(data)
			})
		}

	}, [path])

	function handleDelete(event) {
		console.log(event.target.id)
		console.log(`${AUTH_BASE_URL}resources/${event.target.id}`)
		fetch(`${AUTH_BASE_URL}resources/${event.target.id}`, {
			method:'DELETE',
			body: JSON.stringify({path: window.location.pathname}),
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		}).then(response => response.json()).then(data => {
			setData(data)
		})

	}

	function handlePageDelete(event) {
		let fullMenu = props.sidebarData
		let menu = fullMenu[6];
		let submenu = menu.submenu.filter(val =>  val.path !== event.target.getAttribute('data-id'));
		console.log('the sub', event.target.getAttribute('data-id'))
		menu.submenu = submenu

		console.log('the menu', menu)
		fetch(`${AUTH_BASE_URL}sidebar`, {
			method:'POST',
			body: JSON.stringify(fullMenu),
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		}).then(response => response.json()).then(data => {
			props.getData(data.data)
		})

	}

	const handleOpenResourceModalTwo = (item) => {
		setSelectedItem(item);
		setShowModalTwo(true);
	}

	const handleCloseResourceModalTwo = () => {
		setShowModalTwo(false)
	}
	const handleOpenResourceModal = () => {
		setShowModal(true)
	}

	const handleCloseResourceModal = () => {
		setShowModal(false)
	}
	console.log(path)
	return (
		<Wrapper>
			<div className="main-content pt-0">
				<div className="container-fluid">
					<div className="inner-body users">
						{showModal === true ? <NewResource handleCloseNewModal={handleCloseResourceModal} /> : null}
						{showModalTwo === true ? <NewItem item={selectedItem} setData={setData} handleCloseNewModal={handleCloseResourceModalTwo} /> : null}

						{/* Page Header */}
						<div className="page-header">
							<div>
								<h2 className="main-content-title tx-24 mg-b-5">Resources {title}</h2>
							</div>
							<div className="d-flex">
								<div className="justify-content-center">
									<div className="fields"></div>
									{
										props.session.systemAccess >= 3 ?
											<>
												{
													window.location.pathname !== '/resources' ?
														<DropDown page={'resources'} data={props.data} label={"Manage"} icon={"fa-chevron-down"}>
															<li><button onClick={handleOpenResourceModalTwo}>Add Item To Page</button></li>
															<li><button onClick={handlePageDelete} disabled={data && data.length !== 0} data-id={window.location.pathname}>Delete Page</button></li>
														</DropDown> :

													<button onClick={handleOpenResourceModal} type="button" className="btn btn-primary my-2 mg-r-8 btn-icon-text">
														Add New Resource Page
													</button>
												}


											</> : <></>
									}

									</div>
							</div>
						</div>
						{/* End Page Header */}
						{/*Row*/}
						<div className="row row-sm">
							{
								data && data.length !== 0 ? data.map(item => {
									return (
										<div className="col-sm-6 col-md-6 col-xl-3" style={{marginBottom: '20px'}}>
											<div className="card custom-card" style={{padding: '20px', height: '100%'}}>
												<div>
													<DropDownEllipsis page={'resources'} data={props.data}>
														<li><button onClick={() => handleOpenResourceModalTwo(item)}>Edit</button></li>
														<li><button onClick={handleDelete} id={item._id} data-id={window.location.pathname}>Delete</button></li>
													</DropDownEllipsis>
												</div>
												<div className="card-body user-card text-center pd-0">
													<div className="icon-service bg-primary rounded-circle text-primary  align-content-center justify-content-center"  style={{backgroundColor: ' rgb(235 154 29 / 10%)', width:'4rem', height:'4rem'}}>
														<FontAwesomeIcon className='sidemenu-icon d-flex align-content-center justify-content-center' icon={item.svg}  style={{fontSize: '28px'}} />
													</div>
													<div className="mt-2">
														<h4 className="mb-1">{item.title}</h4>
														<span><i className="far fa-check-circle text-success mr-1" />{item.text}</span>
													</div>
													<a href={item.leftButtonLink} target='_blank' className="btn ripple btn-primary mt-3 d-inline-flex align-items-center">{item.leftButtonText}</a>
													{
														item.rightButtonText && item.rightButtonText.length !== 0 ? <a target='_blank' href={item.rightButtonLink} className="btn ripple btn-primary mt-3 d-inline-flex align-items-center mg-l-8">{item.rightButtonText}</a> : <></>
													}
												</div>
											</div>
										</div>
									)
								}) : <div>No items have been added.</div>
							}

						</div>
						{/* row closed  */}
					</div>
				</div>
			</div>

		</Wrapper>
	)
}

const mapStateToProps = ({session, wsClient, sidebarData}) => ({
	session,wsClient, sidebarData
});

const mapDispatchToProps = dispatch => ({
	saveWSClient: (data) => dispatch(saveWSClient(data)),
	getData: (data) => dispatch(getSidebarData(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Resources);

