export const TOAST = 'TOAST';
export const CLEAR_TOAST = 'CLEAR_TOAST';

export const toastMessage = (data) => {
    return {
        type: TOAST,
        toast: data
    }
};

export const clearToast = () => {
    return {
        type: CLEAR_TOAST
    }
};
