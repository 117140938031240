import React, {useEffect, useState, Suspense} from "react";
import {connect} from "react-redux";
import Wrapper from "../../global/Wrapper";
import SkillScraping from "./SkillScraping";


function TableContainer(props) {

    return (
        <Wrapper>
            <div className="main-content pt-0 gitlab-repos">
                <div className="container-fluid">
                    <div className="inner-body users">
                        {/* Page Header */}
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Dev Ops - Utilities</h2>
                            </div>
                        </div>
                        <div className="row row-sm">
                            <SkillScraping />
                            <div className="col-sm-6 col-md-6 col-xl-3" style={{marginBottom: 20}}>
                                <div className="card custom-card" style={{padding: 20, height: "100%"}}>
                                    <div>
                                        <div
                                            id="resources"
                                            className="dropdown-container"
                                            style={{
                                                position: "relative",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                        >
                                            <button type="button" className="btn btn-outline-primary btn-rounded">
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="ellipsis-vertical"
                                                    className="svg-inline--fa fa-ellipsis-vertical "
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 128 512"
                                                    fontSize={20}
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"
                                                    />
                                                </svg>
                                            </button>
                                            <div
                                                className="dropdown-menu more animated-menu actions-menu"
                                                style={{right: 0}}
                                            >
                                                <ul className="" style={{textAlign: "left"}}>
                                                    <li>
                                                        <button>Edit</button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            id="63d22f42c1fbe0a76f2c3fb8"
                                                            data-id="/resources/Timesheets"
                                                        >
                                                            Delete
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body user-card text-center pd-0">
                                        <div
                                            className="icon-service bg-primary rounded-circle text-primary  align-content-center justify-content-center"
                                            style={{
                                                backgroundColor: "rgba(235, 154, 29, 0.1)",
                                                width: "4rem",
                                                height: "4rem"
                                            }}
                                        >
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="paperclip"
                                                className="svg-inline--fa fa-paperclip sidemenu-icon d-flex align-content-center justify-content-center"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{fontSize: 28}}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="mt-2">
                                            <h4 className="mb-1">Skill Mapping</h4>
                                            <span>
                                                <i className="far fa-check-circle text-success mr-1"/>
                                                <p>Map skills using the Faethom API.</p><p> Running this, will iterate over our any new course data, find and save any new skills associated with that new data</p>   <p><strong>This can take several hours, you will receive an email when this completes.</strong></p>
                                              </span>
                                        </div>
                                        <a
                                            href="https://pearsoneducationinc.sharepoint.com/:x:/s/OperationsStrategy/ETZPVW1Og8hApyqGkKnVuP4BUSyAVMfyVe0Q6ozvJ_QJtA?e=o6H0nK"
                                            target="_blank"
                                            className="btn ripple btn-primary mt-3 d-inline-flex align-items-center"
                                        >
                                            Run Utility
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Wrapper>
    )


}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TableContainer);
