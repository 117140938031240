
function FileUpload(props) {
  
      return(
        <>
            <label>{props.label}</label>
            <input 
                {...props.register(props.name)} 
                type="file" 
            />
        </>
      )
  
  }
  
  export default FileUpload