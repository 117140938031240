import React from 'react';
import MyTasksAndPinnedProjectsController from './Controller';
import { fetchMyTasks } from "../../../util/users/getUserData";

/**
 * Main Entry point for My Tasks Widget.
 * Gathers data to be displayed.
 * @param {*} param0 
 * @returns 
 */
const MyTasksInitializer = ({endeavorUsers}) => {
    const getMyTasks = async (userId) => {
        const tasksResponse = await fetchMyTasks(userId);
        console.log("Response:", tasksResponse);
        if (tasksResponse.ok) {
          const tasks = await tasksResponse.json();
          console.log("Tasks:", tasks);
          return tasks;
        }
      };

    return (
        <MyTasksAndPinnedProjectsController
              endeavorUsers={endeavorUsers}
              getData={getMyTasks}
              type="tasks"
            />
    );
};

export default MyTasksInitializer;