import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import Select from "react-select";
import { allTestData } from "../../actions/allUserTests";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import {TextareaAutosize} from '@mui/material/TextareaAutosize';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AUTH_BASE_URL from "../../util/AUTH_BASE_URL";
import {toastMessage} from "../../actions/toast";
// Create a theme instance and increase the z-index for the Popover
const theme = createTheme({
	zIndex: {
		modal: 1300, // This is the default z-index for modal in MUI, ensure your modal does not exceed it
		popover: 1000, // You can set this value higher than the modal's z-index
	},
});
const GenerateReport = props => {
	const [nameData, setNameData] = useState({});
	const [selected, setSelected] = useState([]);
	const [reportData, setReportData] = useState([]);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const currentDate = new Date(); // This will give you the current date

	function handleName(event) {
		setNameData({ ...nameData, name: event.target.value });
	}


	// This function will be called when the "Generate" button is clicked
	const handleSave = async () => {
		// Format the selected teammates for the request
		props.setLoading(true)
		props.handleCloseNewModal()
		props.toastMessage('Please give the system a few minutes to generate the report.')

		const selectedTeamMates = selected.map(teamMate => teamMate.value);

		// Prepare the data to be sent
		const reportData = {
			creator: props.session.userId,
			name: nameData.name,
			teammates: selectedTeamMates,
			startDate: startDate ? startDate.toISOString() : null, // Convert to ISO string if date is not null
			endDate: endDate ? endDate.toISOString() : null, // Convert to ISO string if date is not null
		};

		try {
			const response = await fetch(`${AUTH_BASE_URL}reporting/generate`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// Include other headers like authorization if needed
				},
				body: JSON.stringify(reportData),
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const responseData = await response.json();
			props.setLoading(false)
			props.toastMessage(responseData.message)

			// Handle response data here, like notifying the user of success
		} catch (error) {
			// Handle errors here, like notifying the user of failure
		}
	};

	useEffect(() => {
		if (process.env.REACT_APP_NODE_ENV === 'local') {
			setReportData(props.session.ssoDepartmentMembers)
		} else {
			setReportData(props.session.ssoDirectReports)
		}
	}, []);

	console.log('the report data', reportData)
	return (
		<ThemeProvider theme={theme}>
			<div className="modal d-block" style={{ zIndex: theme.zIndex.modal }}>
			<div className="modal-dialog" role="dialog" style={{maxWidth: '450px'}}>
				<div className="modal-content modal-content-demo shadow">
					<div className="modal-header">
						<h6 className="modal-title">Generate New Report</h6>
					</div>
					<form className={"pd-30"}>
						<div className={"mg-b-20"}>
							<label>Name of the report</label>
							<input onChange={handleName} className="form-control"/>
						</div>
						<div className={"mg-b-20"}>
							<label>Choose teammates</label>
							<Select
								options={reportData ? reportData.map(val => {return {label: val, value: val}}) : []}
								className="basic-multi-select"
								classNamePrefix="select"
								isMulti={true}
								onChange={(value)=>{setSelected(value)}}
							/>
						</div>
						<div className={"mg-b-20"}>
							<label className={'d-flex mg-b-20'}>Choose the dates</label>
							<div className={'d-flex justify-content-between'}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									maxDate={currentDate}
									label="Start Date"
									value={startDate}
									onChange={(newValue) => {
										setStartDate(newValue);
									}}
									PopperProps={{
										style: { zIndex: 1400 },
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									maxDate={currentDate}
									label="End Date"
									value={endDate}
									onChange={(newValue) => {
										setEndDate(newValue);
									}}
									PopperProps={{
										style: { zIndex: 1400 },
									}}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
							</div>
						</div>
						{/* Additional form inputs and submit button can be added here */}
					</form>
					<div className="modal-footer">
						<button onClick={handleSave} className="btn ripple btn-primary" type="button" >Generate</button>
						<button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseNewModal}>Cancel</button>
					</div>
				</div>
			</div>
		</div>
			</ThemeProvider>
	);
};

const mapStateToProps = ({ session,	toast }) => ({
	session, 	toast
});

const mapDispatchToProps = dispatch => ({
	allTestData: (data) => dispatch(allTestData(data)),
	toastMessage: (data) => dispatch(toastMessage(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GenerateReport);
