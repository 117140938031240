import React, {useState} from "react";
import {useFilter} from "./hooks/filter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import AWS from "aws-sdk";

// Displays the Asset's Link Data
const FilteredAssets = (props) => {

  const [data] = useFilter(props.linkRecords, props.filter);
  const [showActions, setShowActions] = useState(null)

  const handleShowActions = (index) => {
    setShowActions(index)
  }

  const handleHideActions = () => {
    setShowActions(null)
  }

  const s3 = new AWS.S3({
      endpoint: 'https://nyc3.digitaloceanspaces.com',
      accessKeyId: 'DO00G7FHTCCRD4RWUXE4',
      secretAccessKey: 'caC2B2nkRiaxP79k0cgGTMrv6B2wkOGzP30h35NZowQ'
  });

  const s3url = (key) => {
    // console.log(key)
    // console.log(decodeURI(key))
    return s3.getSignedUrl('getObject', {
        Bucket: 'assets.pearsonct/assets',
        Key: decodeURI(key)
    })
  }

  // const openLink = (link) => {
  //   const image_window = window.open("", "_blank")
  //   image_window.document.write(`
  //         <html>
  //           <head>
  //           </head>
  //           <body>
  //             <img src=${link}>
  //           </body>
  //         </html>
  //   `);
  // }

  return (
    <ol value="1">
      {data.length > 0 ? <>
        {data.map((link, index) => {
            return (
                <li key={index} className="project-link" onMouseEnter={()=>{handleShowActions(index)}} onMouseLeave={handleHideActions}>
                  <div>
                    <span
                        className="badge badge-primary"
                        data-type={link.type.substring(
                            link.type.lastIndexOf("/") + 1
                        )}
                    >
                      {link.type.substring(link.type.lastIndexOf("/") + 1)}
                    </span>
                    <a
                        data-placement="top"
                        data-toggle="tooltip"
                        data-original-title={link.link ? link.link : ""}
                        className="files"
                        target="_blank"
                        href={link.type === 'link' ? link.link : s3url(link.link.split('/assets/')[1])}
                        // onClick={()=>{
                        //     openLink(s3url(link.link.split('/assets/')[1]))
                        //   }
                        // }
                    >
                      {link.name
                          ? link.name
                          : link.link.substring(
                              link.link.lastIndexOf("/") + 1
                          )}
                    </a>
                  </div>
					{
						props.session.systemAccess >= 2 ?     <div className={`actions ${showActions !== index ? 'hidden' : ''}`}>

							<button onClick={()=>{props.handleOpenEditAssetModal(link)}} className="btn ripple btn-primary btn-icon" type="button" >
								<FontAwesomeIcon icon="fa-solid fa-pencil" />
							</button>
							<button onClick={()=>{props.handleOpenDeleteAssetModal(link)}} className="btn ripple btn-primary btn-icon" type="button" >
								<FontAwesomeIcon icon="fa-solid fa-trash" />
							</button>
						</div> : <></>
					}

                </li>
            );
        })
        }
      </> : <>test</>
      }
    </ol>
  );
};


const mapStateToProps = ({ session }) => ({
	session
});

export default connect(mapStateToProps)(FilteredAssets);
