import {FIGMA_PROJECT_DATA} from "../actions/figmaProjectData";

// export a function that takes in state and action
export default (state = null, {type, figmaProjectsData = null }) => {

	// freeze the state
	Object.freeze(state);
	// switch on the type
	switch (type) {
		// if the type is FIGMA_TEAM_ID
		case FIGMA_PROJECT_DATA:
			// return the figmaTeamData
			return figmaProjectsData;
		// otherwise
		default:
			// return the state
			return state;
	}
}
