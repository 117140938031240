import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../global/Wrapper';
import ArchiveTableRow from './ArchiveTableRow';
import { signin } from '../../../actions/session/signIn';
import { allGitLabRepos } from '../../../actions/allGitlabRepos';
import { getGitlabGroupIdData } from '../../../actions/gitlabGroupId';
import { getGitlabGroupData } from '../../../actions/gitlabGroups';
import { getGitlabSubGroupData } from '../../../actions/gitlabSubGroups';
import { getGitlabSubGroupIdData } from '../../../actions/gitlabSubGroupId';
import { getGitlabRenderData } from '../../../actions/gitlabRenderRepos';
import AUTH_BASE_URL from '../../../util/AUTH_BASE_URL';
import appendRepoData from '../../../util/dev-ops/appendRepoData';
import WebUrlModal from "./WebUrlModal";
function ArchiveRepo(props) {
	let navigate = useNavigate();
	const [archiveData, setArchiveData] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState(null);

	function fetchData () {
		fetch(`${AUTH_BASE_URL}dev-ops/gitlab/repos/archive`, {
			method: 'GET',
			headers: new Headers({ 'Content-Type': 'application/json' }),
		})
			.then(response => response.json())
			.then(async data => {
				let newData = await data;
				setArchiveData(newData.repos);
			});
	}
	useEffect(() => {
		fetchData()
	}, [setShowModal]);

	const handleCloseNewModal = () => setShowModal(false);


	return (
		<Wrapper>
			<div className="main-content pt-0 gitlab-repos">
				<div className="container-fluid">
					<div className="inner-body users">
						{showModal && <WebUrlModal fetchData={fetchData} data={modalData} handleCloseNewModal={handleCloseNewModal} />}

						<div className="page-header">
							<div>
								<h2 className="main-content-title tx-24 mg-b-5">Code Archive</h2>
							</div>
						</div>

						<div className="row row-sm">
							<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
								<div className="card custom-card pd-0">
									<div className="card-body pd-0">
										<div className="table-responsive userlist-table">
											<table className="table card-table table-striped table-vcenter mb-0">
												<thead>
												<tr>
													<th className="wd-lg-1p">Creator</th>
													<th className="wd-lg-15p">Repo Name</th>
													<th className="wd-lg-15p">Web URL</th>
													<th className="wd-lg-15p">Code</th>
													<th className="wd-lg-10p">Updated</th>
													<th className="wd-lg-10p">Created</th>
													<th className="wd-lg-10p">Active</th>
													<th className="wd-lg-25p">Action</th>
												</tr>
												</thead>
												<tbody>
												{archiveData ? archiveData.map((data, index) => {
													console.log('72', data)
													if (!['back-end', 'front-end', 'react-template', 'send-success-email-job', 'vue-template', 'html5-template', 'express-template', 'node-template'].includes(data.name)) {
														return (
															<ArchiveTableRow
																key={index}
																data={data}
																setShowModal={setShowModal}
																setModalData={setModalData}
															/>
														);
													}
													return null;
												}) : <tr><td colSpan={8}>Loading Repositories...</td></tr>}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}

const mapStateToProps = ({ session, repos, gitlabGroupId, gitlabSubGroupId, gitlabGroups, gitlabSubGroups, gitlabRenderRepos, filteredData, repoLoading }) => ({
	session, filteredData, gitlabGroupId, gitlabSubGroupId, gitlabGroups, gitlabSubGroups, gitlabRenderRepos, repoLoading
});

const mapDispatchToProps = dispatch => ({
	signin: user => dispatch(signin(user)),
	allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
	getGitlabSubGroupData: groups => dispatch(getGitlabSubGroupData(groups)),
	getGitlabGroupData: groups => dispatch(getGitlabGroupData(groups)),
	getGitlabSubGroupIdData: id => dispatch(getGitlabSubGroupIdData(id)),
	getGitlabGroupIdData: id => dispatch(getGitlabGroupIdData(id)),
	getGitlabRenderData: id => dispatch(getGitlabRenderData(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveRepo);
