import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CodeArchiveLinks = () => {
    return (
        <Link className='nav-link' to={'/archive'}>
            <span className="shape1"/>
            <span className="shape2"/>
            <FontAwesomeIcon className='sidemenu-icon' icon='box-archive'/>
            <span className="sidemenu-label">Code Archive</span>
        </Link>
    );
};

export default CodeArchiveLinks ;
