import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import Wrapper from "../global/Wrapper";
import { allUserData } from "../../actions/allUsers";
import {
  getNativeTeamUsers,
  getSsoTeamUsers,
} from "../../util/team/getTeamUsers";
// import gitlabAvatar from "../../assets/images/gitlab-avatar.png";
import CreateStatus from "../status/Insert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStatus } from "../../util/status/post";
import FilterStatus from "./FilterStatus";
// import DrillButton from "./DrillButton";
import { useAuthFetch } from "../global/hooks/sso/useAuthFetch";
import ChartController from "./orgChart/ChartController";

const selectFields = "displayName,mail,jobTitle,userPrincipalName,department";
const configName = "graphUsers";

const Team = (props) => {
  /**
   * Package Manager Request for all employees
   * with Managers.  Package current employee, if
   * jobTitle is at CEO level
   */
  let managerRequest = {
    msGraphName: configName,
    email:
      props.session.role.toLowerCase().trim() === "ceo"
        ? props.session.email
        : props.session.ssoManager,
    fields: selectFields,
  };

  // Note: 8/11/23: ManagerData not used
  // const [managerData] = useAuthFetch(managerRequest);

  // console.log("User:", props.users);
  const actionsRef = useRef(null);
  let [rootUsers, setRootUsers] = useState([]);
  const [showAddStatus, setShowAddStatus] = useState(false);
  const [statusType, setStatusType] = useState("all");
  const [initialManualLength, setInitialManualLength] = useState(null);

  // Required: sessionManualStatusToggle will refresh page when manual Status added
  const [sessionManualStatusToggle, setSessionManualStatusToggle] =
    useState(false);

  const isFirstRender = useRef(true);

  const userName = props.session.firstName + " " + props.session.lastName;
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {},
  });
  const handleActionsMenu = () => {
    if (actionsRef.current.classList.contains("show")) {
      actionsRef.current.classList.remove("show");
    } else {
      actionsRef.current.classList.add("show");
    }
  };

  const handleOpenDetailModal = (type) => {
    switch (type) {
      case "status":
        setShowAddStatus(true);
        break;
      default:
    }
    handleActionsMenu();
  };

  const handleCloseDetailModal = () => {
    setShowAddStatus(false);
  };

  const handleStatusSave = async (data) => {
    // console.log("Add Status Data:", data);

    /***********Send Status to backend to save *******/
    sendStatus(data.status);
    /********************************************/

    // Close modal
    handleCloseDetailModal();

    // reset selectedValues
    reset();
  };

  // FindUser By User ID
  const findUser = (users, userId) => {
    const target = users?.find((user) => {
      // setRootUsers([user])
      return user.user && user.user._id === userId;
    });
    return target;
  };

  // Determine if userSessionManualStatus Updated
  const manualStatusToggle = (userId, users = []) => {
    // console.log("Id :", userId, "Users:", users);
    const targetUser = findUser(users, userId);
    //  console.log("targetUser:", targetUser, "initialManualLength:", initialManualLength)
    if (
      initialManualLength &&
      targetUser &&
      targetUser.status.manual.length > initialManualLength
    ) {
      // The object with the specific _id was found
      const userId = targetUser.user._id;
      // console.log("Manual status added:", targetUser.status.manual, "userid:", userId); // Output: "62c49680b51938b9dc2e0d56"
      return true;
    } else {
      // The object with the specific _id was not found
      // console.log("Manual Status not changed.");
      return false;
    }
  };
  async function sendStatus(input) {
    createStatus(props.session.userId, input, "manual").then(async (data) => {
      // console.log("Team: Data:", data);
      if (data.status === 201) {
        // Reset Root Users if an Update has been made
        getSsoTeamUsers(props.session.ssoDepartmentMembers, statusType).then(
          (data) => {
            setRootUsers(data);
            // console.log("Team: RootUser2:", data);

            const manualStatusAdded = manualStatusToggle(
              props.session.userId,
              data
            );
            //  console.log("isManualStatusAdded:", manualStatusAdded);
            // Update Status Page
            if (manualStatusAdded) {
              setSessionManualStatusToggle(!sessionManualStatusToggle);
            }
          }
        );
      }
    });
  }
  /********************************* */
  // Get users manager and display his node, un render and manual status update
  useEffect(() => {
    // console.log("ManagerData:", managerData);
    // console.log("Props.Session:", props.session);
    if (props.session.loginType === "sso") {
      // Get all members in your department
      getSsoTeamUsers(props.session.ssoDepartmentMembers, statusType).then(
        (data) => {
          // console.log("First Render Data0:", data);
          // Check if it's the first render
          if (isFirstRender.current) {
            // console.log("First Render Data1:", data, props.session.userId);
            const foundUser = findUser(data, props.session.userId);
            // console.log("First Render Data2:", foundUser, foundUser?.status?.manual.length)
            // set initialMessageLength
            setInitialManualLength(foundUser?.status?.manual.length);
            // After performing actions for the first render, set the ref to false
            isFirstRender.current = false;
          }
          setRootUsers(data);
          // console.log("Team: RootUsersSSO1:", data);
        }
      );
    }
    // }, [managerData, sessionManualStatusToggle]);
  }, []);

  return (
    <Wrapper>
      <div className="main-content pt-0">
        <div className="container-fluid">
          <div className="inner-body team-status">
            {showAddStatus === true ? (
              <CreateStatus
                header="Add Status"
                handleClose={handleCloseDetailModal}
                submitForm={handleStatusSave}
                handleSubmit={handleSubmit}
                register={register}
              />
            ) : null}
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Team Status - {props.session.team}
                </h2>
              </div>
              <div className="d-flex">
                <div className="team-group-controls">
                  {/* <FilterStatus setStatusType={setStatusType} /> */}

                </div>
              </div>
            </div>
            {rootUsers.length > 0 ? (
              <ChartController
                rootUsers={rootUsers}
                ssoManager={props.session.ssoManager}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = ({ session, users }) => ({
  session,
  users,
});

const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Team));
