import AUTH_BASE_URL from "../AUTH_BASE_URL";

export default async function fetchAndSetUserData(data, type) {
    const { token, user } = data;
    if (!type) {
        type = 'read'
    }

    const url = `${AUTH_BASE_URL}dev-ops/gitlab/gitlab-aggregated-data/${type}`;
    const response = await fetch(url, {
        method: 'POST', // Change method to POST
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, user }),
        credentials: 'include'// Send token and user in body
    });

    return response

};
