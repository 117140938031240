import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { signin } from "../../../actions/session/signIn";

import { Link } from "react-router-dom";
import Avatar from "../../global/Avatar";
import { addPinnedItems } from "../../../util/users/addPinnedItems";
import { removePinnedItems } from "../../../util/users/removePinnedItems";
import getUserById from "../../../util/users/getUserById";
import truncateDescription from "../../../util/tasks/truncateDescription";

const color = {
  TEAL: "rgb(78,163,146)",
  BLACK: "rgb(119,119,119)",
};

const regex = /0/;
const nameCharLimit = 30;

const Task = (props) => {
  // console.log("TASK:", props.task);
  const [isItemPinned, setIsItemPinned] = useState(false); // State to track checkbox
  const thumbtackColor = isItemPinned ? color.TEAL : color.BLACK; // Color based on checkbox state
  const [user, setUser] = useState(null);

  /**
   * getUser: Responsible for tracking if item is Pinned
   * @param {*} userId
   * @returns
   */
  const getUser = async (id) => {
    const user = await getUserById(id);
    // console.log("User:", user);

    if (user) {
      setUser(user);
      setIsItemPinned(user.pinnedTasks.includes(props.task._id));
    }
    return user;
  };

  const toggleChecked = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    // Remove pinnedProject, that is checked:
    if (isItemPinned) {
      const data = {
        userId: props.session.userId,
        itemId: props.task._id,
        type: "task",
      };
      const removedPinResponse = await removePinnedItems(data);
      // console.log("RemovePinned: response:", removedPinResponse);
    } else {
      const data = {
        userId: props.session.userId,
        itemId: props.task._id,
        type: "task",
      };
      // AddedPinnedItem
      const addedPinnedResponse = await addPinnedItems(data);
      // console.log("AddPinned: response:", addedPinnedResponse.ok);
      if (addedPinnedResponse.ok) {
        const user = await getUser(props.session.userId);
        // console.log("Add Pinned User:", user);
      }
    }
    // If project is currently pinned, unpin it
    setIsItemPinned(!isItemPinned);
  };

  // Set Initial Project Checked InitialState
  useEffect(() => {
    getUser(props.session.userId);
  }, []);

  useEffect(() => {}, [user]);

  return (
    <>
      <td className="font-weight-semibold d-flex">
          <span
            className="mg-l-4"
            style={{
              color: thumbtackColor,
              cursor: "pointer",
            }}
            onClick={(e) => toggleChecked(e)}
          >
            <FontAwesomeIcon
            className="pinned-icon"
            icon="fa-solid fa-bookmark"
          />
          </span>
          <Link to={`/tasks/${props.task._id}`}>
            {props.task?.name && props.task.version
              ? truncateDescription(props.task.name, nameCharLimit) +
                "_v" +
                props.task.version
              : truncateDescription(props.task?.name, nameCharLimit)
              ? truncateDescription(props.task.name, nameCharLimit)
              : ""}
          </Link>
      </td>
      {/* {console.log("Responsible:", props.task.responsible)} */}
      {props.task.responsible.length > 0 
      // &&
      // props.task.status !== "new" &&
      // props.task.status !== "inactive" 
      ? (<td style={{display: "tableCell"}}>
          <ul className="user-row">
          {props.task.responsible.map((user, index) => {
            if (index <= 3) {
              return (
                <li key={index}>
                  <Avatar user={user} size="sm" />
                </li>
              );
            }
          })}

        </ul>
        </td>
      ) : 
        <td style={{display: "tableCell"}}>
            Not assigned
        </td>
      }
      {/* // : (props.task.status === "new") | (props.task.status === "inactive") ? (
      //   <></>
      // ) : (
      //   <td>
      //     <Avatar size="sm" />{" "}
      //   </td>
      // )} */}
      <td>
        {props.task.endDate
          ? new Date(props.task.endDate).toLocaleDateString()
          : "-"}
      </td>
      <td>
        <span
          className={`repos badge badge-${props.task.status}-pr`}
          data-type="link"
          style={{
            background:
              props.task.status === "active"
                ? "#0CA789"
                : props.task.status === "new"
                ? "#ecb529"
                : props.task.status === "inactive"
                ? "#777"
                : "#fd6074",
            color:
              props.task.status === "active"
                ? "white"
                : props.task.status === "new"
                ? "#333333"
                : props.task.status === "inactive"
                ? "white"
                : "white",
            color: "white",
          }}
        >
          {props.task.status}
        </span>
      </td>
    </>
  );
};
const mapStateToProps = ({ session }) => ({
  session,
});

const mapDispatchToProps = (dispatch) => ({
  signin: (user) => dispatch(signin(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Task);
