import React, {useEffect, useState, Suspense} from "react";
import {signin} from "../../../actions/session/signIn";
import {connect} from "react-redux";
import Wrapper from "../../global/Wrapper";
import GitlabSettings from "../../settings/GitLab";
import GroupControl from "../GroupControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {allGitLabRepos} from "../../../actions/allGitlabRepos";
import {getGitlabSubGroupData} from "../../../actions/gitlabSubGroups";
import {getGitlabGroupData} from "../../../actions/gitlabGroups";
import {getGitlabSubGroupIdData} from "../../../actions/gitlabSubGroupId";
import {getGitlabGroupIdData} from "../../../actions/gitlabGroupId";
import {getGitlabRenderData} from "../../../actions/gitlabRenderRepos";
import Table from "./Table";
import {toastMessage} from "../../../actions/toast";

function TableContainer(props) {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [start, setStart] = useState(false)
	const [noToken, setNoToken] = useState(null)
	const [showGitlab, setShowGitlab] = useState(false)
	useEffect(() => {
		setLoading(true)
	}, []);

	useEffect(() => {
		console.log('the repos', props.repos)
		if (props.repos) {
			setLoading(false)
		}
	}, [props.repos]);

	useEffect(()=> {
		if (!props.session.token && !noToken && props.repos) {
			setNoToken(true)
			props.toastMessage('Your GitLab token has expired and you are viewing cached data.  Please go to settings and re-authenticate.')
		}
	})
	console.log('ughhh', showGitlab)
	return (
		<Wrapper>
			<div className="main-content pt-0 gitlab-repos">
				<div className="container-fluid">
					<div className="inner-body users">
						{/* Page Header */}
						<div className="page-header">
							<div>
								<h2 className="main-content-title tx-24 mg-b-5">Dev Ops - Gitlab</h2>
							</div>
							<div className='page-group-controls'>

								<GroupControl setShowGitlab={setShowGitlab} setLoading={setLoading} groupLabel={'Change group'} subGroupLabel={'Change sub group'} showFilter={true}/>

								<button onClick={()=> {navigate('./add')}} type="button" className="btn btn-primary btn-icon-text">
									<FontAwesomeIcon className='sidemenu-icon mg-r-4' icon={`fa-solid fa-plus`} /> Add Gitlab Repo
								</button>
							</div>

						</div>
						<div className="row row-sm">
							{
								showGitlab ?
									<div className={"col-sm-12 col-md-12 col-lg-12 col-xl-4 grid-margin"}>
										<div className="card custom-card pd-0">
											<div className={'figma-auth'}><GitlabSettings /></div>
										</div>
									</div> :
									<div className={"col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin"}>
										<div className="card custom-card pd-0">
											<Table loading={loading} />
										</div>
									</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	)


}

const mapStateToProps = ({session, repos, gitlabGroupId,  gitlabSubGroupId, gitlabGroups, gitlabSubGroups, gitlabRenderRepos, filteredData, repoLoading}) => ({
	session, repos, filteredData, gitlabGroupId,  gitlabSubGroupId, gitlabGroups, gitlabSubGroups, gitlabRenderRepos, repoLoading
});

const mapDispatchToProps = dispatch => ({
	signin: user =>dispatch(signin(user)),
	allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
	getGitlabSubGroupData: groups => dispatch(getGitlabSubGroupData(groups)),
	getGitlabGroupData: groups => dispatch(getGitlabGroupData(groups)),
	getGitlabSubGroupIdData: id => dispatch(getGitlabSubGroupIdData(id)),
	getGitlabGroupIdData: id => dispatch(getGitlabGroupIdData(id)),
	getGitlabRenderData: id => dispatch(getGitlabRenderData(id)),
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TableContainer);
