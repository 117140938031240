import { signin as signInUtil } from '../../util/session/signIn';
import { signInGhost } from "../../util/session/ghostUser";

export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';

// Action creator for receiving the current user
export const receiveCurrentUser = user => {
	console.log('REDUCER', user);
	return {
		type: RECEIVE_CURRENT_USER,
		user
	}
};

// Sign in function
export const signin = user => async dispatch => {

	// Check if the user object is defined
	if (!user) {
		console.error('No user provided');
		return;
	}

	// If the user provides a password, attempt to sign in with password
	if (user.password) {
		const response = await signInUtil(user);
		if (response.ok) {
			const userData = await response.json();
			return await dispatch(receiveCurrentUser(userData));
		} else {
			return response;
		}
	}

	// If the user is a ghost user, sign in the ghost user
	else if (user.ghost) {
		console.log('GHOST', user);
		const response = await signInGhost(user);
		// Uncomment the following lines if you need to handle the response
		// if (response.ok) {
		//   const userData = await response.json();
		//   console.log('USER DATA', userData);
		//   return await dispatch(receiveCurrentUser(userData));
		// }
	}

	// If the user doesn't have a password or isn't a ghost user, just return the user
	else {
		return await dispatch(receiveCurrentUser(user));
	}
};
