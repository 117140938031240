import React, {useEffect, useRef, useState} from "react";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import Wrapper from "../../global/Wrapper"
import {Link, useNavigate, useParams} from "react-router-dom";
import Table from "./Table"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImportUsersModal from "./ImportUsersModal";

function ManageTestUsers(props) {

     let [userData, setUserData] = useState([])
     const [showImportUsersModal, setShowImportUsersModal] = useState(false)
     const [testId, setTestId] = useState(null)
     const [testName, setTestName] = useState(null)
     const actionsRef = useRef(null);
     const tableRef = useRef(null);
     const navigate = useNavigate();
     const params = useParams();

      useEffect(()=>{
        setTestId(params.id)
      },[])  

      useEffect(()=>{
        if(testId){
            fetch(`${AUTH_BASE_URL}usertesting/test_users/${testId}`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            }).then(response => response.json()).then(data => {
                console.log('test data')
                console.log(data)
                setUserData(data.testUsers)
                setTestName(data.test)
            })
        }
      },[testId]) 

      const handleActionsMenu = () => {
        if (actionsRef.current.classList.contains("show")) {
          actionsRef.current.classList.remove("show");
        } else {
          actionsRef.current.classList.add("show");
        }
      };

      const handleImportUsersModal = () => {
		setShowImportUsersModal(true)
	  }

      const handleCloseModal = () => {
        setShowImportUsersModal(false)
      }

      const handleExportUsersModal = () => {
        let csv = toCsv(tableRef.current);
        console.log(csv)
        download(csv);
      }

      const toCsv = function (table) {
		// Query all rows
		const rows = table.querySelectorAll('tr');
		//https://stackoverflow.com/questions/2125714/explanation-of-slice-call-in-javascript
		return [].slice
			.call(rows)
			.map(function (row, index) {
				// Query all cells
				const cells = row.querySelectorAll('th,td');
				let cellsString = [].slice
					.call(cells)
					.map(function (cell) {
						return cell.textContent;
					})
					.join(',');
				return cellsString
			})
			.join('\n');
	};

      const download = function (text) {
		const link = document.createElement('a');
		link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
		link.setAttribute('download', testId + '_test_users.csv');
	
		link.style.display = 'none';
		document.body.appendChild(link);
	
		link.click();
	
		document.body.removeChild(link);
	};

      return(
        <Wrapper>
            <div className="main-content pt-0">
				<div className="container-fluid">
					<div className="inner-body ">
                        {showImportUsersModal === true ? <ImportUsersModal handleCloseNewModal={handleCloseModal} testId={testId}/> : null}
                        <div className="main-content pt-0">
                            <div className="container-fluid">
                                <div className="inner-body project-detail">
                                    {/* Page Header */}
                                    <div className="page-header">

                                            <div>
                                                <Link to="/usertesting/online">Back to User Testing</Link>
                                                <div>
                                                    <h2 className="main-content-title tx-24 mg-b-5 mg-t-10">Manage Users for {testName}</h2>
                                                </div>
                                            </div>

                                            <button
                                                type="button"
                                                className="btn btn-primary my-2 btn-icon-text"
                                                onClick={handleActionsMenu}
                                                >
                                                Manage
                                                <FontAwesomeIcon
                                                    className="mg-l-4"
                                                    icon="fa-solid fa-chevron-down"
                                                />
                                            </button>

                                            <div
                                                ref={actionsRef}
                                                className="dropdown-menu animated-menu actions-menu"
                                            >
                                                <ul>
                                                    <li>
                                                        <button
                                                          onClick={handleImportUsersModal}
                                                        >
                                                        Import Users
                                                        </button>
                                                    </li>
                                                    <li>
                                                    <button
                                                        onClick={handleExportUsersModal}
                                                    >
                                                        Export Users
                                                    </button>
                                                </li>
                                                </ul>
                                            </div>

                                            {/* <p>
                                                This usertest was created by <strong>{resultData ? resultData.user[0].firstName + ' ' + resultData.user[0].lastName : ""}</strong>
                                            </p> */}
                        
                                    </div>
                                    <Table userData={userData} tableRef={tableRef}/>

                                </div>
                            </div>
                        </div>
            </div>
        </div>
      </div>                            
    </Wrapper>
      )
  
  }
  
  export default ManageTestUsers