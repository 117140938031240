import Wrapper from "../../global/Wrapper";
import NewResource from "../NewResource";
import NewItem from "../NewItem";
import DropDown from "../../global/DropDown";
import DropDownEllipsis from "../../global/DropDownEllipsis";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
import {saveWSClient} from "../../../actions/wsclient";
import {getSidebarData} from "../../../actions/sidebar";
import {connect} from "react-redux";
import NewPodcast from "./NewPodcast";
import PodcastCard from "./PodcastCard";

function Podcasts (props) {
    console.log(props.session)
    const [selectedItem, setSelectedItem] = useState();
    const [showModal, setShowModal] = useState(false)
    const [showModalTwo, setShowModalTwo] = useState(false)
    const path = useLocation()
    const [data, setData] = useState([]);
    const [title, setTitle] = useState();
    const [podcast, setPodcast] = useState(false);

    useEffect(()=> {
        let result = /[^/]*$/.exec(window.location.pathname)[0];
        if (result === 'resources') {
            result = 'All'
        }
        setTitle(result.replace(/_/g," "));
        if (window.location.pathname === '/Podcasts') {
            setPodcast(true)
        }

    }, [path])


    const handleOpenResourceModalTwo = (item) => {
        setSelectedItem(item);
        setShowModalTwo(true);
    }

    const handleCloseResourceModal = () => {
        setShowModalTwo(false)
    }

    // Fetch and store all podcasts
    useEffect(() => {
        const fetchPodcasts = async () => {
            try {
                const response = await fetch(`${AUTH_BASE_URL}resources/getAllPodcasts`);
                const podcasts = await response.json();
                setData(podcasts);  // Assuming 'data' is where you want to store your podcasts
            } catch (error) {
                console.error('Failed to fetch podcasts:', error);
            }
        };

        fetchPodcasts();
    }, []);
    console.log('the data', data)
    return (
        <Wrapper>
            <div className="main-content pt-0">
                <div className="container-fluid">
                    <div className="inner-body users">
                        {showModalTwo === true ? <NewPodcast item={selectedItem} setData={setData} handleCloseNewModal={handleCloseResourceModal} /> : null}
                        {/* Page Header */}
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Resources - {title}</h2>
                            </div>
                            <div className="d-flex">
                                <div className="justify-content-center">
                                    <div className="fields"></div>
                                    {
                                        props.session.systemAccess >= 3 ?
                                            <DropDown page={'resources'} data={props.data} label={"Manage"} icon={"fa-chevron-down"}>
                                                <li><button onClick={handleOpenResourceModalTwo}>Add Podcast To Page</button></li>
                                            </DropDown>
                                          : null
                                    }

                                </div>
                            </div>
                        </div>
                        {/* End Page Header */}
                        {/*Row*/}
                        <div className="row row-sm">
                            {
                                data ? data.map((podcast, index) => (
                                    <PodcastCard key={index} podcast={podcast} />
                                )) : <div>No items have been added.</div>
                            }

                        </div>
                        {/* row closed  */}
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}

const mapStateToProps = ({session, wsClient, sidebarData}) => ({
    session,wsClient, sidebarData
});

const mapDispatchToProps = dispatch => ({
    saveWSClient: (data) => dispatch(saveWSClient(data)),
    getData: (data) => dispatch(getSidebarData(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Podcasts);