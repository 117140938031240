import React, {useRef} from 'react';
import {connect} from "react-redux";
import {useParams} from "react-router-dom"
import {updateAsset} from "../../util/projects/updateAsset"

const EditAsset = props => {

    const nameRef = useRef();
    const figmaRef = useRef();
    //for links
    const urlRef = useRef();

    const handleSave = () => {
        let updatedAsset = {...props.asset}
        updatedAsset.name = nameRef.current.value
        updatedAsset.figma = figmaRef.current.checked
        if(updatedAsset.type === "link"){
            updatedAsset.link = urlRef.current.value
        }

        updateAsset(updatedAsset).then(()=>{
            props.handleClose()
            props.getDetails()
        })
    }

    return(
        <div className="modal d-block">
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow">
                    <div className="modal-header">
                        <h6 className="modal-title">Edit Asset</h6>
                        <p>Any changes made here will apply to all projects or tasks with this asset.</p>
                    </div>

                    <div className='detail-modal-body'>
                        <form>
                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    ref={nameRef}
                                    className="form-control card-input"
                                    placeholder="Name"
                                    defaultValue={props.asset.name}
                                    type="text"
                                    required
                                />
                            </div>
                            {
                                props.asset.type === 'link' ?
                                    <div className="form-group">
                                        <label>URL</label>
                                        <input
                                            ref={urlRef}
                                            className="form-control card-input"
                                            placeholder="https://example.com"
                                            defaultValue={props.asset.link}
                                            type="text"
                                            required
                                        />
                                    </div> :
                                    <p>{props.asset.link.split("/").pop().replace(/%20/g, " ")}</p>
                            }
                            <label className="custom-switch mg-t-8">
                                <input ref={figmaRef} type="checkbox" name="custom-switch-checkbox" className="custom-switch-input" defaultChecked={props.asset.figma}/>
                                <span className="custom-switch-indicator"></span>
                                <span className="custom-switch-description">Is this a design file?</span>
                            </label>
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button className="btn ripple btn-primary" type="button" onClick={handleSave}>Save</button>
                        <button className="btn ripple btn-secondary muted" type="button" onClick={props.handleClose}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({session}) => ({
    session
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAsset);
