import {ALL_PROJECT_DATA} from "../actions/allProjects";

export default (state = null, {type, projects = null }) => {
    Object.freeze(state);
    switch (type) {
        case ALL_PROJECT_DATA:
            return projects;
        default:
            return state;
    }
};
