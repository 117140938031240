import Wrapper from "../global/Wrapper";
import CreateRepo from "./CreateBlankRepo";
import React from "react";

function AddGitLabRepo () {

    return (
        <Wrapper>
            <div className="main-content pt-0">
                <div className="container-fluid">
                    <div className="inner-body users">
                        {/* Page Header */}
                        <div className="page-header">
                            <div>
                                <h2 className="main-content-title tx-24 mg-b-5">Add a New Gitlab Repository</h2>
                            </div>
                            <div className="d-flex">
                                <div className="justify-content-center">

                                </div>
                            </div>
                        </div>
                        {/* End Page Header */}
                        {/*Row*/}
                        <div className="row row-sm">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 grid-margin">
                                <CreateRepo />
                            </div>{/* COL END */}
                        </div>
                        {/* row closed  */}
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}
export default AddGitLabRepo
