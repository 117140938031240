import React, {useEffect, useState, useRef} from "react";
import { connect } from "react-redux";
import { useDataFilter } from "../global/hooks/useDataFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Wrapper from "../global/Wrapper";
import ProjectsTable from "./ProjectsTable/ProjectsTable";
import Pagination from "../global/Pagination/Pagination";
import Search from "../global/inputs/Search";
import NewProject from "./NewProject";
import Filter from "./filter/Filter";
import { projectFilters } from "../../actions/projectFilters";
import { projectSort } from "../../actions/projectSort";
import { useGetAndSetProjects } from "../../util/projects/useGetAndSetProjects";


const Projects = (props) => {
  // Set Pagination Results
  const recommendedPageLimit = 10;
  const dataLimit = 20;

  const {getAndSetProjects} = useGetAndSetProjects()
  
  const [showModal, setShowModal] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([])
  const [searchTerm, setSearchTerm] = useState(null);
  const [filteredProjectsBySearch, setFilteredProjectsBySearch] = useDataFilter(
    filteredProjects,
    searchTerm,
    "name"
  );

  //check to see if the projects are already loaded in redux
  //if they aren't, fetch them by access level
  useEffect(async() => {
    if(!props.projects){
      getAndSetProjects(props.session)
    }
  }, []);


  const handleOpenNewModal = () => {
    setShowModal(true);
  };

  const handleCloseNewModal = () => {
    setShowModal(false);
  };

  const handleSort = (projects) => {
    if(projects && props.project_sort){
      let returnArray = []
      let sortedArray = [...projects]
      let property = props.project_sort.property
      let direction = props.project_sort.direction
      if(property === 'updated' || property === 'created'){
        sortedArray = sortedArray.sort((a, b) => {
          const propertyA = new Date(a[property])
          const propertyB = new Date(b[property])
          if (propertyA > propertyB) {
            return -1;
          }
          if (propertyA < propertyB) {
            return 1;
          } 
          // properties must be equal
          return 0;
        });
      }else if(property === 'creator'){
        sortedArray = sortedArray.sort((a, b) => {
          const propertyA = `${a[property].firstName + ' ' + a[property].lastName}`.toLowerCase();
          const propertyB = `${b[property].firstName + ' ' + b[property].lastName}`.toLowerCase();
          if (propertyA < propertyB) {
            return -1;
          }
          if (propertyA > propertyB) {
            return 1;
          } 
          // properties must be equal
          return 0;
        });
      }
      else{
          sortedArray = sortedArray.sort((a, b) => {
            const propertyA = a[property].toLowerCase();
            const propertyB = b[property].toLowerCase();
            if (propertyA < propertyB) {
              return -1;
            }
            if (propertyA > propertyB) {
              return 1;
            } 
            // properties must be equal
            return 0;
          });
      }
      //change ascending/decending order
      if(direction === 'descending'){
        returnArray = sortedArray.toReversed()
      }else{
        returnArray = sortedArray
      }
      return returnArray
    }
  }

  return (
    <Wrapper>
      <div className="main-content pt-0">
        <div className="container-fluid">
          <div className="inner-body projects">
            {showModal === true ? (
              <NewProject
                handleCloseNewModal={handleCloseNewModal}
              />
            ) : null}
            {/* Page Header */}
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Projects</h2>
              </div>
              <div className="d-flex">
                <div className="justify-content-center table-controls d-flex align-items-center">
                  <Search
                    placeholderText="Search Projects"
                    setParentTerm={setSearchTerm}
                  />
                   <Filter
                    setFilteredProjects={setFilteredProjects}
                  />
                  {props.session.systemAccess >= 2 ? (
                    <div className="mg-l-15">
                      <button
                        type="button"
                        className="btn btn-primary my-2 btn-icon-text"
                        onClick={handleOpenNewModal}
                      >
                        <FontAwesomeIcon
                          className="mg-r-4"
                          icon={`fa-solid fa-plus`}
                        />{" "}
                        New Project
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* End Page Header */}
            {/*Row*/}
            <div className="row row-sm">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
                    <div className="card custom-card pd-0">
                      <div className="card-body pd-0">
                        {/*Pagination Table and Controls */}
                        <Pagination
                          renderComponentOptions={{
                            setSortedBy: props.setProjectSort,
                            sortedBy: props.project_sort
                          }}
                          type="projects"
                          data={searchTerm ? handleSort(filteredProjectsBySearch) : handleSort(filteredProjects)}
                          dataLimit={dataLimit}
                          recommendedPageLimit={recommendedPageLimit}
                          RenderComponent={ProjectsTable}
                        />
                      </div>
                    </div>
                  {/* </> */}
                 {/* )} */}
              </div>
              {/* COL END */}
            </div>
            {/* row closed  */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = ({ session, projects, users, project_filters, project_sort }) => ({
  session,
  projects,
  users, 
  project_filters,
  project_sort
});

const mapDispatchToProps = (dispatch) => ({
  // allProjectData: (data) => dispatch(allProjectData(data)),
  // allUserData: (data) => dispatch(allUserData(data)),
  setProjectFilters: (data) => dispatch(projectFilters(data)),
  setProjectSort: (data) => dispatch(projectSort(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Projects));
