import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Wrapper from "../../global/Wrapper";
import Table from "./Table";
import { toastMessage } from "../../../actions/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";
function TableContainer(props) {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [repos, setRepos] = useState([]);
	const [deleteItem, setDeleteItem] = useState(null);
	const fetchRepos = async () => {
		try {
			setLoading(true);
			const response = await fetch(`${AUTH_BASE_URL}dev-ops/webcomponents`, {
				headers: {
					"Content-Type": "application/json"
				}
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();
			setRepos(data);
			setLoading(false);

		} catch (error) {
			console.error("Error fetching repositories:", error);
			setLoading(false);
		}
	};

	const handleDelete = async (id) => {
		setLoading(true)
		try {
			const response = await fetch(`${AUTH_BASE_URL}dev-ops/webcomponents/${id._id}`, {
				method: 'DELETE',
				headers: {
					"Content-Type": "application/json"
				}
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();
			props.toastMessage(data.message);
			fetchRepos()
			setRepos(repos.filter(repo => repo._id !== id));
		} catch (error) {
			console.error("Error deleting web component:", error);
			props.toastMessage('Error deleting web component');
		}
	};

	useEffect(() => {

		fetchRepos();
	}, []);

	return (
		<Wrapper>
			<div className="main-content pt-0 gitlab-repos">
				<div className="container-fluid">
					<div className="inner-body users">
						<div className="page-header">
							<div>
								<h2 className="main-content-title tx-24 mg-b-5">Web Components</h2>
							</div>
							<div className='page-group-controls'>
								<button onClick={() => { navigate('/dev-ops/web-components/add') }} type="button" className="btn btn-primary btn-icon-text">
									<FontAwesomeIcon className='sidemenu-icon mg-r-4' icon={`fa-solid fa-plus`} /> Add Web Component
								</button>
							</div>
						</div>
						<div className="row row-sm">
							<div className={"col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin"}>
								<div className="card custom-card pd-0">
									<Table loading={loading} repos={repos} onDelete={handleDelete} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}

const mapStateToProps = ({ session }) => ({
	session
});

const mapDispatchToProps = dispatch => ({
	toastMessage: (data) => dispatch(toastMessage(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TableContainer);
