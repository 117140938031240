import {GHOST_USER} from "../actions/ghostUser";

export default (state = null, {type, ghostUser = null }) => {
	Object.freeze(state);
	switch (type) {
		case GHOST_USER:
			return ghostUser;
		default:
			return state;
	}
};
