import AUTH_BASE_URL from "../AUTH_BASE_URL"

export const getAllAssets = async () => {
    const response = await fetch(`${AUTH_BASE_URL}assets`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    const data = await response.json();
    return data;
}