import AUTH_BASE_URL from "../AUTH_BASE_URL";

export default async function allUsers () {
	return await fetch(`${AUTH_BASE_URL}usertesting`, {
		method: 'GET',
		headers: {
			"Content-Type": "application/json",
		},
	}).then((response => {
		return response.json()
	})).then((data => {
		return data
	}))
}

