import React, {useEffect, useState, Suspense} from "react";
import {signin} from "../../../actions/session/signIn";
import {connect} from "react-redux";
import Wrapper from "../../global/Wrapper";
import GitlabSettings from "../../settings/GitLab";
import GroupControl from "../GroupControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {allGitLabRepos} from "../../../actions/allGitlabRepos";
import {getGitlabSubGroupData} from "../../../actions/gitlabSubGroups";
import {getGitlabGroupData} from "../../../actions/gitlabGroups";
import {getGitlabSubGroupIdData} from "../../../actions/gitlabSubGroupId";
import {getGitlabGroupIdData} from "../../../actions/gitlabGroupId";
import {getGitlabRenderData} from "../../../actions/gitlabRenderRepos";
import TableRow from "./TableRow";

function Table(props) {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(false)

    useEffect(() => {
        setLoading(true)
    }, []);

    useEffect(() => {
        if (props.repos) {
            setLoading(false)
        }
    }, [props.repos]);

    console.log('is loading', props.loading)
    if (props.loading) {
        return (
            <div className="table-responsive userlist-table">
                <table className="table card-table table-striped table-vcenter mb-0">
                    <thead>
                    <tr>
                        <th className={'wd-lg-1p'}><span>Creator</span></th>
                        <th className={'wd-lg-15p'}><span>Repo Name</span></th>
                        <th className={'wd-lg-15p'}><span>UX Server</span></th>
                        <th className={'wd-lg-15p'}><span>Code</span></th>
                        <th className={'wd-lg-10p'}><span>CI/CD</span></th>
                        <th className={'wd-lg-10p'}><span>Updated</span></th>
                        <th className={'wd-lg-10p'}><span>Created</span></th>
                        <th className={'wd-lg-10p'}><span>Active</span></th>
                        <th className={'wd-lg-25p'}>Action</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan={1}><div className={`skeleton-text user-avatar`} style={{ width: '32px', height: '32px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text user-avatar`} style={{ width: '25px', height: '25px'}}></div></td>
                        <td colSpan={1}><div className={`skeleton-text `} style={{ width: '92px', height: '38px'}}></div></td>
                    </tr>
                    </tbody>
                </table>
                {/*<Pagination />*/}
            </div>
        )
    } else {
        return (
            <div className="table-responsive userlist-table">
                <table className="table card-table table-striped table-vcenter mb-0">
                    <thead>
                    <tr>
                        <th className={'wd-lg-1p'}><span>Creator</span></th>
                        <th className={'wd-lg-15p'}><span>Repo Name</span></th>
                        <th className={'wd-lg-15p'}><span>UX Server</span></th>
                        <th className={'wd-lg-15p'}><span>Code</span></th>
                        <th className={'wd-lg-10p'}><span>CI/CD</span></th>
                        <th className={'wd-lg-10p'}><span>Updated</span></th>
                        <th className={'wd-lg-10p'}><span>Created</span></th>
                        <th className={'wd-lg-10p'}><span>Active</span></th>
                        <th className={'wd-lg-25p'}>Action</th>

                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.repoLoading ?
                            <tr>
                                <td colSpan={1}><div className={`skeleton-text user-avatar`} style={{ width: '32px', height: '32px'}}></div></td>
                                <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                                <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                                <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                                <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                                <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                                <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                                <td colSpan={1}><div className={`skeleton-text user-avatar`} style={{ width: '25px', height: '25px'}}></div></td>
                                <td colSpan={1}><div className={`skeleton-text `} style={{ width: '92px', height: '38px'}}></div></td>
                            </tr>
                            : <></>
                    }
                    {
                        props.filteredData && props.filteredData.length === 0 ?              <>
                            <tr><td colSpan={9}><div>There are no Repos that belong to you in this group.</div></td></tr>
                            <tr><td colSpan={9}><div>Try using the filter and select 'All' to see all the repos in this group.</div></td></tr>
                        </> : <></>

                    }
                    {
                        props.filteredData ?  props.filteredData.map((data,index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        data={data}
                                    />
                                )
                        }):
                        <tr>
                            <td colSpan={1}><div className={`skeleton-text user-avatar`} style={{ width: '32px', height: '32px'}}></div></td>
                            <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                            <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                            <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                            <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                            <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                            <td colSpan={1}><div className={`skeleton-text `} style={{ width: '100%', height: '10px'}}></div></td>
                            <td colSpan={1}><div className={`skeleton-text user-avatar`} style={{ width: '25px', height: '25px'}}></div></td>
                            <td colSpan={1}><div className={`skeleton-text `} style={{ width: '92px', height: '38px'}}></div></td>
                        </tr>
                    }

                    </tbody>
                </table>
                {/*<Pagination />*/}
            </div>
        );

    }

}

const mapStateToProps = ({session, repos, gitlabGroupId,  gitlabSubGroupId, gitlabGroups, gitlabSubGroups, gitlabRenderRepos, filteredData, repoLoading}) => ({
    session, repos, filteredData, gitlabGroupId,  gitlabSubGroupId, gitlabGroups, gitlabSubGroups, gitlabRenderRepos, repoLoading
});

const mapDispatchToProps = dispatch => ({
    signin: user =>dispatch(signin(user)),
    allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
    getGitlabSubGroupData: groups => dispatch(getGitlabSubGroupData(groups)),
    getGitlabGroupData: groups => dispatch(getGitlabGroupData(groups)),
    getGitlabSubGroupIdData: id => dispatch(getGitlabSubGroupIdData(id)),
    getGitlabGroupIdData: id => dispatch(getGitlabGroupIdData(id)),
    getGitlabRenderData: id => dispatch(getGitlabRenderData(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Table);
