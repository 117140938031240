import React, { useEffect, useState, useRef } from "react";
import Avatar from "../Avatar";
import DisplayStatus from "../../status/DisplayStatus";
import "./customEmp.css";
import DisplayNewActivity from "./DisplayNewActivity";

const CustomEmployeeDetails = ({
  employee,
  handleClose,
  handleModalClose,
  showModal,
  modalRef,
  setModalOpen,
  chartDataRefreshToggle
}) => {
  console.log(
    "EmpDetails: Employee:",
    employee,
    "ShowModal:",
    showModal,
    "ModalRef:",
    modalRef
  );
  // const { user, status } = employee;
  const [count, setCount] = useState(0);

  // keep modal open if in the mouse entered window
  const handleMouseEnter = (event) => {
    if (showModal) {
      // console.log("Mouse Enter", event.target);
      setModalOpen(true);
    }
  };
  /**
   * Prohibit click listener from triggering on load
   * Increment count once rendered
   */
  useEffect(() => {
    setCount(count + 1);
    // console.log("chartDataRefreshToggle:", chartDataRefreshToggle, )
  }, []);

  /**
   * Start Click Listener after the component
   * has been rendered and Count is equal to one,
   * which indicates the modal is open
   */
  useEffect(() => {
    const modal = modalRef.current;
    /**
     * Close modal when mouse clicked outside window
     */
    const handleClickOutside = (event) => {
      setCount(count + 1);
      // console.log("Count:", count);
      // console.log("target:", event.target);
      // console.log(
      //   "MODALREF:",
      //   "eventTarget:",
      //   event.target,
      //   "modalRef.current: ",
      //   modal,
      //   "modalRef.current.contains",
      //   modal?.contains
      // );
      if (count >= 1 && modal && !modal.contains(event.target) && showModal) {
        // console.log("Clicked outside of component");
        handleModalClose();
        setModalOpen(false);
      }
    };

    if (showModal) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [count]);

  console.log('the employee', employee)

  return (
    <>
      {employee?.user?._id ? (
        <>
          {employee?.user?.trackActivity ? (
            <div
              className="main-content pt-0"
              ref={modalRef}
              onMouseEnter={handleMouseEnter}
            >
              <div className="container-fluid">
                <div className={"modal d-block"}>
                  <div className="employee-detail-dialog shadow" role="dialog" style={{maxHeight:'75%'}}>
                    <div className="modal-content modal-content-demo">
                      <div className="row align-items-center mg-b-20 pd-x-20 ">
                        <Avatar user={employee?.user} size="md" />
                        <div className="col mg-t-15">
                          <h3 className="node-header--color">
                            {employee?.user?.firstName +
                              " " +
                              employee?.user?.lastName}
                          </h3>
                          <span>{employee?.user?.role}</span>
                        </div>
                        <button
                          className="modal-close-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClose(e);
                          }}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                          </svg>
                        </button>
                      </div>

                        <DisplayNewActivity gitlabId={employee.user.gitLab[0].id}/>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>{/* <p>NO DATA</p> */}</>
      )}
    </>
  );
};

export default React.memo(CustomEmployeeDetails);
