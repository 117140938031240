import { getProjectsByUserId } from "../projects/getProjectsByUserId";
import {getPublicProjects} from "../projects/getAllPublicProjects";
import getProducts from "../projects/getProducts"

    export const fetchProducts = async (setProductOptions) => {
    const products = await getProducts();
    if(products.ok) {
      const response = await products.json();
      // console.log("Response1:", response);
      setProductOptions(response);
    }
  };

  export const fetchProjects = async (id, access, setProjectsData) => {
    const response = await getProjectsByUserId(id, access);
    const projects = await response.json();
    // console.log("ProjectUtil: UpdatedProjects:", projects);
    // Set ProjectData State variable
    setProjectsData([...projects]);
  }

  export const fetchPublicProjects = async(id, access, setProjectsData) => {
    const response = await getPublicProjects(id, access);
    const projects = await response.json();
    setProjectsData(projects);
  }
