import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import AUTH_BASE_URL from "../../../util/AUTH_BASE_URL";

const NewPodcast = (props) => {
    const [podcasts, setPodcasts] = useState([]);
    const [selectedPodcasts, setSelectedPodcasts] = useState([]);



    const fetchPodcasts = async (inputValue) => {
        try {
            const response = await fetch(`https://itunes.apple.com/search?term=${inputValue}&media=podcast&limit=10`);
            const data = await response.json();

            if (data && data.results) {
                const transformedPodcasts = data.results.map(podcast => ({
                    ...podcast,
                    value: podcast.trackId,
                    label: podcast.trackName,
                    artworkUrl100: podcast.artworkUrl100,
                    artistName: podcast.artistName,
                    releaseDate: podcast.releaseDate,
                }));
                setPodcasts(transformedPodcasts);
            } else {
                console.error("Unexpected data structure", data);
            }
        } catch (error) {
            console.error('Error fetching podcasts:', error);
        }
    };



    const handlePodcastSelect = (selectedOptions) => {
        if (selectedOptions) {
            // Convert selectedOptions to full podcast details
            const newPodcastDetails = selectedOptions.map(option => {
                return podcasts.find(podcast => podcast.value === option.value);
            }).filter(Boolean); // Remove any undefined values

            // Merge old and new podcast selections
            const allPodcastDetails = [...new Set([...selectedPodcasts, ...newPodcastDetails])];

            setSelectedPodcasts(allPodcastDetails);

            // Log details for debugging
            console.log("Selected Podcasts:");
            allPodcastDetails.forEach(podcast => {
                console.log(`Name: ${podcast.label}`);
                console.log(`Artist: ${podcast.artistName}`);
                console.log(`Artwork URL: ${podcast.artworkUrl100}`);
                console.log(`Release Date: ${podcast.releaseDate}`);
            });
        } else {
            // Clear selected podcasts if none are selected
            setSelectedPodcasts([]);
        }
    };





    const handleInputChange = (inputValue) => {
        fetchPodcasts(inputValue);
    };

    const handleSave = async () => {
        if (selectedPodcasts.length > 0) {
            try {
                const response = await fetch(`${AUTH_BASE_URL}resources/addPodcasts`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ podcasts: selectedPodcasts }),
                });

                const data = await response.json();

                if (response.status === 201) {
                    console.log(data.message);
                    // Here is where you update the parent's state
                    props.setData(prevData => [...prevData, ...selectedPodcasts]);
                    // Close the modal
                    props.handleCloseNewModal();
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };


    return (
        <div className="modal d-block" style={{ zIndex: 1, position: 'relative' }}>
            <div className="modal-dialog" role="dialog">
                <div className="modal-content modal-content-demo shadow pd-20">
                    <div className="modal-header border-bottom-0 pd-l-0">
                        <h6 className="modal-title">Add a podcast</h6>
                    </div>
                    <form>
                        <div className="form-group" style={{ zIndex: 1000, position: 'relative' }}>
                            <label>Search iTunes for Podcasts</label>
                            <Select
                                options={podcasts}
                                onChange={handlePodcastSelect}
                                onInputChange={handleInputChange}
                                isSearchable
                                isMulti
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                }}
                            />
                        </div>
                    </form>
                    <div className="modal-footer pd-l-0 pd-r-0" style={{ zIndex: 0, position: 'relative' }}>
                        <div style={{
                            display: 'flex',
                            alignContent: 'center',
                            width: '100%',
                            justifyContent: 'space-between'
                        }}>
                            {/* Avatars */}
                            <div className="avatar-grid" style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', alignItems: 'center' }}>
                                {selectedPodcasts.map((podcast, index) => (
                                    <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '60px' }}>
                                        <img
                                            src={podcast.artworkUrl100}
                                            alt={podcast.label}
                                            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                                        />
                                        <span style={{ fontSize: '12px', marginTop: '8px', textAlign: 'center', wordWrap: 'break-word' }}>{podcast.label}</span>
                                    </div>
                                ))}
                            </div>
                            {/* Buttons */}
                            <div className={'d-flex align-items-center justify-content-center'}>
                                <button className="btn ripple btn-primary mg-r-8" type="button" onClick={handleSave}>Save</button>
                                <button className="btn ripple btn-secondary muted" type="button" onClick={props.handleCloseNewModal}>Cancel</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default NewPodcast;
