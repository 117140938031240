import AUTH_BASE_URL from "../AUTH_BASE_URL";

export const removePinnedItems = async (data) => {
    // console.log("removedPinnedProject: data:", data)
    return fetch(`${AUTH_BASE_URL}auth/user/pinned/remove/` + data.userId, {
        method: "Delete",
        body: JSON.stringify({data: data}),
        headers: {
            "Content-Type": "application/json"
        }
    })
}