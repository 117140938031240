import React, { useEffect, useState, useCallback, useMemo } from "react";
import validateToken from "../../util/dev-ops/validateToken";
import getAllRepos from "../../util/dev-ops/getAllRepos";
import getGitlabGroups from "../../util/dev-ops/getGitlabGroups";
import getGitlabSubGroups from "../../util/dev-ops/getGitlabSubGroups";
import fetchAndSetUserData from "../../util/dev-ops/fetchAndSetUserData";
import { signin } from "../../actions/session/signIn";
import { allGitLabRepos } from "../../actions/allGitlabRepos";
import { getGitlabSubGroupData } from "../../actions/gitlabSubGroups";
import { getGitlabGroupData } from "../../actions/gitlabGroups";
import { getGitlabSubGroupIdData } from "../../actions/gitlabSubGroupId";
import { getGitlabGroupIdData } from "../../actions/gitlabGroupId";
import {allCreators} from '../../actions/creators'
import { setFilter } from "../../actions/filterBy";
import { filteredGitLabRepos } from "../../actions/filteredData";
import {setRepoLoading} from "../../actions/repoLoading";
import { connect } from "react-redux";
import DropDown from "../global/DropDown";
import AUTH_BASE_URL	 from "../../util/AUTH_BASE_URL";
import {toastMessage} from "../../actions/toast";

const CustomSelect = ({ label, name, handleOnChange, value, options }) => (
	<div >
		<label htmlFor={name}>{label}</label>
		<select
			onChange={handleOnChange}
			style={{ display: "inline-flex", height: "40px" }}
			name={name}
			className="form-control select mg-r-8"
			required=""
			value={value}
		>
			{options}
		</select>
	</div>
);

function GroupControl(props) {
	console.log('da props', props)
	const {
		session,
		gitlabGroups,
		gitlabSubGroups,
		groupLabel,
		subGroupLabel,
		showFilter,
		creators,
		filterBy,
		signin,
		allGitLabRepos,
		getGitlabGroupData,
		getGitlabSubGroupData,
		getGitlabGroupIdData,
		getGitlabSubGroupIdData,
		setFilter,
		setRepoLoading,
		setLoading,
		filteredGitLabRepos,
		setShowGitlab
	} = props;

	const [group, setGroup] = useState(0);
	const [subGroup, setSubGroup] = useState(null);
	const [data, setData] = useState([]);


	const fetchData = useCallback(async (sub, group) => {
		try {
				console.log('fetch data, 68')
			const data = await validateToken(session);
			let response = await fetchAndSetUserData(data);
			let responseData = await response.json()
			console.log('fetch data, 72', responseData)

			if (responseData.message === '401 Unauthorized') {
				props.toastMessage('Your GitLab token has expired please login to Gitlab and try again.')
				setShowGitlab(true)
			}
			let userData = responseData;

			const userRepos = userData.repos.filter((repo) => repo.creator_id === parseInt(session.gitLab[0].id));
			setGroup(userData.groupId)
			setSubGroup(userData.subGroupId)
			getGitlabGroupData(userData.groups);
			getGitlabSubGroupData(userData.subGroups);

			console.log('fetch data 86', userData)
			allGitLabRepos(userData.repos);
			filteredGitLabRepos(userRepos);
			setRepoLoading(false)
		}
		catch (error) {
			console.log(error)
		}
	}, []);



	const groupOptions = useMemo(() => {

		const groupsOption = gitlabGroups && group !== 0
			? gitlabGroups.map((group, index) => (
				<option key={index} data-index={index} value={group.id}>
					{group.name}
				</option>
			))
			: 	[<option>
				Loading Groups...
			</option>];

		return [ ...groupsOption];
	}, [gitlabGroups]);


	useEffect(() => {
		setGroup(0)
		fetchData();
	}, []);


	const handleFilterChange = useCallback((value) => {
		console.log('the flow 99')
		props.setFilter(value.toString())
	}, [props.setFilter]);

	useEffect(()=> {
		if (props.filterBy && props.repos) {
			if (props.filterBy === '0') {
				props.filteredGitLabRepos(props.repos)
			} else {
				props.filteredGitLabRepos(props.repos.filter(repo => repo.creator_id === parseInt(props.filterBy)))
			}
		}
	},[props.filterBy])

	const handleGroupChange = useCallback(async (event) => {
		try {
			const value = event.target.value;
			setGroup(value);
			setSubGroup(0);
			setLoading(true)
			console.log('the subgroup', subGroup)
			// Sending PUT request to update the gitlabGroup
			const response = await fetch(`${AUTH_BASE_URL}dev-ops/gitlab/group`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ gitlabGroup: value, id: session.userId }),
			});

			if (response.ok) {
				const updatedData = await response.json();
				console.log('what', updatedData)
				await signin(updatedData.user);
				fetchData();
				console.log("Group updated successfully: ", updatedData);
			} else {
				console.log("Failed to update group");
			}

			getGitlabGroupIdData(value);

			const data = await validateToken(session);



			const subGroupData = await getGitlabSubGroups(data, value);
			if (subGroupData && Array.isArray(subGroupData)) {
				console.log('handleGroupChange: Successfully fetched subgroups', subGroupData);
				setSubGroup(0);
				getGitlabSubGroupData(subGroupData);
			} else {
				console.log('handleGroupChange: No subgroups found or error in fetching');
			}
		} catch (error) {
			console.log('handleGroupChange: Error in fetching subgroups', error);
		}
	}, [getGitlabGroupIdData, session, signin, getGitlabSubGroupData]);


	const handleSubGroupChange = useCallback(async (event) => {
		try {
			const value = event.target.value;
			setSubGroup(value);
			setLoading(true)
			// Sending PUT request to update the gitlabSubGroup
			const response = await fetch(`${AUTH_BASE_URL}dev-ops/gitlab/subgroup`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ gitlabSubGroup: value, id: session.userId }),
			});

			if (response.ok) {
				const updatedData = await response.json();
				console.log('what', updatedData)
				await signin(updatedData.user);
				fetchData();
				console.log("Group updated successfully: ", updatedData);
			} else {
				console.log("Failed to update group");
			}



			getGitlabSubGroupIdData(value);

		}
		catch(error) {
			console.log(error)
		}

	}, [getGitlabSubGroupIdData, session, signin, group]);



	useEffect(()=> {
		let arr = []
		if (props.repos) {
			if (props.creators && props.creators.length === 0) {
				props.repos.map(repo => {
					if (repo && repo.user && repo.user[0]) {
						console.log('155')
						let data = {}
						let user = repo.user[0]
						data.name = `${user.firstName} ${user.lastName}`
						data.id = repo.creator_id
						arr.push(data)
					}
				})
			}
		}
		props.allCreators([...new Map(arr.map(item => [item['id'], item])).values()])
	},[props.repos])

	console.log('do these repos load', props.allGitLabRepos)
	return (
		<>
			<CustomSelect
				label={groupLabel}
				name="group"
				handleOnChange={handleGroupChange}
				defaultValue={group}
				value={group || group !== 0 ? group : 'Loading...'}
				options={groupOptions}
			/>

			<CustomSelect
				label={subGroupLabel}
				name="subgroup"
				handleOnChange={handleSubGroupChange}
				value={subGroup || 0}
				options={[
					<option key={0} data-index={0} value={0}>
						No Subgroup Selected - Root Folder
					</option>,
					gitlabSubGroups ? gitlabSubGroups.map((group, index) => (
						<option key={index + 1} data-index={index + 1} value={group.id}>
							{group.name}
						</option>
					)) || [] : <></>
				]}
			/>

			{showFilter && (
				<DropDown data={data} icon={"fa-filter"} label={"Filter"}>
					<h6 className="dropdown-header tx-uppercase tx-11 tx-bold tx-inverse tx-spacing-1">
						Creator
					</h6>
					<li>
						<button onClick={() => handleFilterChange(0)} data-type={'all'}>
							All
						</button>
					</li>
					{creators?.map((creator, index) => (
						<li key={index}>
							<button onClick={() => handleFilterChange(creator.id)} data-type={'creator'}>
								{creator.name}
							</button>
						</li>
					))}
				</DropDown>
			)}
		</>
	);

}

const mapStateToProps = (state) => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	signin: user =>dispatch(signin(user)),
	allGitLabRepos: repos => dispatch(allGitLabRepos(repos)),
	getGitlabGroupData: groups => dispatch(getGitlabGroupData(groups)),
	getGitlabGroupId: id => dispatch(getGitlabGroupIdData(id)),
	getGitlabSubGroupData: groups => dispatch(getGitlabSubGroupData(groups)),
	getGitlabSubGroupId: id => dispatch(getGitlabSubGroupIdData(id)),
	setFilter: id => dispatch(setFilter(id)),
	filteredGitLabRepos: repos => dispatch(filteredGitLabRepos(repos)),
	allCreators: arr => dispatch(allCreators(arr)),
	setRepoLoading: bool => dispatch(setRepoLoading(bool)),
	getGitlabGroupIdData: id => dispatch(getGitlabGroupIdData(id)), // changed the name here
	getGitlabSubGroupIdData: (id) => dispatch(getGitlabSubGroupIdData(id)),
	toastMessage: (data) => dispatch(toastMessage(data))
});
const areEqual = (prevProps, nextProps) => {
	// Add your comparison logic here
	return prevProps.setLoading === nextProps.setLoading;
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupControl);
