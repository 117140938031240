import {connect} from "react-redux";
import Avatar from "../../Avatar";
import React from "react";
import DropDown from "../../DropDown";

/*****************************
 * Returns Pagination Table Rows
 *****************************/
  function Row(props) {
    const {deleteUser, tableFields, type} = props;
	// console.log("TableRow: PERSON:", person)
  // console.log("TableRow: Fields:", tableFields)
  // console.log("Type:", type);
  const renderFields = tableFields.map((field, idx) => {
    if (field === "name"){
      return <td style={{ paddingLeft: "0" }} key={`name-${idx}`}>
      {props[type].firstName} {props[type].lastName}
    </td>
    }
    else if (field === "createdAt") {
      return <td key={`createdAt-${idx}`}>{new Date(props[type].createdAt).toLocaleDateString()}</td>
    }
    else if(field === "action") {
      return <td key={`action-${idx}`}>
			<DropDown label={"Manage"} icon={"fa-chevron-down"}>
				<li> <button onClick={() => deleteUser(props[type].email)} className={'text-danger'}>Delete</button></li>
			</DropDown>
		</td>
    }
    else{
    return <td key={`field-${idx}`}>{props[type][field]}</td>
    }
  });

  return (
    <tr>
      <td>
        <Avatar user={props[type]} size='sm'/>
      </td>
      {renderFields}
    </tr>
  );
}

const mapStateToProps = ({ session }) => ({
  session,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Row);
