import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Displays content inside the wrapper
 * if the toggle button is clicked
 * @param {*} param0
 * @returns
 */
const ContentToggle = ({ sectionName, header, children, showContent }) => {
  // console.log("ContentToggle: ShowContent:", showContent);
  const [displayProjectToggle, setDisplayProjectToggle] = useState(false);

  const handleShow = (err, sectionName) => {
    err.preventDefault();
    err.stopPropagation();
    // console.log("SectionName:", sectionName);
    setDisplayProjectToggle(!displayProjectToggle);
  };

  useEffect(()=> {
    // console.log("displayProjectToggle:", displayProjectToggle)
  }, [displayProjectToggle]);

  return (
    <div className={showContent ? "content-toggle-show" : "content-toggle-hide"}>
      <div className="content-toggle-chevron">
        {/*Header*/}
        <h4 className="mg-b-0 pd-8">
          {header} {sectionName}
        </h4>
        <span
        onClick={(e) => handleShow(e, sectionName)}
        >
        <FontAwesomeIcon
          className="mg-l-4"
          icon={
            displayProjectToggle
              ? "fa-solid fa-chevron-down"
              : "fa-solid fa-chevron-up"
          }
          />
        </span>
        
          
      </div>{" "}
      {/*Header*/}
      {/*Content*/}
      <div className={displayProjectToggle ? "content-toggle-show" : "content-toggle-hide"}>{children}</div>
      {/*Content*/}
    </div>
  );
};

export default ContentToggle;
