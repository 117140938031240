import React from 'react';
import MyTasksAndPinnedProjectsController from './Controller';
import { getUserWithPins } from "../../../util/users/getUserData";

const PinnedProjectInitializer = ({endeavorUsers, session}) => {
    const getMyPins = async () => {
        const pinResponse = await getUserWithPins(session.userId);
        if (pinResponse.ok) {
          const pinnedData = await pinResponse.json();
        // console.log("PinnedData:", pinnedData);
          return pinnedData;
        }
      };

    
    return (
        <MyTasksAndPinnedProjectsController
              endeavorUsers={endeavorUsers}
              getData={getMyPins}
              type="pinned"
            />
    );
};

export default PinnedProjectInitializer;