import { OrgChart } from "d3-org-chart";
// import newEmployees from "./dummy_data";
import { getSsoTeamUsers} from "../../../util/team/getTeamUsers";
import { parseEmployeesByManagerId } from "../../../util/helpers/orgChartUtil";
import { v4 as uuidv4 } from 'uuid';
export class LazyOrgChart extends OrgChart {
  clickedNode;
  rootUsers;
  constructor(data) {
    super();
    this.rootUsers = data;
    // console.log("LOC RootData:", data)
  }

  fetchData = async (ssoManagerId, ssoDirectReports, rootUsers) => {
    // console.log("LOC DATA:", this.data);
    const statusType = "all";
    // console.log("Fetched ssoDepartment:", ssoDirectReports);
    const subordinates = await getSsoTeamUsers(ssoDirectReports, statusType);
    // console.log("FetchedData:", subordinates);
    const parsedSubordinates = parseEmployeesByManagerId(subordinates, ssoManagerId);
    // console.log("LOC: Subordinates:", parsedSubordinates)
    return parsedSubordinates;
  };

  // storeFetchedData = (value) => {
  //   console.log("LazyLoad storeFetchedData:", value);
  //   this.fetchedData = value;
  // };

  /**
   * Create a new Node for each new data element
   * Adds _directSubordinates, _totalSubordinates, and
   * _expanded properties to new node.
   * NOTE: The id for the new node must be unique
   * @param {*} nodes
   * @returns
   */
  addNodes(nodes) {
    const chartState = this.getChartState();

    for (const obj of nodes) {
      const nodeId = chartState.nodeId(obj);
      const parentNodeId = chartState.parentNodeId(obj);

      if (
        chartState.allNodes.some(
          ({ data }) => chartState.nodeId(data) === nodeId
        )
      ) {
        // console.log(`ORG CHART - ADD - Node with id "${nodeId}" already exists in tree`);
        return this;
      }

      if (
        !chartState.allNodes.some(
          ({ data }) => chartState.nodeId(data) === parentNodeId
        )
      ) {
        // console.log(`ORG CHART - ADD - Parent node with id "${parentNodeId}" not found in the tree`);
        return this;
      }

      if (obj._centered && !obj._expanded) {
        obj._expanded = true;
      }

      chartState.data.push(obj);
    }

    this.updateNodesState();

    return this;
  }

  /**
   * Configures lazy loading for a chart instance.
   * hasChildren: a function which determines if node.data
   * is supposed to have any children once expanded
   * load: an async function which, given a node.data, loads
   * its children
   * OnButtonClick fetch new Data then add the data to the
   * selected node as children parameter
   *
   * @param {*} event
   * @param {*} clickedNode
   */
  async onButtonClick(event, clickedNode) {
    const nodeId = clickedNode.id;
    const eventId = event.target.__data__.id;
    const ssoManagerId = clickedNode?.data?.id;
    const ssoDirectReports = clickedNode?.data?.user?.ssoDirectReports;
    // console.log("ssoDirectReports:", ssoDirectReports);
    // console.log("LOC: NodeID:", nodeId, "EventID:", eventId);
    // console.log("Button Clicked");
    // console.log("CLICKEDNODE0 B4 Fetch:", clickedNode);
    // Call Fetch
    const fetchedData = await this.fetchData(ssoManagerId, ssoDirectReports);
    const node = clickedNode;
    const data = node.data;
    // console.log("DATA:", node.data);
    let filteredFetchData = fetchedData.filter(
      (item) => !data.children?.some((child) => child.id === item.id)
    );
    /*************************************************
     * For Testing Only: Add a unique Id to the new node element
     * in order to display duplicate nodes in the org chart
     *************************************************/
    // filteredFetchData = filteredFetchData.map(obj => {
    //   return { ...obj, id: uuidv4() };
    // });
   /******************************************/

    // console.log("LOC.filteredFetchData:", filteredFetchData);
    // console.log("LOC._children:", clickedNode._children);
    // console.log("LOC.children:", clickedNode.children);
    if (
      clickedNode.data.numOfSubordinates &&
      ((!clickedNode.children && !clickedNode._children) ||
        clickedNode.children === undefined ||
        clickedNode.children === undefined)
    ) {
      // set clickedNode instance var
      this.clickedNode = clickedNode;

      // console.log("LOC.block In If);");
      this.addNodes(
        filteredFetchData.map((node) => {
          node._expanded = true;
          // console.log("Just Ran add nodes", clickedNode);
          return node;
        })
      );
      super.onButtonClick(event, clickedNode);
    } else {
      super.onButtonClick(event, clickedNode);
    }
  }

  update(params) {
    const [x0, y0, x, y, width, height] = params;
    // console.log("PARAMS:", params);
    // call the super with params
    super.update(x0, y0, x, y, width, height);
    const chartState = this.getChartState();
    const containerElem = chartState.container;

    for (const node of containerElem.querySelectorAll(".node-button-g")) {
      const nodeButtonDiv = node.querySelector(".node-button-div");
      const spanElem = nodeButtonDiv.querySelector("span");
      const spanElemValue = parseInt(spanElem.innerText);
      // console.log("LOC: OnbuttonClick ClickedNode SpanTab:", spanElemValue);
      if (
        nodeButtonDiv &&
        nodeButtonDiv.childElementCount &&
        spanElemValue > 0
      ) {
        node.removeAttribute("display");
        node.removeAttribute("opacity");
      }
    }
  }
}
