import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AUTH_BASE_URL from '../../../util/AUTH_BASE_URL';
import { toastMessage } from '../../../actions/toast';
import allTests from '../../../util/usertesting/allTests';
import { allTestData } from '../../../actions/allUserTests';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const IntroductionVideo = (props) => {
	const [formattedText, setFormattedText] = useState('');

	useEffect(() => {
		if (props.data.introductionData.text) {
			console.log(props.data.introductionData.text)
			setFormattedText(props.data.introductionData.text);
		}
	}, [props.data.introductionData.text]);

	async function handleSave() {
		let data = {};
		data.name = props.data.name;

		if (!formattedText) {
			props.toastMessage('Please add introduction text.');
			return;
		}

		// Prepare the data to send in the request
		const requestData = {
			name: props.data.name,
			content: formattedText, // Add the formatted text
		};

		try {
			const response = await fetch(`${AUTH_BASE_URL}usertesting/intro/text`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(requestData),
			});

			if (!response.ok) {
				props.toastMessage("There's a problem with the server, please try again later");
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			props.toastMessage('Introduction text saved successfully');
			await allTests().then((data) => {
				props.allTestData(data);
			});
			props.handleCloseNewModal();
		} catch (error) {
			props.toastMessage(`Error saving introduction text: ${error.message}`);
		}
	}
	console.log('formattedText', formattedText)
	return (
		<div className="modal d-block">
			<div className="modal-dialog" role="dialog">
				<div className="modal-content modal-content-demo shadow">
					<div className="modal-header">
						<h6 className="modal-title">Add Introduction Text</h6>
					</div>
					<form className="pd-l-30 pd-b-30 pd-r-30 mg-b-30">
						<div className="row row-sm card-body">
							<div className="col-sm-12 col-md-12 col-lg-12">
									<p>Introduction Text</p>
									<div className="input-group file-browser d-flex">
										<ReactQuill
											id="content"
											placeholder="Enter your text here"
											theme="snow"
											value={formattedText}
											onChange={setFormattedText}
										/>
									</div>
							</div>
						</div>
					</form>
					<div className="modal-footer">
						<button
							className="btn ripple btn-primary"
							type="button"
							onClick={handleSave}
						>
							Save
						</button>
						<button
							className="btn ripple btn-secondary muted"
							type="button"
							onClick={props.handleCloseNewModal}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
	toastMessage: (data) => dispatch(toastMessage(data)),
	allTestData: (data) => dispatch(allTestData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionVideo);
