import React, { useEffect, useState, useRef } from "react";
import StaticSelect from "../../../global/inputs/StaticSelect";
import Text from "../../../global/inputs/Text";
import Number from "../../../global/inputs/Number";
import Modal from "../../../global/templates/Modal";
import StatusDropDown from "../../StatusDropDown";
import TextArea from "../../../global/inputs/TextArea";
import Date from "../../../global/inputs/DateSelector-A";
import { allPrototypes } from "../../../../actions/allPrototypes";
import allUsers from "../../../../util/users/allusers";
import { allUserData } from "../../../../actions/allUsers";
import { connect } from "react-redux";
import moment from "moment";
// import { retrieveTasks } from "../../../../util/tasks/retrieveTasksByProject";
import {
  formatDataByName,
  formatDataByEmail,
} from "../../../../util/tasks/formatData";
const modalMessage = "";
const teamName = "creativeTechnology";

const Update = (props) => {
  const {
    subtask,
    header,
    handleClose,
    submitForm,
    handleSubmit,
    control,
    register,
    searchSelectName,
    setValue,
    taskOptions,
  } = props;
  // console.log("Update: subtask:", subtask);

  const [responsibleOptions, setResponsibleOptions] = useState([]);

  const [htmlContent, setHtmlContent] = useState(subtask?.description);

  // Initialize Data
  useEffect(() => {
    // console.log("Subtask:", subtask);
    //if users is not available from redux
    // get them from the database and store them
    if (!props.users) {
      allUsers().then(async (data) => {
        props.allUserData(await data);
      });
    }
  }, []);

  useEffect(() => {}, [taskOptions]);

  // Once users are available in redux save them to state
  useEffect(() => {
    if (props.users && props.users.length > 0) {
      setResponsibleOptions(formatDataByEmail(props.users, "email", "_id"));
    }
  }, [props.users]);

  useEffect(() => {
    // console.log("ResponsibleUsers:", responsibleOptions);
    // console.log("AssetsUsers:", assetOptions);
  }, [responsibleOptions]);

  return (
    <Modal
      modalClass="subtask-settings-modal"
      itemName={subtask.name}
      message={modalMessage}
      header={header}
      handleCloseModal={handleClose}
      handleSubmit={handleSubmit}
      submitForm={submitForm}
      searchSelectName={searchSelectName ? searchSelectName : ""}
    >
      <div className="form-group">
        <label>Name</label>
        <div className="d-flex align-items-center">
          <Text
            defaultValue={subtask.name}
            label="Name"
            name="name"
            register={register}
            placeholder="Subtask Name"
            required={false}
            noHeader={true}
            card
          />
          <StatusDropDown
            name="status"
            register={register}
            subtask={subtask}
            setValue={setValue}
            required={true}
            card
          />
        </div>
      </div>
      <Number
        label="Version"
        name="version"
        register={register}
        className="form-control card-input"
        value={subtask.version}
        step="0.1"
        required={false}
        card
      />
      <StaticSelect
        isMulti={false}
        name="task"
        label="Associated task"
        options={taskOptions}
        control={control}
        required={true}
        card
      />
      <StaticSelect
        isMulti={true}
        name="responsible"
        label="Responsible"
        options={responsibleOptions}
        control={control}
        required={false}
        card
      />
      <Date
        name="startDate"
        label="Start Date"
        control={control}
        register={register}
        required={false}
        defaultValue={moment(subtask.startDate).utc().format("YYYY-MM-DD")}
        card
        theme={props.session.theme}
      />
      <Date
        name="endDate"
        label="End Date"
        control={control}
        register={register}
        required={false}
        defaultValue={moment(subtask.endDate).utc().format("YYYY-MM-DD")}
        card
        theme={props.session.theme}
      />
      <TextArea
        label="Description"
        name="description"
        register={register}
        placeholder="Update description"
        required={false}
        defaultValue={htmlContent}
        card
      />
      <TextArea
        label="Notes"
        name="notes"
        register={register}
        placeholder="Update notes"
        required={false}
        defaultValue={subtask.note?.message}
        card
      />
    </Modal>
  );
};

const mapStateToProps = ({ session, users, prototypes }) => ({
  session,
  users,
  prototypes,
});

const mapDispatchToProps = (dispatch) => ({
  allUserData: (data) => dispatch(allUserData(data)),
  allPrototypes: (data) => dispatch(allPrototypes(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Update);
